import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  isLoading: false,
  isReportFetched: false,
  orders: [],
  orderCount: 0,
  trackingIconsList: [],
  trackingIcons: [],
  teams: [],
  reportDetails: {},
  orderDetails: {},
  isOrderDetailsFetching: false,
  isOrderDetailsFetched: false,
  isTrackingIconUpdating: false,
  isTrackingIconUpdated: false,
  apiMessage: '',
  isPriceUpdating: false,
  isPriceUpdated: false,
  orderLogs: [],
  isOrderLogsFetching: false,
  isOrderLogsFetched: false,
  orderComments: [],
  isOrderCommentFetching: false,
  isOrderCommentFetched: false,
  isCommentPosting: false,
  isCommentPosted: false,
  isProvinceListFetched: false,
  provinceList: [],
  isBotDataUpdating: false,
  isBotDataUpdated: false,
  isStatusUpdating: false,
  isStatusUpdated: false,
  isFileUploading: false,
  isFileUploaded: false,
  file: '',
  isCreditApplicationDeleting: false,
  isCreditApplicationDeleted: false,
  companies: [],
  isCompaniesFetching: false,
  isCompaniesFetched: false,
  isCommentDeleted: false,
  isCommentDeleting: false,
  isEscDataFetching: false,
  isEscDataFetched: false,
  escData: {},
  isEscDataUpdating: false,
  isEscDataUpdated: false,
  isEscSearching: false,
  isEscSearched: false,
  isBankDetailsFetching: false,
  isBankDetailsFetched: false,
  isBankDetailsFetchError: false,
  bankDetails: {},
};

export const adminGetAllCreditReports = createAsyncThunk(
  `admin_credit_report`,
  async (payload) => {
    try {
      const res = await api.post(`report/list-order-admins`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getTrackingIcons = createAsyncThunk(`tracking_icons`, async () => {
  try {
    const res = await api.get(`order-tracker/list?language=en`);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getTeams = createAsyncThunk(`teams`, async () => {
  try {
    const res = await api.post(`dispatch/list`);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getReportDetails = createAsyncThunk(
  `report_details`,
  async (payload) => {
    try {
      const res = await api.post(`report/order-list-item-details`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getOrderDetails = createAsyncThunk(
  `order_details`,
  async (payload) => {
    try {
      const res = await api.post(`report/order-details`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const updateTrackingIcons = createAsyncThunk(
  `update_icons`,
  async (payload) => {
    try {
      const res = await api.post(`order-tracker/attach-to-order`, payload);
      const { data } = res;
      return data;
    } catch (error) {}
  }
);

export const updatePrice = createAsyncThunk(`update_price`, async (payload) => {
  try {
    const res = await api.post(`report/custom-price`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getActivityLog = createAsyncThunk(
  `activity_log`,
  async (payload) => {
    try {
      const res = await api.post(`report/order-logs`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getOrderComments = createAsyncThunk(
  `order_comments`,
  async (payload) => {
    try {
      const res = await api.post(`report/order-comments`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const postComment = createAsyncThunk(`post_comment`, async (payload) => {
  try {
    const res = await api.post(`report/comment-order`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getProvinceList = createAsyncThunk(
  `province_list`,
  async (payload) => {
    try {
      const res = await api.post(`province/list-province`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const updateBotData = createAsyncThunk(`update_bot`, async (payload) => {
  try {
    const res = await api.post(`report/edit-bot-api-data`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const updateReportStatus = createAsyncThunk(
  `update_report_status`,
  async (payload) => {
    try {
      const res = await api.post(`report/update-status`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const uploadCreditApplication = createAsyncThunk(
  `admin_upload_credit_application`,
  async (payload) => {
    try {
      const res = await api.post(`report/update-credit-application`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const deleteCreditApplication = createAsyncThunk(
  `admin_delete_credit_application`,
  async (payload) => {
    try {
      const res = await api.post(`report/update-credit-application`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getAllCompanies = createAsyncThunk(
  `get_companies`,
  async (payload) => {
    try {
      const res = await api.post(`company/list-companies`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const deleteComment = createAsyncThunk(
  `admin_delete_coment`,
  async (payload) => {
    try {
      const res = await api.post(`report/delete-comment`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getEscData = createAsyncThunk(
  `admin_get_esc_data`,
  async (payload) => {
    try {
      const res = await api.post(`report/get-esc-search-by-user`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const updateEscData = createAsyncThunk(
  `admin_update_esc_data`,
  async (payload) => {
    try {
      const res = await api.post(
        `report/update-incorporate-companies`,
        payload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const searchEscData = createAsyncThunk(
  `admin_search_esc_data`,
  async (payload) => {
    try {
      const res = await api.post(
        `report/search-incorporate-companies`,
        payload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getBankDetails = createAsyncThunk(
  `admin_get_bank_details`,
  async (payload) => {
    try {
      const res = await api.post(
        `banking/old-bank-notification-details`,
        payload
      );
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

const adminCreditReportSlice = createSlice({
  name: `adminCreditReport`,
  initialState,
  reducers: {
    resetAdminReport: (state) => {
      state.isTrackingIconUpdated = false;
      state.isPriceUpdated = false;
      state.isCommentPosted = false;
      state.isBotDataUpdated = false;
      state.isStatusUpdated = false;
      state.isFileUploaded = false;
      state.file = '';
      state.apiMessage = '';
      state.isCreditApplicationDeleted = false;
      state.isCommentDeleted = false;
      state.isEscDataUpdated = false;
      state.isEscSearched = false;
    },
  },
  extraReducers: {
    // get all reports for admin
    [adminGetAllCreditReports.pending]: (state) => {
      state.isLoading = true;
      state.isReportFetched = false;
    },
    [adminGetAllCreditReports.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isReportFetched = true;
      state.orders = action?.payload?.orders;
      state.orderCount = action?.payload?.count;
    },
    // get tracking icons
    [getTrackingIcons.pending]: (state) => {
      state.trackingIconsList = [];
      state.trackingIcons = [];
    },
    [getTrackingIcons.fulfilled]: (state, action) => {
      state.trackingIcons = action?.payload;
      state.trackingIconsList = action?.payload
        ?.map((icon) => icon?.indicator)
        ?.flat(Infinity);
    },
    // get teams
    [getTeams.pending]: (state) => {
      state.teams = [];
    },
    [getTeams.fulfilled]: (state, action) => {
      state.teams = action?.payload;
    },
    // report details
    [getReportDetails.pending]: (state) => {
      state.reportDetails = {};
    },
    [getReportDetails.fulfilled]: (state, action) => {
      state.reportDetails = action?.payload;
    },
    //order details
    [getOrderDetails.pending]: (state) => {
      state.orderDetails = {};
      state.isOrderDetailsFetching = true;
      state.isOrderDetailsFetched = false;
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      state.orderDetails = action?.payload;
      state.isOrderDetailsFetched = true;
      state.isOrderDetailsFetching = false;
    },
    // update tracking icons
    [updateTrackingIcons.pending]: (state) => {
      state.isTrackingIconUpdating = true;
      state.isTrackingIconUpdated = false;
      state.apiMessage = '';
    },
    [updateTrackingIcons.fulfilled]: (state, action) => {
      state.isTrackingIconUpdating = false;
      state.isTrackingIconUpdated = true;
      state.apiMessage = action?.payload?.message;
    },
    // update price
    [updatePrice.pending]: (state) => {
      state.isPriceUpdating = true;
      state.isPriceUpdated = false;
      state.apiMessage = '';
    },
    [updatePrice.fulfilled]: (state, action) => {
      console.log('🚀 ~ action:', action);
      state.isPriceUpdated = true;
      state.isPriceUpdating = false;
      state.apiMessage = action?.payload;
    },
    // activity log
    [getActivityLog.pending]: (state) => {
      state.orderLogs = [];
      state.isOrderLogsFetching = true;
      state.isOrderLogsFetched = false;
    },
    [getActivityLog.fulfilled]: (state, action) => {
      state.orderLogs = action?.payload?.logsList;
      state.isOrderLogsFetching = false;
      state.isOrderLogsFetched = true;
    },
    [getOrderComments.pending]: (state) => {
      state.orderComments = [];
      state.isOrderCommentFetching = true;
      state.isOrderCommentFetched = false;
    },
    [getOrderComments.fulfilled]: (state, action) => {
      state.orderComments = action?.payload?.reverse();
      state.isOrderCommentFetching = false;
      state.isOrderCommentFetched = true;
    },
    [postComment.pending]: (state) => {
      state.isCommentPosting = true;
      state.isCommentPosted = false;
    },
    [postComment.fulfilled]: (state, action) => {
      state.isCommentPosting = false;
      state.isCommentPosted = true;
    },
    [getProvinceList.pending]: (state) => {
      state.isProvinceListFetched = false;
      state.provinceList = [];
    },
    [getProvinceList.fulfilled]: (state, action) => {
      state.isProvinceListFetched = true;
      state.provinceList = action?.payload;
    },
    [updateBotData.pending]: (state) => {
      state.isBotDataUpdated = false;
      state.isBotDataUpdating = true;
    },
    [updateBotData.fulfilled]: (state, action) => {
      state.isBotDataUpdating = false;
      state.isBotDataUpdated = true;
      state.apiMessage = action?.payload;
    },
    [updateReportStatus.pending]: (state) => {
      state.isStatusUpdating = true;
      state.isStatusUpdated = false;
      state.apiMessage = '';
    },
    [updateReportStatus.fulfilled]: (state, action) => {
      state.isStatusUpdated = true;
      state.isStatusUpdating = false;
      state.apiMessage = action.payload;
    },
    [uploadCreditApplication.pending]: (state) => {
      state.isFileUploading = true;
      state.isFileUploaded = false;
      state.file = '';
    },
    [uploadCreditApplication.fulfilled]: (state, action) => {
      state.isFileUploaded = true;
      state.isFileUploading = false;
      state.apiMessage = action?.payload;
    },
    [deleteCreditApplication.pending]: (state) => {
      state.isCreditApplicationDeleting = true;
      state.isCreditApplicationDeleted = false;
    },
    [deleteCreditApplication.fulfilled]: (state, action) => {
      state.isCreditApplicationDeleting = false;
      state.isCreditApplicationDeleted = true;
      state.apiMessage = action?.payload;
    },
    [getAllCompanies.pending]: (state) => {
      state.isCompaniesFetching = true;
      state.isCompaniesFetched = false;
    },
    [getAllCompanies.fulfilled]: (state, action) => {
      state.isCompaniesFetching = false;
      state.isCompaniesFetched = true;
      state.companies = action?.payload?.reverse();
    },
    [deleteComment.pending]: (state) => {
      state.isCommentDeleting = true;
      state.isCommentDeleted = false;
    },
    [deleteComment.fulfilled]: (state, action) => {
      state.isCommentDeleting = false;
      state.isCommentDeleted = true;
      state.apiMessage = action?.payload;
    },
    [getEscData.pending]: (state) => {
      state.isEscDataFetching = true;
      state.isEscDataFetched = false;
    },
    [getEscData.fulfilled]: (state, action) => {
      state.isEscDataFetching = false;
      state.isEscDataFetched = true;
      state.escData = action?.payload;
    },
    [updateEscData.pending]: (state) => {
      state.isEscDataUpdating = true;
      state.isEscDataUpdated = false;
    },
    [updateEscData.fulfilled]: (state, action) => {
      state.isEscDataUpdating = false;
      state.isEscDataUpdated = true;
      state.apiMessage = action?.payload;
    },
    [searchEscData.pending]: (state) => {
      state.isEscSearching = true;
      state.isEscSearched = false;
    },
    [searchEscData.fulfilled]: (state, action) => {
      state.isEscSearching = false;
      state.isEscSearched = true;
    },
    [getBankDetails.pending]: (state) => {
      state.isBankDetailsFetching = true;
      state.isBankDetailsFetched = false;
      state.isBankDetailsFetchError = false;
    },
    [getBankDetails.fulfilled]: (state, action) => {
      console.log('🚀 ~ action:', action);
      state.isBankDetailsFetching = false;
      state.isBankDetailsFetched = true;
      state.bankDetails = action?.payload;
      state.isBankDetailsFetchError = false;
    },
    [getBankDetails.rejected]: (state) => {
      state.isBankDetailsFetching = false;
      state.isBankDetailsFetched = false;
      state.isBankDetailsFetchError = true;
    },
  },
});

export const { resetAdminReport } = adminCreditReportSlice.actions;

export default adminCreditReportSlice.reducer;
