import Button from '../../ReusableComponents/Button';

export const monitoringReportRenderer = (
  entity,
  header,
  action,
  type,
  translations
) => {
  if (header === `reportId`) {
    return <span className='report_id'>{entity?.reportId}</span>;
  }
  if (header === `subjectName`) {
    return <span className='subject_name'>{entity?.subjectName}</span>;
  }

  if (header === `reportDate`) {
    return (
      <span>
        {type === `owned`
          ? new Date(entity?.orderDate)?.toLocaleDateString('en-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : new Date(entity?.completionDate)?.toLocaleDateString('en-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
      </span>
    );
  }
  if (header === `reportType`) {
    return type === `owned` ? (
      entity?.reportTypes?.map((reportType) => (
        <span className='report_type'>{translations?.[reportType]}</span>
      ))
    ) : (
      <span className='report_type'>{entity?.reportType}</span>
    );
  }
  if (header === `actions`) {
    return (
      <span className='credit_risk_actions'>
        {type === `owned` && (
          <>
            {entity?.isCompleted ? (
              <Button
                type='seven'
                text={translations?.download}
                onClickHandler={() => action(type, entity)}
              />
            ) : (
              <span className='status Processing'>
                {translations?.processing}
              </span>
            )}
          </>
        )}
        {type === `available` && (
          <Button
            type='seven'
            text={translations?.buy_report}
            onClickHandler={() => action(type, entity)}
          />
        )}
      </span>
    );
  }
};
