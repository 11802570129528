import ReportIconOne from "../../images/report_icon_one.svg";
import ReportIconTwo from "../../images/report_icon_two.svg";
import ReportIconThree from "../../images/report_icon_three.svg";

import { AiOutlineDownload } from "react-icons/ai";

export const userCreditReportRenderer = (entity, header, translations) => {
  if (header === `reference_id`) {
    return <span>{entity?.reference_id}</span>;
  }
  if (header === `order_date`) {
    return <span>{new Date(entity?.order_date)?.toLocaleDateString()}</span>;
  }
  if (header === `subject_name`) {
    return <span>{entity?.subject_name}</span>;
  }
  if (header?.[0] === `user_name` && header?.[1] === `company_name`) {
    return (
      <span className="company_user_wrapper">
        <span className="user_name">{entity?.user_name}</span>
        <span className="company_name">{entity?.company_name?.en}</span>
      </span>
    );
  }
  if (header === `status`) {
    return <span className={entity?.status}>{entity?.status}</span>;
  }
  if (header === `report_status`) {
    return (
      <div className="user_credit_report_status">
        <span className="user_credit_status_box">
          {entity?.reports?.bank?.is_ordered === true && (
            <>
              <img src={ReportIconOne} alt={ReportIconOne} />
              <span>{translations.bank}</span>
            </>
          )}
          {entity?.reports?.incorporate?.is_ordered === true && (
            <>
              <img src={ReportIconOne} alt={ReportIconOne} />
              <span>{translations.incorporate}</span>
            </>
          )}
          {entity?.reports?.legal?.is_ordered === true && (
            <>
              <img src={ReportIconThree} alt={ReportIconThree} />
              <span>{translations.legal}</span>
            </>
          )}
          {entity?.reports?.scoring?.is_ordered === true && (
            <>
              <img src={ReportIconOne} alt={ReportIconOne} />
              <span>Scoring</span>
            </>
          )}
          {entity?.reports?.suppliers?.is_ordered === true && (
            <>
              <img src={ReportIconTwo} alt={ReportIconOne} />
              <span>{translations.suppliers}</span>
            </>
          )}
          {entity?.status === `Completed` && (
            <span className="final_report">
              <AiOutlineDownload /> {translations.final_report}
            </span>
          )}
        </span>
      </div>
    );
  }
  if (header === `action`) {
    return (
      <div className="credit_report_action_wrapper">
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.799805 1L6.7998 7L12.7998 1"
            stroke="#6D738D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }
};
