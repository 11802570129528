import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableComponents/Button';
import Table from '../../ReusableComponents/Table';

const CreditReports = ({
  reportType,
  setReportType,
  reports,
  isLoading,
  onReportAction,
  onOrderReportHandler,
  isButtonLoading,
}) => {
  const { t } = useTranslation();

  const tableProps = [
    {
      name: `${t(`report_id`)}`,
      dataIndex: 'reportId',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`subject_name`)}`,
      dataIndex: 'subjectName',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: false,
      isResizable: true,
    },
    {
      name: `${t(`report_date`)}`,
      dataIndex: 'reportDate',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`report_type`)}`,
      dataIndex: 'reportType',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: false,
      isResizable: true,
    },
    {
      name: '',
      dataIndex: 'actions',
      minimumWidth: '20rem',
      initialWidth: '1fr',
      isSortable: false,
      isResizable: false,
    },
  ];

  return (
    <>
      <div className='credit_risk_reports_wrapper'>
        <div className='credit_report_actions'>
          <div className='report_selector_wrapper'>
            <div
              className={`report_selector ${
                reportType === `owned` ? `report_selected` : null
              }`}
              onClick={() => setReportType(`owned`)}
            >{`${t(`owned_reports`)}`}</div>
            <div
              className={`report_selector ${
                reportType === `available` ? `report_selected` : null
              }`}
              onClick={() => setReportType(`available`)}
            >{`${t(`available_reports`)}`}</div>
          </div>
          <Button
            type='primary'
            text={`${t(`order_report`)}`}
            onClickHandler={onOrderReportHandler}
            isLoading={isButtonLoading}
          />
        </div>
      </div>
      <Table
        tableProps={tableProps}
        from={`credit_risk_${reportType}_reports`}
        isLoading={isLoading}
        tableData={reports}
        defaultColumnHeight={'5rem'}
        action={onReportAction}
      />
    </>
  );
};

export default CreditReports;
