export const historicalDataRenderer = (entity, header) => {
  if (header === `clientId`) {
    return <span className='client_id'>{entity?.clientId}</span>;
  }
  if (header === `subjectName`) {
    return <span className='subject_name'>{entity?.subjectName}</span>;
  }

  if (header === `risk`) {
    return <span className={`risk risk_${entity?.risk}`}>{entity?.risk}</span>;
  }

  if (header === `score`) {
    return (
      <span className={`score score_${entity?.risk}`}>{entity?.score}</span>
    );
  }

  if (header === `lastUpdatedOn`) {
    return (
      <span>
        {new Date(entity?.lastUpdatedOn)?.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </span>
    );
  }
};
