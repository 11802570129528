import React, { useCallback, useEffect, useRef, useState } from "react";
import ReportSelection from "../OrderReport/ReportSelection";
import BankOptions from "../OrderReport/BankOptions";
import Select from "../../ReusableComponents/Select";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../Utils/utils";
import Input from "../../ReusableComponents/Input";
import { orderReportViaCreditApplication } from "../../slices/creditApplicationSlice";
import Persons from "./Persons";

const OrderReport = ({
  isOrderReport,
  clientOptions,
  creditAppId,
  isConsumer,
  reportOptions,
  primaryReports,
  setPrimaryReports,
  secondaryReport,
  setSecondaryReport,
  isAllOptionsSelected,
  setIsAllOptionsSelected,
  reportErrors,
  setReportErrors,
  referenceNumber,
  setReferenceNumber,
  companyId,
  setCompanyId,
  bankType,
  setBankType,
}) => {
  const { t } = useTranslation();
  const bankRef = useRef();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!isOrderReport) {
  //     setIsAllOptionsSelected(false);
  //     setPrimaryReports([]);
  //     setSecondaryReport("");
  //     setBankType("");
  //     setOtherBankType("");
  //     setErrors({});
  //   }
  // }, [isOrderReport]);

  // useEffect(() => {
  //   if (primaryReports?.includes(`Bank`)) {
  //     bankRef.current?.scrollIntoView({
  //       behaviour: `smooth`,
  //       block: `center`,
  //     });
  //     if (primaryReports?.length !== 4) {
  //       setIsAllOptionsSelected(false);
  //     }
  //     if (primaryReports?.length === 4) {
  //       setIsAllOptionsSelected(true);
  //     }
  //   }
  // }, [primaryReports, bankRef]);

  // const orderReport = useCallback(() => {
  //   const payload = {
  //     company_id: clientId,
  //     credit_application_id: creditAppId,
  //     ordered_report:
  //       primaryReports?.length > 0 ? [...primaryReports] : [secondaryReport],
  //     reference_id: referenceNumber,
  //     bank_option: bankType || otherBankType,
  //   };
  //   dispatch(orderReportViaCreditApplication(payload));
  // }, [
  //   clientId,
  //   primaryReports,
  //   bankType,
  //   otherBankType,
  //   secondaryReport,
  //   referenceNumber,
  //   creditAppId,
  //   dispatch,
  // ]);

  // validation
  // useEffect(() => {
  //   if (isSubmitOrderReport) {
  //     if (isEmpty(clientId)) {
  //       setErrors((errors) => ({
  //         ...errors,
  //         client: `${t(`client`)} ${t(`is_required`)}`,
  //       }));
  //     }
  //     if (primaryReports?.length === 0 && isEmpty(secondaryReport)) {
  //       setErrors((errors) => ({
  //         ...errors,
  //         report: `${t(`report`)} ${t(`is_required`)}`,
  //       }));
  //     }
  //     if (primaryReports?.includes(`Bank`)) {
  //       if (isEmpty(bankType) && isEmpty(otherBankType)) {
  //         setErrors((errors) => ({ ...errors, bank: `${t(`bank_error`)}` }));
  //       }
  //     }
  //     if (
  //       !isEmpty(clientId) &&
  //       (primaryReports?.length > 0 || !isEmpty(secondaryReport))
  //     ) {
  //       if (primaryReports?.includes(`Bank`)) {
  //         if (!isEmpty(bankType || !isEmpty(otherBankType))) {
  //           orderReport();
  //         }
  //       } else {
  //         orderReport();
  //       }
  //     }
  //   }
  // }, [
  //   isSubmitOrderReport,
  //   clientId,
  //   primaryReports,
  //   secondaryReport,
  //   bankType,
  //   otherBankType,
  //   orderReport,
  //   t,
  // ]);

  const onSelection = (optionValue) => {
    // setClientId(optionValue);
    // setErrors((errors) => ({ ...errors, client: "" }));
  };

  const onInputChange = (e) => setReferenceNumber(e.target.value);

  return (
    <div className="credit_application_order_report_wrapper order_new_report_components">
      {JSON.stringify(primaryReports)}-{JSON.stringify(secondaryReport)}-
      {JSON.stringify(bankType)}
      <div className="credit_application_report_fields">
        <Select
          options={clientOptions}
          text={`${t(`select_client`)}`}
          // isError={errors?.client}
          onOptionSelection={onSelection}
          onOptionCallback={() => {}}
          onBlur={() => {}}
          // errorMessage={errors?.client}
          isClosed={isEmpty(companyId)}
        />
        <Input
          text={`${t(`reference_number`)}`}
          onChange={onInputChange}
          value={referenceNumber}
          onBlur={() => {}}
        />
      </div>
      <div className="report_options_wrapper">
        <ReportSelection
          primaryReports={primaryReports}
          setPrimaryReports={setPrimaryReports}
          secondaryReport={secondaryReport}
          setSecondaryReport={setSecondaryReport}
          isAllOptionsSelected={
            isAllOptionsSelected || primaryReports?.length === 4
          }
          setIsAllOptionsSelected={setIsAllOptionsSelected}
          from="creditApplication"
          reportErrors={reportErrors}
          setReportErrors={setReportErrors}
          reportOptions={reportOptions}
          isFromCreditApplication={true}
          location={`canada`}
        />
        {primaryReports?.includes(`Bank`) && (
          <div ref={bankRef}>
            <BankOptions
              bankType={bankType}
              setBankType={setBankType}
              reportErrors={reportErrors}
              setReportErrors={setReportErrors}
              reportOptions={reportOptions}
              isFromCreditApplication={true}
            />
          </div>
        )}
        <Persons reportOptions={reportOptions} />
      </div>
    </div>
  );
};

export default OrderReport;
