import React, { useEffect, useState } from 'react';
import TableSelect from '../../ReusableComponents/TableSelect';
import TableInput from '../../ReusableComponents/TableInput';
import TableDatePicker from '../../ReusableComponents/Datepicker/TableDatePicker';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableComponents/Button';
import Table from '../../ReusableComponents/Table';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCompaniesList,
  getCreditApplications,
  getReportOptions,
  resetCreditApplicationStore,
  sendCreditApplicationFile,
  sendCreditApplicationForm,
} from '../../slices/creditApplicationSlice';
import { useDebounce } from '../../hooks/useDebounce';
import { isEmpty } from '../../Utils/utils';
import ActionPopup from '../../ReusableComponents/ActionPopup';
import ApplicationForm from './ApplicationForm';
import ToastMessage from '../../ReusableComponents/ToastMessage';
import OrderReport from './OrderReport';
import ResendEmail from './ResendEmail';
import { BASE_URL } from '../../constants/config';
import useFormValidation from '../../hooks/useFormValidation';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../ReusableComponents/Pagination';

const Application = ({ isAdmin, isUser, isFromMonitoring, clientName }) => {
  const { onSubmit } = useFormValidation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isLoading,
    creditApplications,
    applicationCount,
    clients,
    configurations,
    isConfigurationFetching,
    isConfigurationfetched,
    isApplicationSending,
    isApplicationSent,
    isApplicationSendError,
    apiMessage,
    isReportOrdering,
    isReportOrdered,
    isReportOrderError,
    isReportOptionsFetching,
    isReportOptionsFetched,
    isReportOptionsError,
    isEmailSending,
    isEmailSent,
    reportOptions,
  } = useSelector((state) => state.creditApplication);

  const { userCompanyId, userCompany, userCompanies } = useSelector(
    (state) => state.auth
  );
  const { isEN } = useSelector((state) => state.common);

  const [statusOptions, setStatusOptions] = useState([
    {
      name: `${t(`application_opened`)}`,
      value: `opened`,
      isSelected: false,
    },
    {
      name: `${t(`application_submitted`)}`,
      value: `submitted`,
      isSelected: false,
    },
    {
      name: `${t(`application_sent`)}`,
      value: `sent`,
      isSelected: false,
    },
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [statusFilters, setStatusFilters] = useState([]);
  const [isFilteredByStatus, setIsFilteredByStatus] = useState(false);
  const [listFilters, setListFilters] = useState({
    search: '',
    start_date: null,
    end_date: null,
    status: '',
    limit: 25,
    skip: 0,
  });
  const [isCreditApplication, setIsCreditApplication] = useState(false);
  const [isSubmitCreditApplication, setIsSubmitCreditApplication] =
    useState(false);
  const [isOrderReport, setIsOrderReport] = useState(false);
  const [isSubmitOrderReport, setIsSubmitOrderReport] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [creditAppId, setCreditAppId] = useState('');
  const [isConsumer, setIsConsumer] = useState(false);
  const [rowIndex, setRowIndex] = useState('');
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [isSubmitResendEmail, setIsSubmitResendEmail] = useState(false);
  const [sortingIndex] = useState('');
  const [application, setApplication] = useState({
    isSingle: true,
    language: `en`,
    referenceNumber: '',
    creditFile: '',
    fileError: '',
  });
  const [applications, setApplications] = useState([]);
  const [individualFields, setIndividualFields] = useState([
    {
      value: isUser ? userCompanyId : '',
      name: 'company_id',
      text: `${t(`account`)}`,
      format: `generic`,
      isRequired: true,
      isError: false,
      isValid: false,
      errorMessage: '',
      type: `select`,
    },
    {
      value: '',
      name: 'configuration_id',
      text: `${t(`credit_application`)}`,
      format: `generic`,
      isRequired: true,
      isError: false,
      isValid: false,
      errorMessage: '',
      type: `select`,
    },
    {
      value: isFromMonitoring ? clientName : '',
      name: 'subject_name',
      format: `generic`,
      text: `${t(`subject_name`)}`,
      isRequired: true,
      isError: false,
      isValid: false,
      errorMessage: '',
      isWarning: false,
    },
    {
      value: '',
      name: 'email_id',
      format: `email`,
      text: `${t(`subject_email`)}`,
      isRequired: true,
      isError: false,
      isValid: false,
      errorMessage: '',
      isWarning: false,
    },
  ]);
  const [isAllOptionsSelected, setIsAllOptionsSelected] = useState(false);
  const [primaryReports, setPrimaryReports] = useState([]);
  const [secondaryReport, setSecondaryReport] = useState();
  const [reportErrors, setReportErrors] = useState({});
  const [referenceNumber, setReferenceNumber] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [bankType, setBankType] = useState('');
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);

  const debouncedSearchTerm = useDebounce(searchTerm, 700);

  // on search
  useEffect(() => {
    if (!isEmpty(debouncedSearchTerm)) {
      setListFilters((filters) => ({
        ...filters,
        skip: 0,
        search: debouncedSearchTerm,
      }));
      setIsSearched(true);
    }
  }, [debouncedSearchTerm]);

  //remove search
  useEffect(() => {
    if (isSearched && isEmpty(debouncedSearchTerm)) {
      setListFilters((filters) => ({ ...filters, skip: 0, search: '' }));
    }
  }, [isSearched, debouncedSearchTerm]);

  // start date
  useEffect(() => {
    if (startDate) {
      if (endDate) {
        setListFilters((filters) => ({
          ...filters,
          start_date: startDate,
          end_date: endDate,
        }));
      }
    }
  }, [startDate, endDate]);

  // status filters
  useEffect(() => {
    if (statusFilters?.length > 0) {
      setIsFilteredByStatus(true);
      setListFilters((filters) => ({ ...filters, status: statusFilters }));
    }
  }, [statusFilters]);
  // remove status filters
  useEffect(() => {
    if (isFilteredByStatus && statusFilters?.length === 0) {
      setListFilters((filters) => ({ ...filters, status: '' }));
    }
  }, [isFilteredByStatus, statusFilters]);

  // credit applications list for admin
  useEffect(() => {
    isAdmin && dispatch(getCreditApplications(listFilters));
  }, [dispatch, listFilters, isAdmin]);

  useEffect(() => {
    if (isUser) {
      const payload = listFilters;
      payload.credit_application_id = userCompanyId;
      !isFromMonitoring && dispatch(getCreditApplications(payload));
    }
  }, [dispatch, listFilters, userCompanyId, isFromMonitoring, isUser]);

  // companies list
  useEffect(() => {
    isAdmin &&
      dispatch(
        getAllCompaniesList({
          include_sub_companies: true,
          sort_by: `company_name`,
        })
      );
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (isAdmin && clients?.length > 0) {
      const options = clients
        ?.filter((client) => client?.is_active && !client?.is_deleted)
        ?.map((client) => {
          return {
            name: `${client?.company_name} ${client?.reference_id}`,
            value: client?._id,
          };
        });
      setClientOptions(options);
    }
    if (isUser) {
      if (isFromMonitoring) {
        const options = userCompanies?.map((company) => ({
          name: isEN ? company?.company_name?.en : company?.company_name?.fr,
          value: company?._id,
        }));
        setClientOptions(options);
      } else {
        const option = [
          {
            name: isEN ? userCompany?.en : userCompany?.fr,
            value: userCompanyId,
          },
        ];
        setClientOptions(option);
      }
    }
  }, [
    clients,
    isAdmin,
    isUser,
    userCompanyId,
    userCompany,
    isFromMonitoring,
    userCompanies,
    isEN,
  ]);

  // on send application success
  useEffect(() => {
    if (isApplicationSent) {
      setIsCreditApplication(false);
      setIndividualFields((fields) =>
        fields?.map((field) => {
          return {
            ...field,
            isValid: false,
            isError: false,
            isWarning: false,
            errorMessage: '',
            value: isUser && field?.name === `company_id` ? field?.value : '',
          };
        })
      );
      setApplication((prev) => ({
        ...prev,
        referenceNumber: '',
        creditFile: '',
        language: `en`,
        isSingle: true,
      }));
    }
  }, [isApplicationSent, isUser]);

  // on report order success
  useEffect(() => {
    if (isReportOrdered) {
      setTimeout(() => {
        onCloseOrderReport();
        dispatch(getCreditApplications(listFilters));
      }, 2750);
    }
  }, [isReportOrdered, dispatch, listFilters]);

  useEffect(() => {
    if (isReportOrderError) {
      setIsSubmitOrderReport(false);
    }
  }, [isReportOrderError]);

  useEffect(() => {
    if (isReportOptionsFetched) {
      setRowIndex('');
      setIsOrderReport(true);
    }
  }, [isReportOptionsFetched]);

  // on email sent
  useEffect(() => {
    if (isEmailSent) {
      onCloseResendEmail();
    }
  }, [isEmailSent]);

  const tableProps = [
    {
      name: `${t(`credit_app_id`)}`,
      dataIndex: 'credit_app_id',
      minimumWidth: '14rem',
      initialWidth: '14rem',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`date`)}`,
      dataIndex: 'date',
      minimumWidth: '10rem',
      initialWidth: '10rem',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`subject`)}`,
      dataIndex: 'subject',
      minimumWidth: '18rem',
      initialWidth: '0.75fr',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`requested_by`)}`,
      dataIndex: 'requested_by',
      minimumWidth: '20rem',
      initialWidth: '0.5fr',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`status`)}`,
      dataIndex: 'status',
      minimumWidth: '8rem',
      initialWidth: '8rem',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`bank_connection`)}`,
      dataIndex: 'bank_connection',
      minimumWidth: '12rem',
      initialWidth: '12rem',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`reports_ordered`)}`,
      dataIndex: 'reports_ordered',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: false,
      isResizable: false,
    },
    {
      name: `${t(`actions`)}`,
      dataIndex: 'actions',
      minimumWidth: '38rem',
      initialWidth: '38rem',
      isSortable: false,
      isResizable: false,
    },
  ];

  const onDownload = (entity) => {
    const path = `${BASE_URL}/${
      isEN ? entity?.creditApplication?.en : entity?.creditApplication?.fr
    }`;
    window.open(path);
  };

  const onView = (creditApplicationId) => {
    navigate(`/application-details/${creditApplicationId}`);
  };

  const onOrderReport = (entity, entityIndex) => {
    //65f0728c2a2ec3d5d9b368c6
    setRowIndex(entityIndex);
    setCreditAppId(entity?._id);
    setIsConsumer(entity?.creditApplicationType === `consumer`);
    entity?._id &&
      dispatch(getReportOptions(`647a1083142016244217c174` ?? entity?._id));
  };

  const onResendEmailHandler = (entityId) => {
    setCreditAppId(entityId);
    setIsResendEmail(true);
  };

  const onCloseResendEmail = () => {
    setIsResendEmail(false);
    setCreditAppId('');
    setIsSubmitResendEmail(false);
  };

  const onCloseCreditApplication = () => {
    setIndividualFields((fields) =>
      fields?.map((field) => {
        return {
          ...field,
          isError: false,
          isValid: false,
          isWarning: false,
          value: isUser ? field?.value : '',
          errorMessage: '',
        };
      })
    );
    setIsCreditApplication(false);
    setApplication((prev) => ({
      ...prev,
      referenceNumber: '',
      isSingle: true,
      language: `en`,
      creditFile: '',
    }));
  };

  const onSendCreditApplicationHandler = () => {
    setIsCreditApplication(true);
  };

  const onSubmitCreditApplication = () => {
    const isValid = application?.isSingle
      ? onSubmit(individualFields, setIndividualFields, `levelOne`)
      : onSubmit(individualFields, setIndividualFields, `levelOne`) &&
        !isEmpty(application?.creditFile?.name);
    !isValid &&
      isEmpty(application?.creditFile?.name) &&
      setApplication((prev) => ({
        ...prev,
        fileError: `${t(`please_upload_file`)}`,
      }));
    if (isValid) {
      const payload = individualFields?.reduce(
        (obj, { name, value }) => ({ ...obj, [name]: value }),
        {}
      );
      if (application?.isSingle) {
        payload.language = application?.language;
        payload.reference_number = application?.referenceNumber;
        dispatch(sendCreditApplicationForm(payload));
      } else {
        delete payload.reference_number;
        delete payload.email_id;
        delete payload.subject_name;
        payload.file = application?.creditFile;
        const formData = new FormData();
        for (let key in payload) {
          formData.append(key, payload[key]);
        }
        dispatch(sendCreditApplicationFile(formData));
      }
    }
  };

  const onToastCallback = () => {
    dispatch(resetCreditApplicationStore());
  };

  const onCloseOrderReport = () => {
    setIsOrderReport(false);
    setCreditAppId('');
    setPrimaryReports([]);
    setSecondaryReport('');
    setReferenceNumber('');
    setBankType('');
    setIsAllOptionsSelected(false);
    setCompanyId('');
  };

  const onSubmitOrderReport = () => {
    setIsSubmitOrderReport(true);
  };

  const onResendEmail = () => {
    setIsSubmitResendEmail(true);
  };

  const onSorting = () => {};

  const onApplicationChange = (type, value) => {
    setApplication((prev) => ({
      ...prev,
      [type]: value,
      fileError: type === `creditFile` && '',
    }));
    if (type === `isSingle`) {
      setIndividualFields((fields) =>
        fields?.map((field) => {
          return {
            ...field,
            isError:
              (field?.name === `configuration_id` && field?.errorMessage) ||
              false,
            isValid: false,
            errorMessage:
              field?.name === `configuration_id` ? field?.errorMessage : '',
            value: [`company_id`, `configuration_id`]?.includes(field?.name)
              ? field?.value
              : '',
            isRequired: [`subject_name`, `email_id`]?.includes(field?.name)
              ? value
              : true,
          };
        })
      );
      setApplication((prev) => ({ ...prev, fileError: '', creditFile: '' }));
    }
  };

  return (
    <>
      {apiMessage && (
        <ToastMessage
          type={
            isApplicationSent || isReportOrdered || isEmailSent
              ? 'success'
              : isApplicationSendError ||
                isReportOrderError ||
                isReportOptionsError
              ? `error`
              : null
          }
          message={apiMessage}
          action={onToastCallback}
        />
      )}

      <div
        className={`${
          isFromMonitoring ? '' : `credit_applications_filter_wrapper`
        }  listing_filters_wrapper`}
      >
        {!isFromMonitoring && (
          <>
            <TableInput
              placeholder={`${t(`search`)}`}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            <TableSelect
              options={statusOptions}
              placeholder={`${t(`status`)}`}
              updateOptions={setStatusOptions}
              updateFilters={setStatusFilters}
              updateAppliedFilters={() => {}}
              optionCount={statusFilters?.length}
            />
            <span className='table_date_wrapper'>
              <span className='date_range'>Date Range</span>
              <TableDatePicker
                text={`${t(`from`)}`}
                dateHandler={setStartDate}
                // isRemoveDate={isRemoveDateFilter}
                // removeDateHandler={setIsRemoveDateFilter}
              />
              <TableDatePicker
                text={`${t(`to`)}`}
                dateHandler={setEndDate}
                // isRemoveDate={isRemoveDateFilter}
                // removeDateHandler={setIsRemoveDateFilter}
              />
            </span>{' '}
          </>
        )}

        <div className='credit_application_extras'>
          {!isFromMonitoring && (
            <div className='credit_application_sent'>
              <span>{`${t(`sent`)}`}</span>
              <span>51/60</span>
            </div>
          )}
          <div className='credit_application_btn'>
            <Button
              type='primary'
              text={`${t(`send_credit_application`)}`}
              onClickHandler={onSendCreditApplicationHandler}
            />
          </div>
        </div>
      </div>
      {!isFromMonitoring && (
        <>
          <Table
            from={`credit_applications`}
            isLoading={isLoading}
            defaultColumnHeight={`7.5rem`}
            tableProps={tableProps}
            tableData={creditApplications}
            action={onDownload}
            action2={onView}
            action3={onOrderReport}
            action4={onResendEmailHandler}
            isButtonOneLoading={isReportOptionsFetching}
            rowIndex={rowIndex}
            onSorting={onSorting}
            sortingIndex={sortingIndex}
            isSorted={listFilters?.is_desc}
          />
          <Pagination
            total={applicationCount}
            limit={listFilters?.limit}
            setListFilters={setListFilters}
            selectedPageNumber={selectedPageNumber}
            setSelectedPageNumber={setSelectedPageNumber}
          />
        </>
      )}

      {/* send credit application */}
      <ActionPopup
        isVisible={isCreditApplication}
        onCancel={onCloseCreditApplication}
        onSubmit={onSubmitCreditApplication}
        from={`send_credit_application`}
        headerText={`${t(`send_credit_application`)}`}
        buttonText1={`${t(`cancel`)}`}
        buttonText2={`${t(`send_credit_application`)}`}
        isLoading={isApplicationSending}
      >
        <ApplicationForm
          isCreditApplication={isCreditApplication}
          isSubmitCreditApplication={isSubmitCreditApplication}
          setIsSubmitCreditApplication={setIsSubmitCreditApplication}
          clientOptions={clientOptions}
          configurations={configurations}
          isConfigurationFetching={isConfigurationFetching}
          isConfigurationfetched={isConfigurationfetched}
          isUser={isUser}
          application={application}
          onApplicationChange={onApplicationChange}
          applications={applications}
          setApplications={setApplications}
          individualFields={individualFields}
          setIndividualFields={setIndividualFields}
          isFromMonitoring={isFromMonitoring}
        />
      </ActionPopup>
      {/* order report */}
      <ActionPopup
        isVisible={isOrderReport}
        onCancel={onCloseOrderReport}
        onSubmit={onSubmitOrderReport}
        from={`credit_application_order_report`}
        headerText={`${t(`order_report_through_credit_application`)}`}
        buttonText1={`${t(`cancel`)}`}
        buttonText2={`${t(`save_changes`)}`}
        isLoading={isReportOrdering}
      >
        <OrderReport
          isOrderReport={isOrderReport}
          isSubmitOrderReport={isSubmitOrderReport}
          setIsSubmitOrderReport={setIsSubmitOrderReport}
          clientOptions={clientOptions}
          creditAppId={creditAppId}
          isConsumer={isConsumer}
          reportOptions={reportOptions}
          primaryReports={primaryReports}
          setPrimaryReports={setPrimaryReports}
          secondaryReport={secondaryReport}
          setSecondaryReport={setSecondaryReport}
          isAllOptionsSelected={isAllOptionsSelected}
          setIsAllOptionsSelected={setIsAllOptionsSelected}
          reportErrors={reportErrors}
          setReportErrors={setReportErrors}
          referenceNumber={referenceNumber}
          setReferenceNumber={setReferenceNumber}
          companyId={companyId}
          setCompanyId={setCompanyId}
          bankType={bankType}
          setBankType={setBankType}
        />
      </ActionPopup>
      {/* resend email */}
      <ActionPopup
        isVisible={isResendEmail}
        onCancel={onCloseResendEmail}
        onSubmit={onResendEmail}
        from={`credit_application_resend_email`}
        headerText={`${t(`resend_email`)}`}
        buttonText1={`${t(`cancel`)}`}
        buttonText2={`${t(`send`)}`}
        isLoading={isEmailSending}
      >
        <ResendEmail
          isSubmitResendEmail={isSubmitResendEmail}
          setIsSubmitResendEmail={setIsSubmitResendEmail}
          creditAppId={creditAppId}
          isResendEmail={isResendEmail}
        />
      </ActionPopup>
    </>
  );
};

export default Application;
