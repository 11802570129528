import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';
import { get, set } from 'idb-keyval';

const initialState = {
  isLoading: false,
  companies: [],
  clientCount: 0,
  users: [],
  isClientDetailsFetching: false,
  isClientDetailsFetched: false,
  clientDetails: {},
  isUserAdding: false,
  isUserAdded: false,
  isUserAddError: false,
  apiMessage: '',
  userInformation: '',
  isUserInformationFetching: false,
  isUserInformationFetched: false,
  adminInformation: '',
  isAdminInformationFetching: false,
  isAdminInformationFetched: false,
  isUserUpdating: false,
  isUserUpdated: false,
  isUserUpdateError: false,
  isUserAccepting: false,
  isUserAccepted: false,
  isInviteSending: false,
  isInviteSent: false,
  isEmailSending: false,
  isEmailSent: false,
  isEmailSendError: false,
  isUserStatusUpdating: false,
  isUserStatusUpdated: false,
  isUserSimulating: false,
  isUserSimulated: false,
  simulatedUser: '',
  agingList: [],
  industries: [],
  priceChart: [],
  groups: [],
  isAgingUploading: false,
  isAgingUploaded: false,
  isAgingUploadError: false,
  isClientAdding: false,
  isClientAdded: false,
  isClientAddError: false,
  isClientUpdating: false,
  isClientUpdated: false,
  isClientUpdateError: false,
  isSubClientAdding: false,
  isSubClientAdded: false,
  isSubClientAddError: false,
  isSubClientUpdating: false,
  isSubClientUpdated: false,
  isSubClientUpdateError: false,
  userCompanies: [],
  isSubAdminAdding: false,
  isSubAdminAdded: false,
  isSubAdminAddError: false,
  isSubAdminUpdating: false,
  isSubAdminUpdated: false,
  isSubAdminUpdateError: false,
  isLinkSending: false,
  isLinkSent: false,
  isDeleting: false,
  isDeleted: false,
  isSummaryFetching: false,
  isSummaryFetched: false,
  summaryDetails: '',
  isCreditReportFetching: false,
  isCreditReportFetched: false,
  creditReport: {},
  isDatabaseReportFetching: false,
  isDatabaseReportFetched: false,
  databaseReport: {},
  emailMessage: '',
};

export const getCompaniesList = createAsyncThunk(
  `admin_client`,
  async (payload) => {
    try {
      const res = await api.post(`company/list-companies-paging`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);
export const getUsersList = createAsyncThunk(
  `admin_client_users`,
  async (payload) => {
    try {
      const res = await api.post(`user/list-user`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getClientDetails = createAsyncThunk(
  `admin_client_company_details`,
  async (payload) => {
    try {
      const res = await api.post(`company/company-detail`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const addUser = createAsyncThunk(`add_user`, async (payload) => {
  try {
    const res = await api.post(`user/create-user`, payload);
    const { message } = res?.data;
    return message;
  } catch (error) {
    const { message } = error?.response?.data;
    throw new Error(`${message}`);
  }
});

export const getUserDetails = createAsyncThunk(
  `admin_client_user_details`,
  async (payload) => {
    try {
      const res = await api.post(`user/user-details`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getAdminDetails = createAsyncThunk(
  `admin_details`,
  async (payload) => {
    try {
      const res = await api.post(`user/user-details`, payload);
      const user = await get(`user`);
      const { data } = res?.data;
      user.user_data = data;
      await set(`user`, user);
      return data;
    } catch (error) {}
  }
);

export const updateUser = createAsyncThunk(`update_user`, async (payload) => {
  try {
    const res = await api.post(`user/update-user`, payload);
    const data = res?.data;
    return data;
  } catch (error) {
    const { message } = error?.response?.data;
    throw new Error(`${message}`);
  }
});

export const acceptUser = createAsyncThunk(
  `admin_accept_user`,
  async (payload) => {
    try {
      const res = await api.post(
        `user/update-user-verification-status`,
        payload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const resendInvite = createAsyncThunk(
  `admin_resend_invite`,
  async (payload) => {
    try {
      const res = await api.post(`user/generate-external-link`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const sendPasswordEmail = createAsyncThunk(
  `admin_password_email`,
  async (payload) => {
    try {
      const res = await api.post(`user/forgot-password`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  `admin_client_update_user_status`,
  async (payload) => {
    try {
      const res = await api.post(`user/update-user-activation-status`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const simulateUser = createAsyncThunk(
  `admin_client_simulate_user`,
  async (payload) => {
    try {
      const res = await api.post(`user/simulate-user`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getAgingList = createAsyncThunk(
  `admin_client_aging_list`,
  async (payload) => {
    try {
      const res = await api.post(`aging/aging-list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getIndustryList = createAsyncThunk(
  `admin_client_industry_list`,
  async (payload) => {
    try {
      const res = await api.post(`industry/list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);
export const getPriceList = createAsyncThunk(
  `admin_client_pricing_list`,
  async (payload) => {
    try {
      const res = await api.post(`pricing/list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getGroups = createAsyncThunk(
  `admin_client_group_list`,
  async (payload) => {
    try {
      const res = await api.post(`group/list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const uploadAging = createAsyncThunk(
  `admin_client_upload_aging`,
  async (payload) => {
    try {
      const res = await api.post(`aging/upload-user-aging`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const addClient = createAsyncThunk(
  `admin_client_add_client`,
  async (payload) => {
    try {
      const res = await api.post(`company/add-company`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateClient = createAsyncThunk(
  `admin_client_update_client`,
  async (payload) => {
    try {
      const res = await api.post(`company/update-company`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const addSubClient = createAsyncThunk(
  `admin_client_add_sub_client`,
  async (payload) => {
    try {
      const res = await api.post(`company/add-sub-company`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateSubClient = createAsyncThunk(
  `admin_client_update_sub_client`,
  async (payload) => {
    try {
      const res = await api.post(`company/update-sub-company`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getSubAdmins = createAsyncThunk(
  `admin_sub_admins_list`,
  async (payload) => {
    try {
      const res = await api.post(`user/list-sub-admin`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const addSubAdmin = createAsyncThunk(
  `add_sub_admin`,
  async (payload) => {
    try {
      const res = await api.post(`user/create-sub-admin`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateSubAdmin = createAsyncThunk(
  `update_sub_admin`,
  async (payload) => {
    try {
      const res = await api.post(`user/update-user`, payload);
      const data = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const generateLink = createAsyncThunk(
  `generate_link`,
  async (payload) => {
    try {
      const res = await api.post(`user/generate-external-link`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const removeSubAdmin = createAsyncThunk(
  `remove_sub_admin`,
  async (payload) => {
    try {
      const res = await api.post(`user/delete-user`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getSummary = createAsyncThunk(
  `admin_client_summary`,
  async (payload) => {
    try {
      const finalPayload = { ...payload };
      delete finalPayload.endpoint;
      const res = await api.post(
        `transaction/${payload?.endpoint}`,
        finalPayload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getCreditReports = createAsyncThunk(
  `admin_transaction_client_credit_reports`,
  async (payload) => {
    try {
      const res = await api.post(`transaction/report-order-list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getDatabaseReports = createAsyncThunk(
  `admin_transaction_client_database_reports`,
  async (payload) => {
    try {
      const res = await api.post(`transaction/database-order-list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const sendLinkEmail = createAsyncThunk(
  `admin_send_email`,
  async (payload) => {
    try {
      const res = await api.post(`report/send-external-form-mail`, payload);
      const { data } = res?.data;
      console.log('🚀 ~ data:', data);
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const sendBankFaxEmail = createAsyncThunk(
  `admin_bank_fax_email`,
  async (payload) => {
    try {
      const res = await api.post(
        `banking/handle-old-bank-notification`,
        payload
      );
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

const adminClientSlice = createSlice({
  name: `admin_client`,
  initialState,
  reducers: {
    resetAdminClientStore: (state) => {
      state.isUserAddError = false;
      state.apiMessage = '';
      state.isUserUpdateError = '';
      state.isUserAccepted = false;
      state.isUserUpdated = false;
      state.isUserAdded = false;
      state.isInviteSent = false;
      state.isEmailSent = false;
      state.isEmailSendError = false;
      state.isUserStatusUpdated = false;
      state.isAgingUploaded = false;
      state.isAgingUploadError = false;
      state.isClientAddError = false;
      state.isClientAdded = false;
      state.isClientUpdated = false;
      state.isClientUpdateError = false;
      state.isSubClientAdded = false;
      state.isSubClientAddError = false;
      state.isSubClientUpdated = false;
      state.isSubClientUpdateError = false;
      state.isSubAdminAdded = false;
      state.isSubAdminUpdated = false;
      state.isLinkSent = false;
      state.isDeleted = false;
      state.summaryDetails = '';
      state.isSummaryFetched = false;
      state.isEmailSent = false;
    },
    resetAdmin: (state) => {
      state.isAdminInformationFetched = false;
      state.adminInformation = '';
    },
  },
  extraReducers: {
    [getCompaniesList.pending]: (state) => {
      state.isLoading = true;
    },
    [getCompaniesList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.companies = action?.payload?.companiesList;
      state.clientCount = action?.payload?.count;
    },
    [getUsersList.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsersList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = action?.payload;
    },
    [getSubAdmins.pending]: (state) => {
      state.isLoading = true;
      state.users = [];
    },
    [getSubAdmins.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = action?.payload;
    },
    [getClientDetails.pending]: (state) => {
      state.isClientDetailsFetching = true;
      state.isClientDetailsFetched = false;
    },
    [getClientDetails.fulfilled]: (state, action) => {
      const parentCompany = {
        id: action?.payload?._id,
        value: action?.payload?._id,
        name: {
          en: action?.payload?.company_name_en,
          fr: action?.payload?.company_name_fr,
        },
      };
      const subCompanies = action?.payload?.sub_companies?.map((company) => {
        return {
          id: company?._id,
          value: company?._id,
          name: {
            en: company?.company_name_en,
            fr: company?.company_name_fr,
          },
        };
      });
      const companies = [...subCompanies, parentCompany];
      state.isClientDetailsFetching = false;
      state.clientDetails = action?.payload;
      state.isClientDetailsFetched = true;
      state.userCompanies = companies;
    },
    [addUser.pending]: (state) => {
      state.isUserAdding = true;
      state.isUserAdded = false;
      state.isUserAddError = false;
      state.apiMessage = '';
    },
    [addUser.fulfilled]: (state, action) => {
      state.isUserAdding = false;
      state.isUserAdded = true;
      state.isUserAddError = false;
      state.apiMessage = action?.payload;
    },
    [addUser.rejected]: (state, action) => {
      state.isUserAdding = false;
      state.isUserAdded = false;
      state.isUserAddError = true;
      state.apiMessage = action?.error?.message;
    },
    [updateUser.pending]: (state) => {
      state.isUserUpdating = true;
      state.isUserUpdated = false;
      state.isUserUpdated = false;
      state.apiMessage = '';
    },
    [updateUser.fulfilled]: (state, action) => {
      state.isUserUpdating = false;
      state.isUserUpdated = true;
      state.isUserUpdateError = false;
      state.apiMessage = action?.payload?.message;
    },
    [updateUser.rejected]: (state, action) => {
      state.isUserUpdating = false;
      state.isUserUpdated = false;
      state.isUserUpdateError = true;
      state.apiMessage = action?.error?.message;
    },
    [getUserDetails.pending]: (state) => {
      state.userInformation = '';
      state.isUserInformationFetching = true;
      state.isUserInformationFetched = false;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.userInformation = action?.payload;
      state.isUserInformationFetching = false;
      state.isUserInformationFetched = true;
    },
    [getAdminDetails.pending]: (state) => {
      state.adminInformation = '';
      state.isAdminInformationFetching = true;
      state.isAdminInformationFetched = false;
    },
    [getAdminDetails.fulfilled]: (state, action) => {
      state.adminInformation = action?.payload;
      state.isAdminInformationFetching = false;
      state.isAdminInformationFetched = true;
    },
    [acceptUser.pending]: (state) => {
      state.isUserAccepting = true;
      state.isUserAccepted = false;
      state.apiMessage = '';
    },
    [acceptUser.fulfilled]: (state, action) => {
      state.isUserAccepted = true;
      state.isUserAccepting = false;
      state.apiMessage = action?.payload;
    },
    [resendInvite.pending]: (state) => {
      state.isInviteSending = true;
      state.apiMessage = '';
      state.isInviteSent = false;
    },
    [resendInvite.fulfilled]: (state, action) => {
      state.isInviteSending = false;
      state.isInviteSent = true;
      state.apiMessage = action?.payload;
    },
    [sendPasswordEmail.pending]: (state) => {
      state.isEmailSending = true;
      state.isEmailSent = false;
      state.apiMessage = '';
      state.isEmailSendError = false;
    },
    [sendPasswordEmail.fulfilled]: (state, action) => {
      state.isEmailSending = false;
      state.isEmailSent = true;
      state.apiMessage = action?.payload;
      state.isEmailSendError = false;
    },
    [sendPasswordEmail.rejected]: (state, action) => {
      state.isEmailSending = false;
      state.isEmailSent = false;
      state.isEmailSendError = true;
      state.apiMessage = action?.error?.message;
    },
    [updateUserStatus.pending]: (state) => {
      state.isUserStatusUpdating = true;
      state.isUserStatusUpdated = false;
      state.apiMessage = '';
    },
    [updateUserStatus.fulfilled]: (state, action) => {
      state.isUserStatusUpdated = true;
      state.isUserStatusUpdating = false;
      state.apiMessage = action?.payload;
    },
    [simulateUser.pending]: (state) => {
      state.isUserSimulating = true;
      state.isUserSimulated = false;
      state.simulatedUser = '';
    },
    [simulateUser.fulfilled]: (state, action) => {
      state.isUserSimulating = false;
      state.isUserSimulated = true;
      state.simulatedUser = action?.payload;
      set(`simulatedUser`, action?.payload);
    },
    [getAgingList.pending]: (state) => {
      state.isLoading = true;
    },
    [getAgingList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.agingList = action?.payload;
    },
    [getIndustryList.pending]: (state) => {
      state.industries = [];
    },
    [getIndustryList.fulfilled]: (state, action) => {
      state.industries = action?.payload;
    },
    [getPriceList.pending]: (state) => {
      state.priceChart = [];
    },
    [getPriceList.fulfilled]: (state, action) => {
      state.priceChart = action?.payload;
    },
    [getGroups.pending]: (state) => {
      state.groups = [];
    },
    [getGroups.fulfilled]: (state, action) => {
      state.groups = action?.payload;
    },
    [uploadAging.pending]: (state) => {
      state.isAgingUploading = true;
      state.isAgingUploaded = false;
      state.isAgingUploadError = false;
      state.apiMessage = '';
    },
    [uploadAging.fulfilled]: (state, action) => {
      state.isAgingUploading = false;
      state.isAgingUploaded = true;
      state.apiMessage = action?.payload;
      state.isAgingUploadError = false;
    },
    [uploadAging.rejected]: (state, action) => {
      state.isAgingUploading = false;
      state.isAgingUploaded = false;
      state.apiMessage = action?.error?.message;
      state.isAgingUploadError = true;
    },
    [addClient.pending]: (state) => {
      state.isClientAdding = true;
      state.isClientAdded = false;
      state.isClientAddError = false;
      state.apiMessage = '';
    },
    [addClient.fulfilled]: (state, action) => {
      state.isClientAdding = false;
      state.isClientAdded = true;
      state.isClientAddError = false;
      state.apiMessage = action?.payload;
    },
    [addClient.rejected]: (state, action) => {
      state.isClientAdding = false;
      state.isClientAdded = false;
      state.isClientAddError = true;
      state.apiMessage = action?.error?.message;
    },
    [updateClient.pending]: (state) => {
      state.isClientUpdating = true;
      state.isClientUpdated = false;
      state.isClientUpdateError = false;
      state.apiMessage = '';
    },
    [updateClient.fulfilled]: (state, action) => {
      state.isClientUpdating = false;
      state.isClientUpdated = true;
      state.isClientUpdateError = false;
      state.apiMessage = action?.payload;
    },
    [updateClient.rejected]: (state, action) => {
      state.isClientUpdating = false;
      state.isClientUpdated = false;
      state.isClientUpdateError = true;
      state.apiMessage = action?.error?.message;
    },
    [addSubClient.pending]: (state) => {
      state.isSubClientAdding = true;
      state.isSubClientAdded = false;
      state.isSubClientAddError = false;
      state.apiMessage = '';
    },
    [addSubClient.fulfilled]: (state, action) => {
      state.isSubClientAdding = false;
      state.isSubClientAdded = true;
      state.isSubClientAddError = false;
      state.apiMessage = action?.payload;
    },
    [addSubClient.rejected]: (state, action) => {
      state.isSubClientAdding = false;
      state.isSubClientAdded = false;
      state.isSubClientAddError = true;
      state.apiMessage = action?.error?.message;
    },
    [updateSubClient.pending]: (state) => {
      state.isSubClientUpdating = true;
      state.isSubClientUpdated = false;
      state.isSubClientUpdateError = false;
      state.apiMessage = '';
    },
    [updateSubClient.fulfilled]: (state, action) => {
      state.isSubClientUpdating = false;
      state.isSubClientUpdated = true;
      state.isSubClientUpdateError = false;
      state.apiMessage = action?.payload;
    },
    [updateSubClient.rejected]: (state, action) => {
      state.isSubClientUpdating = false;
      state.isSubClientUpdated = false;
      state.isSubClientUpdateError = true;
      state.apiMessage = action?.error?.message;
    },
    [addSubAdmin.pending]: (state) => {
      state.isSubAdminAdding = true;
      state.isSubAdminAdded = false;
      state.isSubAdminAddError = false;
    },
    [addSubAdmin.fulfilled]: (state, action) => {
      state.isSubAdminAdding = false;
      state.isSubAdminAdded = true;
      state.isSubAdminAddError = false;
      state.apiMessage = action?.payload;
    },
    [addSubAdmin.rejected]: (state, action) => {
      state.isSubAdminAdding = false;
      state.isSubAdminAdded = false;
      state.isSubAdminAddError = true;
      state.apiMessage = action?.error?.message;
    },
    [updateSubAdmin.pending]: (state) => {
      state.isSubAdminUpdating = true;
      state.isSubAdminUpdated = false;
      state.isSubAdminUpdateError = false;
    },
    [updateSubAdmin.fulfilled]: (state, action) => {
      state.isSubAdminUpdating = false;
      state.isSubAdminUpdated = true;
      state.isSubAdminUpdateError = false;
      state.apiMessage = action?.payload?.message;
    },
    [updateSubAdmin.rejected]: (state, action) => {
      state.isSubAdminUpdating = false;
      state.isSubAdminUpdated = false;
      state.isSubAdminUpdateError = true;
      state.apiMessage = action?.payload;
    },
    [generateLink.pending]: (state) => {
      state.isLinkSending = true;
      state.isLinkSent = false;
    },
    [generateLink.fulfilled]: (state, action) => {
      state.isLinkSending = false;
      state.isLinkSent = true;
      state.apiMessage = action?.payload;
    },
    [removeSubAdmin.pending]: (state) => {
      state.isDeleting = true;
      state.isDeleted = false;
    },
    [removeSubAdmin.fulfilled]: (state, action) => {
      state.isDeleting = false;
      state.isDeleted = true;
      state.apiMessage = action?.payload;
    },
    [getSummary.pending]: (state) => {
      state.isSummaryFetching = true;
      state.isSummaryFetched = false;
    },
    [getSummary.fulfilled]: (state, action) => {
      state.isSummaryFetching = false;
      state.isSummaryFetched = true;
      state.summaryDetails = action?.payload;
    },
    [getCreditReports.pending]: (state) => {
      state.isCreditReportFetching = true;
      state.isCreditReportFetched = false;
    },
    [getCreditReports.fulfilled]: (state, action) => {
      state.isCreditReportFetching = false;
      state.isCreditReportFetched = true;
      state.creditReport = action?.payload;
    },
    [getDatabaseReports.pending]: (state) => {
      state.isDatabaseReportFetching = true;
      state.isDatabaseReportFetched = false;
    },
    [getDatabaseReports.fulfilled]: (state, action) => {
      state.isDatabaseReportFetching = false;
      state.isDatabaseReportFetched = true;
      state.databaseReport = action?.payload;
    },
    [sendLinkEmail.pending]: (state) => {
      state.isEmailSending = true;
      state.isEmailSent = false;
      state.isEmailSendError = false;
      state.emailMessage = '';
    },
    [sendLinkEmail.fulfilled]: (state, action) => {
      state.isEmailSending = false;
      state.isEmailSent = true;
      state.emailMessage = action?.payload;
      state.isEmailSendError = false;
    },
    [sendLinkEmail.rejected]: (state, action) => {
      state.isEmailSending = false;
      state.isEmailSent = false;
      state.isEmailSendError = true;
      state.emailMessage = action?.error?.message;
    },
    [sendBankFaxEmail.pending]: (state) => {
      state.isEmailSending = true;
      state.isEmailSent = false;
      state.isEmailSendError = false;
      state.emailMessage = '';
    },
    [sendBankFaxEmail.fulfilled]: (state, action) => {
      state.isEmailSending = false;
      state.isEmailSent = true;
      state.emailMessage = action?.payload;
      state.isEmailSendError = false;
    },
  },
});

export const { resetAdminClientStore, resetAdmin } = adminClientSlice.actions;

export default adminClientSlice.reducer;
