import React from "react";
import { useTranslation } from "react-i18next";

const Persons = ({ reportOptions }) => {
  const { t } = useTranslation();

  return (
    <div className="person_wrapper">
      <div className="heading">{`${t(`select_person`)}`}</div>
      <div className="bank_options_wrapper">
        <div className="bank_options_list">
          {reportOptions?.isFirstPersonAvailable && (
            <label>
              <input
                className="checkbox_wrapper"
                type="checkbox"
                checked={[`new_bank_report`, `history_all`]?.includes(
                  `bankType`
                )}
                //onChange={() => onBankTypeSelection(`new_bank_report`)}
              />
              <span>{reportOptions?.firstPersonName}</span>
            </label>
          )}
          {reportOptions?.isSecondPersonAvailable && (
            <label>
              <input
                className="checkbox_wrapper"
                type="checkbox"
                checked={[`new_bank_report`, `history_all`]?.includes(
                  `bankType`
                )}
                //onChange={() => onBankTypeSelection(`new_bank_report`)}
              />
              <span>{reportOptions?.secondPersonName}</span>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default Persons;
