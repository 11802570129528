import React, { useEffect, useState } from 'react';
import { useDebounce } from '../hooks/useDebounce';
import { isEmpty } from '../Utils/utils';

const SearchFilter = ({ placeholder, filterName, onFilterChange, isReset }) => {
  const [searchTerm, setSearchTerm] = useState(``);

  const debouncedSearchTerm = useDebounce(searchTerm, 700);

  useEffect(() => {
    if (isReset) {
      setSearchTerm('');
    }
  }, [isReset]);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    isEmpty(e.target.value) && onFilterChange(filterName, '');
  };

  useEffect(() => {
    if (!isEmpty(debouncedSearchTerm)) {
      onFilterChange(filterName, debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, onFilterChange, filterName]);

  const onRemoveFilter = () => {
    onFilterChange(filterName, '');
    setSearchTerm(``);
  };

  return (
    <div className='search_wrapper'>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_4334_4033)'>
          <path
            d='M13 6.5C13 7.93437 12.5344 9.25938 11.75 10.3344L15.7063 14.2937C16.0969 14.6844 16.0969 15.3188 15.7063 15.7094C15.3156 16.1 14.6812 16.1 14.2906 15.7094L10.3344 11.75C9.25938 12.5375 7.93437 13 6.5 13C2.90937 13 0 10.0906 0 6.5C0 2.90937 2.90937 0 6.5 0C10.0906 0 13 2.90937 13 6.5ZM6.5 11C8.98438 11 11 8.98438 11 6.5C11 4.01562 8.98438 2 6.5 2C4.01562 2 2 4.01562 2 6.5C2 8.98438 4.01562 11 6.5 11Z'
            fill='#94A3B8'
          />
        </g>
        <defs>
          <clipPath id='clip0_4334_4033'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>

      <input
        value={searchTerm}
        onChange={onSearch}
        placeholder={placeholder}
        className='filter_input'
      />

      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='remove_filter_icon'
        onClick={onRemoveFilter}
      >
        <g clipPath='url(#clip0_7201_2394)'>
          <path
            d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.46875 5.46875C5.7625 5.175 6.2375 5.175 6.52812 5.46875L7.99687 6.9375L9.46562 5.46875C9.75937 5.175 10.2344 5.175 10.525 5.46875C10.8156 5.7625 10.8187 6.2375 10.525 6.52812L9.05625 7.99687L10.525 9.46562C10.8187 9.75937 10.8187 10.2344 10.525 10.525C10.2312 10.8156 9.75625 10.8187 9.46562 10.525L7.99687 9.05625L6.52812 10.525C6.23438 10.8187 5.75938 10.8187 5.46875 10.525C5.17812 10.2312 5.175 9.75625 5.46875 9.46562L6.9375 7.99687L5.46875 6.52812C5.175 6.23438 5.175 5.75938 5.46875 5.46875Z'
            fill={`${isEmpty(searchTerm) ? `white` : `#94A3B8`} `}
          />
        </g>
        <defs>
          <clipPath id='clip0_7201_2394'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SearchFilter;
