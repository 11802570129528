import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonitoringCard from './MonitoringCard';
import { money } from '../../Utils/utils';
import Skeleton from '../../ReusableComponents/Skeleton';
import { monthOptions } from '../../Utils/utils';
import BarGraph from './BarGraph';
import LineGraph from './LineGraph';

const ProfileInformation = ({
  isClientInformationFetching,
  isClientInformationFetched,
  clientInformation,
  onDiscover,
  clientDuePayment,
  clientDuePaymentTrend,
  isEN,
}) => {
  const { t } = useTranslation();

  const [barGraphData, setBarGraphData] = useState([]);
  const [lineGraphData, setLineGraphData] = useState([]);
  const [isInsufficient, setIsInsufficient] = useState(false);

  useEffect(() => {
    if (isClientInformationFetched) {
      const barData = [
        {
          label: `${t(`current`)}`,
          value: Math.round(clientDuePayment?.current),
        },
        {
          label: `${t(`period_1`)}`,
          value: Math.round(clientDuePayment?.period1),
        },
        {
          label: `${t(`period_2`)}`,
          value: Math.round(clientDuePayment?.period2),
        },
        {
          label: `${t(`period_3`)}`,
          value: Math.round(clientDuePayment?.period3),
        },
        {
          label: `${t(`period_4`)}`,
          value: Math.round(clientDuePayment?.period4),
        },
      ];
      setBarGraphData(barData);
      const lineData = Object.values(clientDuePaymentTrend)?.map((trend) => ({
        label: isEN
          ? monthOptions[trend?.month]?.value?.en
          : monthOptions[trend?.month]?.value?.fr,
        value: Math.round(trend?.amount),
      }));
      setLineGraphData(lineData);
      setIsInsufficient(lineData?.length < 2);
    }
  }, [
    isClientInformationFetched,
    isEN,
    clientDuePayment,
    clientDuePaymentTrend,
  ]);

  const renderLocked = (type) => {
    return (
      <div className='credit_risk_locked_wrapper'>
        <span className='credit_risk_locked_text'>{`${t(`locked`)}`}</span>
        <span className='credit_risk_locked' onClick={() => onDiscover(type)}>
          <svg
            width='14'
            height='12'
            viewBox='0 0 14 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.99893 0.75C5.10518 0.75 3.58877 1.6125 2.48486 2.63906C1.38799 3.65625 0.654395 4.875 0.30752 5.71172C0.230176 5.89687 0.230176 6.10312 0.30752 6.28828C0.654395 7.125 1.38799 8.34375 2.48486 9.36094C3.58877 10.3875 5.10518 11.25 6.99893 11.25C8.89268 11.25 10.4091 10.3875 11.513 9.36094C12.6099 8.34141 13.3435 7.125 13.6927 6.28828C13.77 6.10312 13.77 5.89687 13.6927 5.71172C13.3435 4.875 12.6099 3.65625 11.513 2.63906C10.4091 1.6125 8.89268 0.75 6.99893 0.75ZM3.62393 6C3.62393 5.10489 3.97951 4.24645 4.61244 3.61351C5.24538 2.98058 6.10382 2.625 6.99893 2.625C7.89403 2.625 8.75248 2.98058 9.38541 3.61351C10.0183 4.24645 10.3739 5.10489 10.3739 6C10.3739 6.89511 10.0183 7.75355 9.38541 8.38649C8.75248 9.01942 7.89403 9.375 6.99893 9.375C6.10382 9.375 5.24538 9.01942 4.61244 8.38649C3.97951 7.75355 3.62393 6.89511 3.62393 6ZM6.99893 4.5C6.99893 5.32734 6.32627 6 5.49893 6C5.33252 6 5.17314 5.97187 5.02314 5.92266C4.89424 5.88047 4.74424 5.96016 4.74893 6.09609C4.75596 6.25781 4.77939 6.41953 4.82393 6.58125C5.14502 7.78125 6.38018 8.49375 7.58018 8.17266C8.78018 7.85156 9.49268 6.61641 9.17158 5.41641C8.91143 4.44375 8.05127 3.78984 7.09502 3.75C6.95908 3.74531 6.8794 3.89297 6.92158 4.02422C6.9708 4.17422 6.99893 4.33359 6.99893 4.5Z'
              fill='#334155'
            />
          </svg>
        </span>
      </div>
    );
  };

  const renderRefresh = (type) => {
    return (
      <div className='credit_risk_locked_wrapper'>
        <span className='credit_risk_locked' onClick={() => onDiscover(type)}>
          <svg
            width='13'
            height='12'
            viewBox='0 0 13 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_10278_5635)'>
              <path
                d='M2.60537 4.74841C2.78584 4.23748 3.07881 3.75701 3.49131 3.34685C4.95615 1.88201 7.33037 1.88201 8.79522 3.34685L9.196 3.74998H8.39209C7.97725 3.74998 7.64209 4.08513 7.64209 4.49998C7.64209 4.91482 7.97725 5.24998 8.39209 5.24998H11.0054H11.0147C11.4296 5.24998 11.7647 4.91482 11.7647 4.49998V1.87498C11.7647 1.46013 11.4296 1.12498 11.0147 1.12498C10.5999 1.12498 10.2647 1.46013 10.2647 1.87498V2.69998L9.85459 2.28748C7.80381 0.236694 4.48037 0.236694 2.42959 2.28748C1.85771 2.85935 1.44521 3.53201 1.19209 4.25154C1.05381 4.64294 1.26006 5.06951 1.64912 5.20779C2.03818 5.34607 2.46709 5.13982 2.60537 4.75076V4.74841ZM1.05615 6.78044C0.938965 6.8156 0.826465 6.87888 0.735059 6.97263C0.641309 7.06638 0.578027 7.17888 0.545215 7.30076C0.538184 7.32888 0.531152 7.35935 0.526465 7.38982C0.519434 7.42966 0.51709 7.46951 0.51709 7.50935V10.125C0.51709 10.5398 0.852246 10.875 1.26709 10.875C1.68193 10.875 2.01709 10.5398 2.01709 10.125V9.30232L2.42959 9.71247C4.48037 11.7609 7.80381 11.7609 9.85225 9.71247C10.4241 9.1406 10.839 8.46794 11.0921 7.74841C11.2304 7.35701 11.0241 6.93044 10.6351 6.79216C10.246 6.65388 9.81709 6.86013 9.67881 7.24919C9.49834 7.76013 9.20537 8.2406 8.79287 8.65076C7.32803 10.1156 4.95381 10.1156 3.48897 8.65076L3.48662 8.64841L3.08584 8.24998H3.89209C4.30693 8.24998 4.64209 7.91482 4.64209 7.49998C4.64209 7.08513 4.30693 6.74998 3.89209 6.74998H1.27646C1.23896 6.74998 1.20146 6.75232 1.16396 6.75701C1.12646 6.76169 1.09131 6.76873 1.05615 6.78044Z'
                fill='#334155'
              />
            </g>
            <defs>
              <clipPath id='clip0_10278_5635'>
                <rect
                  width='12'
                  height='12'
                  fill='white'
                  transform='translate(0.14209)'
                />
              </clipPath>
            </defs>
          </svg>

          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='dollar_lock_icon'
          >
            <rect width='12' height='12' rx='6' fill='#15803D' />
            <path
              d='M6.01272 1.99854C6.28928 1.99854 6.51272 2.22197 6.51272 2.49854V3.05635C6.53772 3.05947 6.56116 3.0626 6.58616 3.06729C6.59241 3.06885 6.59709 3.06885 6.60334 3.07041L7.35334 3.20791C7.62522 3.25791 7.80491 3.51885 7.75491 3.78916C7.70491 4.05947 7.44397 4.24072 7.17366 4.19072L6.43147 4.05479C5.94241 3.98291 5.51116 4.03135 5.20803 4.15166C4.90491 4.27197 4.78303 4.4376 4.75491 4.59072C4.72366 4.75791 4.74709 4.85166 4.77366 4.90947C4.80178 4.97041 4.85959 5.03916 4.97366 5.11572C5.22834 5.28291 5.61897 5.39229 6.12522 5.52666L6.17053 5.53916C6.61741 5.65791 7.16428 5.80166 7.57053 6.06729C7.79241 6.2126 8.00178 6.40947 8.13147 6.68447C8.26428 6.96416 8.29241 7.27666 8.23147 7.60947C8.12366 8.20322 7.71428 8.6001 7.20647 8.80791C6.99241 8.89541 6.75959 8.95166 6.51272 8.97978V9.50166C6.51272 9.77822 6.28928 10.0017 6.01272 10.0017C5.73616 10.0017 5.51272 9.77822 5.51272 9.50166V8.95635C5.50647 8.95478 5.49866 8.95479 5.49241 8.95322H5.48928C5.10803 8.89385 4.48147 8.72978 4.05959 8.54228C3.80803 8.42978 3.69397 8.13447 3.80647 7.88291C3.91897 7.63135 4.21428 7.51728 4.46584 7.62979C4.79241 7.7751 5.32991 7.91885 5.64084 7.96729C6.13928 8.04072 6.55022 7.99854 6.82834 7.88447C7.09241 7.77666 7.21272 7.62041 7.24709 7.43291C7.27678 7.26728 7.25334 7.17197 7.22678 7.11416C7.19709 7.05166 7.13928 6.98291 7.02366 6.90635C6.76741 6.73916 6.37522 6.62979 5.86741 6.49541L5.82366 6.48447C5.37834 6.3626 4.83147 6.21729 4.42522 5.95166C4.20334 5.80635 3.99553 5.60791 3.86584 5.33291C3.73459 5.05322 3.70803 4.74072 3.77053 4.40791C3.88303 3.81104 4.32991 3.42354 4.83772 3.22197C5.04553 3.13916 5.27366 3.08291 5.51272 3.0501V2.49854C5.51272 2.22197 5.73616 1.99854 6.01272 1.99854Z'
              fill='white'
            />
          </svg>
        </span>
      </div>
    );
  };

  return (
    <>
      <div className='credit_risk_profile_wrapper'>
        <div className='credit_risk_section_one'>
          <div className='client_information'>
            {isClientInformationFetching ? (
              <div className='client_info_loading'>
                {[1, 2, 3]?.map((_, index) => (
                  <Skeleton index={index} />
                ))}
              </div>
            ) : (
              <>
                <div className='name'>
                  <span className='label'>{`${t(`subject_name`)}: `}</span>
                  <span className='label_value'>
                    {clientInformation?.clientName || `-`}
                  </span>
                </div>
                <div className='name'>
                  <span className='label'>{`${t(`legal_name`)}: `}</span>
                  {clientInformation?.legalName === `locked` ? (
                    <>{renderLocked(`LegalName`)}</>
                  ) : (
                    <span className='label_value'>
                      {clientInformation?.legalName || `-`}
                    </span>
                  )}
                </div>
                <div className='risk_score_wrapper'>
                  <div className='risk_type'>
                    <span className='label'>{`${t(`risk`)}: `}</span>
                    {clientInformation?.risk === `locked` ? (
                      <>{renderLocked(`Risk`)}</>
                    ) : (
                      <>
                        <span
                          className={`label_value risk risk_${clientInformation?.risk}`}
                        >
                          {clientInformation?.riskExpired ? (
                            <span className='entity_expired'>
                              <svg
                                width='10'
                                height='8'
                                viewBox='0 0 10 8'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className={`score_expired_icon expired_${clientInformation?.risk}`}
                              >
                                <path
                                  d='M4.57077 0C4.82434 0 5.05827 0.133929 5.18684 0.353571L9.04398 6.925C9.17434 7.14643 9.17434 7.41964 9.04756 7.64107C8.92077 7.8625 8.68327 8 8.42791 8H0.713627C0.458269 8 0.220769 7.8625 0.0939837 7.64107C-0.032802 7.41964 -0.0310162 7.14464 0.0975552 6.925L3.9547 0.353571C4.08327 0.133929 4.3172 0 4.57077 0ZM4.57077 2.28571C4.33327 2.28571 4.1422 2.47679 4.1422 2.71429V4.71429C4.1422 4.95179 4.33327 5.14286 4.57077 5.14286C4.80827 5.14286 4.99934 4.95179 4.99934 4.71429V2.71429C4.99934 2.47679 4.80827 2.28571 4.57077 2.28571ZM5.1422 6.28571C5.1422 6.13416 5.08199 5.98882 4.97483 5.88165C4.86767 5.77449 4.72232 5.71429 4.57077 5.71429C4.41922 5.71429 4.27387 5.77449 4.16671 5.88165C4.05954 5.98882 3.99934 6.13416 3.99934 6.28571C3.99934 6.43727 4.05954 6.58261 4.16671 6.68978C4.27387 6.79694 4.41922 6.85714 4.57077 6.85714C4.72232 6.85714 4.86767 6.79694 4.97483 6.68978C5.08199 6.58261 5.1422 6.43727 5.1422 6.28571Z'
                                  fill='#334155'
                                />
                              </svg>
                              <span>{clientInformation?.risk}</span>
                            </span>
                          ) : (
                            clientInformation?.risk
                          )}
                        </span>
                        {clientInformation?.riskExpired && (
                          <span>{renderRefresh(`Risk`)}</span>
                        )}
                      </>
                    )}
                  </div>
                  <svg
                    width='6'
                    height='7'
                    viewBox='0 0 6 7'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='3' cy='3.5' r='3' fill='#E2E8F0' />
                  </svg>

                  <div className='score'>
                    <span className='label'>{`${t(`score`)}: `}</span>
                    {clientInformation?.score === `locked` ? (
                      <>{renderLocked(`Score`)}</>
                    ) : (
                      <span
                        className={`label_value score_${clientInformation?.risk}`}
                      >
                        {clientInformation?.scoreExpired ? (
                          <span className='entity_expired'>
                            <svg
                              width='10'
                              height='8'
                              viewBox='0 0 10 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              className={`score_expired_icon expired_${clientInformation?.risk}`}
                            >
                              <path
                                d='M4.57077 0C4.82434 0 5.05827 0.133929 5.18684 0.353571L9.04398 6.925C9.17434 7.14643 9.17434 7.41964 9.04756 7.64107C8.92077 7.8625 8.68327 8 8.42791 8H0.713627C0.458269 8 0.220769 7.8625 0.0939837 7.64107C-0.032802 7.41964 -0.0310162 7.14464 0.0975552 6.925L3.9547 0.353571C4.08327 0.133929 4.3172 0 4.57077 0ZM4.57077 2.28571C4.33327 2.28571 4.1422 2.47679 4.1422 2.71429V4.71429C4.1422 4.95179 4.33327 5.14286 4.57077 5.14286C4.80827 5.14286 4.99934 4.95179 4.99934 4.71429V2.71429C4.99934 2.47679 4.80827 2.28571 4.57077 2.28571ZM5.1422 6.28571C5.1422 6.13416 5.08199 5.98882 4.97483 5.88165C4.86767 5.77449 4.72232 5.71429 4.57077 5.71429C4.41922 5.71429 4.27387 5.77449 4.16671 5.88165C4.05954 5.98882 3.99934 6.13416 3.99934 6.28571C3.99934 6.43727 4.05954 6.58261 4.16671 6.68978C4.27387 6.79694 4.41922 6.85714 4.57077 6.85714C4.72232 6.85714 4.86767 6.79694 4.97483 6.68978C5.08199 6.58261 5.1422 6.43727 5.1422 6.28571Z'
                                fill='#334155'
                              />
                            </svg>
                            <span>{clientInformation?.score}</span>
                            <span>{renderRefresh(`Score`)}</span>
                          </span>
                        ) : (
                          clientInformation?.score
                        )}
                      </span>
                    )}
                  </div>
                  <div className='last_updated'>
                    <span>{`${t(`last_updated_on`)}: `}</span>
                    <span>
                      {new Date(
                        clientInformation?.lastUpdateDate
                      )?.toLocaleDateString('en-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </span>
                  </div>
                </div>
                <hr className='line-divider' />
                <div className='transaction_information'>
                  <MonitoringCard
                    title={t('total_amount_due_current_credit_limit')}
                    text={
                      money(clientInformation?.totalDue) +
                      ` / ` +
                      money(clientInformation?.currentCreditLimit)
                    }
                    isShowProgress={true}
                    value1={Number(clientInformation?.totalDue)}
                    value2={Number(clientInformation?.currentCreditLimit)}
                  />
                  <div className='vertical_divider' />
                  <MonitoringCard
                    title={t('longest_due')}
                    text={
                      isClientInformationFetching
                        ? 0
                        : ` ${t('days+')}` +
                            clientInformation?.longestDuePeriod || 0
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className='credit_risk_section_two'>
          {isClientInformationFetching ? (
            <Skeleton />
          ) : (
            <div className='graph_section'>
              <>
                <div className='graph_wrapper'>
                  <p className='label graph_label_specific'>{`${t(
                    `due_payments`
                  )}`}</p>
                  <div className='graph_1'>
                    <BarGraph data={barGraphData} />
                  </div>
                </div>

                <div className='graph_wrapper'>
                  {isInsufficient ? (
                    <div className='insufficient_wrapper'>
                      <div className='row_1'>
                        <svg
                          width='16'
                          height='14'
                          viewBox='0 0 16 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2 1C2 0.446875 1.55313 0 1 0C0.446875 0 0 0.446875 0 1V11.5C0 12.8813 1.11875 14 2.5 14H15C15.5531 14 16 13.5531 16 13C16 12.4469 15.5531 12 15 12H2.5C2.225 12 2 11.775 2 11.5V1ZM14.7063 3.70625C15.0969 3.31563 15.0969 2.68125 14.7063 2.29063C14.3156 1.9 13.6812 1.9 13.2906 2.29063L10 5.58437L8.20625 3.79063C7.81563 3.4 7.18125 3.4 6.79063 3.79063L3.29063 7.29062C2.9 7.68125 2.9 8.31563 3.29063 8.70625C3.68125 9.09688 4.31563 9.09688 4.70625 8.70625L7.5 5.91563L9.29375 7.70938C9.68437 8.1 10.3188 8.1 10.7094 7.70938L14.7094 3.70937L14.7063 3.70625Z'
                            fill='#64748B'
                          />
                        </svg>
                        <span className='unavailable'>
                          {`${t(`unavailable`)}`}
                        </span>
                      </div>
                      <div className='row_2'>
                        <span>{`${t(`insufficient_data`)}`}</span>
                        <br />
                        <span>{`${t(`requires_data`)}`}</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p className='label graph_label_specific'>
                        {`${t(`total_due_trend`)}`}
                      </p>
                      <div className='graph_2'>
                        <LineGraph data={lineGraphData} />
                      </div>
                    </>
                  )}
                </div>
              </>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileInformation;
