import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  apiMessage: '',
  isClientAdding: false,
  isClientAdded: false,
  isClientAddError: false,
  isClientDetailsFetching: false,
  isClientDetailsFetched: false,
  isClientDetailsFetchError: false,
  clientDetails: {},
  isSummaryFetching: false,
  isSummaryFetched: false,
  summary: {},
  isAlertsFetching: false,
  isAlertsFetched: false,
  alerts: [],
  isColumnsUpdating: false,
  isColumnsUpdated: false,
  isColumnUpdateError: false,
  isClientInformationFetching: false,
  isClientInformationFetched: false,
  isClientInformationFetchError: false,
  clientInformation: {},
  isCorporateDetailsFetching: false,
  isCorporateDetailsFetched: false,
  corporateDetails: {},
  isLegalDetailsFetching: false,
  isLegalDetailsFetched: false,
  legalDetails: {},
  isActivitiesFetching: false,
  isActivitiesFetched: false,
  activities: [],
  isRiskDiscovering: false,
  isRiskDiscovered: false,
  isTypeDiscovering: false,
  isTypeDiscovered: false,
  isDiscoverError: false,
  isOwnedReportsFetching: false,
  isOwnedReportsFetched: false,
  ownedReports: [],
  isAvailableReportsFetching: false,
  isAvailableReportsFetched: false,
  availableReports: [],
  isClientDeleting: false,
  isClientDeleted: false,
  isAlertDeleting: false,
  isAlertDeleted: false,
  isClientUpdating: false,
  isClientUpdated: false,
  isClientUpdateError: false,
  isHistoricalDataFetching: false,
  isHistoricalDataFetched: false,
  historicalData: [],
  isRefreshRequestSending: false,
  isRefreshRequestSent: false,
  isReportOrdering: false,
  isReportOrdered: false,
  isReportOrderError: false,
  isReportBuying: false,
  isReportBought: false,
  isReportBuyError: false,
  isReportDownloading: false,
  isReportDownloaded: false,
  isReportDownloadError: false,
  downloadedReport: '',
  isAgingFileUploading: false,
  isAgingFileUploaded: false,
  isAgingFileUploadError: false,
  isVerifiedCompaniesFetching: false,
  isVerifiedCompaniesFetched: false,
  verifiedCompanies: [],
  isClientsUnderProcessFetching: false,
  isClientsUnderProcessFetched: false,
  clientsUnderProcess: 0,
  isRefreshing: false,
  isRefreshed: false,
  isMonthsFetching: false,
  isMonthsFetched: false,
  months: [],
  isClientAddressFetching: false,
  isClientAddressFetched: false,
  clientAddress: {},
};

export const addClient = createAsyncThunk(
  `credit_risk_add_client`,
  async (payload) => {
    try {
      const res = await api.post(`monitoring/client`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const uploadAgingFile = createAsyncThunk(
  `credit_risk_upload_aging`,
  async (payload) => {
    try {
      const res = await api.post(`monitoring/upload-file`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getClientDetails = createAsyncThunk(
  `credit_risk_client_details`,
  async (payload) => {
    try {
      const res = await api.post(`monitoring/client-list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getDashboardDetails = createAsyncThunk(
  `credit_risk_dashboard_details`,
  async (payload) => {
    try {
      const res = await api.get(`monitoring/summary`, { params: payload });
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getAlerts = createAsyncThunk(
  `credit_risk_alerts`,
  async (payload) => {
    try {
      const res = await api.get(`monitoring/alert`, {
        params: payload,
      });
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateColumns = createAsyncThunk(
  `credit_risk_update_columns`,
  async (payload) => {
    try {
      const res = await api.post(
        `monitoring/dashboard-clients-column-metadata`,
        payload
      );
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getClientInformation = createAsyncThunk(
  `credit_risk_company_details`,
  async (payload) => {
    try {
      const params = {};
      if (payload?.companyId) params.companyId = payload?.companyId;
      if (payload?.clientId) params.clientId = payload?.clientId;
      const res = await api.get(`monitoring/client`, { params });
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getCorporateDetails = createAsyncThunk(
  `credit_risk_corporate_details`,
  async (payload) => {
    try {
      const params = {};
      if (payload?.companyId) params.companyId = payload?.companyId;
      if (payload?.clientId) params.clientId = payload?.clientId;
      const res = await api.get(`monitoring/corporate-details`, { params });
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getLegalDetails = createAsyncThunk(
  `credit_risk_legal_details`,
  async (payload) => {
    try {
      const params = {};
      if (payload?.companyId) params.companyId = payload?.companyId;
      if (payload?.clientId) params.clientId = payload?.clientId;
      const res = await api.get(`monitoring/legal-details`, { params });
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getActivities = createAsyncThunk(
  `credit_risk_activities`,
  async (payload) => {
    try {
      const params = {};
      if (payload?.companyId) params.companyId = payload?.companyId;
      if (payload?.clientId) params.clientId = payload?.clientId;
      if (payload?.type === `Transaction`) params.type = payload?.type;

      const res = await api.get(`monitoring/user-activity`, { params });
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const discoverRiskInformation = createAsyncThunk(
  `credit_risk_discover_risk`,
  async (payload) => {
    try {
      const res = await api.post(`monitoring/request-information`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const discoverTypeInformation = createAsyncThunk(
  `credit_risk_discover_information`,
  async (payload) => {
    try {
      const res = await api.post(`monitoring/request-information`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getMonitoringOwnedReports = createAsyncThunk(
  `credit_risk_owned_reports`,
  async (payload) => {
    try {
      const res = await api.get(`report/chms-orders?chmsClientId=${payload}`);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getMonitoringAvailableReports = createAsyncThunk(
  `credit_risk_available_reports`,
  async (payload) => {
    try {
      const res = await api.get(
        `database/chms-available-reports?chmsClientId=${payload}&page=1`
      );
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const deleteMonitoringClient = createAsyncThunk(
  `credit_risk_delete_client`,
  async (payload) => {
    try {
      const res = await api.delete(`monitoring/client`, { data: payload });
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const deleteMonitoringAlert = createAsyncThunk(
  `credit_risk_delete_alert`,
  async (payload) => {
    try {
      const res = await api.delete(`monitoring/alert`, { data: payload });
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateClientDetails = createAsyncThunk(
  `credit_risk_update_client`,
  async (payload) => {
    try {
      const res = await api.patch(`monitoring/client`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getHistoricalMonths = createAsyncThunk(
  `credit_risk_historical_months`,
  async (payload) => {
    try {
      const params = {};
      if (payload?.companyId) params.companyId = payload?.companyId;
      const res = await api.get(`monitoring/historical/periods`, { params });
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getHistoricalList = createAsyncThunk(
  `credit_risk_historical_data`,
  async (payload) => {
    try {
      const res = await api.post(`monitoring/historical`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const refreshDetails = createAsyncThunk(
  `credit_risk_refresh`,
  async (payload) => {
    try {
      const res = await api.post(`monitoring/request-refresh`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const orderReport = createAsyncThunk(
  `credit_risk_order_report`,
  async (payload) => {
    try {
      const res = await api.post(`report/chms-order-report`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const buyReport = createAsyncThunk(
  `credit_risk_buy_report`,
  async (payload) => {
    try {
      const res = await api.post(`database/buy-report`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const downloadReport = createAsyncThunk(
  `credit_risk_download_report`,
  async (payload) => {
    try {
      const res = await api.post(`report/download-report`, payload, {
        responseType: 'blob',
      });
      const { data } = res;
      const url = window.URL.createObjectURL(data);
      return url;
    } catch (error) {
      console.log(`err`, error);
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getVerifiedCompanies = createAsyncThunk(
  `credit_risk_verified_companies`,
  async (payload) => {
    try {
      const res = await api.get(`user/chms/companies`);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getClientsUnderProcess = createAsyncThunk(
  `credit_risk_clients_under_process`,
  async (payload) => {
    try {
      const res = await api.get(
        `monitoring/client/processing?companyId=${payload}`
      );
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const refreshData = createAsyncThunk(
  `credit_risk_refresh_data`,
  async (payload) => {
    try {
      const res = await api.put(
        `monitoring/request-refresh-expired-information`,
        payload
      );
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getClientAddress = createAsyncThunk(
  `credit_risk_client_address`,
  async (payload) => {
    try {
      const res = await api.get(
        `monitoring/client/report-order/validation?clientId=${payload}`
      );
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const creditRiskSlice = createSlice({
  name: 'healthMonitoring',
  initialState,
  reducers: {
    resetHealthMonitoringStore: (state) => {
      state.isClientAddError = false;
      state.apiMessage = '';
      state.isClientAdded = false;
      state.isColumnUpdateError = false;
      state.isColumnsUpdated = false;
      state.isDiscoverError = false;
      state.isClientDeleted = false;
      state.isAlertDeleted = false;
      state.isClientUpdated = false;
      state.isClientInformationFetchError = false;
      state.isRefreshRequestSent = false;
      state.isReportOrderError = false;
      state.isReportBought = false;
      state.isTypeDiscovered = false;
      state.isAgingFileUploadError = false;
      state.isRiskDiscovered = false;
      state.isClientUpdateError = false;
      state.isAgingFileUploadError = false;
    },
    onReportOrdered: (state) => {
      state.isReportOrdered = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addClient.pending, (state) => {
        state.isClientAdding = true;
      })
      .addCase(addClient.fulfilled, (state, action) => {
        console.log(action);
        state.isClientAdding = false;
        state.isClientAdded = true;
        state.apiMessage = action.payload;
      })
      .addCase(addClient.rejected, (state, action) => {
        state.isClientAdding = false;
        state.isClientAdded = false;
        state.isClientAddError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(getClientDetails.pending, (state) => {
        state.isClientDetailsFetching = true;
        state.isClientDetailsFetched = false;
        state.isClientDetailsFetchError = false;
      })
      .addCase(getClientDetails.fulfilled, (state, action) => {
        state.isClientDetailsFetching = false;
        state.isClientDetailsFetched = true;
        state.isClientDetailsFetchError = false;
        state.clientDetails = action.payload;
      })
      .addCase(getClientDetails.rejected, (state, action) => {
        state.isClientDetailsFetching = false;
        state.isClientDetailsFetched = false;
        state.isClientDetailsFetchError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(getDashboardDetails.pending, (state) => {
        state.isSummaryFetching = true;
        state.isSummaryFetched = false;
      })
      .addCase(getDashboardDetails.fulfilled, (state, action) => {
        state.isSummaryFetching = false;
        state.isSummaryFetched = true;
        state.summary = action.payload;
      })
      .addCase(getDashboardDetails.rejected, (state) => {
        state.isSummaryFetching = false;
        state.isSummaryFetched = false;
      });

    builder
      .addCase(getAlerts.pending, (state) => {
        state.isAlertsFetching = true;
        state.isAlertsFetched = false;
      })
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.isAlertsFetching = false;
        state.isAlertsFetched = true;
        state.alerts = action.payload;
      })
      .addCase(getAlerts.rejected, (state) => {
        state.isAlertsFetching = false;
        state.isAlertsFetched = false;
      });

    builder
      .addCase(updateColumns.pending, (state) => {
        state.isColumnsUpdating = true;
        state.isColumnsUpdated = false;
        state.isColumnUpdateError = false;
      })
      .addCase(updateColumns.fulfilled, (state, action) => {
        state.isColumnsUpdating = false;
        state.isColumnsUpdated = true;
        state.apiMessage = action?.payload;
        state.isColumnUpdateError = false;
      })
      .addCase(updateColumns.rejected, (state, action) => {
        state.isColumnsUpdating = false;
        state.isColumnsUpdated = false;
        state.isColumnUpdateError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(getClientInformation.pending, (state) => {
        state.isClientInformationFetching = true;
        state.isClientInformationFetched = false;
        state.isClientInformationFetchError = false;
      })
      .addCase(getClientInformation.fulfilled, (state, action) => {
        state.isClientInformationFetching = false;
        state.isClientInformationFetched = true;
        state.clientInformation = action.payload;
        state.isClientInformationFetchError = false;
      })
      .addCase(getClientInformation.rejected, (state, action) => {
        state.isClientInformationFetching = false;
        state.isClientInformationFetched = false;
        state.isClientInformationFetchError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(getCorporateDetails.pending, (state) => {
        state.isCorporateDetailsFetching = true;
        state.isCorporateDetailsFetched = false;
      })
      .addCase(getCorporateDetails.fulfilled, (state, action) => {
        state.isCorporateDetailsFetching = false;
        state.isCorporateDetailsFetched = true;
        state.corporateDetails = action.payload;
      })
      .addCase(getCorporateDetails.rejected, (state) => {
        state.isCorporateDetailsFetching = false;
        state.isCorporateDetailsFetched = false;
      });
    builder
      .addCase(getLegalDetails.pending, (state) => {
        state.isLegalDetailsFetching = true;
        state.isLegalDetailsFetched = false;
      })
      .addCase(getLegalDetails.fulfilled, (state, action) => {
        state.isLegalDetailsFetching = false;
        state.isLegalDetailsFetched = true;
        state.legalDetails = action.payload;
      })
      .addCase(getLegalDetails.rejected, (state) => {
        state.isLegalDetailsFetching = false;
        state.isLegalDetailsFetched = false;
      });
    builder
      .addCase(getActivities.pending, (state) => {
        state.isActivitiesFetching = true;
        state.isActivitiesFetched = false;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.isActivitiesFetching = false;
        state.isActivitiesFetched = true;
        state.activities = action.payload?.activities;
      })
      .addCase(getActivities.rejected, (state) => {
        state.isActivitiesFetching = false;
        state.isActivitiesFetched = false;
      });
    builder
      .addCase(discoverRiskInformation.pending, (state) => {
        state.isRiskDiscovering = true;
        state.isRiskDiscovered = false;
        state.isDiscoverError = false;
      })
      .addCase(discoverRiskInformation.fulfilled, (state, action) => {
        state.isRiskDiscovered = true;
        state.isRiskDiscovering = false;
        state.isDiscoverError = false;
        state.apiMessage = action?.payload;
      })
      .addCase(discoverRiskInformation.rejected, (state, action) => {
        state.isRiskDiscovering = false;
        state.isRiskDiscovered = false;
        state.isDiscoverError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(discoverTypeInformation.pending, (state) => {
        state.isTypeDiscovering = true;
        state.isTypeDiscovered = false;
        state.isDiscoverError = false;
      })
      .addCase(discoverTypeInformation.fulfilled, (state, action) => {
        state.isTypeDiscovered = true;
        state.isTypeDiscovering = false;
        state.isDiscoverError = false;
        state.apiMessage = action?.payload;
      })
      .addCase(discoverTypeInformation.rejected, (state, action) => {
        state.isTypeDiscovering = false;
        state.isTypeDiscovered = false;
        state.isDiscoverError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(deleteMonitoringClient.pending, (state) => {
        state.isClientDeleting = true;
        state.isClientDeleted = false;
      })
      .addCase(deleteMonitoringClient.fulfilled, (state, action) => {
        state.isClientDeleting = false;
        state.isClientDeleted = true;
        state.apiMessage = action?.payload;
      })
      .addCase(deleteMonitoringClient.rejected, (state) => {
        state.isClientDeleting = false;
        state.isClientDeleted = false;
      });

    builder
      .addCase(deleteMonitoringAlert.pending, (state) => {
        state.isAlertDeleting = true;
        state.isAlertDeleted = false;
      })
      .addCase(deleteMonitoringAlert.fulfilled, (state, action) => {
        state.isAlertDeleting = false;
        state.isAlertDeleted = true;
        state.apiMessage = action?.payload;
      })
      .addCase(deleteMonitoringAlert.rejected, (state) => {
        state.isAlertDeleting = false;
        state.isAlertDeleted = false;
      });

    builder
      .addCase(updateClientDetails.pending, (state) => {
        state.isClientUpdating = true;
        state.isClientUpdated = false;
        state.isClientUpdateError = false;
      })
      .addCase(updateClientDetails.fulfilled, (state, action) => {
        state.isClientUpdating = false;
        state.isClientUpdated = true;
        state.isClientUpdateError = false;
        state.apiMessage = action?.payload;
      })
      .addCase(updateClientDetails.rejected, (state, action) => {
        state.isClientUpdating = false;
        state.isClientUpdated = false;
        state.isClientUpdateError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(getHistoricalMonths.pending, (state) => {
        state.isMonthsFetching = true;
        state.isMonthsFetched = false;
      })
      .addCase(getHistoricalMonths.fulfilled, (state, action) => {
        state.isMonthsFetching = false;
        state.isMonthsFetched = true;
        state.months = action.payload;
      })
      .addCase(getHistoricalMonths.rejected, (state) => {
        state.isMonthsFetching = false;
        state.isMonthsFetched = false;
      });

    builder
      .addCase(getHistoricalList.pending, (state) => {
        state.isHistoricalDataFetching = true;
        state.isHistoricalDataFetched = false;
      })
      .addCase(getHistoricalList.fulfilled, (state, action) => {
        state.isHistoricalDataFetching = false;
        state.isHistoricalDataFetched = true;
        state.historicalData = action.payload;
      })
      .addCase(getHistoricalList.rejected, (state) => {
        state.isHistoricalDataFetching = false;
        state.isHistoricalDataFetched = false;
      });

    builder
      .addCase(getMonitoringOwnedReports.pending, (state) => {
        state.isOwnedReportsFetching = true;
        state.isOwnedReportsFetched = false;
      })
      .addCase(getMonitoringOwnedReports.fulfilled, (state, action) => {
        state.isOwnedReportsFetching = false;
        state.isOwnedReportsFetched = true;
        state.ownedReports = action.payload;
      })
      .addCase(getMonitoringOwnedReports.rejected, (state) => {
        state.isOwnedReportsFetching = false;
        state.isOwnedReportsFetched = false;
      });

    builder
      .addCase(getMonitoringAvailableReports.pending, (state) => {
        state.isAvailableReportsFetching = true;
        state.isAvailableReportsFetched = false;
      })
      .addCase(getMonitoringAvailableReports.fulfilled, (state, action) => {
        state.isAvailableReportsFetching = false;
        state.isAvailableReportsFetched = true;
        state.availableReports = action.payload;
      })
      .addCase(getMonitoringAvailableReports.rejected, (state) => {
        state.isAvailableReportsFetching = false;
        state.isAvailableReportsFetched = false;
      });

    builder
      .addCase(refreshDetails.pending, (state) => {
        state.isRefreshRequestSending = true;
        state.isRefreshRequestSent = false;
      })
      .addCase(refreshDetails.fulfilled, (state, action) => {
        state.isRefreshRequestSending = false;
        state.isRefreshRequestSent = true;
        state.apiMessage = action?.payload;
      })

      .addCase(orderReport.pending, (state) => {
        state.isReportOrdering = true;
        state.isReportOrdered = false;
        state.isReportOrderError = false;
      })
      .addCase(orderReport.fulfilled, (state, action) => {
        state.isReportOrdering = false;
        state.isReportOrdered = true;
        state.apiMessage = action?.payload;
      })
      .addCase(orderReport.rejected, (state, action) => {
        state.isReportOrdering = false;
        state.isReportOrdered = false;
        state.isReportOrderError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(buyReport.pending, (state) => {
        state.isReportBuying = true;
        state.isReportBought = false;
        state.isReportBuyError = false;
      })
      .addCase(buyReport.fulfilled, (state, action) => {
        state.isReportBuying = false;
        state.isReportBought = true;
        state.isReportBuyError = false;
        state.apiMessage = action?.payload;
      })
      .addCase(buyReport.rejected, (state, action) => {
        state.isReportBuying = false;
        state.isReportBought = false;
        state.isReportBuyError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(downloadReport.pending, (state) => {
        state.isReportDownloading = true;
        state.isReportDownloaded = false;
        state.isReportDownloadError = false;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.isReportDownloading = false;
        state.isReportDownloaded = true;
        state.isReportDownloadError = false;
        state.downloadedReport = action?.payload;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.isReportDownloading = false;
        state.isReportDownloaded = false;
        state.isReportDownloadError = true;
        state.apiMessage = action.error.message;
        state.downloadedReport = '';
      });

    builder
      .addCase(uploadAgingFile.pending, (state) => {
        state.isAgingFileUploading = true;
        state.isAgingFileUploaded = false;
        state.isAgingFileUploadError = false;
        state.isClientsUnderProcessFetched = false;
        state.clientsUnderProcess = 0;
      })
      .addCase(uploadAgingFile.fulfilled, (state, action) => {
        state.isAgingFileUploading = false;
        state.isAgingFileUploaded = true;
        state.isAgingFileUploadError = false;
        state.apiMessage = action?.payload;
      })
      .addCase(uploadAgingFile.rejected, (state, action) => {
        state.isAgingFileUploading = false;
        state.isAgingFileUploaded = false;
        state.isAgingFileUploadError = true;
        state.apiMessage = action.error.message;
      });

    builder
      .addCase(getVerifiedCompanies.pending, (state) => {
        state.isVerifiedCompaniesFetching = true;
        state.isVerifiedCompaniesFetched = false;
      })
      .addCase(getVerifiedCompanies.fulfilled, (state, action) => {
        state.isVerifiedCompaniesFetching = false;
        state.isVerifiedCompaniesFetched = true;
        state.verifiedCompanies = action.payload;
      })
      .addCase(getVerifiedCompanies.rejected, (state) => {
        state.isVerifiedCompaniesFetching = false;
        state.isVerifiedCompaniesFetched = false;
      });
    builder
      .addCase(getClientsUnderProcess.pending, (state) => {
        state.isClientsUnderProcessFetching = true;
        state.isClientsUnderProcessFetched = false;
      })
      .addCase(getClientsUnderProcess.fulfilled, (state, action) => {
        state.isClientsUnderProcessFetching = false;
        state.isClientsUnderProcessFetched = true;
        state.clientsUnderProcess = action.payload;
      })
      .addCase(getClientsUnderProcess.rejected, (state) => {
        state.isClientsUnderProcessFetching = false;
        state.isClientsUnderProcessFetched = false;
      });

    builder
      .addCase(refreshData.pending, (state) => {
        state.isRefreshing = true;
        state.isRefreshed = false;
      })
      .addCase(refreshData.fulfilled, (state, action) => {
        state.isRefreshing = false;
        state.isRefreshed = true;
        state.apiMessage = action?.payload;
      })
      .addCase(refreshData.rejected, (state) => {
        state.isRefreshing = false;
        state.isRefreshed = false;
      });
    builder
      .addCase(getClientAddress.pending, (state) => {
        state.isClientAddressFetching = true;
        state.isClientAddressFetched = false;
      })
      .addCase(getClientAddress.fulfilled, (state, action) => {
        state.isClientAddressFetching = false;
        state.isClientAddressFetched = true;
        state.clientAddress = action?.payload;
      })
      .addCase(getClientAddress.rejected, (state) => {
        state.isClientAddressFetching = false;
        state.isClientAddressFetched = false;
      });
  },
});

export const { resetHealthMonitoringStore, onReportOrdered } =
  creditRiskSlice.actions;

export default creditRiskSlice.reducer;
