import { useTranslation } from 'react-i18next';
import {
  isAlphabet,
  isCurrency,
  isEmailValid,
  isEmpty,
  isNumber,
  isPhoneValid,
  isPostalCodeValid,
  isZip,
} from '../Utils/utils';

const useFormValidation = (level) => {
  const { t } = useTranslation();

  const onChangeValue = (
    inputValue,
    index,
    fields,
    setField,
    fieldSetIndex
  ) => {
    const formFields = [...fields];
    const selectedField =
      level === `levelOne`
        ? formFields?.[index]
        : formFields?.[fieldSetIndex]?.[index];
    selectedField.value = inputValue;
    setField(formFields);
  };

  const onFormatCheck = (inputValue, format) => {
    if (format === `phone`) {
      if (inputValue?.length < 15) {
        return isPhoneValid(inputValue);
      }
    }
    if (format === `zip`) {
      if (inputValue?.length === 5) {
        return isZip(inputValue);
      }
    }
    if (format === `email`) {
      return isEmailValid(inputValue);
    }
    if (format === `number`) {
      return isNumber(inputValue);
    }
    if (format === `postalCode`) {
      return isPostalCodeValid(inputValue);
    }
    if (format === `currency`) {
      return isCurrency(inputValue);
    }
    if (format === `generic`) {
      return true;
    }
  };

  const onUpdateStatus = (fields, index, setField, type, fieldSetIndex) => {
    const formFields = [...fields];
    const selectedField =
      level === `levelOne`
        ? formFields?.[index]
        : formFields?.[fieldSetIndex]?.[index];

    if (type === `success`) {
      selectedField.isValid = true;
      selectedField.isError = false;
      selectedField.isWarning = false;
      selectedField.errorMessage = '';
    }
    if (type === `warning`) {
      selectedField.isValid = false;
      selectedField.isError = false;
      selectedField.isWarning = true;
      selectedField.errorMessage = `${selectedField?.text} ${t(`is_invalid`)}`;
    }
    if (type === `empty`) {
      selectedField.isValid = false;
      selectedField.isError = true;
      selectedField.isWarning = false;
      selectedField.errorMessage = `${selectedField?.text} ${t(`is_required`)}`;
    }
    if (type === `error`) {
      selectedField.isValid = false;
      selectedField.isError = true;
      selectedField.isWarning = false;
      selectedField.errorMessage = `${selectedField?.text} ${t(`is_invalid`)}`;
    }
    if (type === `reset`) {
      selectedField.isValid = false;
      selectedField.isError = false;
      selectedField.isWarning = false;
      selectedField.errorMessage = '';
    }
    setField(formFields);
  };

  const onFormatCheckHandler = (
    inputValue,
    index,
    field,
    fields,
    setField,
    fieldSetIndex
  ) => {
    onFormatCheck(inputValue, field?.format)
      ? onUpdateStatus(fields, index, setField, `success`, fieldSetIndex)
      : isEmpty(inputValue)
      ? onUpdateStatus(fields, index, setField, `empty`, fieldSetIndex)
      : onUpdateStatus(fields, index, setField, `warning`, fieldSetIndex);
  };

  const onInputChange = (
    inputValue,
    index,
    field,
    fields,
    setField,
    fieldSetIndex
  ) => {
    if (field?.format === `phone`) {
      const x = inputValue
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      inputValue = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
      onFormatCheckHandler(
        inputValue,
        index,
        field,
        fields,
        setField,
        fieldSetIndex
      );
    }
    if (field?.format === `postalCode`) {
      const string = inputValue?.split('');
      const value =
        string?.length === 1
          ? string?.[0]
          : string?.length === 2
          ? string?.[1]
          : string?.length === 3
          ? string?.[2]
          : string?.length === 4
          ? string?.[3]
          : string?.length === 5
          ? string?.[4]
          : string?.length === 6
          ? string?.[5]
          : null;

      if (
        string?.length === 1 ||
        string?.length === 3 ||
        string?.length === 5
      ) {
        if (isAlphabet(value)) {
          onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
          onFormatCheckHandler(
            inputValue,
            index,
            field,
            fields,
            setField,
            fieldSetIndex
          );
        }
      }
      if (
        string?.length === 2 ||
        string?.length === 4 ||
        string?.length === 6
      ) {
        if (isNumber(value)) {
          onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
          onFormatCheckHandler(
            inputValue,
            index,
            field,
            fields,
            setField,
            fieldSetIndex
          );
        }
      }
      if (string?.length === 0) {
        onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
      }
    }
    if (field?.format === `email`) {
      onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
      onFormatCheckHandler(
        inputValue,
        index,
        field,
        fields,
        setField,
        fieldSetIndex
      );
    }
    if (field?.format === `currency`) {
      onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
      onFormatCheckHandler(
        inputValue,
        index,
        field,
        fields,
        setField,
        fieldSetIndex
      );
    }
    if (field?.format === `generic`) {
      onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
      onFormatCheckHandler(
        inputValue,
        index,
        field,
        fields,
        setField,
        fieldSetIndex
      );
    }
    if (field?.format === `zip`) {
      if (isNumber(inputValue)) {
        onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
        onFormatCheckHandler(
          inputValue,
          index,
          field,
          fields,
          setField,
          fieldSetIndex
        );
      }
    }
    if (field?.format === `number`) {
      if (isNumber(inputValue)) {
        onChangeValue(inputValue, index, fields, setField, fieldSetIndex);
        onFormatCheckHandler(
          inputValue,
          index,
          field,
          fields,
          setField,
          fieldSetIndex
        );
      }
    }
    if (field?.isOptional && !field?.isRequired) {
      if (isEmpty(inputValue)) {
        onUpdateStatus(fields, index, setField, `reset`, fieldSetIndex);
      }
    }
    if (field?.isRequired) {
      if (isEmpty(inputValue)) {
        onUpdateStatus(fields, index, setField, `empty`, fieldSetIndex);
      }
    }
  };

  const onBlur = (
    inputValue,
    index,
    field,
    fields,
    setField,
    fieldSetIndex
  ) => {
    if (field?.isRequired) {
      if (isEmpty(inputValue)) {
        onUpdateStatus(fields, index, setField, `empty`, fieldSetIndex);
      }
      if (!isEmpty(inputValue)) {
        onFormatCheck(inputValue, field?.format)
          ? onUpdateStatus(fields, index, setField, `success`, fieldSetIndex)
          : onUpdateStatus(fields, index, setField, `error`, fieldSetIndex);
      }
    }
    if (field?.isOptional && !field?.isRequired) {
      if (isEmpty(inputValue)) {
        onUpdateStatus(fields, index, setField, `reset`, fieldSetIndex);
      }
      if (!isEmpty(inputValue)) {
        onFormatCheck(inputValue, field?.format)
          ? onUpdateStatus(fields, index, setField, `success`, fieldSetIndex)
          : onUpdateStatus(fields, index, setField, `error`, fieldSetIndex);
      }
    }
    if (field?.format === `currency` && !isEmpty(inputValue)) {
      const formFields = [...fields];
      const selectedField =
        level === `levelOne`
          ? formFields?.[index]
          : formFields?.[fieldSetIndex]?.[index];
      let inputValue = selectedField?.value;
      inputValue = inputValue?.replace(/[^0-9.-]/g, '');
      selectedField.value = parseFloat(inputValue).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      onFormatCheck(selectedField.value, field?.format)
        ? onUpdateStatus(fields, index, setField, `success`, fieldSetIndex)
        : onUpdateStatus(fields, index, setField, `error`, fieldSetIndex);
      setField(formFields);
    }
  };

  const onSubmit = (fields, setFields, level) => {
    let isFormValid;
    if (level === `levelOne`) {
      const isError = fields?.filter((field) => field?.isError)?.length > 0;
      if (isError) {
        isFormValid = false;
      }
      if (!isError) {
        const formFields = fields?.map((field) => {
          if (field?.isRequired) {
            if (isEmpty(field?.value)) {
              isFormValid = false;
              return {
                ...field,
                isError: true,
                errorMessage: `${field?.text} ${t(`is_required`)}`,
              };
            }
            if (!isEmpty(field?.value)) {
              onFormatCheck(field?.value, field?.format)
                ? (isFormValid = true)
                : (isFormValid = false);
            }
          }
          if (field?.isOptional) {
            if (!isEmpty(field?.value)) {
              if (!onFormatCheck(field?.value, field?.format)) {
                isFormValid = false;
                return {
                  ...field,
                  isError: true,
                  errorMessage: `${field?.text} ${t(`is_invalid`)}`,
                };
              } else {
                isFormValid = true;
              }
            }
          }
          return field;
        });

        setFields(formFields);
      }
    }
    if (level === `levelTwo`) {
      const fieldSet = fields?.flat(Infinity);
      const isError = fieldSet?.filter((field) => field?.isError)?.length > 0;
      if (isError) {
        isFormValid = false;
      }
      if (!isError) {
        const formFields = fields?.map((fieldSet) => {
          return fieldSet?.map((field) => {
            if (field?.isRequired) {
              if (isEmpty(field?.value)) {
                isFormValid = false;
                return {
                  ...field,
                  isError: true,
                  errorMessage: `${field?.text} ${t(`is_required`)}`,
                };
              }
              if (!isEmpty(field?.value)) {
                onFormatCheck(field?.value, field?.format)
                  ? (isFormValid = true)
                  : (isFormValid = false);
              }
            }
            return field;
          });
        });
        setFields(formFields);
      }
    }

    return isFormValid;
  };

  const onSelection = (
    optionValue,
    index,
    fields,
    setFields,
    fieldSetIndex
  ) => {
    const formFields = [...fields];
    const selectedField =
      level === `levelOne`
        ? formFields?.[index]
        : formFields?.[fieldSetIndex]?.[index];
    selectedField.value = optionValue;
    selectedField.isError = false;
    selectedField.errorMessage = '';
    setFields(formFields);
  };

  return {
    onInputChange,
    onBlur,
    onSubmit,
    onSelection,
  };
};

export default useFormValidation;
