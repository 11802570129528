import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const UploadFile = ({
  from,
  onRemoveFile,
  onUploadFile,
  files,
  isMultiple,
  setErrors,
  setWarning,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const [isDragActive, setIsDragActive] = useState(false);
  const [isUploadActive, setIsUploadActive] = useState(true);

  useEffect(() => {
    from === `credit_application` &&
      setIsUploadActive(files?.length === 3 ? false : true);
  }, [files, from]);

  const onFileValidation = (selectedFiles) => {
    const allFiles = [...files, ...selectedFiles];
    setErrors((errors) => ({
      ...errors,
      upload: allFiles?.length > 3 ? `${t(`upload_max_files`)}` : null,
    }));
    if (allFiles?.length < 4) {
      const bigFiles = allFiles?.filter((file) => file?.size > 7 * 1024 * 1024);
      const smallFiles = allFiles?.filter(
        (file) => file?.size < 7 * 1024 * 1024
      );
      if (bigFiles?.length) {
        const warningText = `${smallFiles?.length} ${t(`files_uploaded`)}, ${
          bigFiles?.length
        } ${t(`size_limit_exceeded`)}`;
        setWarning(warningText);
      } else {
        setWarning(``);
      }
      onUploadFile(smallFiles);
    }
  };

  const onFileUpload = (e) => {
    if (from === `credit_application`) {
      onFileValidation(Array.prototype.slice.call(e.target.files));
    } else {
      onUploadFile(e.target.files?.[0]);
    }
    e.target.value = '';
  };

  const onContainerHandler = () => {
    inputRef?.current?.click();
  };

  const onDragHandler = (e) => {
    e.preventDefault();
    if (e.type === `dragenter` || e.type === `dragover`) {
      setIsDragActive(true);
    }
    if (e.type === `dragleave`) {
      setIsDragActive(false);
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    if (from === `credit_application`) {
      onFileValidation(Array.prototype.slice.call(e.dataTransfer.files));
    } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onUploadFile(e.dataTransfer.files?.[0]);
    }
  };

  return (
    <>
      <div
        className={`upload_content_wrapper ${
          isUploadActive ? `upload_active` : `upload_inactive`
        }`}
        onClick={onContainerHandler}
        onDragEnter={onDragHandler}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          type='file'
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={(e) => onFileUpload(e, `english`)}
          accept={
            [
              `credit_risk`,
              `admin_client_upload_aging`,
              `send_credit_application`,
              `upload_credit_application`,
            ]?.includes(from)
              ? '.csv,.xls,.xlsx'
              : '.pdf'
          }
          multiple={isMultiple}
          disabled={!isUploadActive}
        />
        <div className='upload_icon_wrapper'>
          <svg
            width='36'
            height='24'
            viewBox='0 0 36 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.57122 24C4.31229 24 0.856934 20.5446 0.856934 16.2857C0.856934 12.9214 3.0105 10.0607 6.0105 9.00536C6.00515 8.86071 5.99979 8.71607 5.99979 8.57143C5.99979 3.83571 9.8355 0 14.5712 0C17.748 0 20.5176 1.725 22.0016 4.29643C22.8159 3.75 23.8016 3.42857 24.8569 3.42857C27.6962 3.42857 29.9998 5.73214 29.9998 8.57143C29.9998 9.225 29.8766 9.84643 29.6569 10.425C32.7855 11.0571 35.1426 13.8268 35.1426 17.1429C35.1426 20.9304 32.073 24 28.2855 24H8.57122ZM12.8034 12.375C12.2998 12.8786 12.2998 13.6929 12.8034 14.1911C13.3069 14.6893 14.1212 14.6946 14.6194 14.1911L16.7087 12.1018V19.2857C16.7087 19.9982 17.2819 20.5714 17.9944 20.5714C18.7069 20.5714 19.2801 19.9982 19.2801 19.2857V12.1018L21.3694 14.1911C21.873 14.6946 22.6873 14.6946 23.1855 14.1911C23.6837 13.6875 23.6891 12.8732 23.1855 12.375L18.8998 8.08929C18.3962 7.58571 17.5819 7.58571 17.0837 8.08929L12.798 12.375H12.8034Z'
              fill='#356EB1'
            />
          </svg>
        </div>
        <div className='upload_text_wrapper'>
          <div className='upload_title'>{`${t(`select_file_to_upload`)}`}</div>
          <div className='upload_sub_title'>{`${t(`drag_and_drop`)}`}</div>
        </div>

        {isUploadActive && isDragActive && (
          <div
            className='upload_drop_wrapper'
            onDragEnter={onDragHandler}
            onDragLeave={onDragHandler}
            onDragOver={onDragHandler}
            onDrop={onDrop}
          />
        )}
      </div>
      {isMultiple
        ? files?.map((uploadedFile, index) => {
            return (
              <div className='uploaded_file_wrapper' key={index}>
                <span className='file_name'>{uploadedFile?.name}</span>
                <svg
                  onClick={() => onRemoveFile(index)}
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M3.91875 0.414844L3.75 0.75H1.5C1.08516 0.75 0.75 1.08516 0.75 1.5C0.75 1.91484 1.08516 2.25 1.5 2.25H10.5C10.9148 2.25 11.25 1.91484 11.25 1.5C11.25 1.08516 10.9148 0.75 10.5 0.75H8.25L8.08125 0.414844C7.95469 0.159375 7.69453 0 7.41094 0H4.58906C4.30547 0 4.04531 0.159375 3.91875 0.414844ZM10.5 3H1.5L1.99688 10.9453C2.03438 11.5383 2.52656 12 3.11953 12H8.88047C9.47344 12 9.96562 11.5383 10.0031 10.9453L10.5 3Z'
                    fill='#334155'
                  />
                </svg>
              </div>
            );
          })
        : files && (
            <div className='uploaded_file_wrapper'>
              <span className='file_name'>{files}</span>
              <svg
                onClick={onRemoveFile}
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.91875 0.414844L3.75 0.75H1.5C1.08516 0.75 0.75 1.08516 0.75 1.5C0.75 1.91484 1.08516 2.25 1.5 2.25H10.5C10.9148 2.25 11.25 1.91484 11.25 1.5C11.25 1.08516 10.9148 0.75 10.5 0.75H8.25L8.08125 0.414844C7.95469 0.159375 7.69453 0 7.41094 0H4.58906C4.30547 0 4.04531 0.159375 3.91875 0.414844ZM10.5 3H1.5L1.99688 10.9453C2.03438 11.5383 2.52656 12 3.11953 12H8.88047C9.47344 12 9.96562 11.5383 10.0031 10.9453L10.5 3Z'
                  fill='#334155'
                />
              </svg>
            </div>
          )}
    </>
  );
};

export default UploadFile;
