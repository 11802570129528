import Button from '../../ReusableComponents/Button';
import ReportStatus from '../../ReusableComponents/ReportStatus';

export const adminCreditReportRenderer = (
  entity,
  header,
  translations,
  action,
  action2
) => {
  const onMoreOptions = () => {
    action(true);
    action2(entity);
  };

  if (header === `reference_id`) {
    return (
      <span className='credit_application_id_wrapper'>
        <span>{entity?.reference_id}</span>
        {entity?.isOrderedByCreditApplication && (
          <span className='credit_type_tag'>
            {translations?.alliance_credit_app}
          </span>
        )}
        {entity?.is_quick_report && (
          <span className='credit_type_tag last_resort_tag'>
            {translations?.last_resort_option}
          </span>
        )}
      </span>
    );
  }
  if (header === `tracking_status`) {
    return (
      <span className='report_tracking_icons_wrapper'>
        {entity?.indicator_data?.map((indicator, index) => {
          return <img key={index} src={indicator?.icon_path} alt='icon' />;
        })}
      </span>
    );
  }
  if (header === `order_date`) {
    return <span>{new Date(entity?.order_date)?.toLocaleDateString()}</span>;
  }
  if (header === `subject_name`) {
    return <span>{entity?.subject_name}</span>;
  }
  if (header?.[0] === `user_name` && header?.[1] === `company_name`) {
    return (
      <span className='company_user_wrapper'>
        <span className='user_name'>{entity?.user_name}</span>
        <span className='company_name'>{entity?.company_name?.en}</span>
      </span>
    );
  }
  if (header === `status`) {
    return (
      <span
        className={
          entity?.status === `Need Action` ? `NeedAction` : entity?.status
        }
      >
        {entity?.status}
      </span>
    );
  }
  if (header === `section_status`) {
    return <ReportStatus report={entity?.reports} />;
  }
  if (header === `actions`) {
    return (
      <span className='admin_credit_btns_wrapper'>
        <Button
          text={translations.download_final_report}
          isShowIcon={true}
          iconPosition='before'
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM7.64687 12.3531L4.14687 8.85312C4.00312 8.70937 3.9625 8.49375 4.0375 8.30937C4.1125 8.125 4.29688 8 4.5 8H6.5V5C6.5 4.44688 6.94688 4 7.5 4H8.5C9.05313 4 9.5 4.44688 9.5 5V8H11.5C11.7031 8 11.8844 8.12187 11.9625 8.30937C12.0406 8.49687 11.9969 8.7125 11.8531 8.85312L8.35312 12.3531C8.15937 12.5469 7.84062 12.5469 7.64687 12.3531Z'
                fill='#6D738D'
              />
            </svg>
          }
        />
        <span className='more_actions_wrapper' onClick={onMoreOptions}>
          <svg
            width='16'
            height='4'
            viewBox='0 0 16 4'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='2' cy='2' r='2' fill='#6D738D' />
            <circle cx='8' cy='2' r='2' fill='#6D738D' />
            <circle cx='14' cy='2' r='2' fill='#6D738D' />
          </svg>
        </span>
      </span>
    );
  }
};
