import React, { useEffect, useState, useCallback } from 'react';
import NavigationHeader from '../../ReusableComponents/NavigationHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getHistoricalList,
  getHistoricalMonths,
} from '../../slices/creditRiskSlice';
import Table from '../../ReusableComponents/Table';
import Skeleton from '../../ReusableComponents/Skeleton';
import { isEmpty, monthOptions } from '../../Utils/utils';
import SearchFilter from '../../ReusableComponents/SearchFilter';
import SelectFilter from '../../ReusableComponents/SelectFilter';

const HistoricalData = ({ isEN }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const riskOptions = [
    { name: `${t(`high`)}`, value: `High` },
    { name: `${t(`medium`)}`, value: `Medium` },
    { name: `${t(`low`)}`, value: `Low` },
    { name: `${t(`minimal`)}`, value: `Minimal` },
  ];

  const {
    isHistoricalDataFetching,
    historicalData,
    isMonthsFetching,
    isMonthsFetched,
    months,
  } = useSelector((state) => state.creditRisk);

  const tableProps = [
    {
      name: `${t(`client_id`)}`,
      dataIndex: 'clientId',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: true,
      isResizable: true,
    },
    {
      name: `${t(`subject_name`)}`,
      dataIndex: 'subjectName',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: true,
      isResizable: true,
    },
    {
      name: `${t(`risk`)}`,
      dataIndex: 'risk',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: true,
      isResizable: true,
    },
    {
      name: `${t(`score`)}`,
      dataIndex: 'score',
      minimumWidth: '20rem',
      initialWidth: '20rem',
      isSortable: true,
      isResizable: true,
    },
    {
      name: `${t(`last_updated_on`)}`,
      dataIndex: 'lastUpdatedOn',
      minimumWidth: '20rem',
      initialWidth: '1fr',
      isSortable: true,
      isResizable: true,
    },
  ];

  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [isResetFilters, setIsResetFilters] = useState(false);
  const [listFilters, setListFilters] = useState({
    month: '',
    year: '',
    riskType: [],
    query: '',
    companyId: '',
    sort: -1,
    sortBy: `subjectName`,
    limit: 25,
    skip: 0,
  });
  const [sortingIndex, setSortingIndex] = useState(1);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);

  useEffect(() => {
    id && dispatch(getHistoricalMonths({ companyId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (isMonthsFetched && months?.length) {
      setMonth(months?.[0]?.upto?.month);
      setYear(months?.[0]?.upto?.year);
    }
  }, [isMonthsFetched, months]);

  useEffect(() => {
    if (isMonthsFetched && month && year && id) {
      setListFilters((filters) => ({
        ...filters,
        month,
        year,
        companyId: id,
      }));
    }
  }, [isMonthsFetched, month, year, id]);

  useEffect(() => {
    if (
      !isEmpty(listFilters?.month) &&
      !isEmpty(listFilters?.year) &&
      !isEmpty(listFilters?.companyId)
    ) {
      setIsResetFilters(false);
      dispatch(getHistoricalList(listFilters));
    }
  }, [dispatch, listFilters]);

  const onFilterChange = useCallback((filterName, value) => {
    setListFilters((filters) => ({ ...filters, [filterName]: value }));
  }, []);

  const onSorting = (index) => {
    setSortingIndex(index);
    if (index === 0) {
      setListFilters((filters) => ({
        ...filters,
        sortBy: `clientId`,
        sort:
          filters?.sortBy === `clientId` ? (filters.sort === 1 ? -1 : 1) : -1,
      }));
    }
    if (index === 1) {
      setListFilters((filters) => ({
        ...filters,
        sortBy: `subjectName`,
        sort:
          filters?.sortBy === `subjectName`
            ? filters.sort === 1
              ? -1
              : 1
            : -1,
      }));
    }
    if (index === 2) {
      setListFilters((filters) => ({
        ...filters,
        sortBy: `risk`,
        sort: filters?.sortBy === `risk` ? (filters.sort === 1 ? -1 : 1) : -1,
      }));
    }
    if (index === 3) {
      setListFilters((filters) => ({
        ...filters,
        sortBy: `score`,
        sort: filters?.sortBy === `score` ? (filters.sort === 1 ? -1 : 1) : -1,
      }));
    }
    if (index === 4) {
      setListFilters((filters) => ({
        ...filters,
        sortBy: `lastUpdatedOn`,
        sort:
          filters?.sortBy === `lastUpdatedOn`
            ? filters.sort === 1
              ? -1
              : 1
            : -1,
      }));
    }
  };

  const onPeriodChange = (period) => {
    setMonth(period?.upto?.month);
    setYear(period?.upto?.year);
  };

  return (
    <>
      <NavigationHeader
        isIcon={true}
        title={`${t(`historical_data`)}`}
        isActions={false}
      />

      <div className='historical_navigation_wrapper'>
        {isMonthsFetching
          ? [1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6]?.map((_, index) => (
              <div className='historical_nav_loading' key={index}>
                <Skeleton />
              </div>
            ))
          : isMonthsFetched &&
            months?.length > 0 &&
            months?.map((period) => (
              <div
                className={`historical_nav ${
                  period?.upto?.month === month && period?.upto?.year === year
                    ? `nav_selected`
                    : null
                }`}
                key={period?.month}
                onClick={() => onPeriodChange(period)}
              >
                <span className='month'>
                  {isEN
                    ? monthOptions?.[period?.upto?.month - 1]?.value?.en
                    : monthOptions?.[period?.upto?.month - 1]?.value?.fr}
                </span>
                <span className='count'>({period?.count})</span>
              </div>
            ))}
      </div>
      <div className='search_filters_actions_wrapper'>
        <div className='search_filters admin_credit_report_filters'>
          <SearchFilter
            placeholder={`${t(`search_clients`)}`}
            listFilters={listFilters}
            onFilterChange={onFilterChange}
            filterName={`query`}
            isReset={isResetFilters}
          />

          <SelectFilter
            placeholder={`${t(`risk`)}`}
            options={riskOptions}
            onFilterChange={onFilterChange}
            filterName={`riskType`}
            isOptionsObject={false}
            isReset={isResetFilters}
          />
        </div>
      </div>
      <Table
        tableProps={tableProps}
        from={`credit_risk_historical_data`}
        defaultColumnHeight={`5rem`}
        isLoading={isHistoricalDataFetching || isMonthsFetching}
        tableData={historicalData?.discoveries}
        sortingIndex={sortingIndex}
        isSorted={listFilters?.sort < 0}
        onSorting={onSorting}
        selectedPageNumber={selectedPageNumber}
        setSelectedPageNumber={setSelectedPageNumber}
        total={historicalData?.totalCount}
        limit={listFilters?.limit}
        setListFilters={setListFilters}
      />
    </>
  );
};

export default HistoricalData;
