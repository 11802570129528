import Button from '../../ReusableComponents/Button';

export const userTeamsRenderer = (
  entity,
  header,
  action,
  entityIndex,
  rowIndex,
  isButtonOneLoading,
  translations,
  isEN
) => {
  if (header === `title`) {
    return <span>{entity?.title}</span>;
  }
  if (header === `full_name`) {
    return <span>{entity?.full_name}</span>;
  }
  if (header === `display_user_role`) {
    return <span>{entity?.display_user_role}</span>;
  }
  if (header === `email_id`) {
    return <span>{entity?.email_id}</span>;
  }
  if (header === `status`) {
    return (
      <>
        {entity?.is_verified && entity?.account_actived ? (
          <>
            <span className={entity?.is_active ? `Active` : `Inactive`}>
              {entity?.is_active
                ? translations?.active
                : translations?.inactive}
            </span>
          </>
        ) : !entity?.is_verified && !entity?.account_actived ? (
          <span className='Pending'>{translations?.pending}</span>
        ) : (
          <span className='Invited'>{translations?.invited}</span>
        )}
      </>
    );
  }
  if (header === `date_added`) {
    return <span>{entity?.date_added}</span>;
  }
  if (header === `company_access`) {
    return (
      <span>
        {entity?.company_access?.map((company, index) => {
          return <div key={index}>{isEN ? company?.en : company?.fr}</div>;
        })}
      </span>
    );
  }
  if (header === `actions`) {
    return (
      <span className='admin_client_btns_wrapper'>
        <Button
          type='secondary'
          text={translations?.edit}
          onClickHandler={() => action(entity?._id, entityIndex)}
          isDisabled={entity?.is_duplicate}
          isLoading={isButtonOneLoading && rowIndex === entityIndex}
        />
      </span>
    );
  }
};
