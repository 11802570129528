export const clientDatabaseReportsRenderer = (
  entity,
  header,
  translations,
  isEN
) => {
  if (header === `report_Id`) {
    return (
      <span className='transaction_item'>
        <span className='transaction_value'>
          {entity?.report_bought?.[0]?.reference_id}
        </span>
        <span className='transaction_value'>
          {new Date(entity?.order_date)?.toLocaleDateString()}
        </span>
        <span className='transaction_key'>
          {new Date(entity?.order_date)?.toLocaleTimeString()}
        </span>
      </span>
    );
  }
  if (header === `companyName`) {
    return (
      <span className='transaction_item'>
        <span className='transaction_value'>
          {isEN ? entity?.company_name?.en : entity?.company_name?.fr}
        </span>
        <span className='transaction_key_value'>
          <span className='transaction_key'>{translations?.ordered_by}: </span>
          <span className='transaction_value'>
            {entity?.user_data?.full_name || ` -`}
          </span>
        </span>
      </span>
    );
  }
  if (header === `subject`) {
    return (
      <span className='transaction_item'>
        <span className='transaction_value'>
          {entity?.report_bought?.[0]?.subject_name}
        </span>
        <span className='transaction_key_value'>
          <span className='transaction_key'>{translations?.ref}: </span>
          <span className='transaction_value'>
            {entity?.reference_id || ` -`}
          </span>
        </span>
      </span>
    );
  }
  if (header === `report_type`) {
    return (
      <span className='transaction_item'>
        <span className='transaction_value'>
          {entity?.report_bought?.[0]?.report_source_type}
        </span>
      </span>
    );
  }
};
