import Button from '../../ReusableComponents/Button';

export const creditApplicationRenderer = (
  entity,
  header,
  action,
  action2,
  action3,
  action4,
  entityIndex,
  rowIndex,
  isButtonOneLoading,
  translations
) => {
  if (header === `credit_app_id`) {
    return (
      <span className='credit_application_id_wrapper'>
        <span className='credit_type_tag'>
          {entity?.creditApplicationType === `consumer` ? (
            <>{translations?.consumer}</>
          ) : (
            <>{translations?.corporate}</>
          )}
        </span>
        <span className=''>{entity?.referenceId}</span>
        {entity?.isFilledWithPublicLink && (
          <span className='credit_type_tag'>{translations?.public_link}</span>
        )}
        <span className='credit_ref_number'>
          {entity?.referenceNumber ? (
            <>
              <span className='ref_text'>{translations?.ref}: </span>
              <span>{entity?.referenceNumber}</span>
            </>
          ) : null}
        </span>
      </span>
    );
  }
  if (header === `date`) {
    return (
      <span>
        {new Date(entity?.sentDate)?.toLocaleDateString()}
        <br />
        {new Date(entity?.sentDate)?.toLocaleTimeString()}
      </span>
    );
  }
  if (header === `subject`) {
    return (
      <span className='credit_application_subject'>
        <span>{entity?.subjectName}</span> <br />
        <span>{entity?.subjectEmail}</span>
      </span>
    );
  }
  if (header === `requested_by`) {
    return <span>{entity?.companyName}</span>;
  }
  if (header === `status`) {
    return (
      <span
        className={`status ${
          entity?.statusName === `Submitted`
            ? `Filled`
            : entity?.statusName === `Application Sent`
            ? `Sent`
            : entity?.statusName === `Opened`
            ? `Opened`
            : null
        }`}
      >
        {entity?.statusName === `Submitted`
          ? translations?.application_submitted
          : entity?.statusName === `Application Sent`
          ? translations?.application_sent
          : entity?.statusName === `Opened`
          ? translations?.application_opened
          : null}
      </span>
    );
  }
  if (header === `reports_ordered`) {
    return (
      <span className='credit_application_report_wrapper'>
        {entity?.reportList?.map((report) => {
          return (
            <div
              className='credit_application_reports'
              key={report?.referenceId}
            >
              <span className='credit_app_ref_id'>{report?.referenceId}</span>
              <span className={`${report?.statusCodeName}`}>
                {report?.statusCodeName}
              </span>
            </div>
          );
        })}
      </span>
    );
  }
  if (header === `actions`) {
    return (
      <span className='credit_application_btns_wrapper'>
        {entity?.isSubmitted ? (
          <>
            <Button
              type='secondary'
              text={translations?.download}
              onClickHandler={() => action(entity)}
            />
            <Button
              type='secondary'
              text={translations?.view}
              onClickHandler={() => action2(entity?._id, entityIndex)}
            />
            <Button
              type='primary'
              text={translations?.order_report}
              onClickHandler={() => action3(entity, entityIndex)}
              isLoading={isButtonOneLoading && entityIndex === rowIndex}
            />
          </>
        ) : (
          <Button
            type='primary'
            text={translations?.resend_email}
            onClickHandler={() => action4(entity?._id)}
          />
        )}
      </span>
    );
  }
};
