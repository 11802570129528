import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  isReportOrdering: false,
  isReportOrdered: false,
  isReportOrderError: false,
  bankOptions: [],
  isBankOptionFetching: false,
  isBankOptionFetched: false,
  apiMessage: '',
};

export const getBankOptions = createAsyncThunk(
  `bank_optins`,
  async (payload) => {
    try {
      const res = await api.post(`report/list-banks`, payload);
      if (res?.status === 200) {
        const { data } = res?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const orderReport = createAsyncThunk(`order_report`, async (payload) => {
  try {
    const url = `report/order-report`;
    const res = payload?.isUpdate
      ? await api.patch(url, payload)
      : await api.post(url, payload);
    if (res?.status === 200) {
      const { data } = res;
      return data;
    }
  } catch (error) {
    const { message } = error?.response?.data;
    throw new Error(`${message}`);
  }
});

const orderReportSlice = createSlice({
  name: `order_report`,
  initialState,
  reducers: {
    resetOrderReport: (state) => {
      state.apiMessage = '';
      state.isReportOrdered = false;
    },
  },
  extraReducers: {
    [getBankOptions.pending]: (state) => {
      state.bankOptions = [];
      state.isBankOptionFetching = true;
    },
    [getBankOptions.fulfilled]: (state, action) => {
      state.bankOptions = action?.payload?.[0]?.data;
      state.isBankOptionFetching = false;
      state.isBankOptionFetched = true;
    },
    [orderReport.pending]: (state) => {
      state.isReportOrdering = true;
      state.isReportOrdered = false;
      state.isReportOrderError = false;
    },
    [orderReport.fulfilled]: (state, action) => {
      state.isReportOrdering = false;
      state.isReportOrdered = true;
      state.isReportOrderError = false;
      state.apiMessage = action?.payload?.message;
    },
    [orderReport.rejected]: (state, action) => {
      state.isReportOrdering = false;
      state.isReportOrdered = false;
      state.isReportOrderError = true;
      state.message = action?.payload?.message;
    },
  },
});

export const { resetOrderReport } = orderReportSlice.actions;

export default orderReportSlice.reducer;
