import Button from '../../ReusableComponents/Button';

export const subjectListRenderer = (entity, header, action, translations) => {
  if (header === `company_name`) {
    return <span>{entity?.name}</span>;
  }
  if (header === `my_reference_no`) {
    return <span>{entity?.ref_number}</span>;
  }
  if (header === `date_added`) {
    return <span>{new Date(entity?.create_date).toLocaleDateString()}</span>;
  }
  if (header === `actions`) {
    return (
      <span className='watchlist_actions_wrapper'>
        <Button
          type='danger'
          text={translations.remove}
          onClickHandler={() => action(entity?._id)}
        />
      </span>
    );
  }
};
