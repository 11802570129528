import React from 'react';
import CloseIcon from '../images/close.svg';
import Button from './Button';

const DeletePopup = ({
  from,
  isShowPopup,
  header,
  onCancel,
  onSubmit,
  content,
  buttonText1,
  buttonText2,
  isLoading,
}) => {
  return (
    <div
      className={`popup_backdrop_wrapper ${
        isShowPopup ? `popup_visible` : `popup_invisible`
      }`}
    >
      <div className={`popup_container ${from}_delete_wrapper`}>
        <div className='popup_header'>
          {header}
          <img src={CloseIcon} alt='close' onClick={onCancel} />
        </div>
        <div className='popup_content confirmation_text_wrapper'>{content}</div>
        <div className='popup_footer'>
          <Button
            type='secondary'
            text={buttonText1}
            onClickHandler={onCancel}
          />
          <Button
            type={from === `admin_client_users` ? 'primary' : 'danger'}
            text={buttonText2}
            onClickHandler={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
