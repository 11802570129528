import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  buyReport,
  getActivities,
  getClientAddress,
  getClientInformation,
  getCorporateDetails,
  getLegalDetails,
  getMonitoringAvailableReports,
  getMonitoringOwnedReports,
  refreshDetails,
  resetHealthMonitoringStore,
} from '../../slices/creditRiskSlice';
import ProfileNavigation from './ProfileNavigation';
import CompanyDetails from './CompanyDetails';
import CorporateDetails from './CorporateDetails';
import LegalDetails from './LegalDetails';
import CreditReports from './CreditReports';
import Activities from './Activities';
import NewsFeed from './NewsFeed';
import ProfileInformation from './ProfileInformation';
import CompanyInformationLoading from './CompanyInformationLoading';
import NavigationHeader from '../../ReusableComponents/NavigationHeader';
import ActionPopup from '../../ReusableComponents/ActionPopup';
import ToastMessage from '../../ReusableComponents/ToastMessage';
import CreditRiskOrderReport from './CreditRiskOrderReport';
import { set } from 'idb-keyval';
import { camelToSnake } from '../../Utils/utils';
import DiscoverInformation from './DiscoverInformation';
import SideDrawer from '../../ReusableComponents/SideDrawer';
import AddClient from './AddClient';
import Button from '../../ReusableComponents/Button';

const CompanyProfile = ({ isEN }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const { companyId } = state;
  const updateClientRef = useRef();

  const reportRef = useRef();

  const {
    isClientInformationFetching,
    isCorporateDetailsFetching,
    isClientInformationFetchError,
    isLegalDetailsFetching,
    isClientInformationFetched,
    clientInformation,
    isCorporateDetailsFetched,
    corporateDetails,
    isLegalDetailsFetched,
    legalDetails,
    isActivitiesFetching,
    isActivitiesFetched,
    activities,
    isClientUpdating,
    isClientUpdated,
    isOwnedReportsFetching,
    ownedReports,
    isAvailableReportsFetching,
    availableReports,
    apiMessage,
    isRefreshRequestSending,
    isRefreshRequestSent,
    isDiscoverError,
    isRiskDiscovered,
    isReportOrdered,
    isReportOrderError,
    isReportBuying,
    isReportBought,
    isReportBuyError,
    isTypeDiscovered,
    isClientUpdateError,
    isClientAddressFetching,
    isClientAddressFetched,
    clientAddress,
  } = useSelector((state) => state.creditRisk);

  const [navigation, setNavigation] = useState('');
  const [reportType, setReportType] = useState(`owned`);
  const [isVisible, setIsVisible] = useState(false);
  const [isRefreshLegalDetails, setIsRefreshLegalDetails] = useState(false);
  const [isRefreshCorporateDetails, setIsRefreshCorporateDetails] =
    useState(false);
  const [isBuyReport, setIsBuyReport] = useState(false);
  const [isDownloadReport, setIsDownloadReport] = useState(false);
  const [report, setReport] = useState('');
  const [isTransactions, setIsTransactions] = useState(false);
  const [isOrderReport, setIsOrderReport] = useState(false);
  const [isDiscover, setIsDiscover] = useState(false);
  const [isOrderReportFromNavigation, setIsOrderReportFromNavigation] =
    useState(false);
  const [isOrderReportFromCreditReport, setIsOrderReportFromCreditReport] =
    useState(false);
  const [isEditClient, setIsEditClient] = useState(false);

  useEffect(() => {
    setNavigation(state?.isLegal ? `legal` : `company_details`);
  }, [state]);

  useEffect(() => {
    if (isClientAddressFetched) {
      setIsOrderReport(true);
    }
  }, [isClientAddressFetched]);

  const onGetClientInformation = useCallback(() => {
    id &&
      companyId &&
      dispatch(getClientInformation({ clientId: id, companyId }));
  }, [dispatch, id, companyId]);

  const onGetCorporateDetails = useCallback(() => {
    id &&
      companyId &&
      dispatch(getCorporateDetails({ clientId: id, companyId }));
  }, [dispatch, id, companyId]);

  const onGetLegalDetails = useCallback(() => {
    id && companyId && dispatch(getLegalDetails({ clientId: id, companyId }));
  }, [dispatch, id, companyId]);

  const onGetActivities = useCallback(() => {
    id &&
      companyId &&
      dispatch(
        getActivities({
          companyId,
          type: isTransactions ? `Transaction` : `General`,
          clientId: id,
        })
      );
  }, [dispatch, id, companyId, isTransactions]);

  const onGetCreditReports = useCallback(() => {
    id &&
      dispatch(
        (reportType === `owned`
          ? getMonitoringOwnedReports
          : getMonitoringAvailableReports)(id)
      );
  }, [dispatch, reportType, id]);

  useEffect(() => {
    navigation === `company_details` && onGetClientInformation();
    navigation === `corporate_details` && onGetCorporateDetails();
    navigation === `legal` && onGetLegalDetails();
    navigation === `activities` && onGetActivities();
    navigation === `credit_reports` && onGetCreditReports();
  }, [
    navigation,
    onGetClientInformation,
    onGetCorporateDetails,
    onGetLegalDetails,
    onGetActivities,
    onGetCreditReports,
  ]);

  // on actions
  useEffect(() => {
    if (isClientUpdated || isTypeDiscovered || isRiskDiscovered) {
      onGetClientInformation();
      isTypeDiscovered && setIsDiscover(false);
      if (isRiskDiscovered) {
        setIsDiscover(false);
        navigation === `legal` && onGetLegalDetails();
        navigation === `corporate_details` && onGetCorporateDetails();
      }
      isClientUpdated && setIsEditClient(false);
    }
  }, [
    isClientUpdated,
    isTypeDiscovered,
    isRiskDiscovered,
    onGetClientInformation,
    onGetLegalDetails,
    onGetCorporateDetails,
    navigation,
  ]);

  useEffect(() => {
    if (isRefreshRequestSent) {
      onCancelRefreshDetails();
      onGetCorporateDetails();
    }
  }, [isRefreshRequestSent, onGetCorporateDetails]);

  useEffect(() => {
    if (isReportBought) {
      onCancelReportActions();
    }
  }, [isReportBought]);

  const onRefreshDetails = (type) => {
    setIsVisible(true);
    setIsRefreshLegalDetails(type === `legal`);
    setIsRefreshCorporateDetails(type === `corporate`);
  };

  const onCancelRefreshDetails = () => {
    setIsVisible(false);
    setIsRefreshLegalDetails(false);
  };

  const onReportAction = (type, report) => {
    setIsVisible(true);
    setIsBuyReport(type === `available`);
    setIsDownloadReport(type === `owned`);
    setReport(report);
  };

  const onCancelReportActions = () => {
    setIsVisible(false);
    setIsBuyReport(false);
    setIsDownloadReport(false);
    setReport('');
  };

  const onBuyReport = () => {
    const payload = {
      company_id: companyId,
      chmsClientId: id,
      buying_data: [
        {
          _id: report?._id,
          order_id: report?.orderId,
          reference_id: report?.referenceId,
          subject_name: report?.subjectName,
          original_name: report?.originalName,
          dba_name: report?.dbaName,
          address: {
            civic_number: report?.civic_number,
            street_name: report?.street_name,
            suite: report?.suite,
            city: report?.city,
            state: report?.state,
            postal_code: report?.postal_code,
          },
          is_owned: report?.isOwned,
          report_type: report?.reportType,
          completion_date: report?.completionDate,
          report_id: report?.reportId,
          source: report?.source,
          isNoHistoryAvailable: report?.isNoHistoryAvailable,
          is90DaysHistoryAvailable: report?.is90DaysHistoryAvailable,
          isAllHistoryAvailable: report?.isAllHistoryAvailable,
          phone: report?.phone,
        },
      ],
    };
    dispatch(buyReport(payload));
  };
  const onDownloadreport = () => {
    const pdfPayload = {
      from: `credit_report_monitoring`,
      endPoint: report?.isPurchased
        ? `database/download-report`
        : `report/download-report`,
      payload: {
        report_order_id: report?._id,
        type: 'All',
        language: isEN ? `en` : `fr`,
      },
    };
    if (report?.isPurchased) {
      delete pdfPayload.payload.report_order_id;
      delete pdfPayload.payload.type;
      pdfPayload.payload.database_report_id = report?._id;
      pdfPayload.payload.from = `purchase-db`;
    }
    set(`pdfPayload`, pdfPayload);
    window.open(`/creditrisk-management/file`);
    setIsVisible(false);
    setIsDownloadReport(false);
  };
  const onRefresh = () => {
    id &&
      companyId &&
      dispatch(
        refreshDetails({
          companyId,
          clientId: id,
          informationType: isRefreshCorporateDetails
            ? `CorporateDetails`
            : `LegalDetails`,
        })
      );
  };

  const onToastCallback = () => dispatch(resetHealthMonitoringStore());

  const onOrderReportHandler = () => {
    id && dispatch(getClientAddress(id));
    setIsOrderReportFromNavigation(true);
    setIsOrderReportFromCreditReport(false);
  };

  const onCreditReportHandler = () => {
    id && dispatch(getClientAddress(id));
    setIsOrderReportFromCreditReport(true);
    setIsOrderReportFromNavigation(false);
  };

  const onOrderReport = () => {
    reportRef?.current?.onReportValidation();
  };

  const onDiscover = async (type) => {
    const payload = {
      informationType: type === `LegalName` ? `CorporateDetails` : type,
      clientIds: [id],
      headerText: `discover_${camelToSnake(type)}`,
      content: `discover_${camelToSnake(type)}_description`,
      companyId,
      isIndividual: [`LegalDetails`, `CorporateDetails`]?.includes(type)
        ? false
        : true,
    };
    await set(`discoverPayload`, payload);
    setIsDiscover(true);
  };

  const onCancelDiscover = () => {
    setIsDiscover(false);
    set(`discoverPayload`, null);
  };

  const onCancelOrderReport = () => {
    setIsOrderReportFromCreditReport(false);
    setIsOrderReportFromNavigation(false);
    setIsEditClient(false);
  };

  const onEditClientFromReport = () => {
    setIsEditClient(true);
    setIsOrderReportFromCreditReport(false);
    setIsOrderReportFromNavigation(false);
  };

  const onUpdateClient = () => {
    updateClientRef?.current?.validateClientForm();
  };

  const onCancel = () => setIsEditClient(false);

  return (
    <>
      {apiMessage && (
        <ToastMessage
          message={apiMessage}
          type={
            isRefreshRequestSent ||
            isClientUpdated ||
            isRiskDiscovered ||
            isReportOrdered ||
            isReportBought ||
            isTypeDiscovered
              ? `success`
              : isDiscoverError ||
                isReportBuyError ||
                isReportOrderError ||
                isClientInformationFetchError ||
                isClientUpdateError
              ? 'error'
              : null
          }
          action={onToastCallback}
        />
      )}
      <div className='company_profile_wrapper'>
        <NavigationHeader
          isIcon={true}
          title={`${t(`back_to_dashboard`)}`}
          isActions={true}
          actions={[
            {
              text: `${t(`order_report`)}`,
              type: 'primary',
              onClick: onOrderReportHandler,
              isLoading: isClientAddressFetching && isOrderReportFromNavigation,
            },
          ]}
        />

        <ProfileInformation
          clientInformation={clientInformation?.companyDetails?.[0]}
          isTypeDiscovered={isTypeDiscovered}
          onDiscover={onDiscover}
          isClientInformationFetching={isClientInformationFetching}
          isClientInformationFetched={isClientInformationFetched}
          clientDuePayment={clientInformation?.duePayment}
          clientDuePaymentTrend={clientInformation?.duePaymentTrend}
          isEN={isEN}
        />
        <ProfileNavigation
          navigation={navigation}
          setNavigation={setNavigation}
          isClientInformationFetching={isClientInformationFetching}
        />
        <div className='company_profile_content'>
          {navigation === `company_details` && (
            <>
              {isClientInformationFetching && <CompanyInformationLoading />}
              {isClientInformationFetched && (
                <CompanyDetails
                  clientInformation={clientInformation?.companyDetails}
                  setIsEditClient={setIsEditClient}
                />
              )}
            </>
          )}
          {navigation === `corporate_details` && (
            <>
              {isCorporateDetailsFetching && <CompanyInformationLoading />}
              {isCorporateDetailsFetched && (
                <CorporateDetails
                  corporateDetails={corporateDetails}
                  onRefreshDetails={onRefreshDetails}
                  onDiscover={onDiscover}
                />
              )}
            </>
          )}
          {navigation === `legal` && (
            <>
              {isLegalDetailsFetching && <CompanyInformationLoading />}
              {isLegalDetailsFetched && (
                <LegalDetails
                  legalDetails={legalDetails}
                  isEN={isEN}
                  onRefreshDetails={onRefreshDetails}
                  onDiscover={onDiscover}
                />
              )}
            </>
          )}
          {navigation === `credit_reports` && (
            <CreditReports
              onOrderReportHandler={onCreditReportHandler}
              reportType={reportType}
              setReportType={setReportType}
              onReportAction={onReportAction}
              isButtonLoading={
                isOrderReportFromCreditReport && isClientAddressFetching
              }
              isLoading={
                reportType === `owned`
                  ? isOwnedReportsFetching
                  : isAvailableReportsFetching
              }
              reports={
                reportType === `owned`
                  ? ownedReports?.orders
                  : availableReports?.reports
              }
            />
          )}
          {navigation === `activities` && (
            <>
              {isActivitiesFetching && <CompanyInformationLoading />}
              {isActivitiesFetched && (
                <Activities
                  activities={activities}
                  isEN={isEN}
                  isTransactions={isTransactions}
                  setIsTransactions={setIsTransactions}
                />
              )}
            </>
          )}
          {navigation === `news_feed` && <NewsFeed />}
        </div>
      </div>
      <ActionPopup
        isVisible={isVisible}
        from='credit_risk'
        isLoading={
          isRefreshLegalDetails || isRefreshCorporateDetails
            ? isRefreshRequestSending
            : isBuyReport
            ? isReportBuying
            : false
        }
        headerText={`${
          isBuyReport
            ? t(`buy_report`)
            : isDownloadReport
            ? t(`download_report`)
            : t(
                `refresh_${
                  isRefreshLegalDetails ? `legal` : `corporate`
                }_details`
              )
        }`}
        buttonText1={`${t(`cancel`)}`}
        buttonText2={`${
          isBuyReport
            ? t(`buy_report`)
            : isDownloadReport
            ? t(`download_report`)
            : t(`refresh`)
        }`}
        onCancel={
          isBuyReport || isDownloadReport
            ? onCancelReportActions
            : onCancelRefreshDetails
        }
        onSubmit={
          isBuyReport
            ? onBuyReport
            : isDownloadReport
            ? onDownloadreport
            : onRefresh
        }
      >
        <div className='credit_risk_discover_content'>
          {`${
            isBuyReport
              ? t(`buy_report_text`)
              : isDownloadReport
              ? t(`download_report_text`)
              : t(
                  `refresh_${
                    isRefreshLegalDetails
                      ? `legal`
                      : isRefreshCorporateDetails
                      ? `corporate`
                      : null
                  }_details_text`
                )
          }`}
        </div>
      </ActionPopup>
      <CreditRiskOrderReport
        ref={reportRef}
        onOrderReport={onOrderReport}
        isOrderReport={
          isOrderReport &&
          (isOrderReportFromNavigation || isOrderReportFromCreditReport)
        }
        setIsOrderReport={setIsOrderReport}
        clientId={id}
        isClientAddressFetched={isClientAddressFetched}
        clientAddress={clientAddress}
        onCancel={onCancelOrderReport}
        onEditClient={onEditClientFromReport}
      />
      <DiscoverInformation
        isVisible={isDiscover}
        onCancel={onCancelDiscover}
        companyId={companyId}
      />
      <SideDrawer
        from={`generic_drawer credit_risk_add_client`}
        isVisible={isEditClient}
        heading={`${t(`edit_company_details`)}`}
        content={
          <AddClient
            clientInformation={clientInformation?.companyDetails?.[0]}
            isEdit={true}
            ref={updateClientRef}
            companyId={companyId}
          />
        }
        footer={
          <>
            <Button
              type='quaternary'
              text={`${t(`cancel`)}`}
              onClickHandler={onCancel}
            />
            <Button
              type='primary'
              text={`${t(`update_client`)}`}
              onClickHandler={onUpdateClient}
              isLoading={isClientUpdating}
            />
          </>
        }
      />
    </>
  );
};

export default CompanyProfile;
