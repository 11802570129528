import React from 'react';
import { useTranslation } from 'react-i18next';
import { camelToSnake, money } from '../../Utils/utils';
import Button from '../../ReusableComponents/Button';
import EmptyState from './EmptyState';
import Container from '../../ReusableComponents/Container';

const LegalDetails = ({ legalDetails, isEN, onRefreshDetails, onDiscover }) => {
  const { t } = useTranslation();

  const renderTotalFiles = () =>
    legalDetails?.summary &&
    Object.values(legalDetails?.summary).reduce(
      (acc, { numberOfFiles }) => acc + (numberOfFiles ? +numberOfFiles : 0),
      0
    );

  const renderTotalAmount = () =>
    legalDetails?.summary &&
    Object.values(legalDetails?.summary).reduce(
      (acc, { totalAmount }) => acc + (totalAmount ? +totalAmount : 0),
      0
    );

  return (
    <>
      <div className='legal_details_wrapper'>
        {legalDetails === `locked` && (
          <div className='details_locked_wrapper'>
            <div className='details_locked_content'>
              <div
                className='details_locked'
                onClick={() => onDiscover(`LegalDetails`)}
              >
                <svg
                  width='13'
                  height='12'
                  viewBox='0 0 13 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='details_locked_icon'
                >
                  <rect
                    x='0.785156'
                    width='12'
                    height='12'
                    rx='6'
                    fill='#15803D'
                  />
                  <path
                    d='M6.79787 1.99841C7.07444 1.99841 7.29787 2.22185 7.29787 2.49841V3.05623C7.32287 3.05935 7.34631 3.06248 7.37131 3.06716C7.37756 3.06873 7.38225 3.06873 7.3885 3.07029L8.1385 3.20779C8.41037 3.25779 8.59006 3.51873 8.54006 3.78904C8.49006 4.05935 8.22912 4.2406 7.95881 4.1906L7.21662 4.05466C6.72756 3.98279 6.29631 4.03123 5.99319 4.15154C5.69006 4.27185 5.56819 4.43748 5.54006 4.5906C5.50881 4.75779 5.53225 4.85154 5.55881 4.90935C5.58694 4.97029 5.64475 5.03904 5.75881 5.1156C6.0135 5.28279 6.40412 5.39216 6.91037 5.52654L6.95569 5.53904C7.40256 5.65779 7.94944 5.80154 8.35569 6.06716C8.57756 6.21248 8.78694 6.40935 8.91662 6.68435C9.04944 6.96404 9.07756 7.27654 9.01662 7.60935C8.90881 8.2031 8.49944 8.59998 7.99162 8.80779C7.77756 8.89529 7.54475 8.95154 7.29787 8.97966V9.50154C7.29787 9.7781 7.07444 10.0015 6.79787 10.0015C6.52131 10.0015 6.29787 9.7781 6.29787 9.50154V8.95623C6.29162 8.95466 6.28381 8.95466 6.27756 8.9531H6.27444C5.89319 8.89373 5.26662 8.72966 4.84475 8.54216C4.59319 8.42966 4.47912 8.13435 4.59162 7.88279C4.70412 7.63123 4.99944 7.51716 5.251 7.62966C5.57756 7.77498 6.11506 7.91873 6.426 7.96716C6.92444 8.0406 7.33537 7.99841 7.6135 7.88435C7.87756 7.77654 7.99787 7.62029 8.03225 7.43279C8.06194 7.26716 8.0385 7.17185 8.01194 7.11404C7.98225 7.05154 7.92444 6.98279 7.80881 6.90623C7.55256 6.73904 7.16037 6.62966 6.65256 6.49529L6.60881 6.48435C6.1635 6.36248 5.61662 6.21716 5.21037 5.95154C4.9885 5.80623 4.78069 5.60779 4.651 5.33279C4.51975 5.0531 4.49319 4.7406 4.55569 4.40779C4.66819 3.81091 5.11506 3.42341 5.62287 3.22185C5.83069 3.13904 6.05881 3.08279 6.29787 3.04998V2.49841C6.29787 2.22185 6.52131 1.99841 6.79787 1.99841Z'
                    fill='white'
                  />
                </svg>

                <svg
                  width='17'
                  height='12'
                  viewBox='0 0 17 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.49877 0C6.33449 0 4.60145 0.985714 3.33984 2.15893C2.08627 3.32143 1.24788 4.71429 0.851451 5.67054C0.763058 5.88214 0.763058 6.11786 0.851451 6.32946C1.24788 7.28571 2.08627 8.67857 3.33984 9.84107C4.60145 11.0143 6.33449 12 8.49877 12C10.6631 12 12.3961 11.0143 13.6577 9.84107C14.9113 8.67589 15.7497 7.28571 16.1488 6.32946C16.2372 6.11786 16.2372 5.88214 16.1488 5.67054C15.7497 4.71429 14.9113 3.32143 13.6577 2.15893C12.3961 0.985714 10.6631 0 8.49877 0ZM4.64163 6C4.64163 4.97702 5.04801 3.99594 5.77136 3.27259C6.49471 2.54923 7.47579 2.14286 8.49877 2.14286C9.52175 2.14286 10.5028 2.54923 11.2262 3.27259C11.9495 3.99594 12.3559 4.97702 12.3559 6C12.3559 7.02298 11.9495 8.00406 11.2262 8.72741C10.5028 9.45077 9.52175 9.85714 8.49877 9.85714C7.47579 9.85714 6.49471 9.45077 5.77136 8.72741C5.04801 8.00406 4.64163 7.02298 4.64163 6ZM8.49877 4.28571C8.49877 5.23125 7.73002 6 6.78449 6C6.59431 6 6.41217 5.96786 6.24074 5.91161C6.09341 5.86339 5.92199 5.95446 5.92734 6.10982C5.93538 6.29464 5.96216 6.47946 6.01306 6.66429C6.38002 8.03571 7.79163 8.85 9.16306 8.48304C10.5345 8.11607 11.3488 6.70446 10.9818 5.33304C10.6845 4.22143 9.70145 3.47411 8.60859 3.42857C8.45324 3.42321 8.36217 3.59196 8.41038 3.74196C8.46663 3.91339 8.49877 4.09554 8.49877 4.28571Z'
                    fill='#334155'
                  />
                </svg>

                <span className='locked_title'>{`${t(
                  `show_legal_details`
                )}`}</span>
              </div>
              <div className='details_locked_text'>{`${t(
                `legal_locked`
              )}`}</div>
            </div>
          </div>
        )}
        {legalDetails !== `locked` && (
          <div className='corporate_actions_wrapper'>
            <div className='corporate_actions'>
              <div className='last_update'>
                <span>{`${t(`last_update`)} : `}</span>
                <span>
                  {new Date(legalDetails?.lastUpdateDate)?.toLocaleDateString(
                    'en-CA',
                    { year: 'numeric', month: '2-digit', day: '2-digit' }
                  )}
                </span>
              </div>
              <div className='month_tag'></div>
              <span className='action'>
                <Button
                  type='seven'
                  text={`${t(`refresh_data`)}`}
                  onClickHandler={() => onRefreshDetails(`legal`)}
                />
                <svg
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='dollar_icon'
                >
                  <rect width='12' height='12' rx='6' fill='#15803D' />
                  <path
                    d='M6.01272 1.99854C6.28928 1.99854 6.51272 2.22197 6.51272 2.49854V3.05635C6.53772 3.05947 6.56116 3.0626 6.58616 3.06729C6.59241 3.06885 6.59709 3.06885 6.60334 3.07041L7.35334 3.20791C7.62522 3.25791 7.80491 3.51885 7.75491 3.78916C7.70491 4.05947 7.44397 4.24072 7.17366 4.19072L6.43147 4.05479C5.94241 3.98291 5.51116 4.03135 5.20803 4.15166C4.90491 4.27197 4.78303 4.4376 4.75491 4.59072C4.72366 4.75791 4.74709 4.85166 4.77366 4.90947C4.80178 4.97041 4.85959 5.03916 4.97366 5.11572C5.22834 5.28291 5.61897 5.39229 6.12522 5.52666L6.17053 5.53916C6.61741 5.65791 7.16428 5.80166 7.57053 6.06729C7.79241 6.2126 8.00178 6.40947 8.13147 6.68447C8.26428 6.96416 8.29241 7.27666 8.23147 7.60947C8.12366 8.20322 7.71428 8.6001 7.20647 8.80791C6.99241 8.89541 6.75959 8.95166 6.51272 8.97978V9.50166C6.51272 9.77822 6.28928 10.0017 6.01272 10.0017C5.73616 10.0017 5.51272 9.77822 5.51272 9.50166V8.95635C5.50647 8.95478 5.49866 8.95479 5.49241 8.95322H5.48928C5.10803 8.89385 4.48147 8.72978 4.05959 8.54228C3.80803 8.42978 3.69397 8.13447 3.80647 7.88291C3.91897 7.63135 4.21428 7.51728 4.46584 7.62979C4.79241 7.7751 5.32991 7.91885 5.64084 7.96729C6.13928 8.04072 6.55022 7.99854 6.82834 7.88447C7.09241 7.77666 7.21272 7.62041 7.24709 7.43291C7.27678 7.26728 7.25334 7.17197 7.22678 7.11416C7.19709 7.05166 7.13928 6.98291 7.02366 6.90635C6.76741 6.73916 6.37522 6.62979 5.86741 6.49541L5.82366 6.48447C5.37834 6.3626 4.83147 6.21729 4.42522 5.95166C4.20334 5.80635 3.99553 5.60791 3.86584 5.33291C3.73459 5.05322 3.70803 4.74072 3.77053 4.40791C3.88303 3.81104 4.32991 3.42354 4.83772 3.22197C5.04553 3.13916 5.27366 3.08291 5.51272 3.0501V2.49854C5.51272 2.22197 5.73616 1.99854 6.01272 1.99854Z'
                    fill='white'
                  />
                </svg>
              </span>
            </div>
          </div>
        )}
        {!legalDetails?.summary && legalDetails !== `locked` ? (
          <div className='no_activity_wrapper'>
            <svg
              width='80'
              height='80'
              viewBox='0 0 80 80'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M40 80C50.6087 80 60.7828 75.7857 68.2843 68.2843C75.7857 60.7828 80 50.6087 80 40C80 29.3913 75.7857 19.2172 68.2843 11.7157C60.7828 4.21427 50.6087 0 40 0C29.3913 0 19.2172 4.21427 11.7157 11.7157C4.21427 19.2172 0 29.3913 0 40C0 50.6087 4.21427 60.7828 11.7157 68.2843C19.2172 75.7857 29.3913 80 40 80ZM57.6562 32.6562L37.6562 52.6562C36.1875 54.125 33.8125 54.125 32.3594 52.6562L22.3594 42.6562C20.8906 41.1875 20.8906 38.8125 22.3594 37.3594C23.8281 35.9062 26.2031 35.8906 27.6562 37.3594L35 44.7031L52.3438 27.3438C53.8125 25.875 56.1875 25.875 57.6406 27.3438C59.0938 28.8125 59.1094 31.1875 57.6406 32.6406L57.6562 32.6562Z'
                fill='#E2E8F0'
              />
            </svg>

            <span>{`${t(`no_legal_details_found`)}`}</span>
          </div>
        ) : legalDetails === `locked` ? (
          <EmptyState />
        ) : (
          <>
            <Container>
              <div className='credit_risk_legal_details'>
                <div className='credit_risk_title'>{`${t(`summary`)}`}</div>
                <div className='summary_wrapper'>
                  <div className='summary_header summary_list_header'>
                    <div />
                    <div>{`${t(`no_of_files`)}`}</div>
                    <div>{`${t(`total_amount`)}`}</div>
                    <div>{`${t(`date_of_last_item`)}`}</div>
                  </div>
                  <div className='summary_details'>
                    <div>{`${t(`bankruptcy`)}`}</div>
                    <div>
                      {legalDetails?.summary?.bankruptcy?.numberOfFiles}
                    </div>
                    <div>
                      {money(legalDetails?.summary?.bankruptcy?.totalAmount)}
                    </div>
                    <div>
                      {legalDetails?.summary?.bankruptcy?.lastItemDate
                        ? new Date(
                            legalDetails?.summary?.bankruptcy?.lastItemDate
                          )?.toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                        : `-`}
                    </div>
                  </div>
                  <div className='summary_details'>
                    <div>{`${t(`judgments`)}`}</div>
                    <div>{legalDetails?.summary?.judgments?.numberOfFiles}</div>
                    <div>
                      {money(legalDetails?.summary?.judgments?.totalAmount)}
                    </div>
                    <div>
                      {legalDetails?.summary?.judgments?.lastItemDate
                        ? new Date(
                            legalDetails?.summary?.judgments?.lastItemDate
                          )?.toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                        : `-`}
                    </div>
                  </div>
                  <div className='summary_details'>
                    <div>{t(camelToSnake(`courtRecords`))}</div>
                    <div>
                      {legalDetails?.summary?.courtRecords?.numberOfFiles}
                    </div>
                    <div>
                      {money(legalDetails?.summary?.courtRecords?.totalAmount)}
                    </div>
                    <div>
                      {legalDetails?.summary?.courtRecords?.lastItemDate
                        ? new Date(
                            legalDetails?.summary?.courtRecords?.lastItemDate
                          )?.toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                        : `-`}
                    </div>
                  </div>
                  <div className='summary_details'>
                    <div>{`${t(`collections`)}`}</div>
                    <div>
                      {legalDetails?.summary?.collections?.numberOfFiles}
                    </div>
                    <div>
                      {money(legalDetails?.summary?.collections?.totalAmount)}
                    </div>
                    <div>
                      {legalDetails?.summary?.collections?.lastItemDate
                        ? new Date(
                            legalDetails?.summary?.collections?.lastItemDate
                          )?.toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                        : `-`}
                    </div>
                  </div>
                  <div className='summary_details'>
                    <div>{t(camelToSnake(`constructionLiens`))}</div>
                    <div>
                      {legalDetails?.summary?.constructionLiens
                        ?.numberOfFiles || `-`}
                    </div>
                    <div>
                      {money(
                        legalDetails?.summary?.constructionLiens?.totalAmount
                      )}
                    </div>

                    <div>
                      {legalDetails?.summary?.constructionLiens?.lastItemDate
                        ? new Date(
                            legalDetails?.summary?.constructionLiens?.lastItemDate
                          )?.toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                        : `-`}
                    </div>
                  </div>
                  <div className='summary_details last_item'>
                    <div className=''>{`${t(`total_derogatory_alerts`)}`}</div>
                    <div>{renderTotalFiles()}</div>
                    <div>{money(renderTotalAmount())}</div>
                    <div>-</div>
                  </div>
                </div>
                {/* bankruptcy */}
                {legalDetails?.bankruptcy?.length > 0 && (
                  <>
                    <div className='credit_risk_title'>{`${t(
                      `bankruptcy`
                    )}`}</div>
                    <div className='summary_wrapper bankruptcy'>
                      <div className='summary_header summary_list_header'>
                        <div>
                          {`${t(`filing_date`)}`} <br />
                          {`${t(`file_no`)}`} <br />
                        </div>
                        <div>
                          {`${t(`debtor`)}`}
                          <br />
                          {`${t(`address`)}`}
                        </div>
                        <div>{`${t(`procedure_reason`)}`}</div>
                        <div>{`${t(`location`)}`}</div>
                        <div>{`${t(`licensed_trustee_name`)}`}</div>
                        <div>{`${t(`creditor_meeting_date`)}`}</div>
                      </div>
                      <>
                        {legalDetails?.bankruptcy?.map((record, index) => (
                          <div className='summary_details' key={index}>
                            <div>
                              {record?.filingDate
                                ? new Date(
                                    record?.filingDate
                                  ).toLocaleDateString('en-CA', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                  })
                                : `-`}
                              <br />
                              {record?.fileNumber}
                            </div>
                            <div>
                              {record?.debtorName} <br />
                              {record?.address}
                            </div>
                            <div>
                              {isEN
                                ? record?.procedure?.en
                                : record?.procedure?.fr}
                            </div>
                            <div>{record?.location}</div>
                            <div>{record?.trusteeName}</div>
                            <div>
                              {record?.creditorMeetingDate
                                ? new Date(
                                    record?.creditorMeetingDate
                                  ).toLocaleDateString('en-CA', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                  })
                                : `-`}
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </>
                )}
                {/* court records */}
                {legalDetails?.civilCourtRecords?.length > 0 && (
                  <>
                    <div className='credit_risk_title'>{`${t(
                      `civil_court_records`
                    )}`}</div>
                    <div className='summary_wrapper civil_records'>
                      <div className='summary_header summary_list_header'>
                        <div>{`${t(`filing_date`)}`}</div>
                        <div>{`${t(`defendant_address`)}`}</div>
                        <div>{`${t(`procedure_reason`)}`}</div>
                        <div>{`${t(`amount`)}`}</div>
                        <div>{`${t(`plaintiff`)}`}</div>
                        <div>{`${t(`court_location`)}`}</div>
                      </div>
                      <>
                        {legalDetails?.civilCourtRecords?.map(
                          (record, index) => (
                            <div className='summary_details' key={index}>
                              <div>
                                {record?.filingDate
                                  ? new Date(
                                      record?.filingDate
                                    ).toLocaleDateString('en-CA', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                    })
                                  : `-`}
                              </div>
                              <div>
                                <b>{record?.defendant}</b> <br />
                                {record?.address}
                              </div>
                              <div>
                                <span>
                                  <b>
                                    {isEN
                                      ? record?.procedure?.en
                                      : record?.procedure?.fr}
                                  </b>
                                </span>
                                <br />
                                <span>
                                  {isEN
                                    ? record?.reason?.en
                                    : record?.reason?.fr}
                                </span>
                              </div>
                              <div>{money(record?.amount)}</div>
                              <div>{record?.plaintiff}</div>
                              <div>{record?.courtLocation}</div>
                            </div>
                          )
                        )}
                      </>
                    </div>
                  </>
                )}
                {/* collections */}
                {legalDetails?.collections?.length > 0 && (
                  <>
                    <div className='credit_risk_title'>{`${t(
                      `collections`
                    )}`}</div>
                    <div className='summary_wrapper collections'>
                      <div className='summary_header summary_list_header'>
                        <div>
                          {`${t(`filing_date`)}`} <br />
                          {`${t(`file_no`)}`} <br />
                        </div>
                        <div>
                          {`${t(`debtor`)}`}
                          <br />
                          {`${t(`address`)}`}
                        </div>
                        <div>{`${t(`procedure_reason`)}`}</div>
                        <div>{`${t(`amount`)}`}</div>
                      </div>
                      <>
                        {legalDetails?.collections?.map((record, index) => (
                          <div className='summary_details' key={index}>
                            <div>
                              {record?.filingDate
                                ? new Date(
                                    record?.filingDate
                                  ).toLocaleDateString('en-CA', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                  })
                                : `-`}
                              <br />
                              {record?.fileNumber}
                            </div>
                            <div>
                              {record?.debtorName} <br />
                              {record?.address}
                            </div>
                            <div>
                              <span>
                                {isEN
                                  ? record?.procedure?.en
                                  : record?.procedure?.fr}
                              </span>
                              <br />
                              <span>
                                {isEN ? record?.reason?.en : record?.reason?.fr}
                              </span>
                            </div>
                            <div>{money(record?.amount)}</div>
                          </div>
                        ))}
                      </>
                    </div>
                  </>
                )}
                {/* construction liens */}
                {legalDetails?.constructionLiens?.length > 0 && (
                  <>
                    <div className='credit_risk_title'>{`${t(
                      `construction_liens`
                    )}`}</div>
                    <div className='summary_wrapper bankruptcy construction_liens'>
                      <div className='summary_header summary_list_header'>
                        <div>
                          {`${t(`filing_date`)}`} <br />
                          {`${t(`file_no`)}`} <br />
                        </div>
                        <div>{`${t(`procedure_reason`)}`}</div>
                        <div>{`${t(`lot_no`)}`}</div>
                        <div>{`${t(`address`)}`}</div>
                        <div>{`${t(`amount`)}`}</div>
                        <div>{`${t(`owner`)}`}</div>
                      </div>
                      <>
                        {legalDetails?.constructionLiens?.map(
                          (record, index) => (
                            <React.Fragment key={index}>
                              <div className='summary_details'>
                                <div>
                                  {record?.filingDate
                                    ? new Date(
                                        record?.filingDate
                                      ).toLocaleDateString('en-CA', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                      })
                                    : `-`}
                                  <br />
                                  {record?.fileNumber}
                                </div>
                                <div>
                                  <span>
                                    {isEN
                                      ? record?.procedure?.en
                                      : record?.procedure?.fr}
                                  </span>
                                  <br />
                                  <span>
                                    {isEN
                                      ? record?.reason?.en
                                      : record?.reason?.fr}
                                  </span>
                                </div>
                                <div>{record?.lotNumber}</div>
                                <div>{record?.address}</div>
                                <div>{money(record?.amount)}</div>
                                <div>{record?.owner}</div>
                              </div>
                              <div className='summary_wrapper construction_internal'>
                                <div className='summary_header summary_list_header'>
                                  <div>{`${t(`type`)}`}</div>
                                  <div>{`${t(`name`)}`}</div>
                                  <div>{`${t(`address`)}`}</div>
                                </div>
                                <>
                                  {record?.participants?.map(
                                    (participant, index) => (
                                      <div
                                        className='summary_details construction_internal_details'
                                        key={index}
                                      >
                                        <div>
                                          {isEN
                                            ? participant?.type?.en
                                            : participant?.type?.fr}
                                        </div>
                                        <div>{participant?.name}</div>
                                        <div>{participant?.address}</div>
                                      </div>
                                    )
                                  )}
                                </>
                              </div>
                            </React.Fragment>
                          )
                        )}
                      </>
                    </div>
                  </>
                )}
              </div>
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default LegalDetails;
