import React, { useLayoutEffect, useRef, useState } from 'react';

const Button = ({
  type,
  text,
  onClickHandler,
  isLoading,
  isDisabled,
  icon,
  iconPosition,
  isShowIcon,
}) => {
  const buttonRef = useRef();

  const [width, setWidth] = useState('');

  useLayoutEffect(() => {
    buttonRef && setWidth(buttonRef?.current?.clientWidth);
  }, [buttonRef]);

  const buttonRenderer = () => {
    if (isShowIcon) {
      return iconPosition === `after` ? (
        <div className='button_icon_wrapper'>
          <span>{text}</span>
          {icon}
        </div>
      ) : (
        <div className='button_icon_wrapper'>
          {icon}
          <span>{text}</span>
        </div>
      );
    } else {
      return text;
    }
  };

  return (
    <button
      className={`${type} ${
        isLoading ? `button_loading button_loading_${type}` : null
      }`}
      onClick={onClickHandler}
      disabled={isDisabled}
      ref={buttonRef}
      // style={{ width: width }}
    >
      {!isLoading && buttonRenderer()}
    </button>
  );
};

export default React.memo(Button);
