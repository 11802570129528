import Router from './Router';
import Button from './NewComponents/Button/Button';
import { WhitePlusIcon } from './icons/icons';
const App = () => {
  return (
    <div className='App'>
      <Router />
      {/* <Button
        text={`button text`}
        isIconOnly={true}
        isIcon={true}
        isIconLeft={true}
        icon={WhitePlusIcon}
      />
      <Button
        text={`button text`}
        isIcon={true}
        isIconLeft={true}
        icon={WhitePlusIcon}
        size='small'
      />
      <Button
        text={`button text`}
        isIcon={true}
        isIconLeft={false}
        icon={WhitePlusIcon}
      />
      <Button isLoading={true} text='loading button' />
      <Button isIconOnly={true} icon={WhitePlusIcon} />
      <Button variant='destructive' text={`delete`} isLoading={true} />
      <Button variant='destructive' size='small' text={`delete`} />
      <Button variant='outlined' text='some very long text here' />
      <Button variant='outlined' size='small' />
      <Button variant='subtle' text='something' isLoading={true} />
      <Button variant='subtle' size='small' />
      <Button variant='ghost' text='ghost' isLoading={true} />
      <Button variant='ghost' size='small' />
      <Button variant='link' />
      <Button variant='link' size='small' /> */}
    </div>
  );
};

export default App;
