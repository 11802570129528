import Button from '../../ReusableComponents/Button';
import CircularProgressBar from '../../ReusableComponents/CircularProgressBar';
import Tooltip from '../../ReusableComponents/Tooltip';
import { money } from '../../Utils/utils';

export const creditRiskRenderer = (
  entity,
  header,
  action,
  action2,
  action3,
  action4,
  action5,
  action6,
  entityIndex,
  expandedIndex,
  rowIndex,
  isButtonOneLoading,
  isButtonTwoLoading,
  translations,
  action7,
  action8,
  toolTip,
  isExpandedView,
  discoveredEntity
) => {
  const renderLocked = (type) => {
    return (
      <div
        className='credit_risk_locked_wrapper'
        onMouseEnter={() => action7('reveal', type, entityIndex)}
        onMouseLeave={action8}
      >
        <span className='credit_risk_locked_text'>{translations?.locked}</span>
        <span
          className='credit_risk_locked'
          onClick={() => action2(type, entity, `single`)}
        >
          <svg
            width='14'
            height='12'
            viewBox='0 0 14 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.99893 0.75C5.10518 0.75 3.58877 1.6125 2.48486 2.63906C1.38799 3.65625 0.654395 4.875 0.30752 5.71172C0.230176 5.89687 0.230176 6.10312 0.30752 6.28828C0.654395 7.125 1.38799 8.34375 2.48486 9.36094C3.58877 10.3875 5.10518 11.25 6.99893 11.25C8.89268 11.25 10.4091 10.3875 11.513 9.36094C12.6099 8.34141 13.3435 7.125 13.6927 6.28828C13.77 6.10312 13.77 5.89687 13.6927 5.71172C13.3435 4.875 12.6099 3.65625 11.513 2.63906C10.4091 1.6125 8.89268 0.75 6.99893 0.75ZM3.62393 6C3.62393 5.10489 3.97951 4.24645 4.61244 3.61351C5.24538 2.98058 6.10382 2.625 6.99893 2.625C7.89403 2.625 8.75248 2.98058 9.38541 3.61351C10.0183 4.24645 10.3739 5.10489 10.3739 6C10.3739 6.89511 10.0183 7.75355 9.38541 8.38649C8.75248 9.01942 7.89403 9.375 6.99893 9.375C6.10382 9.375 5.24538 9.01942 4.61244 8.38649C3.97951 7.75355 3.62393 6.89511 3.62393 6ZM6.99893 4.5C6.99893 5.32734 6.32627 6 5.49893 6C5.33252 6 5.17314 5.97187 5.02314 5.92266C4.89424 5.88047 4.74424 5.96016 4.74893 6.09609C4.75596 6.25781 4.77939 6.41953 4.82393 6.58125C5.14502 7.78125 6.38018 8.49375 7.58018 8.17266C8.78018 7.85156 9.49268 6.61641 9.17158 5.41641C8.91143 4.44375 8.05127 3.78984 7.09502 3.75C6.95908 3.74531 6.8794 3.89297 6.92158 4.02422C6.9708 4.17422 6.99893 4.33359 6.99893 4.5Z'
              fill='#334155'
            />
          </svg>
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='dollar_lock_icon'
          >
            <rect width='12' height='12' rx='6' fill='#15803D' />
            <path
              d='M6.01272 1.99854C6.28928 1.99854 6.51272 2.22197 6.51272 2.49854V3.05635C6.53772 3.05947 6.56116 3.0626 6.58616 3.06729C6.59241 3.06885 6.59709 3.06885 6.60334 3.07041L7.35334 3.20791C7.62522 3.25791 7.80491 3.51885 7.75491 3.78916C7.70491 4.05947 7.44397 4.24072 7.17366 4.19072L6.43147 4.05479C5.94241 3.98291 5.51116 4.03135 5.20803 4.15166C4.90491 4.27197 4.78303 4.4376 4.75491 4.59072C4.72366 4.75791 4.74709 4.85166 4.77366 4.90947C4.80178 4.97041 4.85959 5.03916 4.97366 5.11572C5.22834 5.28291 5.61897 5.39229 6.12522 5.52666L6.17053 5.53916C6.61741 5.65791 7.16428 5.80166 7.57053 6.06729C7.79241 6.2126 8.00178 6.40947 8.13147 6.68447C8.26428 6.96416 8.29241 7.27666 8.23147 7.60947C8.12366 8.20322 7.71428 8.6001 7.20647 8.80791C6.99241 8.89541 6.75959 8.95166 6.51272 8.97978V9.50166C6.51272 9.77822 6.28928 10.0017 6.01272 10.0017C5.73616 10.0017 5.51272 9.77822 5.51272 9.50166V8.95635C5.50647 8.95478 5.49866 8.95479 5.49241 8.95322H5.48928C5.10803 8.89385 4.48147 8.72978 4.05959 8.54228C3.80803 8.42978 3.69397 8.13447 3.80647 7.88291C3.91897 7.63135 4.21428 7.51728 4.46584 7.62979C4.79241 7.7751 5.32991 7.91885 5.64084 7.96729C6.13928 8.04072 6.55022 7.99854 6.82834 7.88447C7.09241 7.77666 7.21272 7.62041 7.24709 7.43291C7.27678 7.26728 7.25334 7.17197 7.22678 7.11416C7.19709 7.05166 7.13928 6.98291 7.02366 6.90635C6.76741 6.73916 6.37522 6.62979 5.86741 6.49541L5.82366 6.48447C5.37834 6.3626 4.83147 6.21729 4.42522 5.95166C4.20334 5.80635 3.99553 5.60791 3.86584 5.33291C3.73459 5.05322 3.70803 4.74072 3.77053 4.40791C3.88303 3.81104 4.32991 3.42354 4.83772 3.22197C5.04553 3.13916 5.27366 3.08291 5.51272 3.0501V2.49854C5.51272 2.22197 5.73616 1.99854 6.01272 1.99854Z'
              fill='white'
            />
          </svg>
        </span>
        <Tooltip
          isVisible={
            toolTip?.isVisible &&
            entityIndex === toolTip?.entityIndex &&
            toolTip?.type === type &&
            toolTip?.entity === `reveal`
          }
          from='client_credit_risk_list'
          position='center'
          text={toolTip?.text}
          displayPosition={entityIndex === 0 ? `reverse` : `normal`}
        />
      </div>
    );
  };

  const renderRefresh = (type) => {
    return (
      <div
        className='credit_risk_locked_wrapper'
        onMouseEnter={() => action7('refresh', type, entityIndex)}
        onMouseLeave={action8}
      >
        <span
          className='credit_risk_locked'
          onClick={() => action2(type, entity, `single`)}
        >
          <svg
            width='13'
            height='12'
            viewBox='0 0 13 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_10278_5635)'>
              <path
                d='M2.60537 4.74841C2.78584 4.23748 3.07881 3.75701 3.49131 3.34685C4.95615 1.88201 7.33037 1.88201 8.79522 3.34685L9.196 3.74998H8.39209C7.97725 3.74998 7.64209 4.08513 7.64209 4.49998C7.64209 4.91482 7.97725 5.24998 8.39209 5.24998H11.0054H11.0147C11.4296 5.24998 11.7647 4.91482 11.7647 4.49998V1.87498C11.7647 1.46013 11.4296 1.12498 11.0147 1.12498C10.5999 1.12498 10.2647 1.46013 10.2647 1.87498V2.69998L9.85459 2.28748C7.80381 0.236694 4.48037 0.236694 2.42959 2.28748C1.85771 2.85935 1.44521 3.53201 1.19209 4.25154C1.05381 4.64294 1.26006 5.06951 1.64912 5.20779C2.03818 5.34607 2.46709 5.13982 2.60537 4.75076V4.74841ZM1.05615 6.78044C0.938965 6.8156 0.826465 6.87888 0.735059 6.97263C0.641309 7.06638 0.578027 7.17888 0.545215 7.30076C0.538184 7.32888 0.531152 7.35935 0.526465 7.38982C0.519434 7.42966 0.51709 7.46951 0.51709 7.50935V10.125C0.51709 10.5398 0.852246 10.875 1.26709 10.875C1.68193 10.875 2.01709 10.5398 2.01709 10.125V9.30232L2.42959 9.71247C4.48037 11.7609 7.80381 11.7609 9.85225 9.71247C10.4241 9.1406 10.839 8.46794 11.0921 7.74841C11.2304 7.35701 11.0241 6.93044 10.6351 6.79216C10.246 6.65388 9.81709 6.86013 9.67881 7.24919C9.49834 7.76013 9.20537 8.2406 8.79287 8.65076C7.32803 10.1156 4.95381 10.1156 3.48897 8.65076L3.48662 8.64841L3.08584 8.24998H3.89209C4.30693 8.24998 4.64209 7.91482 4.64209 7.49998C4.64209 7.08513 4.30693 6.74998 3.89209 6.74998H1.27646C1.23896 6.74998 1.20146 6.75232 1.16396 6.75701C1.12646 6.76169 1.09131 6.76873 1.05615 6.78044Z'
                fill='#334155'
              />
            </g>
            <defs>
              <clipPath id='clip0_10278_5635'>
                <rect
                  width='12'
                  height='12'
                  fill='white'
                  transform='translate(0.14209)'
                />
              </clipPath>
            </defs>
          </svg>

          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='dollar_lock_icon'
          >
            <rect width='12' height='12' rx='6' fill='#15803D' />
            <path
              d='M6.01272 1.99854C6.28928 1.99854 6.51272 2.22197 6.51272 2.49854V3.05635C6.53772 3.05947 6.56116 3.0626 6.58616 3.06729C6.59241 3.06885 6.59709 3.06885 6.60334 3.07041L7.35334 3.20791C7.62522 3.25791 7.80491 3.51885 7.75491 3.78916C7.70491 4.05947 7.44397 4.24072 7.17366 4.19072L6.43147 4.05479C5.94241 3.98291 5.51116 4.03135 5.20803 4.15166C4.90491 4.27197 4.78303 4.4376 4.75491 4.59072C4.72366 4.75791 4.74709 4.85166 4.77366 4.90947C4.80178 4.97041 4.85959 5.03916 4.97366 5.11572C5.22834 5.28291 5.61897 5.39229 6.12522 5.52666L6.17053 5.53916C6.61741 5.65791 7.16428 5.80166 7.57053 6.06729C7.79241 6.2126 8.00178 6.40947 8.13147 6.68447C8.26428 6.96416 8.29241 7.27666 8.23147 7.60947C8.12366 8.20322 7.71428 8.6001 7.20647 8.80791C6.99241 8.89541 6.75959 8.95166 6.51272 8.97978V9.50166C6.51272 9.77822 6.28928 10.0017 6.01272 10.0017C5.73616 10.0017 5.51272 9.77822 5.51272 9.50166V8.95635C5.50647 8.95478 5.49866 8.95479 5.49241 8.95322H5.48928C5.10803 8.89385 4.48147 8.72978 4.05959 8.54228C3.80803 8.42978 3.69397 8.13447 3.80647 7.88291C3.91897 7.63135 4.21428 7.51728 4.46584 7.62979C4.79241 7.7751 5.32991 7.91885 5.64084 7.96729C6.13928 8.04072 6.55022 7.99854 6.82834 7.88447C7.09241 7.77666 7.21272 7.62041 7.24709 7.43291C7.27678 7.26728 7.25334 7.17197 7.22678 7.11416C7.19709 7.05166 7.13928 6.98291 7.02366 6.90635C6.76741 6.73916 6.37522 6.62979 5.86741 6.49541L5.82366 6.48447C5.37834 6.3626 4.83147 6.21729 4.42522 5.95166C4.20334 5.80635 3.99553 5.60791 3.86584 5.33291C3.73459 5.05322 3.70803 4.74072 3.77053 4.40791C3.88303 3.81104 4.32991 3.42354 4.83772 3.22197C5.04553 3.13916 5.27366 3.08291 5.51272 3.0501V2.49854C5.51272 2.22197 5.73616 1.99854 6.01272 1.99854Z'
              fill='white'
            />
          </svg>
        </span>
        <Tooltip
          isVisible={
            toolTip?.isVisible &&
            entityIndex === toolTip?.entityIndex &&
            toolTip?.type === type &&
            toolTip?.entity === `refresh`
          }
          from='client_credit_risk_list'
          position='center'
          text={toolTip?.text}
          displayPosition={entityIndex === 0 ? `reverse` : `normal`}
        />
      </div>
    );
  };

  if (header === `accountNumber`) {
    return <span className='client_id'>{entity?.accountNumber}</span>;
  }
  if (
    header === `legalName` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return entity?.legalName === `locked` ? (
      renderLocked(`LegalName`)
    ) : (
      <span
        className={`legal_name ${
          entity?.id === discoveredEntity?.id &&
          discoveredEntity?.type === `legalName`
            ? `reveal_animation`
            : null
        }`}
      >
        {entity?.legalName}
      </span>
    );
  }

  if (header === `clientName`) {
    return (
      <span
        className={`client_name ${
          entity?.status === `Over Limit` ? `name_over_limit` : ``
        }`}
      >
        <Tooltip
          isVisible={
            toolTip?.isVisible &&
            entityIndex === toolTip?.entityIndex &&
            toolTip?.type === `info`
          }
          from='client_credit_risk_list_icon'
          position='center'
          text={toolTip?.text}
          displayPosition={entityIndex === 0 ? `reverse` : `normal`}
        />
        {entity?.status === `Not Found` && (
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onMouseEnter={() => action7(`not_found`, `info`, entityIndex)}
            onMouseLeave={action8}
          >
            <path
              d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM8 4C8.41562 4 8.75 4.33437 8.75 4.75V8.25C8.75 8.66562 8.41562 9 8 9C7.58437 9 7.25 8.66562 7.25 8.25V4.75C7.25 4.33437 7.58437 4 8 4ZM7 11C7 10.7348 7.10536 10.4804 7.29289 10.2929C7.48043 10.1054 7.73478 10 8 10C8.26522 10 8.51957 10.1054 8.70711 10.2929C8.89464 10.4804 9 10.7348 9 11C9 11.2652 8.89464 11.5196 8.70711 11.7071C8.51957 11.8946 8.26522 12 8 12C7.73478 12 7.48043 11.8946 7.29289 11.7071C7.10536 11.5196 7 11.2652 7 11Z'
              fill='#B91C1C'
            />
          </svg>
        )}
        {entity?.status === `Over Limit` && (
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onMouseEnter={() => action7(`over_limit`, `info`, entityIndex)}
            onMouseLeave={action8}
          >
            <g clipPath='url(#clip0_10930_6917)'>
              <path
                d='M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0ZM7.25 3.75V8C7.25 8.25 7.375 8.48438 7.58437 8.625L10.5844 10.625C10.9281 10.8562 11.3938 10.7625 11.625 10.4156C11.8562 10.0687 11.7625 9.60625 11.4156 9.375L8.75 7.6V3.75C8.75 3.33437 8.41562 3 8 3C7.58437 3 7.25 3.33437 7.25 3.75Z'
                fill='#94A3B8'
              />
            </g>
            <defs>
              <clipPath id='clip0_10930_6917'>
                <rect width='16' height='16' fill='white' />
              </clipPath>
            </defs>
          </svg>
        )}
        {entity?.status === `Error` && (
          <span className='client_icon'>
            <svg
              width='19'
              height='16'
              viewBox='0 0 19 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onMouseEnter={() => action7(`error`, `info`, entityIndex)}
              onMouseLeave={action8}
            >
              <path
                d='M9.14154 0C9.64868 0 10.1165 0.267857 10.3737 0.707143L18.088 13.85C18.3487 14.2929 18.3487 14.8393 18.0951 15.2821C17.8415 15.725 17.3665 16 16.8558 16H1.42725C0.916539 16 0.441539 15.725 0.187967 15.2821C-0.0656039 14.8393 -0.0620325 14.2893 0.19511 13.85L7.9094 0.707143C8.16654 0.267857 8.6344 0 9.14154 0ZM9.14154 4.57143C8.66654 4.57143 8.2844 4.95357 8.2844 5.42857V9.42857C8.2844 9.90357 8.66654 10.2857 9.14154 10.2857C9.61654 10.2857 9.99868 9.90357 9.99868 9.42857V5.42857C9.99868 4.95357 9.61654 4.57143 9.14154 4.57143ZM10.2844 12.5714C10.2844 12.2683 10.164 11.9776 9.94966 11.7633C9.73533 11.549 9.44464 11.4286 9.14154 11.4286C8.83843 11.4286 8.54774 11.549 8.33342 11.7633C8.11909 11.9776 7.99868 12.2683 7.99868 12.5714C7.99868 12.8745 8.11909 13.1652 8.33342 13.3796C8.54774 13.5939 8.83843 13.7143 9.14154 13.7143C9.44464 13.7143 9.73533 13.5939 9.94966 13.3796C10.164 13.1652 10.2844 12.8745 10.2844 12.5714Z'
                fill='#D97706'
              />
            </svg>
          </span>
        )}
        {entity?.status === `Pending` && (
          <Button type='secondary' text='' isLoading={true} />
        )}
        <span style={{ position: 'relative' }}>{entity?.clientName}</span>
      </span>
    );
  }
  if (
    header === `risk` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return entity?.risk === `locked` ? (
      renderLocked(`Risk`)
    ) : (
      <div
        className={`risk risk_${entity?.risk} ${
          entity?.riskExpired ? `risk_expired` : ``
        } ${
          entity?.id === discoveredEntity?.id &&
          discoveredEntity?.type === `risk`
            ? `reveal_animation`
            : null
        }`}
      >
        {entity?.riskExpired && (
          <svg
            width='10'
            height='8'
            viewBox='0 0 10 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={`score_expired_icon expired_${entity?.risk}`}
          >
            <path
              d='M4.57077 0C4.82434 0 5.05827 0.133929 5.18684 0.353571L9.04398 6.925C9.17434 7.14643 9.17434 7.41964 9.04756 7.64107C8.92077 7.8625 8.68327 8 8.42791 8H0.713627C0.458269 8 0.220769 7.8625 0.0939837 7.64107C-0.032802 7.41964 -0.0310162 7.14464 0.0975552 6.925L3.9547 0.353571C4.08327 0.133929 4.3172 0 4.57077 0ZM4.57077 2.28571C4.33327 2.28571 4.1422 2.47679 4.1422 2.71429V4.71429C4.1422 4.95179 4.33327 5.14286 4.57077 5.14286C4.80827 5.14286 4.99934 4.95179 4.99934 4.71429V2.71429C4.99934 2.47679 4.80827 2.28571 4.57077 2.28571ZM5.1422 6.28571C5.1422 6.13416 5.08199 5.98882 4.97483 5.88165C4.86767 5.77449 4.72232 5.71429 4.57077 5.71429C4.41922 5.71429 4.27387 5.77449 4.16671 5.88165C4.05954 5.98882 3.99934 6.13416 3.99934 6.28571C3.99934 6.43727 4.05954 6.58261 4.16671 6.68978C4.27387 6.79694 4.41922 6.85714 4.57077 6.85714C4.72232 6.85714 4.86767 6.79694 4.97483 6.68978C5.08199 6.58261 5.1422 6.43727 5.1422 6.28571Z'
              fill='#334155'
            />
          </svg>
        )}
        {translations[entity?.risk?.toLowerCase()]}
        {entity?.riskExpired && renderRefresh(`Risk`)}
      </div>
    );
  }
  if (
    header === `score` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return entity?.score === `locked` ? (
      renderLocked(`Score`)
    ) : (
      <div
        className={`score score_${entity?.risk} ${
          entity?.scoreExpired ? `score_expired` : ``
        } ${
          entity?.id === discoveredEntity?.id &&
          discoveredEntity?.type === `score`
            ? `reveal_animation`
            : null
        }`}
      >
        {entity?.scoreExpired && (
          <svg
            width='10'
            height='8'
            viewBox='0 0 10 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={`score_expired_icon expired_${entity?.risk}`}
          >
            <path
              d='M4.57077 0C4.82434 0 5.05827 0.133929 5.18684 0.353571L9.04398 6.925C9.17434 7.14643 9.17434 7.41964 9.04756 7.64107C8.92077 7.8625 8.68327 8 8.42791 8H0.713627C0.458269 8 0.220769 7.8625 0.0939837 7.64107C-0.032802 7.41964 -0.0310162 7.14464 0.0975552 6.925L3.9547 0.353571C4.08327 0.133929 4.3172 0 4.57077 0ZM4.57077 2.28571C4.33327 2.28571 4.1422 2.47679 4.1422 2.71429V4.71429C4.1422 4.95179 4.33327 5.14286 4.57077 5.14286C4.80827 5.14286 4.99934 4.95179 4.99934 4.71429V2.71429C4.99934 2.47679 4.80827 2.28571 4.57077 2.28571ZM5.1422 6.28571C5.1422 6.13416 5.08199 5.98882 4.97483 5.88165C4.86767 5.77449 4.72232 5.71429 4.57077 5.71429C4.41922 5.71429 4.27387 5.77449 4.16671 5.88165C4.05954 5.98882 3.99934 6.13416 3.99934 6.28571C3.99934 6.43727 4.05954 6.58261 4.16671 6.68978C4.27387 6.79694 4.41922 6.85714 4.57077 6.85714C4.72232 6.85714 4.86767 6.79694 4.97483 6.68978C5.08199 6.58261 5.1422 6.43727 5.1422 6.28571Z'
              fill='#334155'
            />
          </svg>
        )}
        {entity?.score}
        {entity?.scoreExpired && renderRefresh(`Score`)}
      </div>
    );
  }
  if (
    header === `totalDue` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return <span className='total_due'>{money(entity?.totalDue)}</span>;
  }
  if (
    header === `allianceMasterId` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return (
      <span className='alliance_master_id'>{entity?.allianceMasterId}</span>
    );
  }
  if (
    header === `nsf` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return <span className='nsf'>{entity?.nsf}</span>;
  }
  if (
    header === `longestDuePeriod` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return (
      <span className='longest_due_period'>{entity?.longestDuePeriod}</span>
    );
  }
  if (
    header === `averageTimeToPay` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return (
      <span className='average_time_to_pay'>{entity?.averageTimeToPay}</span>
    );
  }
  if (
    header === `currentCreditLimit` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return (
      <span className='current_credit_limit'>
        <CircularProgressBar
          progress={
            (Number(entity?.totalDue) / Number(entity?.currentCreditLimit)) *
            100
          }
        />
        {money(entity?.currentCreditLimit)}
      </span>
    );
  }

  if (
    header === `accountOpeningDate` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return (
      <span className='account_opening_date'>
        {new Date(entity?.accountOpeningDate)?.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) || `-`}
      </span>
    );
  }
  if (
    header === `lastUpdateDate` &&
    ![`Not Found`, `Pending`, `Error`, `Over Limit`]?.includes(entity?.status)
  ) {
    return (
      <span className='last_update_date'>
        {new Date(entity?.lastUpdateDate)?.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) || `-`}
      </span>
    );
  }
  if (header === `actions`) {
    return (
      <>
        {[`Not Found`, `Error`, `Over Limit`]?.includes(entity?.status) ? (
          <span className='credit_risk_actions'>
            <Button
              type='danger'
              text={translations?.remove}
              onClickHandler={() => action3(entity)}
            />
            <Button
              type='primary'
              text={translations.edit_details}
              onClickHandler={() => action5(entityIndex, entity?.id)}
              isLoading={entityIndex === rowIndex && isButtonOneLoading}
            />
          </span>
        ) : entity?.status === `Pending` ? (
          <span className='credit_risk_actions'>
            <span className='processing'>{translations?.processing}...</span>
          </span>
        ) : (
          <span className='credit_risk_actions'>
            <svg
              width='12'
              height='8'
              viewBox='0 0 12 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => action4(entityIndex)}
              className={`expand_icon ${
                entityIndex === expandedIndex && isExpandedView
                  ? 'expand_active'
                  : ''
              }`}
            >
              <path
                d='M6.60516 7.17705C6.27045 7.51176 5.72688 7.51176 5.39217 7.17705L0.251032 2.03592C-0.0836773 1.70121 -0.0836773 1.15764 0.251032 0.822932C0.585741 0.488222 1.12931 0.488222 1.46402 0.822932L6 5.35891L10.536 0.825609C10.8707 0.4909 11.4143 0.4909 11.749 0.825609C12.0837 1.16032 12.0837 1.70389 11.749 2.0386L6.60783 7.17973L6.60516 7.17705Z'
                fill='#334155'
              />
            </svg>

            <Button
              type='seven'
              text={translations?.order_report}
              onClickHandler={() => action6(entityIndex, entity?.id)}
              isLoading={entityIndex === rowIndex && isButtonTwoLoading}
            />
            <Button
              type='primary'
              text={translations?.more_details}
              onClickHandler={() => action(entity?.id)}
            />
          </span>
        )}
      </>
    );
  }
};
