import Button from "../../ReusableComponents/Button";
import Switch from "../../ReusableComponents/Switch";

export const adminUserRenderer = (
  entity,
  header,
  action,
  action2,
  action3,
  action4,
  action5,
  entityIndex,
  rowIndex,
  isButtonOneLoading,
  isButtonTwoLoading,
  isButtonThreeLoading,
  translations
) => {
  if (header === `full_name`) {
    return <span>{entity?.full_name}</span>;
  }
  if (header === `display_user_role`) {
    return <span>{entity?.display_user_role}</span>;
  }
  if (header === `email_id`) {
    return <span>{entity?.email_id}</span>;
  }
  if (header === `company_access`) {
    return (
      <span>
        {entity?.company_access?.map((company, index) => {
          return <div key={index}>{company?.en}</div>;
        })}
      </span>
    );
  }
  if (header === `status`) {
    return (
      <>
        {entity?.is_verified && entity?.account_actived ? (
          <>
            <span onClick={() => action(entity?._id, entity?.is_active)}>
              <Switch isActive={entity?.is_active} />
            </span>
            <span
              className={`switch_label_${
                entity?.is_active ? `active` : `inactive`
              }`}
            >
              {entity?.is_active
                ? translations?.active
                : translations?.inactive}
            </span>
          </>
        ) : !entity?.is_verified && !entity?.account_actived ? (
          <span className="Pending">{translations?.pending}</span>
        ) : (
          <span className="Invited">{translations?.invited}</span>
        )}
      </>
    );
  }
  if (header === `actions`) {
    return (
      <span className="admin_client_users">
        {entity?.is_verified && entity?.account_actived ? (
          <Button
            isDisabled={!entity?.is_active}
            type="secondary"
            text={translations?.simulate}
            onClickHandler={() => action5(entity?._id, entityIndex)}
            isLoading={isButtonThreeLoading && entityIndex === rowIndex}
          />
        ) : !entity?.is_verified && !entity?.account_actived ? (
          <Button
            type="secondary"
            text={translations?.accept}
            isLoading={isButtonTwoLoading && rowIndex === entityIndex}
            onClickHandler={() => action3(entity?._id, entityIndex)}
          />
        ) : (
          <Button
            type="secondary"
            text={translations?.resend_invite}
            onClickHandler={() => action4(entity?.email_id)}
          />
        )}

        <Button
          type="primary"
          isLoading={isButtonOneLoading && rowIndex === entityIndex}
          text={translations?.show_details}
          onClickHandler={() => action2(entity?._id, entityIndex)}
        />
      </span>
    );
  }
};
