import React, { useState, useEffect, useRef } from 'react';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';

const TableSelect = ({
  placeholder,
  options,
  updateOptions,
  updateFilters,
  optionCount,
  updateAppliedFilters,
  isSingleSelection,
  isOptionsObject,
  isEN,
}) => {
  const inputRef = useRef();
  const containerRef = useRef();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [cursorIndex, setCursorIndex] = useState(0);

  const clickedOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsInputFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener(`click`, clickedOutside, true);
    return () => {
      document.removeEventListener(`click`, clickedOutside, true);
    };
  });

  const onFocus = () => {
    setIsInputFocused(true);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 38 && cursorIndex > 1) {
      setCursorIndex((cursorIndex) => (cursorIndex > 0 ? cursorIndex - 1 : 0));
    }
    if (e.keyCode === 40 && cursorIndex < options.length) {
      setCursorIndex((cursorIndex) => cursorIndex + 1);
    }
    if (e.keyCode === 13) {
      onOptionEnter();
    }
    if (e.keyCode === 27) {
      setIsInputFocused(false);
    }
  };

  const onInputChange = (e) => {
    e.preventDefault();
  };

  const onInputClick = () => {
    setIsInputFocused(true);
  };

  const onOptionEnter = () => {
    if (cursorIndex > 0) {
      onCheckboxChange(cursorIndex - 1);
    }
  };

  const onCheckboxChange = (optionIndex, option) => {
    const allOptions = [...options];
    const selectedOption = allOptions[optionIndex];
    selectedOption.isSelected = !allOptions[optionIndex].isSelected;
    const otherOptions = allOptions?.map((option, index) => {
      return {
        ...option,
        isSelected: index === optionIndex ? selectedOption?.isSelected : false,
      };
    });

    updateOptions(isSingleSelection ? otherOptions : allOptions);
    const selectedOptionValues = allOptions
      ?.filter((option) => option.isSelected)
      ?.map((option) => option.value);
    updateFilters(
      isSingleSelection
        ? selectedOption?.isSelected
          ? option?.value
          : ''
        : selectedOptionValues
    );
    updateAppliedFilters(option);
  };

  return (
    <div className='table_field_wrapper table_select_wrapper'>
      <input
        ref={inputRef}
        placeholder={placeholder}
        className='table_input_select'
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onChange={onInputChange}
        value={optionCount > 0 ? placeholder : ''}
        onClick={onInputClick}
        readOnly
        disabled={options?.length === 0}
      />
      {optionCount > 0 && <span className='select_count'>{optionCount}</span>}
      <span className='select_icon'>
        {isInputFocused ? <AiFillCaretUp /> : <AiFillCaretDown />}
      </span>
      <div
        ref={containerRef}
        className={`select_options_wrapper ${
          isInputFocused ? `showOptions` : ``
        } `}
      >
        {options.map((option, index) => {
          return (
            <div
              className={`select_options ${
                cursorIndex - 1 === index ? `highlighted` : ``
              }`}
              key={index}
            >
              <label className='checkbox_label_wrapper'>
                <input
                  type='checkbox'
                  className='checkbox_wrapper'
                  value={`something`}
                  onChange={() => onCheckboxChange(index, option)}
                  checked={option.isSelected}
                />
                {isOptionsObject
                  ? isEN
                    ? option?.name?.en
                    : option?.name?.fr
                  : option?.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableSelect;
