import Button from "../../ReusableComponents/Button";

export const groupRenderer = (
  entity,
  header,
  action,
  action2,
  isAdmin,
  translations
) => {
  if (header === `group_name`) {
    return <span>{entity?.name}</span>;
  }
  if (header === `members`) {
    return <span>{entity?.company_count}</span>;
  }
  if (header === `status`) {
    return (
      <span className={entity?.is_active ? `Active` : `Inactive`}>
        {entity?.is_active ? translations?.active : translations?.inactive}
      </span>
    );
  }
  if (header === `actions`) {
    return (
      <span className="group_btns_wrapper">
        {isAdmin && (
          <Button
            type="secondary"
            text={translations?.edit}
            onClickHandler={() => action(entity)}
          />
        )}
        <Button
          type="primary"
          text={translations?.show_more}
          onClickHandler={() => action2(entity)}
        />
      </span>
    );
  }
};
