import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  isLoading: false,
  groups: [],
  isGroupAdding: false,
  isGroupAdded: false,
  isGroupAddError: false,
  apiMessage: '',
  isGroupUpdating: false,
  isGroupUpdated: false,
  isGroupUpdateError: false,
  events: [],
  isEventFetching: false,
  isEventsFetched: false,
  eventDetails: '',
  isEventDetailsFetching: false,
  isEventDetailsFetched: false,
  subjectsList: [],
  isSubjectListFetched: false,
  isSubjectListFetching: false,
  isIndividualReportDownloading: false,
  isIndividualReportDownloaded: false,
  individualReport: '',
  finalReport: '',
  isFinalReportDownloading: false,
  isFinalReportDownloaded: false,
  isMeetCreating: false,
  isMeetCreated: false,
  isMeetUpdating: false,
  isMeetUpdated: false,
  isEventSubjectAdding: false,
  isEventSubjectAdded: false,
  isEventSubjectAddError: false,
  isEventSubjectUpdating: false,
  isEventSubjectUpdated: false,
  isEventSubjectUpdateError: false,
  isMerging: false,
  isMerged: false,
  isReportReleasing: false,
  isReportReleased: false,
  isEventSubmissionListFetching: false,
  isEventSubmissionListFetched: false,
  eventSubmissionList: [],
  isEventSubmissionDetailsFetching: false,
  isEventSubmissionDetailsFetched: false,
  eventSubmissionDetails: [],
  isEventReportsFetching: false,
  isEventReportsFetched: false,
  eventReports: [],
  eventReportOrderCount: 0,
  isEventSubmissionDetailsUpdating: false,
  isEventSubmissionDetailsUpdated: false,
  isEventListFetched: false,
  eventList: [],
  isSubjectDetailsFetching: false,
  isSubjectDetailsFetched: false,
  subjectDetails: {},
  isAlertAdding: false,
  isAlertAdded: false,
  isAlertAddError: false,
  isCategoriesFetching: false,
  isCategoriesFetched: false,
  categories: [],
  isTopicsFetching: false,
  isTopicsFetched: false,
  topics: [],
  isTopicDetailsFetching: false,
  isTopicDetailsFetched: false,
  topicDetails: '',
  isTopicChatsFetching: false,
  isTopicChatsFetched: false,
  topicChats: [],
  isCreditRequesting: false,
  isCreditRequested: false,
  isCreditRequestError: false,
  isCreditSubmitting: false,
  isCreditSubmitted: false,
  isCreditSubmitError: false,
  isAlertsFetching: false,
  isAlertsFetched: false,
  alerts: [],
  isCategoryCreating: false,
  isCategoryCreated: false,
  isCategoryDeleting: false,
  isCategoryDeleted: false,
  isTopicEdited: false,
  isTopicEditing: false,
  isMessageUpdating: false,
  isMessageUpdated: false,
  isMessageDeleting: false,
  isMessageDeleted: false,
  isTopicAdding: false,
  isTopicAdded: false,
  isTopicAddError: false,
  isMembersFetching: false,
  isMembersFetched: false,
  members: [],
  isMessageSending: false,
  isMessageSent: false,
  isSubjectSearching: false,
  isSubjectSearched: false,
  subjects: [],
  isDatabaseReportFetching: false,
  isDatabaseReportFetched: false,
  databaseReports: [],
};

export const getGroups = createAsyncThunk(`get_groups`, async (payload) => {
  try {
    const res = await api.post(`group/list`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const createGroup = createAsyncThunk(`create_group`, async (payload) => {
  try {
    const res = await api.post(`group/create-group`, payload);
    const { message } = res?.data;
    return message;
  } catch (error) {}
});

export const updateGroup = createAsyncThunk(`update_group`, async (payload) => {
  try {
    const res = await api.post(`group/edit-group`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getEvents = createAsyncThunk(`get_events`, async (payload) => {
  try {
    const res = await api.post(`group/event-list`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getEventDetails = createAsyncThunk(
  `get_event_details`,
  async (payload) => {
    try {
      const res = await api.post(`group/event-details`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getEventSubjectList = createAsyncThunk(
  `get_event_subject_list`,
  async (payload) => {
    try {
      const res = await api.post(`group/subject-list-all`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getEventSubjectListForUser = createAsyncThunk(
  `get_event_subject_list_for_user`,
  async (payload) => {
    try {
      const res = await api.post(`group/subject-list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const downloadIndividualReport = createAsyncThunk(
  `download_individual_report`,
  async (payload) => {
    try {
      const res = await api.post(`group/download-subject-report-file`, payload);
      const data = res?.data;
      return data;
    } catch (error) {}
  }
);

export const downloadFinalReport = createAsyncThunk(
  `download_final_report`,
  async (payload) => {
    try {
      const res = await api.post(`group/download-event-report-file`, payload);
      const data = res?.data;
      return data;
    } catch (error) {}
  }
);

export const createMeeting = createAsyncThunk(
  `create_meeting`,
  async (payload) => {
    try {
      const res = await api.post(`group/create-event`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const updateMeeting = createAsyncThunk(
  `update_meeting`,
  async (payload) => {
    try {
      const res = await api.post(`group/edit-event`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const addEventSubject = createAsyncThunk(
  `add_event_subject`,
  async (payload) => {
    try {
      const res = await api.post(`group/create-subject`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateEventSubject = createAsyncThunk(
  `update_event_subject`,
  async (payload) => {
    try {
      const res = await api.post(`group/edit-subject`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const mergeEventSubject = createAsyncThunk(
  `merge_event_subject`,
  async (payload) => {
    try {
      const res = await api.post(`group/merge-subject`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const releaseReport = createAsyncThunk(
  `release_report`,
  async (payload) => {
    try {
      const res = await api.post(`group/enable-report`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getEventSubmissionList = createAsyncThunk(
  `get_submission_list`,
  async (payload) => {
    try {
      const res = await api.post(`group/fill-credit-count-list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getEventSubmissionDetails = createAsyncThunk(
  `get_submission_details`,
  async (payload) => {
    try {
      const res = await api.post(`group/credit-submitted-details`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getEventReports = createAsyncThunk(
  `get_event_reports`,
  async (payload) => {
    try {
      const res = await api.post(`group/report-list-all`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getEventList = createAsyncThunk(
  `get_event_list`,
  async (payload) => {
    try {
      const res = await api.post(`group/event-list-report`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateEventSubmissionDetails = createAsyncThunk(
  `update_event_submission_details`,
  async (payload) => {
    try {
      const res = await api.post(`group/edit-credit-reference`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getSubjectDetails = createAsyncThunk(
  `get_subject_details`,
  async (payload) => {
    try {
      const res = await api.post(`group/subject-detail`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const subjectAddAlert = createAsyncThunk(
  `add_alert`,
  async (payload) => {
    try {
      const res = await api.post(`group/create-alert`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getCategories = createAsyncThunk(
  `get_categories`,
  async (payload) => {
    try {
      const res = await api.post(`group/list-category`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getTopics = createAsyncThunk(`get_topics`, async (payload) => {
  try {
    const res = await api.post(`group/get-topic`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {
    const { message } = error?.response?.data;
    throw new Error(`${message}`);
  }
});

export const getTopicDetails = createAsyncThunk(
  `get_topic_details`,
  async (payload) => {
    try {
      const res = await api.post(`group/get-topic-details`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getTopicChats = createAsyncThunk(
  `get_topic_chats`,
  async (payload) => {
    try {
      const res = await api.post(`group/get-credit-chat`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const addCreditReference = createAsyncThunk(
  `add_credit_reference`,
  async (payload) => {
    try {
      const res = await api.post(`group/create-topic`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const submitCreditReference = createAsyncThunk(
  `submit_credit_reference`,
  async (payload) => {
    try {
      const res = await api.post(`group/create-credits-chat`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getFlashAlerts = createAsyncThunk(
  `get_flash_alerts`,
  async (payload) => {
    try {
      const res = await api.post(`group/alert-list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const createFlashAlerts = createAsyncThunk(
  `create_flash_alert`,
  async (payload) => {
    try {
      const res = await api.post(`group/create-alert`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const createCategory = createAsyncThunk(
  `group_create_category`,
  async (payload) => {
    try {
      const res = await api.post(`group/create-category`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  `group_delete_category`,
  async (payload) => {
    try {
      const res = await api.post(`group/delete-category`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getCategoryTopics = createAsyncThunk(
  `get_category_topics`,
  async (payload) => {
    try {
      const res = await api.post(`group/get-topic`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateTopic = createAsyncThunk(`update_topic`, async (payload) => {
  try {
    const res = await api.post(
      `group/edit-topic-general
    `,
      payload
    );
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getTopicMessages = createAsyncThunk(
  `topic_messages`,
  async (payload) => {
    try {
      const res = await api.post(
        `group/get-chat
    `,
        payload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const updateMessage = createAsyncThunk(`edit_chat`, async (payload) => {
  try {
    const res = await api.post(`group/edit-chat`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const deleteMessage = createAsyncThunk(
  `delete_chat`,
  async (payload) => {
    try {
      const res = await api.post(`group/delete-chat`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const addTopic = createAsyncThunk(`add_topic`, async (payload) => {
  try {
    const res = await api.post(`group/create-topic-general`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const getMembers = createAsyncThunk(`get_members`, async (payload) => {
  try {
    const res = await api.post(`group/group-companies`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const sendMessage = createAsyncThunk(`send_message`, async (payload) => {
  try {
    const res = await api.post(`group/create-chat`, payload);
    const { data } = res?.data;
    return data;
  } catch (error) {}
});

export const searchSubject = createAsyncThunk(
  `seach_subject`,
  async (payload) => {
    try {
      const res = await api.post(`group/search-subject`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getDatabaseReport = createAsyncThunk(
  `get_database_report`,
  async (payload) => {
    try {
      const res = await api.post(`group/database-report`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

const groupSlice = createSlice({
  name: `groupSlice`,
  initialState,
  reducers: {
    resetGroupStore: (state) => {
      state.isGroupAddError = false;
      state.isGroupAdded = false;
      state.apiMessage = '';
      state.isGroupUpdateError = false;
      state.isGroupUpdated = false;
      state.isMeetCreated = false;
      state.isMeetUpdated = false;
      state.isIndividualReportDownloaded = false;
      state.isFinalReportDownloaded = false;
      state.isEventSubjectAddError = false;
      state.isEventSubjectAdded = false;
      state.isEventSubjectUpdateError = false;
      state.isEventSubjectUpdated = false;
      state.isMerged = false;
      state.isAlertAdded = false;
      state.isAlertAddError = false;
      state.isAlertAdded = false;
      state.isAlertAddError = false;
      state.isCreditRequested = false;
      state.isCreditRequestError = false;
      state.isCreditSubmitted = false;
      state.isCreditSubmitError = false;
      state.isCategoryCreated = false;
      state.isMessageUpdated = false;
      state.isMessageDeleted = false;
      state.isTopicAdded = false;
    },
  },

  extraReducers: {
    [getGroups.pending]: (state) => {
      state.isLoading = true;
    },
    [getGroups.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groups = action?.payload;
    },
    [createGroup.pending]: (state) => {
      state.isGroupAdding = true;
      state.isGroupAdded = false;
      state.isGroupAddError = false;
      state.apiMessage = '';
    },
    [createGroup.fulfilled]: (state, action) => {
      state.isGroupAdding = false;
      state.isGroupAdded = true;
      state.isGroupAddError = false;
      state.apiMessage = action?.payload;
    },
    [updateGroup.pending]: (state) => {
      state.isGroupUpdating = true;
      state.isGroupUpdated = false;
      state.isGroupUpdateError = false;
      state.apiMessage = '';
    },
    [updateGroup.fulfilled]: (state, action) => {
      state.isGroupUpdating = false;
      state.isGroupUpdated = true;
      state.isGroupUpdateError = false;
      state.apiMessage = action?.payload;
    },
    [getEvents.pending]: (state) => {
      state.isEventFetching = true;
      state.isEventsFetched = false;
    },
    [getEvents.fulfilled]: (state, action) => {
      state.isEventFetching = false;
      state.isEventsFetched = true;
      state.events = action?.payload;
    },
    [getEventDetails.pending]: (state) => {
      state.isEventDetailsFetching = true;
      state.isEventDetailsFetched = false;
      state.eventDetails = '';
    },
    [getEventDetails.fulfilled]: (state, action) => {
      state.isEventDetailsFetching = false;
      state.isEventDetailsFetched = true;
      state.eventDetails = action?.payload;
    },
    [getEventSubjectList.pending]: (state) => {
      state.isSubjectListFetched = false;
      state.isSubjectListFetching = true;
    },
    [getEventSubjectList.fulfilled]: (state, action) => {
      state.isSubjectListFetching = false;
      state.isSubjectListFetched = true;
      state.subjectsList = action?.payload;
    },
    [getEventSubjectListForUser.pending]: (state) => {
      state.isSubjectListFetched = false;
      state.isSubjectListFetching = true;
    },
    [getEventSubjectListForUser.fulfilled]: (state, action) => {
      state.isSubjectListFetching = false;
      state.isSubjectListFetched = true;
      state.subjectsList = action?.payload;
    },
    [downloadIndividualReport.pending]: (state) => {
      state.isIndividualReportDownloading = true;
      state.isIndividualReportDownloaded = false;
    },
    [downloadIndividualReport.fulfilled]: (state, action) => {
      state.isIndividualReportDownloading = false;
      state.isIndividualReportDownloaded = true;
      state.individualReport = action?.payload;
    },
    [downloadFinalReport.pending]: (state) => {
      state.isFinalReportDownloading = true;
      state.isFinalReportDownloaded = false;
    },
    [downloadFinalReport.fulfilled]: (state, action) => {
      state.isFinalReportDownloading = false;
      state.isFinalReportDownloaded = true;
      state.finalReport = action?.payload;
    },
    [createMeeting.pending]: (state) => {
      state.isMeetCreating = true;
      state.isMeetCreated = false;
    },
    [createMeeting.fulfilled]: (state, action) => {
      state.isMeetCreated = true;
      state.isMeetCreating = false;
      state.apiMessage = action?.payload;
    },
    [updateMeeting.pending]: (state) => {
      state.isMeetUpdating = true;
      state.isMeetUpdated = false;
    },
    [updateMeeting.fulfilled]: (state, action) => {
      state.isMeetUpdated = true;
      state.isMeetUpdating = false;
      state.apiMessage = action?.payload;
    },
    [addEventSubject.pending]: (state) => {
      state.isEventSubjectAdding = true;
      state.isEventSubjectAdded = false;
      state.isEventSubjectAddError = false;
    },
    [addEventSubject.fulfilled]: (state, action) => {
      state.isEventSubjectAdding = false;
      state.isEventSubjectAdded = true;
      state.isEventSubjectAddError = false;
      state.apiMessage = action?.payload;
    },
    [addEventSubject.rejected]: (state, action) => {
      state.isEventSubjectAdding = false;
      state.isEventSubjectAdded = false;
      state.isEventSubjectAddError = true;
      state.apiMessage = action?.error?.message;
    },
    [updateEventSubject.pending]: (state) => {
      state.isEventSubjectUpdating = true;
      state.isEventSubjectUpdated = false;
      state.isEventSubjectUpdateError = false;
    },
    [updateEventSubject.fulfilled]: (state, action) => {
      state.isEventSubjectUpdating = false;
      state.isEventSubjectUpdated = true;
      state.isEventSubjectUpdateError = false;
      state.apiMessage = action?.payload;
    },
    [updateEventSubject.rejected]: (state, action) => {
      state.isEventSubjectUpdating = false;
      state.isEventSubjectUpdated = false;
      state.isEventSubjectUpdateError = true;
      state.apiMessage = action?.error?.message;
    },
    [mergeEventSubject.pending]: (state) => {
      state.isMerged = false;
      state.isMerging = true;
    },
    [mergeEventSubject.fulfilled]: (state, action) => {
      state.isMerged = true;
      state.isMerging = false;
      state.apiMessage = action?.payload;
    },
    [releaseReport.pending]: (state) => {
      state.isReportReleasing = true;
      state.isReportReleased = false;
    },
    [releaseReport.fulfilled]: (state, action) => {
      state.isReportReleased = true;
      state.isReportReleasing = false;
      state.apiMessage = action.payload;
    },
    [getEventSubmissionList.pending]: (state) => {
      state.isEventSubmissionListFetching = true;
      state.isEventSubmissionListFetched = false;
    },
    [getEventSubmissionList.fulfilled]: (state, action) => {
      state.isEventSubmissionListFetching = false;
      state.isEventSubmissionListFetched = true;
      state.eventSubmissionList = action?.payload;
    },
    [getEventSubmissionDetails.pending]: (state) => {
      state.isEventSubmissionDetailsFetched = false;
      state.isEventSubmissionDetailsFetching = true;
      state.eventSubmissionDetails = [];
    },
    [getEventSubmissionDetails.fulfilled]: (state, action) => {
      state.isEventSubmissionDetailsFetched = true;
      state.isEventSubmissionDetailsFetching = false;
      state.eventSubmissionDetails = action?.payload;
    },
    [getEventReports.pending]: (state) => {
      state.isEventReportsFetching = true;
      state.isEventReportsFetched = false;
    },
    [getEventReports.fulfilled]: (state, action) => {
      state.isEventReportsFetching = false;
      state.isEventDetailsFetched = true;
      state.eventReports = action?.payload?.orders;
      state.eventReportOrderCount = action?.payload?.count;
    },
    [updateEventSubmissionDetails.pending]: (state) => {
      state.isEventSubmissionDetailsUpdating = true;
      state.isEventSubmissionDetailsUpdated = false;
      state.eventSubmissionDetails = [];
    },
    [updateEventSubmissionDetails.fulfilled]: (state, action) => {
      state.isEventSubmissionDetailsUpdating = false;
      state.isEventSubmissionDetailsUpdated = true;
      state.apiMessage = action?.payload;
    },
    [getEventList.pending]: (state) => {
      state.eventList = [];
      state.isEventListFetched = false;
    },
    [getEventList.fulfilled]: (state, action) => {
      state.eventList = action?.payload;
      state.isEventListFetched = true;
    },
    [getSubjectDetails.pending]: (state) => {
      state.isSubjectDetailsFetching = true;
      state.isSubjectDetailsFetched = false;
    },
    [getSubjectDetails.fulfilled]: (state, action) => {
      state.isSubjectDetailsFetched = true;
      state.isSubjectDetailsFetching = false;
      state.subjectDetails = action?.payload?.[0];
    },
    [subjectAddAlert.pending]: (state) => {
      state.isAlertAdding = true;
      state.isAlertAdded = false;
      state.isAlertAddError = false;
    },
    [subjectAddAlert.fulfilled]: (state, action) => {
      state.isAlertAdding = false;
      state.isAlertAdded = true;
      state.isAlertAddError = false;
    },
    [subjectAddAlert.rejected]: (state, action) => {
      state.isAlertAdding = false;
      state.isAlertAdded = false;
      state.isAlertAddError = true;
    },
    [getCategories.pending]: (state) => {
      state.isCategoriesFetching = true;
      state.isCategoriesFetched = false;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.isCategoriesFetching = false;
      state.isCategoriesFetched = true;
      state.categories = action?.payload;
    },
    [getTopics.pending]: (state) => {
      state.isTopicsFetching = true;
      state.isTopicsFetched = false;
    },
    [getTopics.fulfilled]: (state, action) => {
      state.isTopicsFetching = false;
      state.isTopicsFetched = true;
      state.topics = action?.payload;
    },
    [getTopicDetails.pending]: (state) => {
      state.isTopicDetailsFetching = true;
      state.isTopicDetailsFetched = false;
    },
    [getTopicDetails.fulfilled]: (state, action) => {
      state.isTopicDetailsFetching = false;
      state.isTopicDetailsFetched = true;
      state.topicDetails = action?.payload;
    },
    [getTopicChats.pending]: (state) => {
      state.isTopicChatsFetching = true;
      state.isTopicChatsFetched = false;
    },
    [getTopicChats.fulfilled]: (state, action) => {
      state.isTopicChatsFetching = false;
      state.isTopicChatsFetched = true;
      state.topicChats = action?.payload?.topics;
    },
    [addCreditReference.pending]: (state) => {
      state.isCreditRequesting = true;
      state.isCreditRequested = false;
      state.isCreditRequestError = false;
    },
    [addCreditReference.fulfilled]: (state, action) => {
      state.isCreditRequesting = false;
      state.isCreditRequested = true;
      state.isCreditRequestError = false;
      state.apiMessage = action?.payload;
    },
    [addCreditReference.rejected]: (state, action) => {
      state.isCreditRequesting = false;
      state.isCreditRequested = false;
      state.isCreditRequestError = action?.error?.message;
    },
    [submitCreditReference.pending]: (state) => {
      state.isCreditSubmitting = true;
      state.isCreditSubmitted = false;
      state.isCreditSubmitError = false;
    },
    [submitCreditReference.fulfilled]: (state, action) => {
      state.isCreditSubmitting = false;
      state.isCreditSubmitted = true;
      state.isCreditSubmitError = false;
      state.apiMessage = action?.payload;
    },
    [submitCreditReference.rejected]: (state, action) => {
      state.isCreditSubmitting = false;
      state.isCreditSubmitted = false;
      state.isCreditSubmitError = action?.error?.message;
    },
    [getFlashAlerts.pending]: (state) => {
      state.isAlertsFetching = true;
      state.isAlertsFetched = false;
    },
    [getFlashAlerts.fulfilled]: (state, action) => {
      state.isAlertsFetching = false;
      state.isAlertsFetched = true;
      state.alerts = action?.payload?.alertList;
    },
    [createFlashAlerts.pending]: (state) => {
      state.isAlertAdding = true;
      state.isAlertAdded = false;
      state.isAlertAddError = false;
    },
    [createFlashAlerts.fulfilled]: (state, action) => {
      state.isAlertAdding = false;
      state.isAlertAdded = true;
      state.isAlertAddError = false;
      state.apiMessage = action?.payload;
    },
    [createFlashAlerts.rejected]: (state, action) => {
      state.isAlertAdding = false;
      state.isAlertAdded = false;
      state.isAlertAddError = true;
      state.apiMessage = action?.error?.message;
    },
    [createCategory.pending]: (state) => {
      state.isCategoryCreating = true;
      state.isCategoryCreated = false;
    },
    [createCategory.fulfilled]: (state, action) => {
      state.isCategoryCreating = false;
      state.isCategoryCreated = true;
      state.apiMessage = action?.payload;
    },
    [deleteCategory.pending]: (state) => {
      state.isCategoryDeleting = true;
      state.isCategoryDeleted = false;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.isCategoryDeleting = false;
      state.isCategoryDeleted = true;
    },
    [getCategoryTopics.pending]: (state) => {
      state.isTopicsFetching = true;
      state.isTopicsFetched = false;
    },
    [getCategoryTopics.fulfilled]: (state, action) => {
      state.isTopicsFetching = false;
      state.isTopicsFetched = true;
      state.topics = action?.payload;
    },
    [updateTopic.pending]: (state) => {
      state.isTopicEditing = true;
      state.isTopicEdited = false;
    },
    [updateTopic.fulfilled]: (state, action) => {
      state.isTopicEdited = true;
      state.isTopicEditing = false;
      state.apiMessage = action?.payload;
    },
    [getTopicMessages.pending]: (state) => {
      state.isTopicChatsFetching = true;
      state.isTopicChatsFetched = false;
    },
    [getTopicMessages.fulfilled]: (state, action) => {
      state.isTopicChatsFetching = false;
      state.isTopicChatsFetched = true;
      state.topicChats = action?.payload?.chat;
    },
    [updateMessage.pending]: (state) => {
      state.isMessageUpdating = true;
      state.isMessageUpdated = false;
    },
    [updateMessage.fulfilled]: (state, action) => {
      state.isMessageUpdating = false;
      state.isMessageUpdated = true;
      state.apiMessage = action?.payload;
    },
    [deleteMessage.pending]: (state) => {
      state.isMessageDeleting = true;
      state.isMessageDeleted = false;
    },
    [deleteMessage.fulfilled]: (state, action) => {
      state.isMessageDeleting = false;
      state.isMessageDeleted = true;
      state.apiMessage = action?.payload;
    },
    [addTopic.pending]: (state) => {
      state.isTopicAdding = true;
      state.isTopicAdded = false;
    },
    [addTopic.fulfilled]: (state, action) => {
      state.isTopicAdding = false;
      state.isTopicAdded = true;
      state.apiMessage = action?.payload;
    },
    [getMembers.pending]: (state) => {
      state.isMembersFetching = true;
      state.isMembersFetched = false;
    },
    [getMembers.fulfilled]: (state, action) => {
      state.isMembersFetching = false;
      state.isMembersFetched = true;
      state.members = action?.payload;
    },
    [sendMessage.pending]: (state) => {
      state.isMessageSending = true;
      state.isMessageSent = false;
    },
    [sendMessage.fulfilled]: (state) => {
      state.isMessageSending = false;
      state.isMessageSent = true;
    },
    [searchSubject.pending]: (state) => {
      state.isSubjectSearching = true;
      state.isSubjectSearched = false;
    },
    [searchSubject.fulfilled]: (state, action) => {
      state.isSubjectSearching = false;
      state.isSubjectSearched = true;
      state.subjects = action?.payload;
    },
    [getDatabaseReport.pending]: (state) => {
      state.isDatabaseReportFetching = true;
      state.isDatabaseReportFetched = false;
      state.databaseReports = [];
    },
    [getDatabaseReport.fulfilled]: (state, action) => {
      state.isDatabaseReportFetching = false;
      state.isDatabaseReportFetched = true;
      state.databaseReports = action?.payload;
    },
  },
});

export const { resetGroupStore } = groupSlice.actions;

export default groupSlice.reducer;
