export const weeks = [
  {
    name: `S`,
    index: 0,
  },
  {
    name: `M`,
    index: 1,
  },
  {
    name: `T`,
    index: 2,
  },
  {
    name: `W`,
    index: 3,
  },
  {
    name: `T`,
    index: 4,
  },
  {
    name: `F`,
    index: 5,
  },
  {
    name: `S`,
    index: 6,
  },
];
export const months = [
  {
    name: `Jan`,
    index: 0,
  },
  {
    name: `Feb`,
    index: 1,
  },
  {
    name: `Mar`,
    index: 2,
  },
  {
    name: `Apr`,
    index: 3,
  },
  {
    name: `May`,
    index: 4,
  },
  {
    name: `Jun`,
    index: 5,
  },
  {
    name: `Jul`,
    index: 6,
  },
  {
    name: `Aug`,
    index: 7,
  },
  {
    name: `Sep`,
    index: 8,
  },
  {
    name: `Oct`,
    index: 9,
  },
  {
    name: `Nov`,
    index: 10,
  },
  {
    name: `Dec`,
    index: 11,
  },
];

export const years = [
  1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909, 1910, 1911, 1912,
  1913, 1914, 1915, 1916, 1917, 1918, 1919, 1920, 1921, 1922, 1923, 1924, 1925,
  1926, 1927, 1928, 1929, 1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938,
  1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951,
  1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964,
  1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977,
  1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990,
  1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
  2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016,
  2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
  2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042,
  2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055,
  2056, 2057, 2058, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067, 2068,
  2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080, 2081,
  2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091, 2092, 2093, 2094,
  2095, 2096, 2097, 2098, 2099,
];

export const hours = [
  { name: '12:00 AM', value: '00:00' },
  { name: '12:30 AM', value: '00:30' },
  { name: '1:00 AM', value: '01:00' },
  { name: '1:30 AM', value: '01:30' },
  { name: '2:00 AM', value: '02:00' },
  { name: '2:30 AM', value: '02:30' },
  { name: '3:00 AM', value: '03:00' },
  { name: '3:30 AM', value: '03:30' },
  { name: '4:00 AM', value: '04:00' },
  { name: '4:30 AM', value: '04:30' },
  { name: '5:00 AM', value: '05:00' },
  { name: '5:30 AM', value: '05:30' },
  { name: '6:00 AM', value: '06:00' },
  { name: '6:30 AM', value: '06:30' },
  { name: '7:00 AM', value: '07:00' },
  { name: '7:30 AM', value: '07:30' },
  { name: '8:00 AM', value: '08:00' },
  { name: '8:30 AM', value: '08:30' },
  { name: '9:00 AM', value: '09:00' },
  { name: '9:30 AM', value: '09:30' },
  { name: '10:00 AM', value: '10:00' },
  { name: '10:30 AM', value: '10:30' },
  { name: '11:00 AM', value: '11:00' },
  { name: '11:30 AM', value: '11:30' },
  { name: '12:00 PM', value: '00:00' },
  { name: '12:30 PM', value: '12:30' },
  { name: '1:00 PM', value: '13:00' },
  { name: '1:30 PM', value: '13:30' },
  { name: '2:00 PM', value: '14:00' },
  { name: '2:30 PM', value: '14:30' },
  { name: '3:00 PM', value: '15:00' },
  { name: '3:30 PM', value: '15:30' },
  { name: '4:00 PM', value: '16:00' },
  { name: '4:30 PM', value: '16:30' },
  { name: '5:00 PM', value: '17:00' },
  { name: '5:30 PM', value: '17:30' },
  { name: '6:00 PM', value: '18:00' },
  { name: '6:30 PM', value: '18:30' },
  { name: '7:00 PM', value: '19:00' },
  { name: '7:30 PM', value: '19:30' },
  { name: '8:00 PM', value: '20:00' },
  { name: '8:30 PM', value: '20:30' },
  { name: '9:00 PM', value: '21:00' },
  { name: '9:30 PM', value: '21:30' },
  { name: '10:00 PM', value: '22:00' },
  { name: '10:30 PM', value: '22:30' },
  { name: '11:00 PM', value: '23:00' },
  { name: '11:30 PM', value: '23:30' },
];
