import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Custom shape function for rounded rectangles
const RoundedRectangleBar = (props) => {
  const { fill, x, y, width, height, radius = 8 } = props;
  const adjustedY = height < 0 ? y + height : y; // Adjust y position for negative height
  const adjustedHeight = Math.abs(height); // Ensure height is positive

  return (
    <rect
      x={x}
      y={adjustedY}
      width={width}
      height={adjustedHeight}
      rx={radius}
      ry={radius}
      fill={fill}
      stroke='none'
    />
  );
};

// Custom label function
const customLabel = ({ x, y, width, height, value }) => {
  const isNegative = value < 0;
  const labelY = isNegative ? y - Math.abs(height) - 10 : y - 10; // Adjust label position

  return (
    <text
      x={x + width / 2}
      y={labelY}
      fill='#666'
      textAnchor='middle'
      dominantBaseline='central'
    >
      ${value}
    </text>
  );
};

// Custom Tooltip component
const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <p>
          {`${t(`label`)}`}:{' '}
          <span style={{ fontWeight: 'bold', color: '#356EB1' }}>{label}</span>
        </p>
        <p>
          {`${t(`amount`)}`}::{' '}
          <span style={{ fontWeight: 'bold', color: '#356EB1' }}>
            ${payload[0].value}
          </span>
        </p>
      </div>
    );
  }

  return null;
};

const BarGraph = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        data={data}
        margin={{
          top: 25,
          right: 20,
          left: 20,
          bottom: 0,
        }}
      >
        <CartesianGrid
          strokeDasharray='3 3'
          vertical={false}
          strokeOpacity={0.3}
        />
        <XAxis dataKey='label' axisLine={false} tickLine={false} />
        <Tooltip
          content={<CustomTooltip />}
          cursor={false}
          animationDuration={500}
          contentStyle={{ borderRadius: '8px' }}
        />
        <Bar
          dataKey='value'
          fill='#356EB1'
          label={customLabel}
          shape={<RoundedRectangleBar />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraph;
