import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getAlerts } from '../../slices/creditRiskSlice';
import SideDrawer from '../../ReusableComponents/SideDrawer';
import Alerts from './Alerts';
import { money, monthOptions } from '../../Utils/utils';
import MonitoringCard from './MonitoringCard';
import DiscoverInformation from './DiscoverInformation';
import { set } from 'idb-keyval';
import Skeleton from '../../ReusableComponents/Skeleton';
import ActionPopup from '../../ReusableComponents/ActionPopup';
import OverLimit from './OverLimit';
import BarGraph from './BarGraph';
import LineGraph from './LineGraph';

const MonitoringSummary = ({
  companyId,
  isEN,
  onGetClientInformation,
  isRiskFilter,
  isRiskDiscovered,
  discoveredRiskType,
  summary,
  isSummaryFetching,
  isAlertsFetching,
  isAlertsFetched,
  alerts,
  isOverLimit,
  isAgingFileUploaded,
  isFilterNotFound,
  setIsFilterNotFound,
  isSummaryFetched,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const targetRef = useRef();

  const [alertType, setAlertType] = useState('all');
  const [isAllAlertsVisible, setIsAllAlertsVisible] = useState(false);
  const [isDiscover, setIsDiscover] = useState(false);
  const [isOutOfView, setIsOutOfView] = useState(false);
  const [isOverLimitVisible, setIsOverLimitVisible] = useState(false);
  const [barGraphData, setBarGraphData] = useState([]);
  const [lineGraphData, setLineGraphData] = useState([]);
  const [isInsufficient, setIsInsufficient] = useState(false);

  useEffect(() => {
    // Callback function for IntersectionObserver
    const observerCallback = (entries) => {
      const [entry] = entries;
      setIsOutOfView(!entry.isIntersecting);
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0,
    });

    const targetCurrent = targetRef.current;

    if (targetCurrent) {
      observer.observe(targetCurrent);
    }

    return () => {
      if (targetCurrent) observer.unobserve(targetCurrent);
    };
  }, []);

  useEffect(() => {
    setIsOverLimitVisible(isOverLimit);
  }, [isOverLimit]);

  useEffect(() => {
    isRiskDiscovered && setIsDiscover(false);
  }, [isRiskDiscovered]);

  useEffect(() => {
    if (isSummaryFetched) {
      const barData = [
        {
          label: `${t(`period`)}`,
          value: Math.round(summary?.pendingAgingPayment?.current),
        },
        {
          label: `${t(`period_1`)}`,
          value: Math.round(summary?.pendingAgingPayment?.period1),
        },
        {
          label: `${t(`period_2`)}`,
          value: Math.round(summary?.pendingAgingPayment?.period2),
        },
        {
          label: `${t(`period_3`)}`,
          value: Math.round(summary?.pendingAgingPayment?.period3),
        },
        {
          label: `${t(`period_4`)}`,
          value: Math.round(summary?.pendingAgingPayment?.period4),
        },
      ];
      setBarGraphData(barData);
      const lineData = Object.values(summary?.pendingPaymentTrend)?.map(
        (trend) => ({
          label: isEN
            ? monthOptions[trend?.upto?.month]?.value?.en
            : monthOptions[trend?.upto?.month]?.value?.fr,
          value: Math.round(trend?.amount),
        })
      );
      setLineGraphData(lineData);
      setIsInsufficient(lineData?.length < 2);
    }
  }, [isSummaryFetched, t, isEN, summary]);

  const alertOptions = [
    {
      name: `${t(`all`)}`,
      value: `all`,
    },
    {
      name: `${t(`urgent`)}`,
      value: `Urgent`,
    },
    {
      name: `${t(`moderate`)}`,
      value: `Moderate`,
    },
    {
      name: `${t(`minimal`)}`,
      value: `Minimal`,
    },
  ];

  const onAlertHandler = () => {
    setIsAllAlertsVisible((prev) => !prev);
  };

  const onAlertChange = (type) => {
    setAlertType(type);
    dispatch(
      getAlerts({ page: 1, type: type === `all` ? '' : type, companyId })
    );
  };

  const onDiscover = async (type) => {
    const riskTypes = {
      high: `High`,
      low: `Low`,
      medium: `Medium`,
      minimal: `Minimal`,
    };
    const payload = {
      companyId,
      headerText: `discover_${riskTypes[type]?.toLowerCase()}_risk`,
      content: `health_risk_${riskTypes[type]?.toLowerCase()}`,
      riskType: riskTypes[type],
      isIndividual: false,
    };
    await set(`discoverPayload`, payload);
    setIsDiscover(true);
  };

  const onCancelDiscover = () => {
    setIsDiscover(false);
    set(`discoverPayload`, null);
  };

  const onCancelOverLimit = () => {
    setIsOverLimitVisible(false);
  };

  return (
    <>
      <div className='credit_risk_basic_wrapper'>
        <>
          <div className='health_section_wrapper' ref={targetRef}>
            <div className='general_info'>
              <div className='label'>{`${
                isSummaryFetching ? '' : t(`general_information`)
              }`}</div>
              <div className='general_section'>
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('clients_being_monitored')}
                  text={`${summary?.clientMonitored} / ${
                    summary?.package?.clientLimit
                  } ${t(`max_limit`)}`}
                  textPartOne={summary?.clientMonitored}
                  textPartTwo={`/ ${summary?.package?.clientLimit} ${t(
                    `max_limit`
                  )}`}
                  isMultiple={summary?.package?.clientsOverLimit > 0}
                  title1={t('over_limit')}
                  text1={summary?.package?.clientsOverLimit}
                  isLimit={true}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('overall_health_score')}
                  text={Math.round(summary?.overallHealthScore) || `-`}
                />
              </div>
            </div>
            <div className='client_health'>
              <div className='label'>{`${
                isSummaryFetching ? '' : t(`client_health`)
              }`}</div>
              <div className='client_section'>
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('high')}
                  text={summary?.clientsUnderHighRisk}
                  isRiskIcon={true}
                  riskType='high'
                  isPercent={true}
                  percent={summary?.clientHealthRisk?.change?.high}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.high &&
                    discoveredRiskType !== `High`
                  }
                  action={onDiscover}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('medium')}
                  text={summary?.clientsUnderMediumRisk}
                  isRiskIcon={true}
                  riskType='medium'
                  isPercent={true}
                  percent={summary?.clientHealthRisk?.change?.medium}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.medium &&
                    discoveredRiskType !== `Medium`
                  }
                  action={onDiscover}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('low')}
                  text={summary?.clientsUnderLowRisk}
                  isRiskIcon={true}
                  riskType='low'
                  isPercent={true}
                  percent={summary?.clientHealthRisk?.change?.low}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.low &&
                    discoveredRiskType !== `Low`
                  }
                  action={onDiscover}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('minimal')}
                  text={summary?.clientsUnderMinimalRisk}
                  isRiskIcon={true}
                  riskType='minimal'
                  isPercent={true}
                  percent={summary?.clientsUnderMinimalRisk}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.minimal &&
                    discoveredRiskType !== `Minimal`
                  }
                  action={onDiscover}
                />
              </div>
            </div>
          </div>

          <div
            className={`health_section_wrapper credit_risk_fixed_wrapper ${
              isOutOfView
                ? `credit_risk_fixed_wrapper_out_of_view`
                : `credit_risk_fixed_wrapper_in_view`
            }`}
          >
            <div className='general_info'>
              <div className='general_section'>
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('clients_being_monitored')}
                  text={summary?.clientMonitored || `-`}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('overall_health_score')}
                  text={Math.round(summary?.overallHealthScore) || `-`}
                />
              </div>
            </div>
            <div className='client_health'>
              <div className='client_section'>
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('high')}
                  text={summary?.clientsUnderHighRisk}
                  isRiskIcon={true}
                  riskType='high'
                  isPercent={true}
                  isOutOfView={isOutOfView}
                  percent={summary?.clientHealthRisk?.change?.high}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.high &&
                    discoveredRiskType !== `High`
                  }
                  action={onDiscover}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('medium')}
                  text={summary?.clientsUnderMediumRisk}
                  isRiskIcon={true}
                  riskType='medium'
                  isPercent={true}
                  isOutOfView={isOutOfView}
                  percent={summary?.clientHealthRisk?.change?.medium}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.medium &&
                    discoveredRiskType !== `Medium`
                  }
                  action={onDiscover}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('low')}
                  text={summary?.clientsUnderLowRisk}
                  isRiskIcon={true}
                  riskType='low'
                  isPercent={true}
                  isOutOfView={isOutOfView}
                  percent={summary?.clientHealthRisk?.change?.low}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.low &&
                    discoveredRiskType !== `Low`
                  }
                  action={onDiscover}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('minimal')}
                  text={summary?.clientsUnderMinimalRisk}
                  isRiskIcon={true}
                  isOutOfView={isOutOfView}
                  riskType='minimal'
                  isPercent={true}
                  percent={summary?.clientsUnderMinimalRisk}
                  isPurchasable={
                    summary?.clientHealthRisk?.purchasable?.minimal &&
                    discoveredRiskType !== `Minimal`
                  }
                  action={onDiscover}
                />
              </div>
            </div>
          </div>

          <div className='health_section_wrapper'>
            <div className='payments_info'>
              <div className='label'>{`${
                isSummaryFetching ? '' : t(`payments`)
              }`}</div>
              <div className='payments_section'>
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('total_amount_due')}
                  text={money(summary?.totalAmountDue)}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('average_time_to_pay')}
                  text={summary?.averageTimeToPay + ` ${t(`days`)}`}
                />
                <MonitoringCard
                  isLoading={isSummaryFetching}
                  title={t('total_credit_limit_granted')}
                  text={money(summary?.totalCreditLimitGranted)}
                />
              </div>
              <div className='graph_section'>
                {isSummaryFetching ? (
                  <Skeleton />
                ) : (
                  <>
                    <div className='graph_wrapper'>
                      <p className='label graph_label_specific'>{`${t(
                        `due_payments`
                      )}`}</p>
                      <div className='graph_1'>
                        <BarGraph data={barGraphData} />
                      </div>
                    </div>

                    <div className='graph_wrapper'>
                      {isInsufficient ? (
                        <div className='insufficient_wrapper'>
                          <div className='row_1'>
                            <svg
                              width='16'
                              height='14'
                              viewBox='0 0 16 14'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M2 1C2 0.446875 1.55313 0 1 0C0.446875 0 0 0.446875 0 1V11.5C0 12.8813 1.11875 14 2.5 14H15C15.5531 14 16 13.5531 16 13C16 12.4469 15.5531 12 15 12H2.5C2.225 12 2 11.775 2 11.5V1ZM14.7063 3.70625C15.0969 3.31563 15.0969 2.68125 14.7063 2.29063C14.3156 1.9 13.6812 1.9 13.2906 2.29063L10 5.58437L8.20625 3.79063C7.81563 3.4 7.18125 3.4 6.79063 3.79063L3.29063 7.29062C2.9 7.68125 2.9 8.31563 3.29063 8.70625C3.68125 9.09688 4.31563 9.09688 4.70625 8.70625L7.5 5.91563L9.29375 7.70938C9.68437 8.1 10.3188 8.1 10.7094 7.70938L14.7094 3.70937L14.7063 3.70625Z'
                                fill='#64748B'
                              />
                            </svg>
                            <span className='unavailable'>
                              {`${t(`unavailable`)}`}
                            </span>
                          </div>
                          <div className='row_2'>
                            <span>{`${t(`insufficient_data`)}`}</span>
                            <br />
                            <span>{`${t(`requires_data`)}`}</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <p className='label graph_label_specific'>
                            {`${t(`total_due_trend`)}`}
                          </p>
                          <div className='graph_2'>
                            <LineGraph data={lineGraphData} />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            {isSummaryFetching ? (
              <div className='summary_alerts_loading'>
                <Skeleton />
              </div>
            ) : (
              <Alerts
                alerts={alerts}
                alertType={alertType}
                onAlertHandler={onAlertHandler}
                alertOptions={alertOptions}
                isAlertsFetching={!isAgingFileUploaded && isAlertsFetching}
                isAlertsFetched={isAlertsFetched}
                isFromDashboard={true}
                isEN={isEN}
                onAlertChange={onAlertChange}
                onGetClientInformation={onGetClientInformation}
                isRiskFilter={isRiskFilter}
                companyId={companyId}
                isFilterNotFound={isFilterNotFound}
                setIsFilterNotFound={setIsFilterNotFound}
              />
            )}
          </div>
        </>
      </div>

      <SideDrawer
        from={`generic_drawer credit_risk`}
        isVisible={isAllAlertsVisible}
        footer={null}
        isHideFooter={true}
        heading={`${t(`alerts`)}`}
        isClosable={true}
        onCloseSidebar={onAlertHandler}
        content={
          <Alerts
            alerts={alerts}
            alertType={alertType}
            onAlertHandler={onAlertHandler}
            alertOptions={alertOptions}
            isAlertsFetching={isAlertsFetching}
            isAlertsFetched={isAlertsFetched}
            isFromDashboard={false}
            isEN={isEN}
            onAlertChange={onAlertChange}
            onGetClientInformation={onGetClientInformation}
            isRiskFilter={isRiskFilter}
            companyId={companyId}
            isFilterNotFound={isFilterNotFound}
            setIsFilterNotFound={setIsFilterNotFound}
          />
        }
      />
      <DiscoverInformation
        isVisible={isDiscover}
        onCancel={onCancelDiscover}
        companyId={companyId}
      />
      <ActionPopup
        isVisible={isOverLimitVisible}
        isHideFooter={true}
        from='credit_risk_overlimit'
        headerText={`${t(`clients_limit_reached`)}`}
        onCancel={onCancelOverLimit}
      >
        <OverLimit
          clientLimit={summary?.package?.clientLimit}
          overLimit={summary?.package?.clientsOverLimit}
        />
      </ActionPopup>
    </>
  );
};

export default MonitoringSummary;
