import React, { useEffect, useState } from 'react';
import Alert from './Alert';
import { useTranslation } from 'react-i18next';
import DeletePopup from '../../ReusableComponents/DeletePopup';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMonitoringAlert } from '../../slices/creditRiskSlice';

const AlertList = ({
  alerts,
  isAlertsFetching,
  isAlertsFetched,
  isEN,
  onGetClientInformation,
  isRiskFilter,
  companyId,
  isFilterNotFound,
  setIsFilterNotFound,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { isAlertDeleting, isAlertDeleted } = useSelector(
    (state) => state.creditRisk
  );

  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const [alertId, setAlertId] = useState('');

  useEffect(() => {
    if (isAlertDeleted) {
      onCancelDeleteAlert();
    }
  }, [isAlertDeleted]);

  const onDeleteAlertHandler = (alertId) => {
    setIsDeleteAlert(true);
    setAlertId(alertId);
  };

  const onCancelDeleteAlert = () => {
    setIsDeleteAlert(false);
    setAlertId(``);
  };

  const onDeleteAlert = () => {
    alertId &&
      companyId &&
      dispatch(deleteMonitoringAlert({ alertId, companyId }));
  };

  return (
    <>
      {/* {isAlertsFetching
        ? [1, 2]?.map((index) => <Alert key={index} isLoading={true} />)
        : isAlertsFetched &&
          alertsToRender?.map((alert, index) => (
            <Alert key={index} alert={alert} />
          ))} */}
      {isAlertsFetching &&
        [1, 2]?.map((index) => (
          <React.Fragment key={index}>
            <Alert isLoading={true} />
          </React.Fragment>
        ))}
      {isAlertsFetched && (
        <>
          {alerts?.length > 0 ? (
            alerts?.map((alert, index) => (
              <React.Fragment key={index}>
                <Alert
                  alert={alert}
                  onDeleteAlertHandler={onDeleteAlertHandler}
                  isEN={isEN}
                  onGetClientInformation={onGetClientInformation}
                  isRiskFilter={isRiskFilter}
                  isFilterNotFound={isFilterNotFound}
                  setIsFilterNotFound={setIsFilterNotFound}
                />
              </React.Fragment>
            ))
          ) : (
            <div className='no_alerts_found'>{`${t(`no_alerts_found`)}`}</div>
          )}{' '}
        </>
      )}
      <DeletePopup
        from='admin_report'
        isShowPopup={isDeleteAlert}
        header={`${t(`delete_alert`)}`}
        content={`${t(`delete_alert_content`)}`}
        onCancel={onCancelDeleteAlert}
        onSubmit={onDeleteAlert}
        buttonText1={`${t(`cancel`)}`}
        buttonText2={`${t(`delete_alert`)}`}
        isLoading={isAlertDeleting}
      />
    </>
  );
};

export default AlertList;
