import React from 'react';
import { useTranslation } from 'react-i18next';

const Tooltip = ({ text, isVisible, position, from, displayPosition }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`tool_tip_wrapper ${
        isVisible ? `tool_tip_visible` : `tool_tip_invisible`
      } ${from} tool_tip_${position} tool_tip_${displayPosition}`}
    >
      <div
        className={`tool_tip_rectangle rectangle_${position} rectangle_${displayPosition}`}
      />
      <div className='tool_tip_text'>{`${t(`${text}`)}`}</div>
    </div>
  );
};

export default Tooltip;
