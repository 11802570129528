import React from 'react';
import Skeleton from '../../ReusableComponents/Skeleton';

const CompanyInformationLoading = () => {
  return (
    <div className='company_information_loading'>
      {[1, 2, 3, 4, 5, 6, 7]?.map((index) => (
        <Skeleton index={index} key={index} />
      ))}
    </div>
  );
};

export default CompanyInformationLoading;
