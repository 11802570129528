import Button from "../../ReusableComponents/Button";

export const subscriberListRenderer = (
  entity,
  header,
  action,
  action2,
  translations
) => {
  if (header === `name`) {
    return <span>{entity?.name}</span>;
  }
  if (header === `email`) {
    return <span>{entity?.email}</span>;
  }
  if (header === `bilingual_attachments`) {
    return <span>{entity?.both_template ? `Yes` : `No`}</span>;
  }
  if (header === `email_language`) {
    return <span>{entity?.temp_lang}</span>;
  }
  if (header === `added_on`) {
    return <span>{new Date(entity?.create_date)?.toLocaleDateString()}</span>;
  }
  if (header === `actions`) {
    return (
      <span className="watchlist_actions_wrapper">
        <Button
          type="primary"
          text={translations?.edit}
          onClickHandler={() => action(entity)}
        />
        <Button
          type="danger"
          text={translations?.delete}
          onClickHandler={() => action2(entity?._id)}
        />
      </span>
    );
  }
};
