import React from 'react';

const Input = ({
  text,
  type,
  value,
  onChange,
  onClick,
  isMandatory,
  isRecommended,
  isDisabled,
  onBlur,
  isError,
  isValid,
  errorMessage,
  isWarning,
  isHideMessage,
}) => {
  const onBlurHandler = () => {
    onBlur();
  };

  return (
    <div className='input_container'>
      <div className='input_wrapper'>
        <input
          className={`custom_input ${
            isWarning
              ? `is_warning`
              : isError
              ? `is_error`
              : isValid
              ? `is_valid`
              : isDisabled
              ? `is_disabled`
              : null
          }`}
          placeholder=' '
          type={type}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlurHandler}
          disabled={isDisabled}
        />
        <label className='input_label'>
          {text}{' '}
          <span
            className={`${
              isMandatory ? `mandatory` : isRecommended ? `recommended` : null
            }`}
          >
            {isMandatory ? `*mandatory` : isRecommended ? `*recommended` : null}
          </span>
        </label>
        {isValid ? (
          <svg
            className='input_icon'
            xmlns='http://www.w3.org/2000/svg'
            width='17'
            height='16'
            viewBox='0 0 17 16'
            fill='none'
          >
            <path
              d='M8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16ZM12.0312 6.53125L8.03125 10.5312C7.7375 10.825 7.2625 10.825 6.97188 10.5312L4.97188 8.53125C4.67813 8.2375 4.67813 7.7625 4.97188 7.47188C5.26562 7.18125 5.74062 7.17813 6.03125 7.47188L7.5 8.94063L10.9688 5.46875C11.2625 5.175 11.7375 5.175 12.0281 5.46875C12.3187 5.7625 12.3219 6.2375 12.0281 6.52812L12.0312 6.53125Z'
              fill='#33AD73'
            />
          </svg>
        ) : isError ? (
          <svg
            className='input_icon'
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
          >
            <path
              d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM8 4C8.41563 4 8.75 4.33437 8.75 4.75V8.25C8.75 8.66563 8.41563 9 8 9C7.58438 9 7.25 8.66563 7.25 8.25V4.75C7.25 4.33437 7.58438 4 8 4ZM7 11C7 10.7348 7.10536 10.4804 7.29289 10.2929C7.48043 10.1054 7.73478 10 8 10C8.26522 10 8.51957 10.1054 8.70711 10.2929C8.89464 10.4804 9 10.7348 9 11C9 11.2652 8.89464 11.5196 8.70711 11.7071C8.51957 11.8946 8.26522 12 8 12C7.73478 12 7.48043 11.8946 7.29289 11.7071C7.10536 11.5196 7 11.2652 7 11Z'
              fill='#B91C1C'
            />
          </svg>
        ) : isWarning ? (
          <svg
            className='input_icon'
            xmlns='http://www.w3.org/2000/svg'
            width='17'
            height='16'
            viewBox='0 0 17 16'
            fill='none'
          >
            <path
              d='M8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16ZM8.5 4C8.91563 4 9.25 4.33437 9.25 4.75V8.25C9.25 8.66563 8.91563 9 8.5 9C8.08438 9 7.75 8.66563 7.75 8.25V4.75C7.75 4.33437 8.08438 4 8.5 4ZM7.5 11C7.5 10.7348 7.60536 10.4804 7.79289 10.2929C7.98043 10.1054 8.23478 10 8.5 10C8.76522 10 9.01957 10.1054 9.20711 10.2929C9.39464 10.4804 9.5 10.7348 9.5 11C9.5 11.2652 9.39464 11.5196 9.20711 11.7071C9.01957 11.8946 8.76522 12 8.5 12C8.23478 12 7.98043 11.8946 7.79289 11.7071C7.60536 11.5196 7.5 11.2652 7.5 11Z'
              fill='#F59E0B'
            />
          </svg>
        ) : null}
        <fieldset className='fieldset_wrapper'>
          <legend className='legend_wrapper'>
            {text}{' '}
            <span
              className={`${
                isMandatory ? `mandatory` : isRecommended ? `recommended` : null
              }`}
            >
              {isMandatory
                ? `*mandatory`
                : isRecommended
                ? `*recommended`
                : null}
            </span>
          </legend>
        </fieldset>
      </div>
      {!isHideMessage && (
        <div className='input_message'>
          <span
            className={`${
              isWarning
                ? `is_warning`
                : isError
                ? `is_error`
                : isValid
                ? `is_valid`
                : null
            }`}
          >
            {(isWarning || isError) && errorMessage}
          </span>
        </div>
      )}
    </div>
  );
};

export default Input;
