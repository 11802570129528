import React, { useEffect, useState } from 'react';
import {
  IoMdInformationCircleOutline,
  IoMdCheckmarkCircleOutline,
} from 'react-icons/io';

const ToastMessage = ({ type, message, action }) => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    message?.trim()?.length > 0 && setIsShow(true);
  }, [message]);

  useEffect(() => {
    isShow &&
      setTimeout(() => {
        setIsShow(false);
        action();
      }, 2750);
  }, [isShow, action]);

  return (
    <div className={`toast_message_wrapper ${type} ${isShow ? `show` : ``}`}>
      {type === `success` ? (
        <IoMdCheckmarkCircleOutline className='toast_icon' />
      ) : (
        <IoMdInformationCircleOutline className='toast_icon' />
      )}
      {message}
    </div>
  );
};

export default ToastMessage;
