import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './common/Login/Login';
import Home from './views/Home';
import RouteGuard from './RouteGuard';
import Onboarding from './views/UserOnboarding/Onboarding';
import UserLayout from './views/UserModule/UserLayout';
// import MyAccount from './views/MyAccount/MyAccount';
import ContentLayout from './Layouts/ContentLayout';
import OrderReport from './views/OrderReport/OrderReport';
import LegalWatchList from './views/LegalWatchlist/LegalWatchList';
// import AdminCreditReport from './views/AdminModule/AdminCreditReport/AdminCreditReport';
import Client from './views/Clients/Client';
import CompanyDetails from './views/Clients/CompanyDetails';
// import UserCreditReport from './views/UserModule/CreditReport/UserCreditReport';
import AddClient from './views/Clients/AddClient';
import EditClient from './views/Clients/EditClient';
import AddSubClient from './views/Clients/AddSubClient';
import EditSubClient from './views/Clients/EditSubClient';
// import AdminCreditApplications from './views/CommonModules/CreditApplications/AdminCreditApplications';
// import UserCreditApplications from './views/CommonModules/CreditApplications/UserCreditApplications';
// import EventReports from './views/CommonModules/Groups/EventReports';
// import AdminGroups from './views/CommonModules/Groups/AdminGroups';
// import UserGroups from './views/CommonModules/Groups/UserGroups';
// import AdminGroupInformation from './views/CommonModules/Groups/AdminGroupInformation';
// import UserGroupsInformation from './views/CommonModules/Groups/UserGroupsInformation';
import CreditReport from './views/CreditReports/CreditReport';
import Groups from './views/Groups/Groups';
import GroupInformation from './views/Groups/GroupInformation';
import EventReports from './views/Groups/EventReports';
import CreditApplication from './views/CreditApplications/CreditApplication';
import CreditApplicationDetails from './views/CreditApplications/CreditApplicationDetails';
import DatabaseReport from './views/DatabaseReports/DatabaseReport';

import { useSelector } from 'react-redux';
import MyAccount from './views/MyAccount/MyAccount';
import ESCData from './ReusableComponents/CreditReportDetails/ESCData';
import UpdateReport from './ReusableComponents/CreditReportDetails/UpdateReport';
import CreditRiskManagement from './views/CreditRiskManagement/CreditRiskManagement';
import CompanyProfile from './views/CreditRiskManagement/CompanyProfile';
import HistoricalData from './views/CreditRiskManagement/HistoricalData';
import PdfViewer from './ReusableComponents/PdfViewer';
import MonitoringVerification from './views/CreditRiskManagement/MonitoringVerification';
import NotFound from './views/NotFound';

const Router = () => {
  const { isAuthenticated, isAdmin, isUser, isSessionTimeOut } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSessionTimeOut) {
      window.location.href = `https://portail.alliancecredit.ca/`;
    }
  }, [isSessionTimeOut]);

  const { isEN } = useSelector((state) => state.common);
  return (
    <>
      <Routes>
        {/* <Route path='/' element={<Login />} /> */}
        <Route path='*' element={<NotFound />} />
        <Route
          path='/creditrisk-management/:language/:token/:userId'
          element={<MonitoringVerification />}
        />

        {/* <Route path='/create-password' element={<Login />} />
        <Route path='/order-report' element={<OrderReport />} /> */}
        {/* <Route path='my-account' element={<HeaderTopbarSidebarContent />} /> */}
        {/* <Route
          element={
            <RouteGuard
              allowedRoles={[roles.user_manager, roles.user]}
              role={role}
            />
          }
        >
          <Route element={<ContentLayout />}>
            <Route element={<UserLayout />}>
              <Route path='/credit-report' element={<UserCreditReport />} />
              <Route path='/database-reports' element={<DatabaseReport />} />
              <Route path='/legal-watchlist' element={<LegalWatchList />} />
              <Route
                path='/credit-applications'
                element={<UserCreditApplications />}
              />
              <Route path='/groups' element={<UserGroups />} />
              <Route
                path='/groups-meetings/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-network/credit-references/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-network/flash-alerts/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-network/general-discussion/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-meetings/event/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-meetings/event-subjects/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-meetings/event-submissions/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-meetings/submit-subjects/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-meetings/submit-references/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-members/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/groups-database/:id'
                element={<UserGroupsInformation />}
              />
              <Route
                path='/application-details'
                element={<CreditApplicationDetails />}
              />
            </Route>
          </Route>
        </Route> */}
        {/* testing purpose only */}
        <Route element={<RouteGuard isAuthenticated={isAuthenticated} />}>
          <Route element={<ContentLayout isAdmin={isAdmin} />}>
            <Route
              path='/creditrisk-management/file'
              element={<PdfViewer isEN={isEN} />}
            />
            <Route
              path='/creditrisk-management'
              element={<CreditRiskManagement isEN={isEN} />}
            />

            <Route
              path='/creditrisk-management/client-details/:id'
              element={<CompanyProfile isEN={isEN} />}
            />
            <Route
              path='/creditrisk-management/historical-data/:id'
              element={<HistoricalData isEN={isEN} />}
            />
            {/* <Route
              path='/credit-reports'
              element={
                <CreditReport isAdmin={isAdmin} isUser={isUser} isEN={isEN} />
              }
            />
            <Route path='/groups' element={<Groups />} />
            <Route
              path='/groups-meetings/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-network/credit-references/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-network/flash-alerts/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-network/general-discussion/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-meetings/event/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-meetings/event-subjects/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-meetings/event-submissions/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-meetings/submit-subjects/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-meetings/submit-references/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-members/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='/groups-database/:id'
              element={<GroupInformation isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route path='/event-reports/:id' element={<EventReports />} />
            <Route path='/clients' element={<Client />} />
            <Route
              path='/client/:id'
              element={
                <CompanyDetails isEN={isEN} isClient={true} isAdmin={isAdmin} />
              }
            />
            <Route
              path='/users/:id'
              element={
                <CompanyDetails isEN={isEN} isUsers={true} isAdmin={isAdmin} />
              }
            />
            <Route
              path='/aging/:id'
              element={
                <CompanyDetails isEN={isEN} isAging={true} isAdmin={isAdmin} />
              }
            />
            <Route path='/add-client' element={<AddClient />} />
            <Route path='/edit-client/:id' element={<EditClient />} />
            <Route path='/add-sub-client/:id' element={<AddSubClient />} />
            <Route path='/edit-sub-client/:id' element={<EditSubClient />} />
            <Route path='/esc-data/:id' element={<ESCData />} />
            <Route
              path='/update-report'
              element={<UpdateReport isAdmin={true} isEditReport={true} />}
            />
            <Route
              path='/transactions/:id'
              element={
                <CompanyDetails
                  isEN={isEN}
                  isTransactions={true}
                  isAdmin={isAdmin}
                />
              }
            />
            <Route
              path='/all-transactions'
              element={
                <CompanyDetails
                  isEN={isEN}
                  isFromAccount={true}
                  isTransactions={true}
                  isAdmin={isAdmin}
                />
              }
            />
            <Route
              path='/clients-legal-watchlist/:id'
              element={
                <CompanyDetails
                  isEN={isEN}
                  isLegalWatchList={true}
                  isAdmin={isAdmin}
                />
              }
            />
            <Route
              path='/credit-applications'
              element={
                <CreditApplication
                  isAdmin={isAdmin}
                  isUser={isUser}
                  isEN={isEN}
                />
              }
            />
            <Route
              path='/application-details/:id'
              element={
                <CreditApplicationDetails isAdmin={isAdmin} isUser={isUser} />
              }
            />
            <Route path='/database-reports' element={<DatabaseReport />} />
            <Route path='/legal-watchlist' element={<LegalWatchList />} />
            <Route
              path='/order-report'
              element={<OrderReport isAdmin={isAdmin} isUser={isUser} />}
            />
            <Route
              path='my-account'
              element={
                <MyAccount
                  isEN={isEN}
                  isAdmin={isAdmin}
                  isUser={isUser}
                  isAccount={true}
                />
              }
            />
            <Route
              path='company-details'
              element={
                <MyAccount
                  isEN={isEN}
                  isAdmin={isAdmin}
                  isUser={isUser}
                  isCompany={true}
                />
              }
            />
            <Route
              path='teams'
              element={
                <MyAccount
                  isEN={isEN}
                  isAdmin={isAdmin}
                  isUser={isUser}
                  isTeams={true}
                />
              }
            /> */}
          </Route>
        </Route>
        {/* <Route
          element={<RouteGuard allowedRoles={[roles.admin]} role={role} />}
        >
          <Route element={<ContentLayout />}>
            <Route element={<AdminLayout />}>
              <Route
                path='/clients-credit-report'
                element={<AdminCreditReport />}
              />
              <Route path='/test' element={<TestRoute />} />
              <Route path='/order-report' element={<OrderReport />} />
              <Route path='/clients' element={<Client />} />
              <Route path='/client/:id' element={<CompanyDetails />} />
              <Route path='/users/:id' element={<CompanyDetails />} />
              <Route path='/aging/:id' element={<CompanyDetails />} />
              <Route path='/add-client' element={<AddClient />} />
              <Route path='/edit-client/:id' element={<EditClient />} />
              <Route path='/add-sub-client/:id' element={<AddSubClient />} />
              <Route path='/edit-sub-client/:id' element={<EditSubClient />} />
              <Route path='/transactions/:id' element={<CompanyDetails />} />
              <Route path='/clients-groups' element={<AdminGroups />} />
              <Route
                path='/clients-groups-meetings/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-network/credit-references/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-network/flash-alerts/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-network/general-discussion/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-meetings/event/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-meetings/event-subjects/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-meetings/event-submissions/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-members/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-groups-database/:id'
                element={<AdminGroupInformation />}
              />
              <Route
                path='/clients-event-reports/:id'
                element={<EventReports />}
              />
              <Route
                path='/clients-credit-applications'
                element={<AdminCreditApplications />}
              />

              <Route
                path='/clients-legal-watchlist/:id'
                element={<CompanyDetails />}
              />
              <Route
                path='/clients-application-details/:id'
                element={<CreditApplicationDetails />}
              />
            </Route>
          </Route>
        </Route> */}

        {/* <Route path='/onboarding' element={<Onboarding />} /> */}
      </Routes>
    </>
  );
};
export default Router;
