import React from 'react';

const SideDrawer = ({
  isVisible,
  heading,
  content,
  footer,
  from,
  isHideFooter,
  isHideHeader,
  isClosable,
  onCloseSidebar,
  isNavigation,
  navigation,
  onBack,
}) => {
  return (
    <div
      className={`side_drawer_wrapper ${from} ${
        isVisible ? `side_drawer_visible` : `side_drawer_invisible`
      }`}
    >
      <div
        className={`side_drawer_container ${
          isHideFooter ? `footer_hidden` : null
        } ${isVisible ? `side_container_visible` : null}`}
      >
        <div className='side_drawer_header'>
          <>
            {isNavigation ? (
              <div className='credit_risk_navigation'>
                <svg
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={onBack}
                >
                  <path
                    d='M13.296 19.294C12.9056 19.6845 12.9056 20.3186 13.296 20.7091L18.2941 25.7071C18.6845 26.0976 19.3187 26.0976 19.7091 25.7071C20.0996 25.3167 20.0996 24.6825 19.7091 24.2921L16.4136 20.9996H25.9973C26.5502 20.9996 26.9969 20.5529 26.9969 20C26.9969 19.4471 26.5502 19.0004 25.9973 19.0004H16.4167L19.706 15.7079C20.0965 15.3175 20.0965 14.6833 19.706 14.2929C19.3156 13.9024 18.6814 13.9024 18.291 14.2929L13.2929 19.2909L13.296 19.294Z'
                    fill='#334155'
                  />
                </svg>
                <span className={navigation?.className} onClick={onBack}>
                  {navigation?.navOne}
                </span>
                <svg
                  width='7'
                  height='12'
                  viewBox='0 0 7 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.60515 5.39484C6.93986 5.72955 6.93986 6.27312 6.60515 6.60783L1.46402 11.749C1.12931 12.0837 0.585741 12.0837 0.251032 11.749C-0.0836773 11.4143 -0.0836773 10.8707 0.251032 10.536L4.78701 6L0.25371 1.46402C-0.0809997 1.12931 -0.0809997 0.585741 0.25371 0.251032C0.588419 -0.0836773 1.13199 -0.0836773 1.4667 0.251032L6.60783 5.39217L6.60515 5.39484Z'
                    fill='#64748B'
                  />
                </svg>
                <span className={navigation?.className}>
                  {navigation?.navTwo}
                </span>
              </div>
            ) : (
              heading
            )}
            {isClosable && (
              <span className='report_info_nav' onClick={onCloseSidebar}>
                <svg
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.6449 2.04935C12.1134 1.58082 12.1134 0.819928 11.6449 0.351398C11.1763 -0.117133 10.4154 -0.117133 9.9469 0.351398L6 4.30205L2.04935 0.355146C1.58082 -0.113384 0.819928 -0.113384 0.351398 0.355146C-0.117133 0.823676 -0.117133 1.58457 0.351398 2.0531L4.30205 6L0.355146 9.95065C-0.113384 10.4192 -0.113384 11.1801 0.355146 11.6486C0.823676 12.1171 1.58457 12.1171 2.0531 11.6486L6 7.69795L9.95065 11.6449C10.4192 12.1134 11.1801 12.1134 11.6486 11.6449C12.1171 11.1763 12.1171 10.4154 11.6486 9.9469L7.69795 6L11.6449 2.04935Z'
                    fill='#334155'
                  />
                </svg>
              </span>
            )}
          </>
        </div>
        <div className='side_drawer_content'>{content}</div>
        {!isHideFooter && <div className='side_drawer_footer'>{footer}</div>}
      </div>
    </div>
  );
};

export default React.memo(SideDrawer);
