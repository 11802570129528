import Button from '../../ReusableComponents/Button';
export const adminClientRenderer = (entity, header, action, translations) => {
  if (header === `company_name`) {
    return <span>{entity?.company_name?.en}</span>;
  }
  if (header === `date_added`) {
    return <span>{new Date(entity?.date_added).toLocaleDateString()}</span>;
  }
  if (header === `subjects`) {
    return <span>na</span>;
  }
  if (header === `sub_companies`) {
    return (
      <span>
        {entity?.sub_companies?.length > 0 ? (
          <>
            {entity?.sub_companies?.map((company) => (
              <span key={company?._id}>{company?.company_name?.en}</span>
            ))}
          </>
        ) : (
          <>--</>
        )}
      </span>
    );
  }
  if (header === `users_count`) {
    return <span>{entity?.users_count}</span>;
  }
  if (header === `status`) {
    return (
      <span className={entity?.is_active ? `Active` : `InActive`}>
        {entity?.is_active ? translations?.active : null}
      </span>
    );
  }
  if (header === `actions`) {
    return (
      <span className='admin_client_btns_wrapper'>
        <Button
          type='primary'
          text={translations?.show_more}
          onClickHandler={() => action(entity)}
        />
      </span>
    );
  }
};
