import React, { useEffect, useState } from 'react';

const Switch = ({ isActive }) => {
  const [isSwitchActive, setIsSwitchActive] = useState(false);

  useEffect(() => {
    setIsSwitchActive(isActive);
  }, [isActive]);

  const onToggleSwitch = () => {
    setIsSwitchActive((isSwitch) => !isSwitch);
  };

  return (
    <div className='switch_wrapper'>
      <div
        onClick={onToggleSwitch}
        className={`switch_holder ${
          isSwitchActive ? `switch_active` : `switch_inactive`
        }`}
      >
        <div
          className={`switch_cicle ${
            isSwitchActive ? `switch_right` : `switch_left`
          }`}
        ></div>
      </div>
    </div>
  );
};

export default Switch;
