import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  isLoading: false,
  isWatchListFetched: false,
  isSubjectListFetched: false,
  isSubscribersListFetched: false,
  watchList: [],
  subjectList: [],
  subscriberList: [],
  companyCreatedCount: 0,
  companyAllowedCount: 0,
  lotCreatedCount: 0,
  lotAllowedCount: 0,
  watchListCreatedCount: 0,
  watchListAllowedCount: 0,
  //adding watchlist
  isWatchListAdding: false,
  isWatchListAdded: false,
  isWatchListAddError: false,
  apiMessage: '',
  // updating watchlist
  isWatchListUpdating: false,
  isWatchListUpdated: false,
  isWatchListUpdateError: false,
  //adding subject
  isSubjectAdding: false,
  isSubjectAdded: false,
  isSubjectAddError: false,
  // add subscriber
  isSubscriberAdding: false,
  isSubscriberAdded: false,
  isSubscriberAddError: false,
  // update subscriber
  isSubscriberUpdating: false,
  isSubscriberUpdated: false,
  isSubscriberUpdateError: false,
  // delete watchlist
  isWatchListDeleting: false,
  isWatchListDeleted: false,
  // delete subject
  isSubjectDeleting: false,
  isSubjectDeleted: false,
  // delete subscriber
  isSubscriberDeleting: false,
  isSubscriberDeleted: false,
  // upload subject
  isSubjectUploading: false,
  isSubjectUploaded: false,
  isSubjectUploadError: false,
};

export const getLegalWatchList = createAsyncThunk(
  `legalWatchList`,
  async (payload) => {
    try {
      const res = await api.post(`watchlist/get-watchlist-list`, payload);
      const { data } = res.data;
      return data;
    } catch (error) {}
  }
);

export const getSubjectList = createAsyncThunk(
  `subjectList`,
  async (payload) => {
    try {
      const res = await api.post(
        `watchlist/get-watchlist-subject-list`,
        payload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getSubscribersList = createAsyncThunk(
  `subscribersList`,
  async (payload) => {
    try {
      const res = await api.post(
        `watchlist/get-watchlist-subscriber-list`,
        payload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const addWatchList = createAsyncThunk(
  `addWatchList`,
  async (payload) => {
    try {
      const res = await api.post(`watchlist/create-watchlist`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateWatchList = createAsyncThunk(
  `updateWatchlist`,
  async (payload) => {
    try {
      const res = await api.post(`watchlist/update-watchlist`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {}
  }
);

export const addSubject = createAsyncThunk(`addSubject`, async (payload) => {
  try {
    const res = await api.post(`watchlist/add-watchlist-subject`, payload);
    const { message } = res?.data;
    return message;
  } catch (error) {
    const { message } = error?.response?.data;
    throw new Error(`${message}`);
  }
});

export const addSubscriber = createAsyncThunk(
  `addSubscriber`,
  async (payload) => {
    try {
      const res = await api.post(`watchlist/add-watchlist-subscriber`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const updateSubscriber = createAsyncThunk(
  `updateSubscriber`,
  async (payload) => {
    try {
      const res = await api.post(
        `watchlist/update-watchlist-subscriber`,
        payload
      );
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const deleteWatchList = createAsyncThunk(
  `deleteWatchList`,
  async (payload) => {
    try {
      const res = await api.post(`watchlist/delete-watchlist`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const deleteSubject = createAsyncThunk(
  `deleteSubject`,
  async (payload) => {
    try {
      const res = await api.post(`watchlist/delete-watchlist-subject`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const deleteSubscriber = createAsyncThunk(
  `deleteSubscriber`,
  async (payload) => {
    try {
      const res = await api.post(
        `watchlist/delete-watchlist-subscriber`,
        payload
      );
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const subjectBulkUpload = createAsyncThunk(
  `subjectUpload`,
  async (payload) => {
    try {
      const res = await api.post(
        `watchlist/add-bulk-watchlist-subject`,
        payload
      );
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

const legalWatchListSlice = createSlice({
  name: `legalWatchList`,
  initialState,
  reducers: {
    resetPopupState: (state) => {
      // watchlist
      state.isWatchListAdded = false;
      state.isWatchListAddError = false;
      state.isWatchListUpdated = false;
      state.isWatchListUpdateError = false;
      state.isWatchListDeleted = false;
      // subject list
      state.isSubjectAdded = false;
      state.isSubjectAddError = false;
      state.isSubjectDeleted = false;
      state.apiMessage = '';
    },
  },
  extraReducers: {
    // get all watchlists
    [getLegalWatchList.pending]: (state) => {
      state.isLoading = true;
      state.isWatchListFetched = false;
    },
    [getLegalWatchList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isWatchListFetched = true;
      state.watchList = action?.payload?.watchlist;
      state.companyAllowedCount = action?.payload?.total_companies_allowed;
      state.companyCreatedCount = action?.payload?.total_companies_created;
      state.lotCreatedCount = action?.payload?.total_lot_created;
      state.lotAllowedCount = action?.payload?.total_lot_allowed;
      state.watchListAllowedCount = action?.payload?.total_watchlist_allowed;
      state.watchListCreatedCount = action?.payload?.total_watchlist_created;
    },
    // get all subjects
    [getSubjectList.pending]: (state) => {
      state.isLoading = true;
      state.isSubjectListFetched = false;
    },
    [getSubjectList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSubjectListFetched = true;
      state.subjectList = action?.payload?.available_watchlist_list;
    },
    // get all subscribers
    [getSubscribersList.pending]: (state) => {
      state.isLoading = true;
      state.isSubscribersListFetched = false;
    },
    [getSubscribersList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSubscribersListFetched = true;
      state.subscriberList = action?.payload;
    },
    // add watchlist
    [addWatchList.pending]: (state) => {
      state.isWatchListAdding = true;
      state.isWatchListAdded = false;
      state.isWatchListAddError = false;
      state.apiMessage = '';
    },
    [addWatchList.fulfilled]: (state, action) => {
      state.isWatchListAdding = false;
      state.isWatchListAdded = true;
      state.isWatchListAddError = false;
      state.apiMessage = action?.payload;
    },
    [addWatchList.rejected]: (state, action) => {
      state.isWatchListAdding = false;
      state.isWatchListAdded = false;
      state.isWatchListAddError = true;
      state.apiMessage = action?.error?.message;
    },
    // update watchlist
    [updateWatchList.pending]: (state) => {
      state.isWatchListUpdating = true;
      state.isWatchListUpdateError = false;
      state.isWatchListUpdated = false;
      state.apiMessage = '';
    },
    [updateWatchList.fulfilled]: (state, action) => {
      state.isWatchListUpdating = false;
      state.isWatchListUpdated = true;
      state.isWatchListUpdateError = false;
      state.apiMessage = action?.payload;
    },
    // delete watchlist
    [deleteWatchList.pending]: (state) => {
      state.isWatchListDeleting = true;
      state.isWatchListDeleted = false;
      state.apiMessage = '';
    },
    [deleteWatchList.fulfilled]: (state, action) => {
      state.isWatchListDeleting = false;
      state.isWatchListDeleted = true;
      state.apiMessage = action?.payload;
    },
    // add subject
    [addSubject.pending]: (state) => {
      state.isSubjectAdding = true;
      state.isSubjectAdded = false;
      state.isSubjectAddError = false;
      state.apiMessage = '';
    },
    [addSubject.fulfilled]: (state, action) => {
      state.isSubjectAdding = false;
      state.isSubjectAdded = true;
      state.isSubjectAddError = false;
      state.apiMessage = action?.payload;
    },
    [addSubject.rejected]: (state, action) => {
      state.isSubjectAdding = false;
      state.isSubjectAdded = false;
      state.isSubjectAddError = true;
      state.apiMessage = action?.error?.message;
    },
    // delete subject
    [deleteSubject.pending]: (state) => {
      state.isSubjectDeleting = true;
      state.isSubjectDeleted = false;
    },
    [deleteSubject.fulfilled]: (state, action) => {
      state.isSubjectDeleting = false;
      state.isSubjectDeleted = true;
      state.apiMessage = action?.payload;
    },
    // add subscriber
    [addSubscriber.pending]: (state) => {
      state.isSubscriberAdding = true;
      state.isSubscriberAdded = false;
      state.isSubscriberAddError = false;
    },
    [addSubscriber.fulfilled]: (state, action) => {
      state.isSubscriberAdding = false;
      state.isSubscriberAdded = true;
      state.isSubscriberAddError = false;
      state.apiMessage = action?.payload;
    },
    [addSubscriber.rejected]: (state, action) => {
      state.isSubscriberAdding = false;
      state.isSubscriberAdded = false;
      state.isSubscriberAddError = true;
      state.apiMessage = action?.error?.message;
    },
    // update subscriber
    [updateSubscriber.pending]: (state) => {
      state.isSubscriberUpdating = true;
      state.isSubscriberUpdated = false;
      state.isSubscriberUpdateError = false;
    },
    [updateSubscriber.fulfilled]: (state, action) => {
      state.isSubscriberUpdating = false;
      state.isSubscriberUpdated = true;
      state.isSubscriberUpdateError = false;
      state.apiMessage = action?.payload;
    },
    [updateSubscriber.rejected]: (state, action) => {
      state.isSubscriberUpdating = false;
      state.isSubscriberUpdated = false;
      state.isSubscriberUpdateError = true;
      state.apiMessage = action?.error?.message;
    },
    //delete subscriber
    [deleteSubscriber.pending]: (state) => {
      state.isSubscriberDeleting = true;
      state.isSubscriberDeleted = false;
    },
    [deleteSubscriber.fulfilled]: (state, action) => {
      state.isSubscriberDeleting = false;
      state.isSubscriberDeleted = true;
      state.apiMessage = action?.payload;
    },
    [subjectBulkUpload.pending]: (state) => {
      state.isSubjectUploading = true;
      state.isSubjectUploaded = false;
      state.isSubjectUploadError = false;
    },
    [subjectBulkUpload.fulfilled]: (state, action) => {
      state.isSubjectUploading = false;
      state.isSubjectUploaded = true;
      state.isSubjectUploadError = false;
      state.apiMessage = action?.payload;
    },
  },
});

export const { resetPopupState } = legalWatchListSlice.actions;

export default legalWatchListSlice.reducer;
