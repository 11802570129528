export const databaseReportRenderer = (
  entity,
  header,
  action,
  options,
  values
) => {
  if (header === `normalised`) {
    return (
      <span className='database_report_subject'>
        <label>
          <input
            type='checkbox'
            className='checkbox_wrapper'
            checked={
              options?.length > 0
                ? options?.includes(entity?.report_type) &&
                  values?.includes(entity?._id)
                : values?.includes(entity?._id)
            }
            onChange={() => action(entity)}
          />
          <span className='company_name'>
            <span className='subject_name'>
              {entity?.normalised?.subject_name}
            </span>
            <br />
            <span className='original_name'>
              {entity?.normalised?.original_name}
            </span>
          </span>
        </label>
      </span>
    );
  }
  if (header === `reference_id`) {
    return <span>{entity?.reference_id}</span>;
  }
  if (header === `report_type`) {
    return (
      <>
        <span className='report_type'>{entity?.report_type} </span>
        {entity?.is_owned && <span className='report_owned'>Owned</span>}
      </>
    );
  }
  if (header === `address`) {
    return (
      <span>
        {entity?.address?.city}, {entity?.address?.postal_code}
      </span>
    );
  }
  if (header === `completion_date`) {
    return (
      <span>
        {new Date(entity?.completion_date)?.toLocaleDateString()} <br />
        {new Date(entity?.completion_date)?.toLocaleTimeString()}
      </span>
    );
  }
};
