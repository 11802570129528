import React, { useEffect, useState, useRef } from 'react';
import { weeks, months, years } from './DatePickerOptions';
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiFillCaretUp,
  AiFillCaretDown,
} from 'react-icons/ai';
import DateIcon from '../../images/date_icon.svg';

const TableDatePicker = ({
  text,
  dateHandler,
  isRemoveDate,
  removeDateHandler,
  isDisabled,
}) => {
  const today = new Date();
  const containerRef = useRef();

  const [isDatePicker, setIsDatePicker] = useState(false);
  const [weekDays, setWeekDays] = useState('');
  const [emptyDays, setEmptyDays] = useState('');
  const [monthCount, setMonthCount] = useState(today?.getMonth() + 1);
  const [yearCount, setYearCount] = useState(2023);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isShowYears, setIsShowYears] = useState(false);

  const clickedOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener(`click`, clickedOutside, true);
    return () => {
      document.removeEventListener(`click`, clickedOutside, true);
    };
  });

  useEffect(() => {
    setIsDateSelected(false);
    setSelectedDate(new Date());
  }, [isRemoveDate]);

  const onDatePickerDisplay = () => {
    setIsDatePicker(!isDatePicker);
  };

  const getNumberOfWeeks = (month, year) => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);
    const timeDifference = Math.abs(startDate - endDate);
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const prefixDays = startDate?.getDay();
    setWeekDays(dayDifference + 1);
    setEmptyDays(prefixDays);
  };

  useEffect(() => {
    getNumberOfWeeks(monthCount, yearCount);
  }, [monthCount, yearCount]);

  const onNextMonth = () => {
    if (monthCount < 12) {
      setMonthCount((month) => month + 1);
    }
    if (monthCount >= 12) {
      setYearCount((year) => year + 1);
      setMonthCount(1);
    }
  };

  const onPrevMonth = () => {
    if (monthCount <= 1) {
      setMonthCount(12);
      setYearCount((year) => year - 1);
    } else {
      setMonthCount((month) => month - 1);
    }
  };

  const onDateSeelction = (day) => {
    const date = new Date(yearCount, monthCount - 1, day);
    setSelectedDate(date);
    setIsDateSelected(true);
    setIsDatePicker(false);
    dateHandler(date);
    removeDateHandler(false);
  };

  const onYearHandler = () => {
    setIsShowYears((isYear) => !isYear);
  };

  const onYearSelection = (year) => {
    setYearCount(year);
    setIsShowYears(false);
  };

  const onBlur = () => {};

  return (
    <div className='datepicker_wrapper table_datepicker_wrapper table_field_wrapper'>
      <input
        className='table_input'
        type='datepicker'
        placeholder={text}
        onClick={onDatePickerDisplay}
        onChange={(e) => e.preventDefault()}
        value={isDateSelected ? selectedDate?.toLocaleDateString() : text}
        onBlur={onBlur}
        disabled={isDisabled}
      />
      <img className='date_icon' src={DateIcon} alt='date_icon' />
      <div
        ref={containerRef}
        className={`datepicker_box ${isDatePicker ? `show_datepicker` : ``}`}
      >
        {isDatePicker && (
          <>
            <div className='month_year_switcher'>
              <div className='month_year' onClick={onYearHandler}>
                <div className='month'>{months?.[monthCount - 1]?.name}</div>
                <div className='year'>{yearCount}</div>
                <span className='datepicker_icon'>
                  {isShowYears ? <AiFillCaretDown /> : <AiFillCaretUp />}
                </span>
              </div>
              <div className='switcher'>
                <div className='switcher_icon_wrapper' onClick={onPrevMonth}>
                  {!isShowYears && <AiOutlineLeft className='switcher_icon' />}
                </div>
                <div className='switcher_icon_wrapper' onClick={onNextMonth}>
                  {!isShowYears && <AiOutlineRight className='switcher_icon' />}
                </div>
              </div>
            </div>
            <div className='weeks_wrapper'>
              {weeks.map((weekDay) => {
                return (
                  <React.Fragment key={weekDay.index}>
                    <div className='week_box'>{weekDay.name}</div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className='days_wrapper'>
              {Array.from({ length: emptyDays }).map((_, index) => {
                return <div className='day_box' key={index}></div>;
              })}
              {Array.from({ length: weekDays }).map((_, index) => {
                return (
                  <div
                    className={`day_box ${
                      today?.getFullYear() === yearCount &&
                      today?.getMonth() + 1 === monthCount &&
                      today?.getDate() === index + 1
                        ? `day_today`
                        : ``
                    } ${
                      isDateSelected &&
                      selectedDate?.getFullYear() === yearCount &&
                      selectedDate?.getMonth() + 1 === monthCount &&
                      selectedDate?.getDate() === index + 1
                        ? `day_selected`
                        : ``
                    } `}
                    key={index}
                    onClick={() => onDateSeelction(index + 1)}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
            <div className={`years_wrapper ${isShowYears ? `show_years` : ``}`}>
              {years.map((year) => {
                return (
                  <div
                    className={`year_box ${
                      yearCount === year ? `year_selected` : ``
                    }`}
                    key={year}
                    onClick={() => onYearSelection(year)}
                  >
                    {year}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TableDatePicker;
