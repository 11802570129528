import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '../../ReusableComponents/Skeleton';

const ProfileNavigation = ({
  navigation,
  setNavigation,
  isClientInformationFetching,
}) => {
  const { t } = useTranslation();

  const tabs = [
    { name: `${t(`company_details`)}`, value: `company_details` },
    { name: `${t(`corporate_details`)}`, value: `corporate_details` },
    { name: `${t(`legal`)}`, value: `legal` },
    { name: `${t(`credit_reports`)}`, value: `credit_reports` },
    { name: `${t(`activities`)}`, value: `activities` },
    { name: `${t(`news_feed`)}`, value: `news_feed` },
  ];

  return (
    <div className='profile_navigation'>
      {isClientInformationFetching ? (
        [1, 2, 3, 4, 5, 6]?.map((_, index) => (
          <Skeleton index={index} key={index} />
        ))
      ) : (
        <div className='profile_navigation_wrapper'>
          {tabs.map((tab) => {
            return (
              <div
                className={`profile_navigation_tab ${tab.value} ${
                  navigation === tab.value
                    ? `profile_nav_active`
                    : `profile_nav_inactive`
                }`}
                key={tab.value}
                onClick={() =>
                  tab.value !== `news_feed` && setNavigation(tab.value)
                }
              >
                {tab.name}

                {tab.value === `news_feed` && (
                  <div className='risk risk_Minimal'>{`${t(
                    `coming_soon`
                  )}`}</div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProfileNavigation;
