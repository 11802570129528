import React, { useEffect, useState } from 'react';
import ListAction from './ListAction';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ListActions = ({ summary, onDiscoverClients, companyId }) => {
  const { t } = useTranslation();

  const {
    isAgingFileUploaded,
    clientsUnderProcess,
    isClientsUnderProcessFetched,
  } = useSelector((state) => state.creditRisk);

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (isAgingFileUploaded) {
      setIsProcessing(true);
    }
    if (isClientsUnderProcessFetched && clientsUnderProcess === 0) {
      setIsProcessing(false);
    }
  }, [isAgingFileUploaded, isClientsUnderProcessFetched, clientsUnderProcess]);

  return (
    <div className='list_action_wrapper'>
      {isProcessing && (
        <ListAction
          title={`${t(`records_processing`)}`}
          text={clientsUnderProcess}
          type='processing'
          isAction={false}
          companyId={companyId}
        />
      )}

      {summary?.clientsUnderProcess === 0 &&
        summary?.clientsUnderHighRisk > 0 && (
          <ListAction
            title={`${t(`clients_in_high_risk`)}`}
            text={summary?.clientsUnderHighRisk}
            type='High'
            isAction={true}
            actionText={`${t(
              summary?.clientHealthRisk?.purchasable?.high ? `discover` : `show`
            )}`}
            action={onDiscoverClients}
            isPurchasable={summary?.clientHealthRisk?.purchasable?.high}
          />
        )}

      {summary?.clientsUnderProcess === 0 &&
        summary?.clientsUnderMediumRisk > 0 && (
          <ListAction
            title={`${t(`clients_in_medium_risk`)}`}
            text={summary?.clientsUnderMediumRisk}
            type='Medium'
            isAction={true}
            actionText={`${t(
              summary?.clientHealthRisk?.purchasable?.medium
                ? `discover`
                : `show`
            )}`}
            action={onDiscoverClients}
            isPurchasable={summary?.clientHealthRisk?.purchasable?.medium}
          />
        )}

      {summary?.clientsUnderProcess === 0 &&
        summary?.clientsUnderLowRisk > 0 && (
          <ListAction
            title={`${t(`clients_in_low_risk`)}`}
            text={summary?.clientsUnderLowRisk}
            type='Low'
            isAction={true}
            actionText={`${t(
              summary?.clientHealthRisk?.purchasable?.low ? `discover` : `show`
            )}`}
            action={onDiscoverClients}
            isPurchasable={summary?.clientHealthRisk?.purchasable?.low}
          />
        )}

      {summary?.clientsUnderProcess === 0 &&
        summary?.clientsUnderMinimalRisk > 0 && (
          <ListAction
            title={`${t(`clients_in_minimal_risk`)}`}
            text={summary?.clientsUnderMinimalRisk}
            type='Minimal'
            isAction={true}
            actionText={`${t(
              summary?.clientHealthRisk?.purchasable?.minimal
                ? `discover`
                : `show`
            )}`}
            action={onDiscoverClients}
            isPurchasable={summary?.clientHealthRisk?.purchasable?.minimal}
          />
        )}

      {summary?.clientsUnderProcess === 0 && summary?.clientsNotFound > 0 && (
        <ListAction
          title={`${t(`clients_not_found`)}`}
          text={summary?.clientsNotFound}
          type='not_found'
          isAction={true}
          actionText={`${t(`show_all`)}`}
          action={onDiscoverClients}
          isPurchasable={false}
        />
      )}
    </div>
  );
};

export default ListActions;
