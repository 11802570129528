import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserDetails } from '../../slices/adminClientSlice';
import { clear, set } from 'idb-keyval';
import i18n from '../../Utils/i18n';
import { setLanguage } from '../../slices/commonSlice';
import { refreshState } from '../../slices/authSlice';

const MonitoringVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, userId, language } = useParams();

  const { isUserInformationFetched, userInformation } = useSelector(
    (state) => state.adminClient
  );

  // verifying user's identity
  const verifyUser = useCallback(async () => {
    if (token && userId) {
      clear();
      await set(`newPlatformToken`, token);
      dispatch(getUserDetails({ user_id: userId }));
    }
  }, [token, userId, dispatch]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  const saveUserDetails = useCallback(
    async (user) => {
      await set(`user`, user);
      await set(`language`, language);
      await set(`isFromOldPlatform`, true);
      navigate(`/creditrisk-management`);
      i18n.changeLanguage(language);
      dispatch(setLanguage(language));
      let userCompanies = [];
      const parentCompany = {
        _id: user?.user_data?.parent_companies?._id,
        company_name: user?.user_data?.parent_companies?.company_name,
      };
      userCompanies = [parentCompany, ...user?.user_data?.sub_company_details];
      const payload = {
        token: user?.auth_token,
        userRole: user?.user_data?.user_role,
        user: user?.user_data,
        userCompanies: userCompanies,
        userCompanyId:
          user?.user_data?.user_role === `admin`
            ? null
            : user?.user_data?.parent_companies?._id,
        userCompanyName:
          user?.user_data?.user_role === `admin`
            ? null
            : user?.user_data?.parent_companies?.company_name,
      };
      dispatch(refreshState(payload));
    },
    [language, dispatch, navigate]
  );

  useEffect(() => {
    if (isUserInformationFetched && userInformation && token) {
      const user = {
        auth_token: token,
        user_data: userInformation,
      };
      saveUserDetails(user);
    }
  }, [isUserInformationFetched, saveUserDetails, token, userInformation]);

  return <div>MonitoringVerification</div>;
};

export default MonitoringVerification;
