import Button from "../../ReusableComponents/Button";

export const watchListRenderer = (
  entity,
  header,
  action,
  action2,
  action3,
  translations
) => {
  if (header === `name`) {
    return (
      <span className="legal_watchlist_name">
        {entity?.name}
        {entity?.email_count === 0 && (
          <svg
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.14154 0.5C9.64868 0.5 10.1165 0.767857 10.3737 1.20714L18.088 14.35C18.3487 14.7929 18.3487 15.3393 18.0951 15.7821C17.8415 16.225 17.3665 16.5 16.8558 16.5H1.42725C0.916539 16.5 0.441539 16.225 0.187967 15.7821C-0.0656039 15.3393 -0.0620325 14.7893 0.19511 14.35L7.9094 1.20714C8.16654 0.767857 8.6344 0.5 9.14154 0.5ZM9.14154 5.07143C8.66654 5.07143 8.2844 5.45357 8.2844 5.92857V9.92857C8.2844 10.4036 8.66654 10.7857 9.14154 10.7857C9.61654 10.7857 9.99868 10.4036 9.99868 9.92857V5.92857C9.99868 5.45357 9.61654 5.07143 9.14154 5.07143ZM10.2844 13.0714C10.2844 12.4393 9.77368 11.9286 9.14154 11.9286C8.5094 11.9286 7.99868 12.4393 7.99868 13.0714C7.99868 13.7036 8.5094 14.2143 9.14154 14.2143C9.77368 14.2143 10.2844 13.7036 10.2844 13.0714Z"
              fill="#FF9900"
            />
          </svg>
        )}
      </span>
    );
  }
  if (header === `type`) {
    return <span>{entity?.type}</span>;
  }
  if (header === `creation_date`) {
    return <span>{new Date(entity?.create_date)?.toLocaleDateString()}</span>;
  }
  if (header === `company_count`) {
    return (
      <span>
        {entity?.type === `company` ? entity?.company_count : entity?.lot_count}
      </span>
    );
  }
  if (header === `email_count`) {
    return <span>{entity?.email_count}</span>;
  }
  if (header === `status`) {
    return (
      <span className={`legal_${entity?.is_active ? `active` : `inactive`}`}>
        {entity?.is_active ? translations.active : translations.inactive}
      </span>
    );
  }
  if (header === `actions`) {
    return (
      <span className="watchlist_actions_wrapper">
        <Button
          type="primary"
          text={translations?.details}
          onClickHandler={() => action(entity)}
        />
        <Button
          type="secondary"
          text={translations?.edit}
          onClickHandler={() => action2(entity)}
        />
        {!entity?.is_active && (
          <Button
            type="danger"
            text={translations?.delete}
            onClickHandler={() => action3(entity)}
          />
        )}
      </span>
    );
  }
};
