import React, {
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { userCreditReportRenderer } from '../views/CreditReports/userCreditReportRenderer';
import { databaseReportRenderer } from '../views/DatabaseReports/databaseReportRenderer';
import { watchListRenderer } from '../views/LegalWatchlist/watchListRenderer';
import { subjectListRenderer } from '../views/LegalWatchlist/subjectListRenderer';
import { subscriberListRenderer } from '../views/LegalWatchlist/subscriberListRenderer';
// admin
import { adminCreditReportRenderer } from '../views/CreditReports/adminCreditReportRenderer';
import { adminClientRenderer } from '../views/Clients/adminClientRenderer';
import { adminUserRenderer } from '../views/Clients/adminUserRenderer';
import Skeleton from './Skeleton';
import NoDataIcon from '../images/no_data.svg';
import { adminAgingRenderer } from '../views/Clients/adminAgingRenderer';
import { creditApplicationRenderer } from '../views/CreditApplications/creditApplicationRenderer';
import { groupRenderer } from '../views/Groups/groupRenderer';
import { subjectListMinifiedRenderer } from '../views/Groups/subjectListMinifiedRenderer';
import { eventSubjectListRenderer } from '../views/Groups/eventSubjectListRenderer';
import { eventReportRenderer } from '../views/Groups/eventReportRenderer';
import { userTeamsRenderer } from '../views/MyAccount/userTeamsRenderer';
import { useSelector } from 'react-redux';
import { adminTeamsRenderer } from '../views/MyAccount/adminTeamsRenderer';
import { clientCreditReportsRenderer } from '../views/Clients/clientCreditReportsRenderer';
import { clientDatabaseReportsRenderer } from '../views/Clients/clientDatabaseReportsRenderer';
import { escDataRenderer } from './CreditReportDetails/escDataRenderer';
import { creditRiskRenderer } from '../views/CreditRiskManagement/creditRiskRenderer';
import { historicalDataRenderer } from '../views/CreditRiskManagement/historicalDataRenderer';
import TableExpandedContent from './TableExpandedContent';
import { monitoringReportRenderer } from '../views/CreditRiskManagement/monitoringReportRenderer';
import Pagination from './Pagination';

const Table = ({
  tableProps,
  tableData,
  isLoading,
  defaultColumnHeight,
  from,
  onSorting,
  sortingIndex,
  isSorted,
  action,
  options,
  values,
  action2,
  action3,
  action4,
  action5,
  action6,
  action7,
  action8,
  rowIndex,
  isButtonOneLoading,
  isButtonTwoLoading,
  isButtonThreeLoading,
  isAdmin,
  selectedResultId,
  isExpandedView,
  expandedIndex,
  total,
  limit,
  selectedPageNumber,
  setSelectedPageNumber,
  setListFilters,
  toolTip,
  discoveredEntity,
}) => {
  const { t } = useTranslation();
  const tableHeaderRef = useRef();
  const tableWrapperRef = useRef();
  const tableContentWrapperRef = useRef();
  const [headers, setHeaders] = useState([]);
  const [columnWidth, setColumnWidth] = useState();
  const [containerHeight, setContainerHeight] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [contentHeight, setContentHeight] = useState('');

  const { isEN } = useSelector((state) => state.common);

  useLayoutEffect(() => {
    const tableHeaders = tableProps?.map((header) => {
      return {
        ...header,
        ref: createRef(),
      };
    });
    const widths = [];
    tableProps?.forEach((header) => {
      widths.push(header.initialWidth);
    });

    setHeaders(tableHeaders);
    setColumnWidth(widths);
  }, [tableProps]);

  useLayoutEffect(() => {
    const offsetTop = tableContentWrapperRef?.current?.offsetTop;
    const height = (window?.innerHeight - offsetTop) / 10;
    const heightInRem = `${height}rem`;
    setContentHeight(heightInRem);
  }, [tableContentWrapperRef]);

  useLayoutEffect(() => {
    const modifiedWidth = columnWidth?.toString()?.split(',')?.join(' ');
    tableHeaderRef.current.style.gridTemplateColumns = modifiedWidth;
    document.querySelectorAll(`.table_content_row`).forEach((element) => {
      element.style.gridTemplateColumns = modifiedWidth;
    });
    setContainerHeight(tableWrapperRef.current.clientHeight - 40);
  }, [columnWidth]);

  const onMouseUpHandler = useCallback(() => {
    setActiveIndex(null);
  }, [setActiveIndex]);

  const onMouseMoveHandler = useCallback(
    (e) => {
      headers.forEach((header, index) => {
        if (index === activeIndex) {
          const widths = [...columnWidth];
          const width =
            tableWrapperRef?.current?.scrollLeft +
            e?.clientX -
            header?.ref?.current?.offsetLeft;

          const widthInRem = `${width / 10}`;
          const headerWidthInNumber = header?.minimumWidth?.split('rem')?.[0];
          if (Number(widthInRem) > Number(headerWidthInNumber)) {
            widths[activeIndex] = `${width / 10}rem`;
            setColumnWidth(widths);
          }
        }
      });
    },
    [activeIndex, headers, columnWidth]
  );

  const removeEventListeners = useCallback(() => {
    window.removeEventListener(`mousemove`, onMouseMoveHandler);
    window.removeEventListener(`mousedown`, onMouseMoveHandler);
    window.removeEventListener(`mouseup`, removeEventListeners);
  }, [onMouseMoveHandler]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener(`mousedown`, onMouseMoveHandler);
      window.addEventListener(`mousemove`, onMouseMoveHandler);
      window.addEventListener(`mouseup`, onMouseUpHandler);
    }
    return () => {
      removeEventListeners();
    };
  }, [activeIndex, onMouseMoveHandler, onMouseUpHandler, removeEventListeners]);

  const onMouseDownHandler = (index, isResizable) => {
    isResizable && setActiveIndex(index);
  };

  const creditReportTranslations = {
    bank: `${t(`bank`)}`,
    incorporate: `${t(`incorporate`)}`,
    legal: `${t(`legal`)}`,
    suppliers: `${t(`suppliers`)}`,
    final_report: `${t(`final_report`)}`,
  };

  const legalWatchListTranslations = {
    details: `${t(`details`)}`,
    edit: `${t(`edit`)}`,
    active: `${t(`active`)}`,
    inactive: `${t(`inactive`)}`,
    delete: `${t(`delete`)}`,
  };

  const legalSubjectListTranslations = {
    remove: `${t(`remove`)}`,
  };

  const legalSubscriberListTranslations = {
    edit: `${t(`edit`)}`,
    delete: `${t(`delete`)}`,
  };

  const adminClientTranslations = {
    show_more: `${t(`show_more`)}`,
    active: `${t(`active`)}`,
    inactive: `${t(`inactive`)}`,
    simulate: `${t(`simulate`)}`,
    show_details: `${t(`show_details`)}`,
    pending: `${t(`pending`)}`,
    invited: `${t(`invited`)}`,
    resend_invite: `${t(`resend_invite`)}`,
    accept: `${t(`accept`)}`,
    delete: `${t(`delete`)}`,
    download: `${t(`download`)}`,
    delete_db: `${t(`delete_db`)}`,
    upload_db: `${t(`upload_db`)}`,
    download_db: `${t(`download_db`)}`,
    view: `${t(`view`)}`,
    order_report: `${t(`order_report`)}`,
    application_filled: `${t(`application_filled`)}`,
    application_submitted: `${t(`application_submitted`)}`,
    application_opened: `${t(`application_opened`)}`,
    application_sent: `${t(`application_sent`)}`,
    resend_email: `${t(`resend_email`)}`,
    public_link: `${t(`public_link`)}`,
    consumer: `${t(`consumer`)}`,
    corporate: `${t(`corporate`)}`,
    ref: `${t(`ref`)}`,
    edit: `${t(`edit`)}`,
    download_individual_report: `${t(`download_individual_report`)}`,
    download_credit_app: `${t(`download_credit_app`)}`,
    download_final_report: `${t(`download_final_report`)}`,
    regenerate_link: `${t(`regenerate_link`)}`,
    original: `${t(`original`)}`,
    ordered_by: `${t(`ordered_by`)}`,
    bank: `${t(`bank`)}`,
    incorporate: `${t(`incorporate`)}`,
    legal: `${t(`legal`)}`,
    suppliers: `${t(`suppliers`)}`,
    credit_and_score: `${t(`consumer_report_score`)}`,
    ac_global: `${t(`ac_score_alliance`)}`,
    alliance_credit_app: `${t(`alliance_credit_app`)}`,
    last_resort_option: `${t(`last_resort_option`)}`,
    select: `${t(`select`)}`,
    selected: `${t(`selected`)}`,
    locked: `${t(`locked`)}`,
    more_details: `${t(`more_details`)}`,
    remove: `${t(`remove`)}`,
    edit_details: `${t(`edit_details`)}`,
    buy_report: `${t(`buy_report`)}`,
    Incorporate: `${t(`incorporate`)}`,
    Legal: `${t(`legal`)}`,
    smart_score: `${t(`ac_score_plus`)}`,
    processing: `${t(`processing`)}`,
    high: `${t(`high`)}`,
    low: `${t(`low`)}`,
    medium: `${t(`medium`)}`,
    minimal: `${t(`minimal`)}`,
    processing: `${t(`processing`)}`,
  };

  return (
    <>
      <div
        className={`table3_container ${
          activeIndex !== null ? `table_no_selection` : ``
        }`}
      >
        <div className='table3_wrapper' ref={tableWrapperRef}>
          <div
            className={`table_header_wrapper ${from}_header`}
            ref={tableHeaderRef}
          >
            {headers?.map((header, index) => {
              return (
                <div className='table_header_item' key={index} ref={header.ref}>
                  <span className='table_header_item_text'>{header.name}</span>
                  {header?.isSortable && (
                    <div
                      className='sortable'
                      onClick={() => onSorting(index, header?.dataIndex)}
                    >
                      {index === sortingIndex ? (
                        <>
                          {isSorted ? (
                            <AiOutlineArrowUp />
                          ) : (
                            <AiOutlineArrowDown />
                          )}
                        </>
                      ) : (
                        <AiOutlineArrowDown />
                      )}
                    </div>
                  )}
                  {header?.isResizable && (
                    <div
                      className={`resizer3 ${
                        activeIndex === index && header?.isResizable
                          ? `resizer3_active`
                          : `resizer3_inactive`
                      }`}
                      style={{ height: containerHeight }}
                      onMouseDown={() =>
                        onMouseDownHandler(index, header?.isResizable)
                      }
                    />
                  )}
                  {/* {!header?.isEditable && (
                  <div className='locked'>
                    <svg
                      width='11'
                      height='12'
                      viewBox='0 0 11 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_9338_6809)'>
                        <path
                          d='M3.375 3.375V4.5H7.125V3.375C7.125 2.33906 6.28594 1.5 5.25 1.5C4.21406 1.5 3.375 2.33906 3.375 3.375ZM1.875 4.5V3.375C1.875 1.51172 3.38672 0 5.25 0C7.11328 0 8.625 1.51172 8.625 3.375V4.5H9C9.82734 4.5 10.5 5.17266 10.5 6V10.5C10.5 11.3273 9.82734 12 9 12H1.5C0.672656 12 0 11.3273 0 10.5V6C0 5.17266 0.672656 4.5 1.5 4.5H1.875Z'
                          fill='#E2E8F0'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_9338_6809'>
                          <rect width='10.5' height='12' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )} */}
                </div>
              );
            })}
          </div>
          <div
            className={`table_content_wrapper ${from}`}
            ref={tableContentWrapperRef}
            style={{ height: contentHeight }}
          >
            {isLoading ? (
              <>
                {Array.from({ length: 12 }).map((key, rowIndex) => {
                  return (
                    <div
                      className='table_content_row loading_state'
                      key={rowIndex}
                    >
                      {Array.from({ length: headers?.length }).map(
                        (key, columnIndex) => {
                          return (
                            <div
                              className='table_content_item'
                              style={{ height: defaultColumnHeight }}
                              key={columnIndex}
                            >
                              <Skeleton />
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {tableData?.length > 0 ? (
                  <>
                    {tableData?.map((entity, entityIndex) => {
                      return (
                        <React.Fragment key={entityIndex}>
                          <div
                            className={`table_content_row ${
                              entityIndex % 2 === 0 ? `even_row` : `odd_row`
                            } ${
                              entityIndex === tableData?.length - 1 &&
                              `last_row`
                            } ${entityIndex === 0 && `first_row`} ${
                              entity?.status
                            }_row`}
                          >
                            {tableProps.map((header, index) => {
                              return (
                                <div
                                  className={`table_content_item ${
                                    entity?.is_duplicate &&
                                    from ===
                                      `client_groups_meetings_event_subjects`
                                      ? `duplicate_group_meeting_subject`
                                      : null
                                  }`}
                                  key={index}
                                  style={{
                                    height:
                                      entity?.indicator_data?.length > 3 ||
                                      entity?.reportList?.length > 1 ||
                                      entity?.company_access?.length > 2 ||
                                      [`client_transaction_reports`]?.includes(
                                        from
                                      )
                                        ? `auto`
                                        : defaultColumnHeight,
                                  }}
                                >
                                  {/* rendering the table content based on page */}
                                  {from === `credit_report`
                                    ? userCreditReportRenderer(
                                        entity,
                                        header?.dataIndex,
                                        creditReportTranslations
                                      )
                                    : from === `admin_credit_report`
                                    ? adminCreditReportRenderer(
                                        entity,
                                        header?.dataIndex,
                                        adminClientTranslations,
                                        action,
                                        action2
                                      )
                                    : from === `user_database_report`
                                    ? databaseReportRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        options,
                                        values
                                      )
                                    : from === `legal_watch_list`
                                    ? watchListRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        action3,
                                        legalWatchListTranslations
                                      )
                                    : from === `legal_subject_list`
                                    ? subjectListRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        legalSubjectListTranslations
                                      )
                                    : from === `legal_subscriber_list`
                                    ? subscriberListRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        legalSubscriberListTranslations
                                      )
                                    : from === `admin_client`
                                    ? adminClientRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        adminClientTranslations
                                      )
                                    : from === `admin_client_users`
                                    ? adminUserRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        action3,
                                        action4,
                                        action5,
                                        entityIndex,
                                        rowIndex,
                                        isButtonOneLoading,
                                        isButtonTwoLoading,
                                        isButtonThreeLoading,
                                        adminClientTranslations
                                      )
                                    : from === `admin_client_aging`
                                    ? adminAgingRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        action3,
                                        entityIndex,
                                        rowIndex,
                                        isButtonOneLoading,
                                        isButtonTwoLoading,
                                        adminClientTranslations
                                      )
                                    : from === `credit_applications`
                                    ? creditApplicationRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        action3,
                                        action4,
                                        entityIndex,
                                        rowIndex,
                                        isButtonOneLoading,
                                        adminClientTranslations
                                      )
                                    : from === `admin_groups`
                                    ? groupRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        isAdmin,
                                        adminClientTranslations
                                      )
                                    : from ===
                                      `clients_groups_meetings_minified`
                                    ? subjectListMinifiedRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        entityIndex,
                                        rowIndex,
                                        isButtonOneLoading,
                                        adminClientTranslations
                                      )
                                    : from ===
                                      `client_groups_meetings_event_subjects`
                                    ? eventSubjectListRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        action3,
                                        adminClientTranslations
                                      )
                                    : from === `group_event_report`
                                    ? eventReportRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        adminClientTranslations
                                      )
                                    : from === `user_account_teams`
                                    ? userTeamsRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        entityIndex,
                                        rowIndex,
                                        isButtonOneLoading,
                                        adminClientTranslations,
                                        isEN
                                      )
                                    : from === `admin_account_teams`
                                    ? adminTeamsRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        action3,
                                        entityIndex,
                                        rowIndex,
                                        isButtonOneLoading,
                                        adminClientTranslations
                                      )
                                    : from === `client_transaction_reports`
                                    ? clientCreditReportsRenderer(
                                        entity,
                                        header?.dataIndex,
                                        adminClientTranslations
                                      )
                                    : from ===
                                      `client_transaction_database_reports`
                                    ? clientDatabaseReportsRenderer(
                                        entity,
                                        header?.dataIndex,
                                        adminClientTranslations,
                                        isEN
                                      )
                                    : from === `esc_data`
                                    ? escDataRenderer(
                                        entity,
                                        header?.dataIndex,
                                        adminClientTranslations,
                                        selectedResultId,
                                        action
                                      )
                                    : from === `credit_risk_client_list`
                                    ? creditRiskRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        action2,
                                        action3,
                                        action4,
                                        action5,
                                        action6,
                                        entityIndex,
                                        expandedIndex,
                                        rowIndex,
                                        isButtonOneLoading,
                                        isButtonTwoLoading,
                                        adminClientTranslations,
                                        action7,
                                        action8,
                                        toolTip,
                                        isExpandedView,
                                        discoveredEntity
                                      )
                                    : from === `credit_risk_historical_data`
                                    ? historicalDataRenderer(
                                        entity,
                                        header?.dataIndex
                                      )
                                    : from === `credit_risk_owned_reports`
                                    ? monitoringReportRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        `owned`,
                                        adminClientTranslations
                                      )
                                    : from === `credit_risk_available_reports`
                                    ? monitoringReportRenderer(
                                        entity,
                                        header?.dataIndex,
                                        action,
                                        `available`,
                                        adminClientTranslations
                                      )
                                    : null}
                                </div>
                              );
                            })}
                          </div>
                          {/* for expanded view only */}
                          <div
                            className={`table_expanded_view ${
                              entityIndex === expandedIndex && isExpandedView
                                ? `expanded`
                                : `shrinked`
                            }`}
                            key={entityIndex}
                          >
                            <TableExpandedContent
                              from={from}
                              entity={entity}
                              translations={adminClientTranslations}
                              action={action3}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <div className='table_no_data_wrapper'>
                    <img src={NoDataIcon} alt='no_data' />
                    <span className='no_data'>{`${t(`no_data`)}`}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Pagination
        total={total}
        limit={limit}
        setListFilters={setListFilters}
        selectedPageNumber={selectedPageNumber}
        setSelectedPageNumber={setSelectedPageNumber}
      />
    </>
  );
};

export default React.memo(Table);
