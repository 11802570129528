import React, { useLayoutEffect, useRef, useState } from 'react';

const Container = ({ children }) => {
  const containerRef = useRef();

  const [containerHeight, setContainerHeight] = useState(0);

  useLayoutEffect(() => {
    const offsetHeight = containerRef?.current?.getBoundingClientRect().top;
    const height = `calc(100vh - ${offsetHeight / 10}rem)`;
    setContainerHeight(height);
  }, []);

  return (
    <div
      className='full_height_container'
      ref={containerRef}
      style={{ height: containerHeight }}
    >
      {children}
    </div>
  );
};

export default Container;
