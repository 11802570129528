export const clientCreditReportsRenderer = (entity, header, translations) => {
  if (header === `report_Id`) {
    return (
      <span className='transaction_item'>
        <span className='transaction_value'>
          {entity?.report_order_data?.reference_id}
        </span>
        <span className='transaction_value'>
          {new Date(entity?.create_date)?.toLocaleDateString()}
        </span>
        <span className='transaction_key'>
          {new Date(entity?.create_date)?.toLocaleTimeString()}
        </span>
      </span>
    );
  }
  if (header === `subject`) {
    return (
      <span className='transaction_item'>
        <span className='transaction_value'>
          {entity?.report_order_data?.subject_name}
        </span>
        <span className='transaction_key_value'>
          <span className='transaction_key'>{translations?.original}: </span>
          <span className='transaction_value'>
            {entity?.report_order_data?.original_name}
          </span>
        </span>
        <span className='transaction_key_value'>
          <span className='transaction_key'>{translations?.ref}: </span>
          <span className='transaction_value'>
            {entity?.report_order_data?.client_reference_id || ` -`}
          </span>
        </span>
        <span className='transaction_key_value'>
          <span className='transaction_key'>{translations?.ordered_by}: </span>
          <span className='transaction_value'>
            {entity?.user_data?.full_name || ` -`}
          </span>
        </span>
      </span>
    );
  }
  if (header === `status`) {
    return (
      <span className='transaction_item'>
        <span
          className={
            entity?.report_order_data?.status === `Need Action`
              ? `NeedAction`
              : entity?.report_order_data?.status
          }
        >
          {entity?.report_order_data?.status}
        </span>
        {entity?.report_order_data?.status === `Completed` && (
          <>
            <span className='transaction_value transaction_completed_date'>
              {new Date(
                entity?.report_order_data?.completion_date
              )?.toLocaleDateString()}
            </span>
            <span className='transaction_key'>
              {new Date(
                entity?.report_order_data?.completion_date
              )?.toLocaleTimeString()}
            </span>
          </>
        )}
      </span>
    );
  }
  if (header === `reports`) {
    return (
      <div className='transaction_ordered_reports'>
        {entity?.report_order_data?.ordered_reports?.map((report, index) => {
          return (
            <div className='transaction_report' key={index}>
              {translations[report?.toLowerCase()]}
            </div>
          );
        })}
      </div>
    );
  }
};
