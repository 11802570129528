import React from 'react';
import { useTranslation } from 'react-i18next';

const Activities = ({
  activities,
  isEN,
  isTransactions,
  setIsTransactions,
}) => {
  const { t } = useTranslation();

  return (
    <div className='credit_risk_activities_wrapper'>
      <div className='activities_actions'>
        <div className='lable_checkbox_wrapper'>
          <label>
            <input
              className='checkbox_wrapper'
              type='checkbox'
              checked={isTransactions}
              onChange={() => setIsTransactions((prev) => !prev)}
            />
            <span>{`${t(`view_transactions`)}`}</span>
          </label>
        </div>
      </div>
      <div className='activities_container'>
        {activities?.length ? (
          activities?.map((activity, index) => (
            <div
              className={`activities_wrapper activity_${activity?.type}`}
              key={index}
            >
              <div className='activities_title'>
                {isEN ? activity.title.en : activity?.title?.fr}
                <div className='misc_details'>
                  {activity?.type === `Transaction` && (
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='12' height='12' rx='6' fill='#64748B' />
                      <path
                        d='M6.01272 1.99841C6.28928 1.99841 6.51272 2.22185 6.51272 2.49841V3.05623C6.53772 3.05935 6.56116 3.06248 6.58616 3.06716C6.59241 3.06873 6.59709 3.06873 6.60334 3.07029L7.35334 3.20779C7.62522 3.25779 7.80491 3.51873 7.75491 3.78904C7.70491 4.05935 7.44397 4.2406 7.17366 4.1906L6.43147 4.05466C5.94241 3.98279 5.51116 4.03123 5.20803 4.15154C4.90491 4.27185 4.78303 4.43748 4.75491 4.5906C4.72366 4.75779 4.74709 4.85154 4.77366 4.90935C4.80178 4.97029 4.85959 5.03904 4.97366 5.1156C5.22834 5.28279 5.61897 5.39216 6.12522 5.52654L6.17053 5.53904C6.61741 5.65779 7.16428 5.80154 7.57053 6.06716C7.79241 6.21248 8.00178 6.40935 8.13147 6.68435C8.26428 6.96404 8.29241 7.27654 8.23147 7.60935C8.12366 8.2031 7.71428 8.59998 7.20647 8.80779C6.99241 8.89529 6.75959 8.95154 6.51272 8.97966V9.50154C6.51272 9.7781 6.28928 10.0015 6.01272 10.0015C5.73616 10.0015 5.51272 9.7781 5.51272 9.50154V8.95623C5.50647 8.95466 5.49866 8.95466 5.49241 8.9531H5.48928C5.10803 8.89373 4.48147 8.72966 4.05959 8.54216C3.80803 8.42966 3.69397 8.13435 3.80647 7.88279C3.91897 7.63123 4.21428 7.51716 4.46584 7.62966C4.79241 7.77498 5.32991 7.91873 5.64084 7.96716C6.13928 8.0406 6.55022 7.99841 6.82834 7.88435C7.09241 7.77654 7.21272 7.62029 7.24709 7.43279C7.27678 7.26716 7.25334 7.17185 7.22678 7.11404C7.19709 7.05154 7.13928 6.98279 7.02366 6.90623C6.76741 6.73904 6.37522 6.62966 5.86741 6.49529L5.82366 6.48435C5.37834 6.36248 4.83147 6.21716 4.42522 5.95154C4.20334 5.80623 3.99553 5.60779 3.86584 5.33279C3.73459 5.0531 3.70803 4.7406 3.77053 4.40779C3.88303 3.81091 4.32991 3.42341 4.83772 3.22185C5.04553 3.13904 5.27366 3.08279 5.51272 3.04998V2.49841C5.51272 2.22185 5.73616 1.99841 6.01272 1.99841Z'
                        fill='white'
                      />
                    </svg>
                  )}
                  {activity?.type === `Transaction` && (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='4'
                      height='4'
                      viewBox='0 0 4 4'
                      fill='none'
                    >
                      <circle cx='2' cy='2' r='2' fill='#E2E8F0' />
                    </svg>
                  )}
                  <span className='username'>{activity?.userName}</span>
                  {activity?.userName && (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='4'
                      height='4'
                      viewBox='0 0 4 4'
                      fill='none'
                    >
                      <circle cx='2' cy='2' r='2' fill='#E2E8F0' />
                    </svg>
                  )}
                  <span className='timestamp'>
                    {new Date(activity?.createdAt)?.toLocaleDateString(
                      'en-US',
                      {
                        day: 'numeric',
                        month: 'short',
                        hour12: true,
                        hour: 'numeric',
                        minute: 'numeric',
                      }
                    )}
                  </span>
                </div>
              </div>
              <div className='activities_description'>
                {isEN ? activity.message.en : activity?.message?.fr}
              </div>
              <hr />
            </div>
          ))
        ) : (
          <div className='no_activity_wrapper'>
            {`${t(`no_activity_logged`)}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default Activities;
