import React, { useRef, useState, useEffect } from 'react';

const SelectFilter = ({
  placeholder,
  options = [],
  onFilterChange,
  isOptionsObject,
  isEN,
  filterName,
  isReset,
  isSingleSelection,
}) => {
  const containerRef = useRef();
  const [isFocus, setIsFocus] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [selectionOption, setSelectedOption] = useState('');

  useEffect(() => {
    if (isReset) {
      setSelectedOptions([]);
      setIsVisible(false);
    }
  }, [isReset]);

  const clickedOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsFocus(false);
    }
  };

  useEffect(() => {
    document.addEventListener(`click`, clickedOutside, true);
    return () => {
      document.removeEventListener(`click`, clickedOutside, true);
    };
  });
  const onFocus = () => setIsFocus(true);

  const onSelection = (isChecked, optionValue) => {
    const options = [...selectedOptions];
    const updatedOptions = isChecked
      ? [...options, optionValue]
      : options?.filter((value) => value !== optionValue);
    setSelectedOption(isSingleSelection ? optionValue : '');
    setSelectedOptions(updatedOptions);
    onFilterChange(
      filterName,
      isSingleSelection ? optionValue : updatedOptions
    );
    setIsVisible(updatedOptions?.length > 0);
    setIsFocus(!isSingleSelection);
  };

  const onRemoveFilter = () => {
    if (selectedOptions?.length) {
      setSelectedOptions([]);
      onFilterChange(filterName, []);
      setIsVisible(false);
      setSelectedOption('');
    }
  };

  return (
    <div
      className='select_filter_wrapper'
      onClick={isVisible ? () => {} : onFocus}
    >
      <span className='placeholder_count_wrapper'>
        <span className='placeholder_wrapper' onClick={onFocus}>
          {placeholder}
        </span>
        <span
          className={`filter_count ${
            isVisible ? `filter_count_visible` : `filter_count_invisible`
          }`}
        >
          {isSingleSelection
            ? [selectionOption]?.length
            : selectedOptions?.length}
        </span>
      </span>

      <span className='close_down_icons_wrapper'>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='on_cursor'
          onClick={onRemoveFilter}
        >
          <g clipPath='url(#clip0_7201_2394)'>
            <path
              d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.46875 5.46875C5.7625 5.175 6.2375 5.175 6.52812 5.46875L7.99687 6.9375L9.46562 5.46875C9.75937 5.175 10.2344 5.175 10.525 5.46875C10.8156 5.7625 10.8187 6.2375 10.525 6.52812L9.05625 7.99687L10.525 9.46562C10.8187 9.75937 10.8187 10.2344 10.525 10.525C10.2312 10.8156 9.75625 10.8187 9.46562 10.525L7.99687 9.05625L6.52812 10.525C6.23438 10.8187 5.75938 10.8187 5.46875 10.525C5.17812 10.2312 5.175 9.75625 5.46875 9.46562L6.9375 7.99687L5.46875 6.52812C5.175 6.23438 5.175 5.75938 5.46875 5.46875Z'
              fill={`${isVisible ? `#94A3B8` : `white`} `}
            />
          </g>
          <defs>
            <clipPath id='clip0_7201_2394'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>

        <svg
          width='14'
          height='8'
          viewBox='0 0 14 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={`filter_arrow ${isFocus ? `in_focus` : `out_focus`}`}
          onClick={onFocus}
        >
          <path
            d='M1 1L7 7L13 1'
            stroke='#94A3B8'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </span>

      <div
        ref={containerRef}
        className={`filter_options_wrapper ${
          isFocus ? `filter_showOptions` : `filter_hideOptions`
        } `}
      >
        {options.map((option, index) => {
          return (
            <div className='filter_options' key={index}>
              <label className='checkbox_label_wrapper'>
                <input
                  type='checkbox'
                  className='checkbox_wrapper'
                  value={`something`}
                  onChange={(e) => onSelection(e.target.checked, option?.value)}
                  checked={
                    isSingleSelection
                      ? option?.value === selectionOption
                      : selectedOptions?.includes(option?.value)
                  }
                />
                {isOptionsObject
                  ? isEN
                    ? option?.name?.en
                    : option?.name?.fr
                  : option?.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectFilter;
