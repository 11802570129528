import ReportStatus from "../../ReusableComponents/ReportStatus";
import Button from "../../ReusableComponents/Button";
export const eventReportRenderer = (
  entity,
  header,
  action,
  action2,
  translations
) => {
  const onMoreOptions = () => {
    action(true);
    action2(entity);
  };

  if (header === `date`) {
    return <span>{entity?.order_date}</span>;
  }
  if (header === `subject`) {
    return <span>{entity?.subject_name}</span>;
  }
  if (header === `meeting`) {
    return (
      <span>
        {entity?.event_name}-{entity?.group_name}
      </span>
    );
  }
  if (header === `meeting_date`) {
    return (
      <span className="company_user_wrapper">
        <span className="user_name">{entity?.event_date?.split(` `)?.[0]}</span>
        <span className="company_name">
          {entity?.event_date?.split(` `)?.[1]}
        </span>
      </span>
    );
  }
  if (header === `requested_by`) {
    return (
      <span className="company_user_wrapper">
        <span className="user_name">{entity?.company_name}</span>
        <span className="company_name">{entity?.user_name}</span>
      </span>
    );
  }
  if (header === `status`) {
    return (
      <span
        className={
          entity?.status === `Need Action` ? `NeedAction` : entity?.status
        }
      >
        {entity?.status}
      </span>
    );
  }
  if (header === `report_status`) {
    return <ReportStatus report={entity?.reports} />;
  }
  if (header === `actions`) {
    return (
      <span className="admin_credit_btns_wrapper">
        <Button
          text={translations.download_final_report}
          isShowIcon={true}
          iconPosition="before"
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM7.64687 12.3531L4.14687 8.85312C4.00312 8.70937 3.9625 8.49375 4.0375 8.30937C4.1125 8.125 4.29688 8 4.5 8H6.5V5C6.5 4.44688 6.94688 4 7.5 4H8.5C9.05313 4 9.5 4.44688 9.5 5V8H11.5C11.7031 8 11.8844 8.12187 11.9625 8.30937C12.0406 8.49687 11.9969 8.7125 11.8531 8.85312L8.35312 12.3531C8.15937 12.5469 7.84062 12.5469 7.64687 12.3531Z"
                fill="#6D738D"
              />
            </svg>
          }
        />
        <span className="more_actions_wrapper" onClick={onMoreOptions}>
          <svg
            width="16"
            height="4"
            viewBox="0 0 16 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#6D738D" />
            <circle cx="8" cy="2" r="2" fill="#6D738D" />
            <circle cx="14" cy="2" r="2" fill="#6D738D" />
          </svg>
        </span>
      </span>
    );
  }
};
