import React, { useEffect, useState } from 'react';
import MonitoringClientDetails from './MonitoringClientDetails';
import Button from '../../ReusableComponents/Button';
import { useTranslation } from 'react-i18next';
import { monthOptions } from '../../Utils/utils';

const CompanyDetails = ({ clientInformation, setIsEditClient }) => {
  const { t } = useTranslation();

  const [isShowPreviousData, setIsShowPreviousData] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    setIsShowPreviousData(clientInformation?.length > 1);
  }, [clientInformation]);

  const onShowPreviousData = () => {
    setIsShowMore((prev) => !prev);
  };

  return (
    <>
      <div className='credit_risk_company_details_wrapper'>
        {clientInformation
          ?.slice(0, isShowMore ? clientInformation?.length : 1)
          ?.map((company, index) => (
            <React.Fragment key={index}>
              <div className='company_actions'>
                <span className='upto'>
                  {monthOptions?.[company?.upto?.month]?.name}
                  {` `}
                  {company?.upto?.year}
                </span>
                {index === 0 && (
                  <Button
                    type='seven'
                    text={`${t(`edit`)}`}
                    onClickHandler={() => setIsEditClient(true)}
                  />
                )}
              </div>

              <div className='credit_risk_company_details'>
                <MonitoringClientDetails
                  clientDetails={company}
                  isCompanyDetails={true}
                />
              </div>
            </React.Fragment>
          ))}
        {isShowPreviousData && (
          <div
            className={`show_previous_data ${
              !isShowMore ? 'show_more' : 'show_less'
            }`}
            onClick={onShowPreviousData}
          >
            <span className='show_data'>{`${
              isShowMore ? t(`show_less`) : t(`show_more`)
            }`}</span>
            <svg
              width='15'
              height='8'
              viewBox='0 0 15 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.79389 7.70709C7.18444 8.09764 7.81869 8.09764 8.20924 7.70709L14.2081 1.70826C14.5986 1.31771 14.5986 0.68346 14.2081 0.292912C13.8175 -0.0976372 13.1833 -0.0976372 12.7927 0.292912L7.5 5.58563L2.20728 0.296036C1.81673 -0.0945131 1.18248 -0.0945131 0.791935 0.296036C0.401386 0.686584 0.401386 1.32084 0.791935 1.71138L6.79076 7.71021L6.79389 7.70709Z'
                fill='black'
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default CompanyDetails;
