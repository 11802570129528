import Button from '../../ReusableComponents/Button';

export const adminAgingRenderer = (
  entity,
  header,
  action,
  action2,
  action3,
  entityIndex,
  rowIndex,
  isButtonOneLoading,
  isButtonTwoLoading,
  translations
) => {
  if (header === `reference_id`) {
    return <span>{entity?.reference_id}</span>;
  }
  if (header === `upload_time`) {
    return (
      <span>
        {new Date(entity?.create_date)?.toLocaleDateString()}
        <br />
        {new Date(entity?.create_date)?.toLocaleTimeString()}
      </span>
    );
  }
  if (header === `update_upto`) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return (
      <span>
        {months[entity?.update_upto?.month]} {entity?.update_upto?.year}
      </span>
    );
  }
  if (header === `status`) {
    return (
      <span
        className={
          entity?.status === `File Received`
            ? `FileReceived`
            : entity?.status === `DB Uploaded`
            ? `DBUploaded`
            : null
        }
      >
        {entity?.status}
      </span>
    );
  }
  if (header === `actions`) {
    return (
      <span className='aging_btns_wrapper'>
        {entity?.status === `DB Uploaded` && (
          <span className='aging_btns'>
            <Button type='secondary' text={translations?.delete_db} />
            <Button type='secondary' text={translations?.download} />
            <Button type='secondary' text={translations?.download_db} />
          </span>
        )}
        {entity?.status === `File Received` && (
          <span className='aging_btns'>
            <Button type='secondary' text={translations?.delete} />
            <Button type='secondary' text={translations?.download} />
            <Button type='primary' text={translations?.upload_db} />
          </span>
        )}
      </span>
    );
  }
};
