import React from "react";
import { useTranslation } from "react-i18next";

const CorporateReportOptions = ({ reportType, setReportType }) => {
  const { t } = useTranslation();

  const reportTypeSelection = (type) => {
    setReportType(type);
  };

  return (
    <div className="bank_options_wrapper">
      <div className="heading">{`${t(`corporate_report_type`)}`}</div>
      <div className="bank_options_list">
        <label>
          <input
            className="checkbox_wrapper"
            type="checkbox"
            checked={reportType === `complete_search`}
            onChange={() => reportTypeSelection(`complete_search`)}
          />
          <span>{`${t(`complete_search`)}`}</span>
        </label>
        <label>
          <input
            className="checkbox_wrapper"
            type="checkbox"
            checked={reportType === `name_verification`}
            onChange={() => reportTypeSelection(`name_verification`)}
          />
          <span>{`${t(`name_verification`)}`}</span>
        </label>
      </div>
    </div>
  );
};

export default CorporateReportOptions;
