import React from 'react';
import MonitoringClientDetails from '../views/CreditRiskManagement/MonitoringClientDetails';
import Application from '../views/CreditApplications/Application';
import Button from './Button';

const TableExpandedContent = ({ from, entity, translations, action }) => {
  return from === `credit_risk_client_list` ? (
    <div className='credit_risk_expanded_view_wrapper'>
      <div className='expanded_view_actions'>
        <Button
          type='danger'
          text={translations?.remove}
          onClickHandler={() => action(entity)}
        />
        <Application
          isFromMonitoring={true}
          isUser={true}
          clientName={entity?.clientName}
        />
      </div>

      <MonitoringClientDetails clientDetails={entity} />
    </div>
  ) : null;
};

export default TableExpandedContent;
