import React, { useRef } from 'react';
import CloseIcon from '../images/close.svg';
import Button from './Button';

const ActionPopup = ({
  isVisible,
  onCancel,
  onSubmit,
  from,
  headerText,
  buttonText1,
  buttonText2,
  children,
  isLoading,
  isHideFooter,
  isActionsLeft,
  isSingleButton,
}) => {
  const containerRef = useRef();

  return (
    <div
      className={`modal_backdrop ${
        isVisible ? `modal_visible` : `modal_hidden`
      }`}
    >
      <div ref={containerRef} className={`modal_container ${from}`}>
        <div className='modal_header'>
          {isVisible && (
            <>
              {headerText}
              <img src={CloseIcon} alt='close' onClick={onCancel} />
            </>
          )}
        </div>
        <div className='modal_content'>{children}</div>
        {!isHideFooter && (
          <div
            className={`modal_footer ${
              isActionsLeft ? `modal_actions_left` : ``
            }`}
          >
            <Button
              type='secondary'
              text={buttonText1}
              onClickHandler={onCancel}
            />
            {!isSingleButton && (
              <Button
                type='primary'
                text={buttonText2}
                onClickHandler={onSubmit}
                isLoading={isLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionPopup;
