import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReportStatus = ({ report, isFromSideBar, isShowDownloadReport }) => {
  const { t } = useTranslation();

  const [isHover, setIsHover] = useState(false);
  const [reportType, setReportType] = useState('');

  const onMouseEnter = (type) => {
    setIsHover(true);
    setReportType(type);
  };
  const onmouseLeave = () => {
    setIsHover(false);
    setReportType('');
  };

  const downloadIcon = (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM7.64687 12.3531L4.14687 8.85312C4.00312 8.70937 3.9625 8.49375 4.0375 8.30937C4.1125 8.125 4.29688 8 4.5 8H6.5V5C6.5 4.44688 6.94688 4 7.5 4H8.5C9.05313 4 9.5 4.44688 9.5 5V8H11.5C11.7031 8 11.8844 8.12187 11.9625 8.30937C12.0406 8.49687 11.9969 8.7125 11.8531 8.85312L8.35312 12.3531C8.15937 12.5469 7.84062 12.5469 7.64687 12.3531Z'
        fill='#33AD73'
      />
    </svg>
  );

  const reportStatusRenderer = (type, statusCode) => {
    const reportClassName =
      statusCode === -1
        ? `report_status_not_ordered`
        : statusCode === 1
        ? `report_status_pending`
        : statusCode === 2
        ? `report_status_processing`
        : statusCode === 3
        ? `report_status_action_needed`
        : statusCode === 4
        ? `report_status_error`
        : statusCode === 5
        ? `report_status_completed`
        : statusCode === 6
        ? `report_status_cancelled`
        : null;
    const statusName =
      statusCode === 1
        ? `Pending`
        : statusCode === 2
        ? `Processing`
        : statusCode === 3
        ? `NeedAction`
        : statusCode === 4
        ? `Error`
        : statusCode === 5
        ? `Completed`
        : statusCode === 6
        ? `Canceled`
        : null;
    const icon =
      statusCode === 1 ? (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_7548_896)'>
            <path
              d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM7 6V10C7 10.5531 6.55312 11 6 11C5.44688 11 5 10.5531 5 10V6C5 5.44688 5.44688 5 6 5C6.55312 5 7 5.44688 7 6ZM11 6V10C11 10.5531 10.5531 11 10 11C9.44687 11 9 10.5531 9 10V6C9 5.44688 9.44687 5 10 5C10.5531 5 11 5.44688 11 6Z'
              fill='#CBD5E1'
            />
          </g>
          <defs>
            <clipPath id='clip0_7548_896'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : statusCode === 2 ? (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8C16 12.4187 12.4187 16 8 16ZM7.25 3.75V8C7.25 8.25 7.375 8.48438 7.58437 8.625L10.5844 10.625C10.9281 10.8562 11.3938 10.7625 11.625 10.4156C11.8562 10.0687 11.7625 9.60625 11.4156 9.375L8.75 7.6V3.75C8.75 3.33437 8.41562 3 8 3C7.58437 3 7.25 3.33437 7.25 3.75Z'
            fill='#FF9900'
          />
        </svg>
      ) : statusCode === 3 ? (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_5061_2299)'>
            <path
              d='M7.99982 1C8.44357 1 8.85295 1.23437 9.07795 1.61875L15.8279 13.1187C16.0561 13.5062 16.0561 13.9844 15.8342 14.3719C15.6123 14.7594 15.1967 15 14.7498 15H1.24982C0.802948 15 0.387323 14.7594 0.165448 14.3719C-0.0564269 13.9844 -0.0533019 13.5031 0.171698 13.1187L6.9217 1.61875C7.1467 1.23437 7.55607 1 7.99982 1ZM7.99982 5C7.5842 5 7.24982 5.33437 7.24982 5.75V9.25C7.24982 9.66562 7.5842 10 7.99982 10C8.41545 10 8.74982 9.66562 8.74982 9.25V5.75C8.74982 5.33437 8.41545 5 7.99982 5ZM8.99982 12C8.99982 11.7348 8.89447 11.4804 8.70693 11.2929C8.51939 11.1054 8.26504 11 7.99982 11C7.73461 11 7.48025 11.1054 7.29272 11.2929C7.10518 11.4804 6.99982 11.7348 6.99982 12C6.99982 12.2652 7.10518 12.5196 7.29272 12.7071C7.48025 12.8946 7.73461 13 7.99982 13C8.26504 13 8.51939 12.8946 8.70693 12.7071C8.89447 12.5196 8.99982 12.2652 8.99982 12Z'
              fill='#B91C1C'
            />
          </g>
          <defs>
            <clipPath id='clip0_5061_2299'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : statusCode === 4 ? (
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z'
            fill='#B91C1C'
            stroke='#B91C1C'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M9 5.80029V9.00029'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M9 12.1997H9.008'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ) : statusCode === 5 ? (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='16' height='16' rx='8' fill='#33AD73' />
          <path
            d='M10.909 6L6.909 10L5.09082 8.18182'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ) : statusCode === 6 ? (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_5061_2294)'>
            <path
              d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.46875 5.46875C5.7625 5.175 6.2375 5.175 6.52812 5.46875L7.99687 6.9375L9.46562 5.46875C9.75937 5.175 10.2344 5.175 10.525 5.46875C10.8156 5.7625 10.8187 6.2375 10.525 6.52812L9.05625 7.99687L10.525 9.46562C10.8187 9.75937 10.8187 10.2344 10.525 10.525C10.2312 10.8156 9.75625 10.8187 9.46562 10.525L7.99687 9.05625L6.52812 10.525C6.23438 10.8187 5.75938 10.8187 5.46875 10.525C5.17812 10.2312 5.175 9.75625 5.46875 9.46562L6.9375 7.99687L5.46875 6.52812C5.175 6.23438 5.175 5.75938 5.46875 5.46875Z'
              fill='#94A3B8'
            />
          </g>
          <defs>
            <clipPath id='clip0_5061_2294'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : null;
    if (type === `incorporate`) {
      return (
        <div
          className={`report_status_tag ${reportClassName}`}
          onMouseEnter={() => onMouseEnter(`incorporate`)}
          onMouseLeave={onmouseLeave}
        >
          {isHover && statusName === `Completed` && type === reportType
            ? downloadIcon
            : icon}
          <span>{`${t(`incorporate`)}`}</span>
          <div className='tooltip_wrapper'>
            <div className={`report_status_tooltip tooltip_${statusName}`}>
              <span className='report_status_name'>{statusName}</span>
            </div>
          </div>
        </div>
      );
    }
    if (type === `bank`) {
      return (
        <div
          className={`report_status_tag ${reportClassName}`}
          onMouseEnter={() => onMouseEnter(`bank`)}
          onMouseLeave={onmouseLeave}
        >
          {isHover && statusName === `Completed` && type === reportType
            ? downloadIcon
            : icon}
          <span>{`${t(`bank`)}`}</span>
          <div className='tooltip_wrapper'>
            <div className={`report_status_tooltip tooltip_${statusName}`}>
              <span className='report_status_name'>{statusName}</span>
            </div>
          </div>
        </div>
      );
    }
    if (type === `legal`) {
      return (
        <div
          className={`report_status_tag ${reportClassName}`}
          onMouseEnter={() => onMouseEnter(`legal`)}
          onMouseLeave={onmouseLeave}
        >
          {isHover && statusName === `Completed` && type === reportType
            ? downloadIcon
            : icon}
          <span>{`${t(`legal`)}`}</span>
          <div className='tooltip_wrapper'>
            <div className={`report_status_tooltip tooltip_${statusName}`}>
              <span className='report_status_name'>{statusName}</span>
            </div>
          </div>
        </div>
      );
    }
    if (type === `suppliers`) {
      return (
        <div
          className={`report_status_tag ${reportClassName}`}
          onMouseEnter={() => onMouseEnter(`suppliers`)}
          onMouseLeave={onmouseLeave}
        >
          {isHover && statusName === `Completed` && type === reportType
            ? downloadIcon
            : icon}
          <span>{`${t(`suppliers`)}`}</span>
          <div className='tooltip_wrapper'>
            <div className={`report_status_tooltip tooltip_${statusName}`}>
              <span className='report_status_name'>{statusName}</span>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={`report_section_status_wrapper ${
        isFromSideBar ? `from_sidebar` : null
      }`}
    >
      {report?.incorporate?.status_code !== -1 &&
        reportStatusRenderer(`incorporate`, report?.incorporate?.status_code)}
      {report?.bank?.status_code !== -1 &&
        reportStatusRenderer(`bank`, report?.bank?.status_code)}
      {report?.legal?.status_code !== -1 &&
        reportStatusRenderer(`legal`, report?.legal?.status_code)}
      {report?.suppliers?.status_code !== -1 &&
        reportStatusRenderer(`suppliers`, report?.suppliers?.status_code)}
      {isShowDownloadReport && (
        <span className='download_report'>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM7.64687 12.3531L4.14687 8.85312C4.00312 8.70937 3.9625 8.49375 4.0375 8.30937C4.1125 8.125 4.29688 8 4.5 8H6.5V5C6.5 4.44688 6.94688 4 7.5 4H8.5C9.05313 4 9.5 4.44688 9.5 5V8H11.5C11.7031 8 11.8844 8.12187 11.9625 8.30937C12.0406 8.49687 11.9969 8.7125 11.8531 8.85312L8.35312 12.3531C8.15937 12.5469 7.84062 12.5469 7.64687 12.3531Z'
              fill='#6D738D'
            />
          </svg>
          {`${t(`download_final_report`)}`}
        </span>
      )}
    </div>
  );
};

export default ReportStatus;
