import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';
import { set } from 'idb-keyval';

const initialState = {
  isLoading: false,
  isAuthenticated: null,
  errorMessage: '',
  user: {},
  userCompanyId: '',
  userCompany: '',
  userRole: '',
  loggedInUserData: '',
  userId: '',
  isAdmin: false,
  isUser: false,
  userCompanies: [],
  isSessionTimeOut: false,
};

export const login = createAsyncThunk(`login`, async (credentials) => {
  try {
    const res = await api.post(`/user/login`, credentials);
    const { data } = res?.data;
    return data;
  } catch (error) {
    const { message } = error?.response?.data;
    throw new Error(`${message}`);
  }
});

const authSlice = createSlice({
  name: `auth`,
  initialState,
  reducers: {
    refreshState: (state, action) => {
      state.isAuthenticated = action?.payload?.token ? true : false;
      state.userRole = action?.payload?.userRole;
      state.userCompanyId = action?.payload?.userCompanyId;
      state.userCompany = action?.payload?.userCompanyName;
      state.userCompanies = action?.payload?.userCompanies;
      state.user = action?.payload?.user;
      state.isAdmin = action?.payload?.userRole === `admin`;
      state.isUser = [`user`, `user-manager`]?.includes(
        action?.payload?.userRole
      );
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userRole = '';
      state.userCompanyId = '';
      state.userCompany = '';
      state.userCompanies = '';
      state.user = '';
      state.isAdmin = false;
      state.isUser = false;
    },
    sessionTimeOut: (state) => {
      state.isSessionTimeOut = true;
    },
  },
  extraReducers: {
    //login
    [login.pending]: (state) => {
      state.isLoading = true;
      state.isAuthenticated = null;
    },
    [login.fulfilled]: (state, action) => {
      if (
        [`user-manager`, `user`]?.includes(
          action?.payload?.user_data?.user_role
        )
      ) {
        let userCompanies = [];
        const parentCompany = {
          _id: action?.payload?.user_data?.parent_companies?._id,
          company_name:
            action?.payload?.user_data?.parent_companies?.company_name,
        };
        userCompanies = [
          parentCompany,
          ...action?.payload?.user_data?.sub_company_details,
        ];
        state.userCompanies = userCompanies || [];
      } else {
        state.userCompanies = [];
      }

      state.isLoading = false;
      state.isAuthenticated = action?.payload?.auth_token ? true : false;
      state.user = action?.payload?.user_data;
      state.userRole = action?.payload?.user_data?.user_role;
      state.userCompanyId = action?.payload?.user_data?.parent_companies?._id;
      state.userCompany =
        action?.payload?.user_data?.parent_companies?.company_name;
      state.loggedInUserData = action?.payload;
      state.isAdmin = action?.payload?.user_data?.user_role === `admin`;
      state.isUser = [`user`, `user-manager`]?.includes(
        action?.payload?.user_data?.user_role
      );

      set(`user`, action?.payload);
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.errorMessage = action?.error?.message;
      state.user = {};
      state.userRole = '';
      state.loggedInUserData = '';
    },
  },
});

export const { refreshState, logout, sessionTimeOut } = authSlice.actions;

export default authSlice.reducer;
