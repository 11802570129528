import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  isLoading: false,
  isReportsFetched: false,
  isMaxHit: false,
  reports: [],
};

export const getAllDatabaseReports = createAsyncThunk(
  `allDatabaseCreditReports`,
  async (payload) => {
    try {
      const res = await api.post(
        `database/search-database-report-list`,
        payload
      );
      const { data } = res.data;
      return data;
    } catch (error) {}
  }
);

const userDatabaseReportSlice = createSlice({
  name: `userDatabaseReport`,
  initialState,
  extraReducers: {
    // get all credit reports for user
    [getAllDatabaseReports.pending]: (state) => {
      state.isLoading = true;
      state.isReportsFetched = false;
      state.isMaxHit = false;
    },
    [getAllDatabaseReports.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isReportsFetched = true;
      state.reports = action?.payload?.searchReportList;
      state.isMaxHit = action?.payload?.maxHit;
    },
  },
});

export default userDatabaseReportSlice.reducer;
