import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  isLoading: false,
  orders: [],
};

export const getAllCreditReports = createAsyncThunk(
  `allCreditReports`,
  async (payload) => {
    try {
      const res = await api.post(`report/list-order-users`, payload);
      const { data } = res.data;
      return data;
    } catch (error) {}
  }
);

const userCreditReportSlice = createSlice({
  name: `userCreditReport`,
  initialState,
  extraReducers: {
    // get all credit reports for user
    [getAllCreditReports.pending]: (state) => {
      state.isLoading = true;
      state.orders = [];
    },
    [getAllCreditReports.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orders = action?.payload?.orders;
    },
  },
});

export default userCreditReportSlice.reducer;
