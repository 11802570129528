import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../ReusableComponents/Input';
import useFormValidation from '../../hooks/useFormValidation';
import DatePicker from '../../ReusableComponents/Datepicker/DatePicker';
import Province from '../../ReusableComponents/Province';
import { formatDate, money } from '../../Utils/utils';
import { useDispatch } from 'react-redux';
import { addClient, updateClientDetails } from '../../slices/creditRiskSlice';

const AddClient = forwardRef(
  ({ clientData, clientInformation, isEdit, companyId }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { onBlur, onInputChange, onSelection, onSubmit } =
      useFormValidation(`levelOne`);

    useImperativeHandle(ref, () => ({ validateClientForm }));

    const [sectionOneFields, setSectionOneFields] = useState([
      {
        value: '',
        name: 'clientName',
        format: `generic`,
        text: `${t(`client_legal_name`)}`,
        isRequired: true,
        isError: false,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isOptional: true,
      },
      {
        value: '',
        name: 'otherNames',
        format: `generic`,
        text: `${t(`trade_name_other_name`)}`,
        isRequired: false,
        isError: false,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isOptional: false,
      },
    ]);

    const [sectionZeroFields, setSectionZeroFields] = useState([
      {
        value: '',
        name: 'accountNumber',
        format: `generic`,
        text: `${t(`client_id`)}`,
        isRequired: false,
        isError: false,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isOptional: false,
      },
      {
        value: '',
        name: 'corporateNumber',
        format: `number`,
        text: `${t(`corporate_number`)}`,
        isRequired: false,
        isError: false,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isOptional: false,
      },
      {
        value: '',
        name: 'phoneNumber',
        format: `phone`,
        text: `${t(`phone_number`)}`,
        isRequired: false,
        isError: false,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isOptional: true,
      },
    ]);

    const [sectionTwoFields, setSectionTwoFields] = useState([
      {
        value: '',
        name: 'civicNumber',
        format: `number`,
        text: `${t(`civic_number`)}`,
        isRequired: false,
        isOptional: true,
        isError: false,
        isValid: false,
        errorMessage: '',
        isWarning: false,
      },
      {
        value: '',
        name: 'street',
        format: `generic`,
        text: `${t(`street`)}`,
        isRequired: false,
        isError: false,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isVisible: true,
      },
      {
        value: '',
        name: 'suite',
        format: `generic`,
        text: `${t(`suite`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
      },
      {
        value: '',
        name: 'city',
        format: `generic`,
        text: `${t(`city`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
      {
        value: '',
        name: 'province',
        text: `${t(`province_state`)}`,
        format: `generic`,
        isRequired: true,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isRecommended: false,
        type: `select`,
      },
      {
        value: '',
        name: 'postalCode',
        format: `postalCode`,
        //   format: report?.region === `USA` ? `zip` : `postalCode`,
        //   text: `${report?.region === `USA` ? t(`zip_code`) : t(`postal_code`)}`,
        text: `${t(`postal_code`)}`,
        isRequired: false,
        isOptional: true,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
    ]);

    const [sectionThreeFields, setSectionThreeFields] = useState([
      {
        value: '',
        name: 'accountOpeningDate',
        format: `date`,
        text: `${t(`account_opening_date`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: true,
      },
      {
        value: '',
        name: 'lastSaleDate',
        format: `date`,
        text: `${t(`date_of_last_sale`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: true,
      },
      {
        value: '',
        name: 'terms',
        format: `generic`,
        text: `${t(`terms`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
    ]);

    const [sectionFourFields, setSectionFourFields] = useState([
      {
        value: '',
        name: 'currentCreditLimit',
        format: `currency`,
        text: `${t(`current_credit_limit`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: true,
      },
      {
        value: '',
        name: 'highestCredit',
        format: `currency`,
        text: `${t(`highest_credit_limit`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: true,
      },
      {
        value: '',
        name: 'averagePayment',
        format: `number`,
        text: `${t(`average_payment_period`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: true,
      },
      {
        value: '',
        name: 'nsf',
        format: `number`,
        text: `${t(`nsf`)}`,
        isRequired: false,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: true,
      },
    ]);
    const [periodTerm, setPeriodTerm] = useState(30);

    const [sectionFiveFields, setSectionFiveFields] = useState([
      {
        value: '',
        name: 'current',
        format: `currency`,
        text: `${t(`current`)}`,
        isRequired: true,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
      {
        value: '',
        name: 'period1',
        format: `currency`,
        text: `${periodTerm === 7 ? t(`7days`) : t(`30days`)}`,
        isRequired: true,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
      {
        value: '',
        name: 'period2',
        format: `currency`,
        text: `${periodTerm === 7 ? t(`14days`) : t(`60days`)}`,
        isRequired: true,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
      {
        value: '',
        name: 'period3',
        format: `currency`,
        text: `${periodTerm === 7 ? t(`21days`) : t(`90days`)}`,
        isRequired: true,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
      {
        value: '',
        name: 'period4',
        format: `currency`,
        text: `${periodTerm === 7 ? t(`28days`) : t(`120days`)}`,
        isRequired: true,
        isError: false,
        isVisible: true,
        isValid: false,
        errorMessage: '',
        isWarning: false,
        isRecommended: false,
      },
    ]);
    const [currency, setCurrency] = useState(`cad`);
    const [country, setCountry] = useState('');
    const [comment, setComment] = useState(``);

    useEffect(() => {
      if (isEdit) {
        setSectionOneFields((fields) =>
          fields?.map((field) => {
            field.value = clientInformation?.[field?.name];
            return field;
          })
        );
        setSectionZeroFields((fields) =>
          fields?.map((field) => {
            field.value = clientInformation?.[field?.name];
            return field;
          })
        );
        setSectionTwoFields((fields) =>
          fields?.map((field) => {
            field.value = clientInformation?.address?.[field?.name];
            return field;
          })
        );

        setSectionThreeFields((fields) =>
          fields?.map((field) => {
            field.value = [`accountOpeningDate`, `lastSaleDate`].includes(
              field?.name
            )
              ? new Date(clientInformation?.[field?.name])
              : clientInformation?.[field?.name];
            return field;
          })
        );
        setSectionFourFields((fields) =>
          fields?.map((field) => {
            field.value =
              field?.format === `currency`
                ? money(clientInformation?.[field?.name])
                : clientInformation?.[field?.name];
            return field;
          })
        );
        setSectionFiveFields((fields) =>
          fields?.map((field) => {
            field.value = money(clientInformation?.[field?.name]);
            return field;
          })
        );
        setComment(clientInformation?.comment);
        setCurrency(clientInformation?.currency?.toLowerCase());
        setPeriodTerm(clientInformation?.periodTerm);
      }
    }, [isEdit, clientInformation]);

    useEffect(() => {
      !isEdit &&
        setSectionTwoFields((fields) =>
          fields?.map((field) => {
            if (field.name === `postalCode`) {
              field.text = `${
                country === `USA` ? t(`zip_code`) : t(`postal_code`)
              }`;
              field.format = country === `USA` ? `zip` : `postalCode`;
              field.value = '';
            }
            return field;
          })
        );
    }, [country, t, isEdit]);

    const onDateChange = (date, type) => {
      setSectionThreeFields((fields) =>
        fields?.map((field) => {
          if (field?.name === type) {
            field.value = date;
          }
          return field;
        })
      );
    };

    const findSum = () => {
      const values = sectionFiveFields?.map((field) =>
        Number(field?.value?.replace(/^\$|[^\d.-]/g, '').replace(/,/g, ''))
      );
      const sum = money(values?.reduce((a, b) => a + b, 0));
      return sum;
    };

    const validateClientForm = () => {
      const isSectionOneValid = onSubmit(
        sectionOneFields,
        setSectionOneFields,
        `levelOne`
      );
      const isSectionTwoValid = onSubmit(
        sectionTwoFields,
        setSectionTwoFields,
        `levelOne`
      );
      const isSectionFiveValid = onSubmit(
        sectionFiveFields,
        setSectionFiveFields,
        `levelOne`
      );
      if (isSectionOneValid && isSectionTwoValid && isSectionFiveValid) {
        const payload = {
          ...clientData,
          companyId,
          client: {},
        };
        const sectionOne = sectionOneFields?.reduce(
          (obj, { name, value }) => ({ ...obj, [name]: value }),
          {}
        );
        const sectionTwo = sectionTwoFields?.reduce(
          (obj, { name, value }) => ({ ...obj, [name]: value }),
          {}
        );

        const sectionThree = sectionThreeFields?.reduce(
          (obj, { name, value }) => ({ ...obj, [name]: value }),
          {}
        );
        const sectionFour = sectionFourFields?.reduce(
          (obj, { name, value }) => ({
            ...obj,
            [name]: value,
          }),
          {}
        );
        const sectionFive = sectionFiveFields?.reduce(
          (obj, { name, value }) => ({
            ...obj,
            [name]: value,
          }),
          {}
        );
        const sectionZero = sectionZeroFields?.reduce(
          (obj, { name, value }) => ({ ...obj, [name]: value }),
          {}
        );
        payload.client = {
          ...sectionOne,
          ...sectionTwo,
          ...sectionThree,
          ...sectionFour,
          ...sectionFive,
          ...sectionZero,
        };
        payload.client.comment = comment;
        payload.client.currency = currency === `cad` ? `CAD` : `USD`;
        payload.client.totalDue = findSum()?.replace(/^\$|[^\d.-]/g, '');
        payload.client.accountOpeningDate = payload.client.accountOpeningDate
          ? formatDate(payload.client.accountOpeningDate)
          : '';
        payload.client.lastSaleDate = payload.client.lastSaleDate
          ? formatDate(payload.client.lastSaleDate)
          : '';
        payload.client.periodTerm = periodTerm;

        if (isEdit) {
          payload.clientId = clientInformation?.id;
          payload.upto = clientInformation?.upto;
          dispatch(updateClientDetails(payload));
        } else {
          dispatch(addClient(payload));
        }
      }
    };

    const onCheckboxChange = (type, value) => {
      type === `period` ? setPeriodTerm(value) : setCurrency(value);
      if (type === `period`) {
        setSectionFiveFields((fields) =>
          fields?.map((field) => {
            if (field?.name === `period1`) {
              field.text = `${value === 30 ? t(`30days`) : t(`7days`)}`;
            }
            if (field?.name === `period2`) {
              field.text = `${value === 30 ? t(`60days`) : t(`14days`)}`;
            }
            if (field?.name === `period3`) {
              field.text = `${value === 30 ? t(`90days`) : t(`21days`)}`;
            }
            if (field?.name === `period4`) {
              field.text = `${value === 30 ? t(`120days`) : t(`28days`)}`;
            }
            return field;
          })
        );
      }
    };

    const onCommentChange = (e) => setComment(e.target.value);

    return (
      <div className='health_add_client_form_wrapper'>
        <div className='add_client_form'>
          <div className='first_section'>
            {sectionOneFields?.map((field, index) => {
              return (
                <React.Fragment key={index}>
                  <Input
                    text={field?.text}
                    isError={field?.isError}
                    isValid={field?.isValid}
                    isWarning={field?.isWarning}
                    value={field?.value}
                    errorMessage={field?.errorMessage}
                    isMandatory={field?.isRequired}
                    isRecommended={field?.isRecommended}
                    onChange={(e) =>
                      onInputChange(
                        e.target.value,
                        index,
                        field,
                        sectionOneFields,
                        setSectionOneFields
                      )
                    }
                    onBlur={() =>
                      onBlur(
                        field?.value,
                        index,
                        field,
                        sectionOneFields,
                        setSectionOneFields
                      )
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>
          <div className='second_section'>
            {sectionZeroFields?.map((field, index) => {
              return (
                <React.Fragment key={index}>
                  <Input
                    text={field?.text}
                    isError={field?.isError}
                    isValid={field?.isValid}
                    isWarning={field?.isWarning}
                    value={field?.value}
                    errorMessage={field?.errorMessage}
                    isMandatory={field?.isRequired}
                    isRecommended={field?.isRecommended}
                    onChange={(e) =>
                      onInputChange(
                        e.target.value,
                        index,
                        field,
                        sectionZeroFields,
                        setSectionZeroFields
                      )
                    }
                    onBlur={() =>
                      onBlur(
                        field?.value,
                        index,
                        field,
                        sectionZeroFields,
                        setSectionZeroFields
                      )
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>
          <hr />
          <div className='health_title'>{`${t(`address`)}`}</div>
          <div className='second_section'>
            {sectionTwoFields?.map((field, index) => {
              return field?.type === `select` ? (
                <React.Fragment key={index}>
                  <Province
                    text={field?.text}
                    isError={field?.isError}
                    value={field?.value}
                    isMandatory={field?.isRequired}
                    isRecommended={field?.isRecommended}
                    isUsa={field?.isUsa}
                    errorMessage={field?.errorMessage}
                    isShowValue={true}
                    setCountry={setCountry}
                    isFromHealthMonitoring={true}
                    isCanada={true}
                    onSetValue={(optionValue) =>
                      onSelection(
                        optionValue,
                        index,
                        sectionTwoFields,
                        setSectionTwoFields
                      )
                    }
                    onBlur={() =>
                      onBlur(
                        field?.value,
                        index,
                        field,
                        sectionTwoFields,
                        setSectionTwoFields
                      )
                    }
                  />
                </React.Fragment>
              ) : (
                <React.Fragment key={index}>
                  <Input
                    text={field?.text}
                    isError={field?.isError}
                    isValid={field?.isValid}
                    isWarning={field?.isWarning}
                    value={field?.value}
                    errorMessage={field?.errorMessage}
                    isMandatory={field?.isRequired}
                    isRecommended={field?.isRecommended}
                    onChange={(e) =>
                      onInputChange(
                        e.target.value,
                        index,
                        field,
                        sectionTwoFields,
                        setSectionTwoFields
                      )
                    }
                    onBlur={() =>
                      onBlur(
                        field?.value,
                        index,
                        field,
                        sectionTwoFields,
                        setSectionTwoFields
                      )
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>
          <hr />
          <div className='second_section'>
            {sectionThreeFields?.map((field, index) => {
              return (
                <React.Fragment key={index}>
                  {field?.format === `date` ? (
                    <DatePicker
                      isOnTop={true}
                      isHidePreviousDate={false}
                      text={field?.text}
                      onDateChange={(date) => onDateChange(date, field?.name)}
                      isShowValue={true}
                      value={field?.value}
                      isRecommended={field?.isRecommended}
                    />
                  ) : (
                    <Input
                      text={field?.text}
                      isError={field?.isError}
                      isValid={field?.isValid}
                      isWarning={field?.isWarning}
                      value={field?.value}
                      errorMessage={field?.errorMessage}
                      isMandatory={field?.isRequired}
                      isRecommended={field?.isRecommended}
                      onChange={(e) =>
                        onInputChange(
                          e.target.value,
                          index,
                          field,
                          sectionThreeFields,
                          setSectionThreeFields
                        )
                      }
                      onBlur={() =>
                        onBlur(
                          field?.value,
                          index,
                          field,
                          sectionThreeFields,
                          setSectionThreeFields
                        )
                      }
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <hr />
          <div className='third_section'>
            {sectionFourFields?.map((field, index) => {
              return (
                <React.Fragment key={index}>
                  <Input
                    text={field?.text}
                    isError={field?.isError}
                    isValid={field?.isValid}
                    isWarning={field?.isWarning}
                    value={field?.value}
                    errorMessage={field?.errorMessage}
                    isMandatory={field?.isRequired}
                    isRecommended={field?.isRecommended}
                    onChange={(e) =>
                      onInputChange(
                        e.target.value,
                        index,
                        field,
                        sectionFourFields,
                        setSectionFourFields
                      )
                    }
                    onBlur={() =>
                      onBlur(
                        field?.value,
                        index,
                        field,
                        sectionFourFields,
                        setSectionFourFields
                      )
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>
          <hr />
          <div className='fourth_section'>
            {sectionFiveFields?.map((field, index) => {
              return (
                <React.Fragment key={index}>
                  <Input
                    text={field?.text}
                    isError={field?.isError}
                    isValid={field?.isValid}
                    isWarning={field?.isWarning}
                    value={field?.value}
                    errorMessage={field?.errorMessage}
                    isMandatory={field?.isRequired}
                    isRecommended={field?.isRecommended}
                    onChange={(e) =>
                      onInputChange(
                        e.target.value,
                        index,
                        field,
                        sectionFiveFields,
                        setSectionFiveFields
                      )
                    }
                    onBlur={() =>
                      onBlur(
                        field?.value,
                        index,
                        field,
                        sectionFiveFields,
                        setSectionFiveFields
                      )
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>
          <div className='fifth_section'>
            <div className='total_due_section'>
              <span className='total_due'>{`${t(`total_due`)}: `}</span>
              <span className='total_amount'>
                {currency?.toUpperCase()} {findSum()}
              </span>
            </div>
            <div className='currency_term_section'>
              <div className='period_section'>
                <span>{`${t(`period_term`)}: `}</span>

                <div className='lable_checkbox_wrapper'>
                  <label>
                    <input
                      className='checkbox_wrapper'
                      type='checkbox'
                      checked={periodTerm === 30}
                      onChange={() => onCheckboxChange(`period`, 30)}
                    />

                    <span>{`${t(`30days`)}`}</span>
                  </label>
                </div>
                <div className='lable_checkbox_wrapper'>
                  <label>
                    <input
                      className='checkbox_wrapper'
                      type='checkbox'
                      checked={periodTerm === 7}
                      onChange={() => onCheckboxChange(`period`, 7)}
                    />

                    <span>{`${t(`7days`)}`}</span>
                  </label>
                </div>
              </div>
              <div className='period_section'>
                <span>{`${t(`currency`)}:`}</span>

                <div className='lable_checkbox_wrapper'>
                  <label>
                    <input
                      className='checkbox_wrapper'
                      type='checkbox'
                      checked={currency === `cad`}
                      onChange={() => onCheckboxChange(`currency`, `cad`)}
                    />

                    <span>{`${t(`cad`)}`}</span>
                  </label>
                </div>
                <div className='lable_checkbox_wrapper'>
                  <label>
                    <input
                      className='checkbox_wrapper'
                      type='checkbox'
                      checked={currency === `usd`}
                      onChange={() => onCheckboxChange(`currency`, `usd`)}
                    />

                    <span>{`${t(`usd`)}`}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='sixth_section'>
            <Input
              text={`${t(`comments`)}`}
              onBlur={() => {}}
              onChange={onCommentChange}
              value={comment}
              isRecommended={true}
            />
          </div>
          <hr />
        </div>
      </div>
    );
  }
);

export default AddClient;
