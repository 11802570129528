import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../../ReusableComponents/Select';
import Input from '../../ReusableComponents/Input';
import { useDispatch } from 'react-redux';
import { getConfigurations } from '../../slices/creditApplicationSlice';
import { isEmpty } from '../../Utils/utils';
import UploadFile from '../../ReusableComponents/UploadFile';
import useFormValidation from '../../hooks/useFormValidation';

const ApplicationForm = ({
  isConfigurationFetching,
  isConfigurationfetched,
  clientOptions,
  isUser,
  application,
  onApplicationChange,
  applications,
  individualFields,
  setIndividualFields,
  configurations,
  setApplications,
  isFromMonitoring,
  isCreditApplication,
}) => {
  const { onInputChange, onBlur, onSelection } = useFormValidation(`levelOne`);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // in case of user
  useEffect(() => {
    if (isUser) {
      if (isFromMonitoring && isCreditApplication) {
        !isEmpty(clientOptions?.[0]?.value) &&
          dispatch(
            getConfigurations({ company_id: clientOptions?.[0]?.value })
          );
      } else {
        !isFromMonitoring &&
          !isEmpty(clientOptions?.[0]?.value) &&
          dispatch(
            getConfigurations({ company_id: clientOptions?.[0]?.value })
          );
      }
    }
  }, [isUser, clientOptions, isFromMonitoring, isCreditApplication, dispatch]);

  // fetching list of application options
  useEffect(() => {
    if (isConfigurationfetched && configurations?.length) {
      const options = configurations?.map((config) => {
        return {
          name: config?.creditApplicationName,
          value: config?._id,
        };
      });
      setApplications(options);
    }
    setIndividualFields((fields) =>
      fields?.map((field) => {
        if (field?.name === `configuration_id`) {
          return {
            ...field,
            isError:
              !isConfigurationFetching &&
              isConfigurationfetched &&
              !configurations?.length,
            errorMessage:
              !isConfigurationFetching &&
              isConfigurationfetched &&
              !configurations?.length &&
              `${t(`no_applications_found`)}`,
            value: '',
          };
        } else return field;
      })
    );
  }, [
    isConfigurationfetched,
    t,
    isConfigurationFetching,
    configurations,
    setApplications,
    setIndividualFields,
  ]);

  const onUploadFile = (file) => {
    onApplicationChange(`creditFile`, file);
  };

  const onRemoveFile = () => {
    onApplicationChange(`creditFile`, '');
  };

  const onOptionCallback = (optionValue) => {
    dispatch(getConfigurations({ company_id: optionValue }));
    setApplications([]);
  };

  return (
    <div className='send_credit_application_wrapper'>
      <div className='credit_form_wrapper'>
        {!isFromMonitoring && (
          <div className='credit_type_selection'>
            <div className='lable_checkbox_wrapper'>
              <label>
                <input
                  type='checkbox'
                  className='checkbox_wrapper'
                  value={`something`}
                  onChange={() => onApplicationChange(`isSingle`, true)}
                  checked={application?.isSingle}
                />
                {`${t(`individual`)}`}
              </label>
            </div>
            <div className='lable_checkbox_wrapper'>
              <label>
                <input
                  type='checkbox'
                  className='checkbox_wrapper'
                  value={`something`}
                  onChange={() => onApplicationChange(`isSingle`, false)}
                  checked={!application?.isSingle}
                />
                {`${t(`in_bulk`)}`}
              </label>
            </div>
          </div>
        )}

        {/* individual fields */}
        {individualFields?.map((field, index) => {
          return field?.type === `select` ? (
            <div className={`credit_section field_${index}`} key={index}>
              <Select
                text={field?.text}
                isError={field?.isError}
                value={field?.value}
                isClosed={isEmpty(field?.value)}
                errorMessage={field?.errorMessage}
                isShowValue={field?.name === `company_id`}
                options={
                  field?.name === `company_id` ? clientOptions : applications
                }
                isDisabled={
                  (isUser &&
                    !isFromMonitoring &&
                    field?.name === `company_id`) ||
                  (field?.name === `configuration_id` &&
                    applications?.length === 0)
                }
                onBlur={() =>
                  onBlur(
                    field?.value,
                    index,
                    field,
                    individualFields,
                    setIndividualFields
                  )
                }
                onOptionSelection={(optionValue) =>
                  onSelection(
                    optionValue,
                    index,
                    individualFields,
                    setIndividualFields
                  )
                }
                onOptionCallback={
                  field?.name === `company_id` ? onOptionCallback : () => {}
                }
              />
            </div>
          ) : application?.isSingle ? (
            <div className={`credit_section field_${index}`} key={index}>
              <Input
                text={field?.text}
                isError={field?.isError}
                isValid={field?.isValid}
                isWarning={field?.isWarning}
                value={field?.value}
                errorMessage={field?.errorMessage}
                isDisabled={field?.name === `subject_name`}
                onBlur={() =>
                  onBlur(
                    field?.value,
                    index,
                    field,
                    individualFields,
                    setIndividualFields
                  )
                }
                onChange={(e) =>
                  onInputChange(
                    e.target.value,
                    index,
                    field,
                    individualFields,
                    setIndividualFields
                  )
                }
              />
            </div>
          ) : null;
        })}
        {application?.isSingle ? (
          <>
            {' '}
            <div className='lang_pref_heading'>{`${t(
              `language_preference_text`
            )}`}</div>
            <div className='credit_type_selection'>
              <div className='lable_checkbox_wrapper'>
                <label>
                  <input
                    type='checkbox'
                    className='checkbox_wrapper'
                    value={`something`}
                    onChange={() => onApplicationChange(`language`, `en`)}
                    checked={application?.language === `en`}
                  />
                  {`${t(`en`)}`}
                </label>
              </div>
              <div className='lable_checkbox_wrapper'>
                <label>
                  <input
                    type='checkbox'
                    className='checkbox_wrapper'
                    value={`something`}
                    onChange={() => onApplicationChange(`language`, `fr`)}
                    checked={application?.language === `fr`}
                  />
                  {`${t(`fr`)}`}
                </label>
              </div>
            </div>
            <div className='credit_section'>
              <Input
                text={`${t(`reference_number`)}`}
                value={application?.referenceNumber}
                onChange={(e) =>
                  onApplicationChange(`referenceNumber`, e.target.value)
                }
                onBlur={() => {}}
              />
            </div>
          </>
        ) : (
          <>
            <div className='credit_section_file'>
              <UploadFile
                from='send_credit_application'
                onUploadFile={onUploadFile}
                files={application?.creditFile?.name}
                onRemoveFile={onRemoveFile}
              />
              <span className='form_error'>{application?.fileError}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ApplicationForm);
