import React from 'react';
import Select from '../../ReusableComponents/Select';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableComponents/Button';
import { isEmpty, monthOptions } from '../../Utils/utils';
import SampleAgingEN from './AgingTemplateEN.xlsx';
import SampleAgingFR from './AgingTemplateFR.xlsx';

const UploadAgingAddClient = ({
  setIsAddClient,
  setClientData,
  clientData,
  companyErrorMessage,
  setCompanyErrorMessage,
  setIsUploadAging,
  isConfirmation,
  fileData,
  onCancelUpload,
  onUploadAgingFile,
  isAgingFileUploading,
  companyOptions,
  isOverLimit,
  isEN,
}) => {
  const { t } = useTranslation();

  const yearOptions = [
    { name: 2023, value: 2023 },
    { name: 2024, value: 2024 },
  ];

  const onSelection = (optionValue, type) => {
    if (type === `company`) {
      setCompanyErrorMessage(``);
      const isOverLimit = companyOptions?.find(
        (company) => company?.value === optionValue
      )?.isOverLimit;
      const maxLimit = companyOptions?.find(
        (company) => company?.value === optionValue
      )?.maxLimit;

      setClientData({
        ...clientData,
        companyId: optionValue,
        isOverLimit: isOverLimit,
        maxLimit: maxLimit,
      });
    } else if (type === `month`) {
      setClientData({
        ...clientData,
        upto: {
          ...clientData.upto,
          month: monthOptions?.find((month) => month?.name === optionValue?.en)
            ?.index,
        },
      });
    } else if (type === `year`) {
      setClientData({
        ...clientData,
        upto: { ...clientData.upto, year: optionValue, month: '' },
      });
    }
  };

  const onAddClientHandler = () => {
    setCompanyErrorMessage(
      clientData?.companyId ? `` : `${t(`health_select_company`)}`
    );
    clientData?.companyId && setIsAddClient(true);
  };

  const onUploadAgingFileHandler = () => {
    setCompanyErrorMessage(
      clientData?.companyId ? `` : `${t(`health_select_company`)}`
    );
    clientData?.companyId && setIsUploadAging(true);
  };

  const onDownloadSampleFile = () => {
    const link = document.createElement('a');
    link.href = isEN ? SampleAgingEN : SampleAgingFR;
    link.download = 'Sample Aging File.xlsx';
    link.click();
  };

  return (
    <>
      <div className='health_upload_aging_add_client_wrapper'>
        <div className='health_companies_wrapper'>
          <Select
            options={companyOptions}
            text={`${t(`select_company_only`)}`}
            onBlur={() => {}}
            isError={companyErrorMessage}
            onOptionCallback={() => {}}
            onOptionSelection={(option) => onSelection(option, `company`)}
            errorMessage={companyErrorMessage}
            isDisabled={isConfirmation}
          />
        </div>

        {isConfirmation ? (
          <div className='confirmation_section'>
            <svg
              width='90'
              height='80'
              viewBox='0 0 90 80'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 10C0 4.48438 4.48438 0 10 0H35V20C35 22.7656 37.2344 25 40 25H60V31.0312C48.4531 34.2969 40 44.9062 40 57.5C40 66.7344 44.5469 74.8906 51.5156 79.8906C51.0156 79.9688 50.5156 80 50 80H10C4.48438 80 0 75.5156 0 70V10ZM60 20H40V0L60 20ZM45 57.5C45 51.5326 47.3705 45.8097 51.5901 41.5901C55.8097 37.3705 61.5326 35 67.5 35C73.4674 35 79.1903 37.3705 83.4099 41.5901C87.6295 45.8097 90 51.5326 90 57.5C90 63.4674 87.6295 69.1903 83.4099 73.4099C79.1903 77.6295 73.4674 80 67.5 80C61.5326 80 55.8097 77.6295 51.5901 73.4099C47.3705 69.1903 45 63.4674 45 57.5ZM78.0156 50.7344C77.0469 49.7656 75.4531 49.7656 74.4844 50.7344L65 60.2188L60.5156 55.7344C59.5469 54.7656 57.9531 54.7656 56.9844 55.7344C56.0156 56.7031 56.0156 58.2969 56.9844 59.2656L63.2344 65.5156C64.2031 66.4844 65.7969 66.4844 66.7656 65.5156L78.0156 54.2656C78.9844 53.2969 78.9844 51.7031 78.0156 50.7344Z'
                fill='#E2E8F0'
              />
            </svg>

            <div className='confirmation_title'>
              {`${t(`file_parsed`)}`} {fileData?.length} {t(`records_found`)}
            </div>
            <div className='confirmation_sub_title'>
              {t(`upload_aging_confirmation_text`)}
            </div>
          </div>
        ) : (
          <>
            <div className='health_upload_aging_file_wrapper'>
              <div className='title'>{`${t(`upload_aging_file`)}`}</div>
              <div className='sub_title'>{`${t(`upload_aging_text`)}`}</div>
              <div className='uptodateby'>{`${t(`upto_date_by`)}`}</div>
            </div>
            <div className='health_year_month_wrapper'>
              <Select
                options={yearOptions}
                text={`${t(`year`)}`}
                onOptionCallback={() => {}}
                onBlur={() => {}}
                onOptionSelection={(option) => onSelection(option, `year`)}
                isShowValue={true}
                value={
                  yearOptions?.find(
                    (year) => year?.name === clientData?.upto?.year
                  )?.value
                }
                isDisabled={isOverLimit}
              />
              <Select
                options={monthOptions}
                text={`${t(`month`)}`}
                onOptionSelection={(option) => onSelection(option, `month`)}
                onOptionCallback={() => {}}
                onBlur={() => {}}
                isShowValue={true}
                value={monthOptions?.[clientData?.upto?.month]?.value}
                isDisabled={isOverLimit}
              />
            </div>
          </>
        )}

        <Button
          type='primary'
          text={`${
            isConfirmation ? t(`confirm_upload`) : t(`upload_aging_file_start`)
          }`}
          onClickHandler={
            isConfirmation ? onUploadAgingFile : onUploadAgingFileHandler
          }
          isLoading={isAgingFileUploading}
          isDisabled={isOverLimit}
        />
        {!isConfirmation && (
          <>
            <div
              className='health_download_sample_aging'
              onClick={onDownloadSampleFile}
            >{`${t(`download_sample_aging_file`)}`}</div>
            <div className='or_section'>{`${t(`or`)}`}</div>
          </>
        )}
        {isConfirmation && <div className='confirmation_divider' />}
        <Button
          type='seven'
          text={`${isConfirmation ? t(`cancel`) : t(`add_client`)}`}
          onClickHandler={isConfirmation ? onCancelUpload : onAddClientHandler}
          isDisabled={isOverLimit}
        />
      </div>
      {isOverLimit && (
        <div className='max_limit_warning'>{`${t(`max_limit_reached`)}`}</div>
      )}
    </>
  );
};

export default UploadAgingAddClient;
