import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  navigationItem: '',
  breadCrumb: '',
  language: '',
  isEN: false,
  isFR: false,

  isEnglishFileDownloading: false,
  isEnglishFileDownloaded: false,
  isEnglishFileDownloadError: false,
  englishDownloadedFile: '',

  isFrenchFileDownloading: false,
  isFrenchFileDownloaded: false,
  isFrenchFileDownloadError: false,
  frenchDonwloadedFile: '',
};

export const downloadEnglishFile = createAsyncThunk(
  `common_file_download_english`,
  async (requestPayload) => {
    console.log('🚀 ~ requestPayload:', requestPayload);
    try {
      const { endPoint, payload } = requestPayload;
      const res = await api.post(endPoint, payload, {
        responseType: 'blob',
      });
      const { data } = res;
      const url = window.URL.createObjectURL(data);
      return url;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const downloadFrenchFile = createAsyncThunk(
  `common_file_download_french`,
  async (requestPayload) => {
    console.log('🚀 ~ requestPayload:=====================', requestPayload);
    try {
      const { endPoint, payload } = requestPayload;
      const res = await api.post(endPoint, payload, {
        responseType: 'blob',
      });
      const { data } = res;
      const url = window.URL.createObjectURL(data);
      return url;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setNavigation: (state, action) => {
      state.navigationItem = action.payload;
    },
    setBreadCrumb: (state, action) => {
      state.breadCrumb = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action?.payload;
      state.isEN = action?.payload === `en`;
      state.isFR = action?.payload === `fr`;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadEnglishFile.pending, (state) => {
        state.isEnglishFileDownloading = true;
        state.isEnglishFileDownloaded = false;
      })
      .addCase(downloadEnglishFile.fulfilled, (state, action) => {
        state.isEnglishFileDownloading = false;
        state.isEnglishFileDownloaded = true;
        state.englishDownloadedFile = action?.payload;
      })
      .addCase(downloadEnglishFile.rejected, (state) => {
        state.isEnglishFileDownloading = false;
        state.isEnglishFileDownloaded = false;
        state.isEnglishFileDownloadError = true;
      });

    builder
      .addCase(downloadFrenchFile.pending, (state) => {
        state.isFrenchFileDownloading = true;
        state.isFrenchFileDownloaded = false;
      })
      .addCase(downloadFrenchFile.fulfilled, (state, action) => {
        state.isFrenchFileDownloading = false;
        state.isFrenchFileDownloaded = true;
        state.frenchDonwloadedFile = action?.payload;
      })
      .addCase(downloadFrenchFile.rejected, (state) => {
        state.isFrenchFileDownloading = false;
        state.isFrenchFileDownloaded = false;
        state.isFrenchFileDownloadError = true;
      });
  },
});

export const { setNavigation, setBreadCrumb, setLanguage } =
  commonSlice.actions;
export default commonSlice.reducer;
