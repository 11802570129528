import React from 'react';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import Application from '../views/CreditApplications/Application';

const NavigationHeader = ({ isIcon, title, isActions, actions }) => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };
  return (
    <div className='navigation_header_wrapper'>
      <div className='navigation_header'>
        {isIcon && (
          <span className='navigation_icon_wrapper' onClick={onBack}>
            <svg
              width='14'
              height='12'
              viewBox='0 0 14 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='navigation_icon'
            >
              <path
                d='M0.295784 5.29402C-0.0946884 5.6845 -0.0946884 6.31863 0.295784 6.7091L5.29383 11.7071C5.6843 12.0976 6.31843 12.0976 6.7089 11.7071C7.09938 11.3167 7.09938 10.6825 6.7089 10.2921L3.41332 6.99961H12.9971C13.55 6.99961 13.9967 6.55291 13.9967 6C13.9967 5.44709 13.55 5.00039 12.9971 5.00039H3.41644L6.70578 1.70793C7.09625 1.31745 7.09625 0.683327 6.70578 0.292854C6.31531 -0.0976181 5.68118 -0.0976181 5.29071 0.292854L0.29266 5.2909L0.295784 5.29402Z'
                fill='#334155'
              />
            </svg>
          </span>
        )}
        <span className='navigation_title'>{title}</span>
      </div>

      {isActions && (
        <div className='navigation_actions'>
          <Application isFromMonitoring={true} isUser={true} />
          {actions?.map((action, index) => (
            <React.Fragment key={index}>
              <Button
                type={action?.type}
                text={action?.text}
                onClickHandler={action?.onClick}
                isLoading={action?.isLoading}
              />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavigationHeader;
