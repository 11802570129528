import Button from '../../ReusableComponents/Button';

export const adminTeamsRenderer = (
  entity,
  header,
  action,
  action2,
  action3,
  entityIndex,
  rowIndex,
  isButtonOneLoading,
  translations
) => {
  if (header === `title`) {
    return <span>{entity?.title}</span>;
  }
  if (header === `full_name`) {
    return <span>{entity?.full_name}</span>;
  }
  if (header === `email_id`) {
    return <span>{entity?.email_id}</span>;
  }
  if (header === `date_added`) {
    return <span>{entity?.date_added}</span>;
  }
  if (header === `status`) {
    return (
      <>
        {entity?.is_verified && entity?.account_actived ? (
          <>
            <span className={entity?.is_active ? `Active` : `Inactive`}>
              {entity?.is_active
                ? translations?.active
                : translations?.inactive}
            </span>
          </>
        ) : !entity?.is_verified && !entity?.account_actived ? (
          <span className='Pending'>{translations?.pending}</span>
        ) : (
          <span className='Invited'>{translations?.invited}</span>
        )}
      </>
    );
  }
  if (header === `actions`) {
    return (
      <span className='admin_client_btns_wrapper'>
        <Button
          type='secondary'
          text={translations?.edit}
          onClickHandler={() => action(entity?._id, entityIndex)}
          isDisabled={entity?.is_duplicate}
          isLoading={isButtonOneLoading && rowIndex === entityIndex}
        />
        <Button
          type='danger'
          text={translations?.delete}
          onClickHandler={() => action3(entity?._id)}
          isDisabled={entity?.is_duplicate}
        />
        {!entity?.account_actived && (
          <Button
            type='secondary'
            text={translations?.regenerate_link}
            onClickHandler={() => action2(entity?.email_id)}
            isDisabled={entity?.is_duplicate}
          />
        )}
      </span>
    );
  }
};
