import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../ReusableComponents/Header';
import NavigationBar from '../ReusableComponents/NavigationBar';
import UserLayout from '../views/UserModule/UserLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { refreshState } from '../slices/authSlice';
import { useTranslation } from 'react-i18next';
import { del, get } from 'idb-keyval';

const clientNavigation = [
  `clients`,
  `client`,
  `users`,
  `clients-legal-watchlist`,
  `aging`,
  `add-client`,
  `edit-client`,
  `add-sub-client`,
  `edit-sub-client`,
  `transactions`,
];

const groupNavigation = [
  `groups`,
  `groups-meetings`,
  `groups-network`,
  `groups-database`,
  `groups-members`,
  `event-reports`,
];

const ContentLayout = ({ isAdmin }) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { navigationItem } = useSelector((state) => state.common);

  const [isSidebar, setIsSidebar] = useState(false);
  const [isHideHeader, setIsHideHeader] = useState(false);
  const [isSumulatedUser, setIsSimulatedUser] = useState(false);
  const [simulatedUserDetails, setSimulatedUserDetails] = useState({});

  const onSidebarHandler = () => setIsSidebar((prevState) => !prevState);

  useLayoutEffect(() => {
    if (navigationItem === `clients`) {
      setIsHideHeader(!params?.id);
    } else if (
      [
        'credit-reports',
        'credit-applications',
        'groups',
        `my-account`,
        'company-details',
        'teams',
        'all-transactions',
        'esc-data',
        'update-report',
        'creditrisk-management',
        'company-profile',
        'historical-data',
        'file',
      ].includes(navigationItem)
    ) {
      setIsHideHeader(true);
    } else if (
      [
        'add-client',
        'groups-meetings',
        'order-report',
        'application-details',
      ].includes(navigationItem)
    ) {
      setIsHideHeader(false);
    }
  }, [navigationItem, params]);

  const getSimulationDetails = async () => {
    const simulationId = await get(`simulationId`);
    const simulatedUser = await get(`simulatedUser`);
    if (simulationId && simulatedUser) {
      setIsSimulatedUser(true);
      setSimulatedUserDetails(simulatedUser);
    }
  };

  useEffect(() => {
    getSimulationDetails();
  }, []);

  const onExitSimulation = async () => {
    const simulationId = await get(`simulationId`);
    const user = await get(`user`);
    if (user?.auth_token) {
      const payload = {
        token: user?.auth_token,
        userRole: user?.user_data?.user_role,
        userCompanyId: user?.user_data?.parent_companies?._id,
      };
      dispatch(refreshState(payload));
      navigate(`/users/${simulationId}`);
      del(`simulationId`);
      del(`simulatedUser`);
      setIsSimulatedUser(false);
    }
  };

  return (
    <>
      {isSumulatedUser && (
        <div className='simulation_wrapper'>
          <div className='simulation_section'>
            <span className='title'>{`${t(`simulating_user`)}: `}</span>
            <span className='value'>
              {simulatedUserDetails?.user_data?.full_name}
            </span>
          </div>
          <div className='simulation_section'>
            <span className='title'>{`${t(`title`)}: `}</span>
            <span className='value'>
              {simulatedUserDetails?.user_data?.title || `NA`}
            </span>
          </div>
          <div className='simulation_section'>
            <span className='title'>{`${t(`role`)}: `}</span>
            <span className='value user_role'>
              {simulatedUserDetails?.user_data?.user_role}
            </span>
          </div>
          <div className='simulation_section'>
            <div className='exit_simulation'>
              <span onClick={onExitSimulation}>{`${t(
                `exit_simulation`
              )}`}</span>
            </div>
          </div>
        </div>
      )}

      <div className='content_layout_wrapper header_sidebar_topbar_content_layout'>
        <div
          className={`topbar_layout_wrapper ${
            isSidebar ? `topbar_visible` : `topbar_hidden`
          }`}
        >
          <Header
            from='my-account'
            isSidebar={isSidebar}
            onSidebarHandler={onSidebarHandler}
            clientNavigation={clientNavigation}
            groupNavigation={groupNavigation}
            isAdmin={isAdmin}
          />
        </div>
        <div
          className={`header_layout_wrapper ${
            isHideHeader ? `hide_header` : null
          }`}
        >
          <NavigationBar
            onSidebarHandler={onSidebarHandler}
            clientNavigation={clientNavigation}
            groupNavigation={groupNavigation}
          />
        </div>

        <div className='content_wrapper'>
          <UserLayout />
        </div>

        <div
          className={`sidebar_backdrop_wrapper ${
            isSidebar ? `sidebar_backdrop_visible` : `sidebar_backdrop_hidden`
          }`}
          onClick={onSidebarHandler}
        />
      </div>
    </>
  );
};

export default ContentLayout;
