import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineMenu, AiOutlineRight } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'idb-keyval';

const NavigationBar = ({
  onSidebarHandler,
  clientNavigation,
  groupNavigation,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { navigationItem } = useSelector((state) => state.common);

  const [route, setRoute] = useState('');
  const [navigation, setNavigation] = useState({});

  const setNavigationValues = async () => {
    const groupName = await get(`groupName`);
    const clientName = await get(`clientName`);
    if (clientName) {
      setNavigation((navigation) => ({
        ...navigation,
        clientName: clientName,
      }));
    }
    if (groupName) {
      setNavigation((navigation) => ({ ...navigation, groupName: groupName }));
    }
  };

  useEffect(() => {
    if (pathname) {
      setRoute(pathname?.split('/')?.[1]);
      setNavigationValues();
    }
  }, [pathname]);

  const onNavigation = () => {
    if (clientNavigation?.includes(navigationItem)) {
      navigate(`/clients`);
    } else if (groupNavigation?.includes(navigationItem)) {
      navigate(`/groups`);
    } else if (navigationItem === `application-details`) {
      navigate(`/credit-applications`);
    }
  };

  const onItemNavigation = async () => {
    if (navigationItem === `event-reports`) {
      const groupId = await get(`groupId`);
      navigate(`/groups-meetings/${groupId}`);
    }
  };

  const onBackToReport = () => {
    navigate(`/credit-reports`);
  };

  return (
    <div className='header_wrapper'>
      <span className='header_nav_text text'>
        <span className='header_nav_link' onClick={onNavigation}>
          {clientNavigation?.includes(navigationItem)
            ? `${t(`clients`)}`
            : navigationItem === `credit-applications`
            ? `${t(`credit_applications`)}`
            : groupNavigation?.includes(navigationItem)
            ? `${t(`groups`)}`
            : navigationItem === `application-details`
            ? `${t(`credit_applications`)}`
            : null}
        </span>
        {navigationItem !== `credit-applications` && route && (
          <span className='header_nav_icon'>
            {navigationItem === `order-report` ? (
              <svg
                className='back_to_report'
                onClick={onBackToReport}
                width='14'
                height='12'
                viewBox='0 0 14 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.296028 5.29402C-0.0944443 5.6845 -0.0944443 6.31863 0.296028 6.7091L5.29408 11.7071C5.68455 12.0976 6.31868 12.0976 6.70915 11.7071C7.09962 11.3167 7.09962 10.6825 6.70915 10.2921L3.41356 6.99961H12.9973C13.5502 6.99961 13.9969 6.55291 13.9969 6C13.9969 5.44709 13.5502 5.00039 12.9973 5.00039H3.41668L6.70602 1.70793C7.0965 1.31745 7.0965 0.683327 6.70602 0.292854C6.31555 -0.0976181 5.68142 -0.0976181 5.29095 0.292854L0.292904 5.2909L0.296028 5.29402Z'
                  fill='#334155'
                />
              </svg>
            ) : (
              <svg
                width='7'
                height='12'
                viewBox='0 0 7 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.60515 5.39484C6.93986 5.72955 6.93986 6.27312 6.60515 6.60783L1.46402 11.749C1.12931 12.0837 0.585741 12.0837 0.251032 11.749C-0.0836773 11.4143 -0.0836773 10.8707 0.251032 10.536L4.78701 6L0.25371 1.46402C-0.0809997 1.12931 -0.0809997 0.585741 0.25371 0.251032C0.588419 -0.0836773 1.13199 -0.0836773 1.4667 0.251032L6.60783 5.39217L6.60515 5.39484Z'
                  fill='#64748B'
                />
              </svg>
            )}
          </span>
        )}

        {route && (
          <span
            className='header_nav_content'
            onClick={onItemNavigation}
            style={{
              cursor:
                navigationItem === `event-reports` ? `pointer` : `default`,
            }}
          >
            {route === `add-client` ? (
              <>{`${t(`add_client`)}`}</>
            ) : route === `order-report` ? (
              <>{`${t(`order_new_report`)}`}</>
            ) : (
              <>
                {groupNavigation?.includes(navigationItem)
                  ? navigation?.groupName
                  : clientNavigation?.includes(navigationItem)
                  ? navigation?.clientName
                  : navigationItem === `application-details`
                  ? `${t(`details`)}`
                  : `Alliance Crédit`}
              </>
            )}
          </span>
        )}

        {navigationItem === `event-reports` && (
          <span className='header_nav_icon'>
            <AiOutlineRight />
            <span className='last_nav_item'> {`${t(`report_progress`)}`}</span>
          </span>
        )}
      </span>

      {/* <div className='header_navigation'>
        <svg
          width='14'
          height='12'
          viewBox='0 0 14 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.296028 5.29402C-0.0944443 5.6845 -0.0944443 6.31863 0.296028 6.7091L5.29408 11.7071C5.68455 12.0976 6.31868 12.0976 6.70915 11.7071C7.09962 11.3167 7.09962 10.6825 6.70915 10.2921L3.41356 6.99961H12.9973C13.5502 6.99961 13.9969 6.55291 13.9969 6C13.9969 5.44709 13.5502 5.00039 12.9973 5.00039H3.41668L6.70602 1.70793C7.0965 1.31745 7.0965 0.683327 6.70602 0.292854C6.31555 -0.0976181 5.68142 -0.0976181 5.29095 0.292854L0.292904 5.2909L0.296028 5.29402Z'
            fill='none'
          />
        </svg>

        <span className='text'>My Account</span>
      </div> */}
      <AiOutlineMenu className='hamburger' onClick={onSidebarHandler} />
    </div>
  );
};

export default NavigationBar;
