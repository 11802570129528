import React from 'react';
import { useTranslation } from 'react-i18next';
import { createAddress, money } from '../../Utils/utils';
import InformationCard from '../../ReusableComponents/InformationCard';

const MonitoringClientDetails = ({ clientDetails }) => {
  const { t } = useTranslation();

  return (
    <div className='information_wrapper'>
      <div className='information_row information_row_4 information_row_white'>
        <InformationCard
          title={`${t(`company_name`)}`}
          value={clientDetails?.clientName}
        />
        <InformationCard
          title={`${t(`client_id`)}`}
          value={clientDetails?.accountNumber}
        />
        <InformationCard
          title={`${t(`neq`)}`}
          value={clientDetails?.corporateNumber}
        />
        <InformationCard
          title={`${t(`alliance_id`)}`}
          value={clientDetails?.allianceMasterId}
        />
      </div>
      <div className='information_row information_row_6'>
        <InformationCard
          title={`${t(`current`)}`}
          value={`${clientDetails?.currency} ${money(clientDetails?.current)}`}
        />
        <InformationCard
          title={`${
            clientDetails?.periodTerm === 7 ? t(`7days`) : t(`30days`)
          }`}
          value={`${clientDetails?.currency} ${money(clientDetails?.period1)}`}
        />
        <InformationCard
          title={`${
            clientDetails?.periodTerm === 7 ? t(`14days`) : t(`60days`)
          }`}
          value={`${clientDetails?.currency} ${money(clientDetails?.period2)}`}
        />
        <InformationCard
          title={`${
            clientDetails?.periodTerm === 7 ? t(`21days`) : t(`90days`)
          }`}
          value={`${clientDetails?.currency} ${money(clientDetails?.period3)}`}
        />
        <InformationCard
          title={`${
            clientDetails?.periodTerm === 7 ? t(`28days`) : t(`120days`)
          }`}
          value={`${clientDetails?.currency} ${money(clientDetails?.period4)}`}
        />
        <InformationCard
          title={`${t(`total_due`)}`}
          value={`${clientDetails?.currency} ${money(clientDetails?.totalDue)}`}
        />
      </div>
      <div className='information_row information_row_6 information_row_white'>
        <InformationCard
          title={`${t(`current_credit_limit`)}`}
          value={`${clientDetails?.currency} ${money(
            clientDetails?.currentCreditLimit
          )}`}
        />
        <InformationCard
          title={`${t(`highest_credit_limit`)}`}
          value={`${clientDetails?.currency} ${money(
            clientDetails?.highestCredit
          )}`}
        />
        <InformationCard
          title={`${t(`date_of_last_sale`)}`}
          value={
            clientDetails?.lastSaleDate &&
            new Date(clientDetails?.lastSaleDate)?.toLocaleDateString('en-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          }
        />
        <InformationCard
          title={`${t(`account_opening_date`)}`}
          value={
            clientDetails?.accountOpeningDate &&
            new Date(clientDetails?.accountOpeningDate)?.toLocaleDateString(
              'en-CA',
              { year: 'numeric', month: '2-digit', day: '2-digit' }
            )
          }
        />
        <InformationCard
          title={`${t(`average_payment`)}`}
          value={
            clientDetails?.averagePayment &&
            `${clientDetails?.averagePayment} ${t(`days`)}`
          }
        />
        <InformationCard title={`${t(`nsf`)}`} value={clientDetails?.nsf} />
      </div>
      <div className='information_row information_row_2'>
        <InformationCard
          title={`${t(`other_names`)}`}
          value={clientDetails?.otherNames}
        />
        <InformationCard title={`${t(`terms`)}`} value={clientDetails?.terms} />
      </div>
      <div className='information_row information_row_2 information_row_white'>
        <InformationCard
          title={`${t(`address`)}`}
          value={createAddress(
            clientDetails?.address?.civicNumber,
            clientDetails?.address?.city,
            clientDetails?.address?.street,
            clientDetails?.address?.suite,
            clientDetails?.address?.province,
            clientDetails?.address?.postalCode
          )}
        />
        <InformationCard
          title={`${t(`phone`)}`}
          value={clientDetails?.phoneNumber}
        />
      </div>
    </div>
  );
};

export default MonitoringClientDetails;
