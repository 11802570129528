import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertList from './AlertList';

const Alerts = ({
  alerts,
  alertType,
  onAlertHandler,
  alertOptions,
  isAlertsFetching,
  isAlertsFetched,
  isFromDashboard,
  isEN,
  onAlertChange,
  onGetClientInformation,
  isRiskFilter,
  companyId,
  isFilterNotFound,
  setIsFilterNotFound,
}) => {
  const { t } = useTranslation();
  return (
    <div className='alerts_wrapper'>
      <div className='alerts_info'>
        <div className='alert_section_left'>
          <div className='label'>{`${t(`alerts_notifications`)}:`}</div>
          <span className='alert_count'>
            {isAlertsFetching ? 0 : alerts?.totalAlert}
          </span>
          {alerts?.newAlert > 0 && (
            <span className='alert_new'>
              {alerts?.newAlert}
              {` ${t(`new`)}`}
            </span>
          )}
          {isFromDashboard && (
            <span className='show_all' onClick={onAlertHandler}>
              <span className='text'>{`${t(`show_all`)}`}</span>
              <svg
                width='5'
                height='8'
                viewBox='0 0 5 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4.40344 3.59656C4.62658 3.8197 4.62658 4.18208 4.40344 4.40522L0.976012 7.83264C0.752873 8.05579 0.390494 8.05579 0.167355 7.83264C-0.0557849 7.60951 -0.0557849 7.24713 0.167355 7.02399L3.19134 4L0.16914 0.976012C-0.0539998 0.752873 -0.0539998 0.390494 0.16914 0.167355C0.392279 -0.0557849 0.754658 -0.0557849 0.977798 0.167355L4.40522 3.59478L4.40344 3.59656Z'
                  fill='#334155'
                />
              </svg>
            </span>
          )}
        </div>
        <div className='alert_section_right'>
          <div className='label'>{`${t(`show_alerts`)}: `}</div>
          <div className='alert_options'>
            {alertOptions?.map((alertOption) => (
              <div
                className={`alert_option ${
                  alertOption?.value === alertType
                    ? `alert_active`
                    : `alert_inactive`
                }`}
                key={alertOption?.value}
                onClick={() => onAlertChange(alertOption?.value)}
              >
                {alertOption?.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`alerts_list ${isFromDashboard ? `alerts_dashboard` : ``}`}
      >
        <AlertList
          alerts={alerts?.alerts}
          isTrimmed={isFromDashboard}
          isAlertsFetching={isAlertsFetching}
          isAlertsFetched={isAlertsFetched}
          isEN={isEN}
          onGetClientInformation={onGetClientInformation}
          isRiskFilter={isRiskFilter}
          companyId={companyId}
          isFilterNotFound={isFilterNotFound}
          setIsFilterNotFound={setIsFilterNotFound}
        />
      </div>
    </div>
  );
};

export default Alerts;
