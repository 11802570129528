import React from 'react';
import { useTranslation } from 'react-i18next';
import { camelToSnake } from '../../Utils/utils';

const ArrangeColumns = ({ visibleColumns, setVisibleColumns }) => {
  const { t } = useTranslation();

  const onDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const onDrop = (e, index) => {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData('text/plain');

    if (draggedIndex !== index) {
      const updatedColumns = [...visibleColumns];
      const [removed] = updatedColumns.splice(draggedIndex, 1);
      updatedColumns.splice(index, 0, removed);
      setVisibleColumns(updatedColumns);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onVisible = (isChecked, columnIndex) =>
    setVisibleColumns(
      visibleColumns.map((column, index) =>
        index === columnIndex ? { ...column, visible: isChecked } : column
      )
    );

  return (
    <div className='arrange_column_wrapper'>
      <div className='label'>{`${t(`display_columns`)}`}</div>
      <div className='arrange_columns'>
        {visibleColumns?.map((column, index) => (
          <div
            className={`arrange_column`}
            key={index}
            draggable={true}
            onDragStart={(e) => onDragStart(e, index)}
            onDrop={(e) => onDrop(e, index)}
            onDragOver={onDragOver}
          >
            <svg
              width='12'
              height='8'
              viewBox='0 0 12 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect width='12' height='2' rx='1' fill='#CBD5E1' />
              <rect y='6' width='12' height='2' rx='1' fill='#CBD5E1' />
            </svg>

            <input
              type='checkbox'
              className='column_check'
              checked={column?.visible}
              disabled={!column?.editable}
              onChange={(e) => onVisible(e.target.checked, index)}
            />
            <span>{t(camelToSnake(column?.field))}</span>
            {!column?.editable && (
              <svg
                width='11'
                height='12'
                viewBox='0 0 11 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='arrange_lock'
              >
                <path
                  d='M3.875 3.375V4.5H7.625V3.375C7.625 2.33906 6.78594 1.5 5.75 1.5C4.71406 1.5 3.875 2.33906 3.875 3.375ZM2.375 4.5V3.375C2.375 1.51172 3.88672 0 5.75 0C7.61328 0 9.125 1.51172 9.125 3.375V4.5H9.5C10.3273 4.5 11 5.17266 11 6V10.5C11 11.3273 10.3273 12 9.5 12H2C1.17266 12 0.5 11.3273 0.5 10.5V6C0.5 5.17266 1.17266 4.5 2 4.5H2.375Z'
                  fill='#64748B'
                />
              </svg>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArrangeColumns;
