import Button from '../Button';

export const escDataRenderer = (
  entity,
  header,
  translations,
  selectedResultId,
  action
) => {
  if (header === `subject_name`) {
    return <span className='esc_subject_name'>{entity?.entity_name}</span>;
  }
  if (header === `score`) {
    return <span className='esc_subject_name'>{entity?.confidence_score}</span>;
  }
  if (header === `type`) {
    return (
      <span className='esc_subject_name'>{entity?.normalized_entity_type}</span>
    );
  }
  if (header === `date_of_registration`) {
    return <span className='esc_subject_name'>{entity?.formation_date}</span>;
  }
  if (header === `jurisdiction`) {
    return <span className='esc_subject_name'>{entity?.jurisdiction}</span>;
  }
  if (header === `actions`) {
    return (
      <div className='esc_data_btns'>
        <Button
          onClickHandler={() => action(Number(entity?.result_id))}
          type={
            selectedResultId === Number(entity?.result_id) ? `six` : `secondary`
          }
          text={
            selectedResultId === Number(entity?.result_id)
              ? translations?.selected
              : translations?.select
          }
          isShowIcon={selectedResultId === Number(entity?.result_id)}
          iconPosition={`before`}
          icon={
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8 16.5C10.1217 16.5 12.1566 15.6571 13.6569 14.1569C15.1571 12.6566 16 10.6217 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5C5.87827 0.5 3.84344 1.34285 2.34315 2.84315C0.842855 4.34344 0 6.37827 0 8.5C0 10.6217 0.842855 12.6566 2.34315 14.1569C3.84344 15.6571 5.87827 16.5 8 16.5ZM11.5312 7.03125L7.53125 11.0312C7.2375 11.325 6.7625 11.325 6.47188 11.0312L4.47188 9.03125C4.17813 8.7375 4.17813 8.2625 4.47188 7.97188C4.76562 7.68125 5.24062 7.67813 5.53125 7.97188L7 9.44063L10.4688 5.96875C10.7625 5.675 11.2375 5.675 11.5281 5.96875C11.8187 6.2625 11.8219 6.7375 11.5281 7.02812L11.5312 7.03125Z'
                fill='#15803D'
              />
            </svg>
          }
        />
      </div>
    );
  }
};
