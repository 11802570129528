import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const LineGraph = ({ data }) => {
  //console.log('🚀 ~ LineGraph ~ data:', data);
  // const data = [
  //   {
  //     label: 'Jan',
  //     value: 2400,
  //   },
  //   {
  //     label: 'Feb',
  //     value: 1398,
  //   },
  //   {
  //     label: 'March',
  //     value: 8800,
  //   },
  //   {
  //     label: 'April',
  //     value: 3908,
  //   },
  //   {
  //     label: 'May',
  //     value: 4800,
  //   },
  //   {
  //     label: 'June',
  //     value: 3800,
  //   },
  // ];

  // Custom Tooltip component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <p>
            Month:{' '}
            <span style={{ margin: 0, fontWeight: 'bold', color: '#356EB1' }}>
              {label}
            </span>{' '}
          </p>
          <p>
            Amount:{' '}
            <span style={{ margin: 0, fontWeight: 'bold', color: '#356EB1' }}>
              ${payload[0].value}
            </span>
          </p>
        </div>
      );
    }

    return null;
  };

  // Custom Label component to position label above the dot
  const CustomLabel = ({ x, y, value }) => (
    <text x={x} y={y - 5} dy={-5} fill='#666' textAnchor='middle'>
      ${value}
    </text>
  );

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart
        data={data}
        margin={{
          top: 25,
          right: 30,
          left: 30,
          bottom: 0,
        }}
      >
        <CartesianGrid
          strokeDasharray='3 3'
          vertical={false}
          strokeOpacity={0.3}
        />
        <XAxis dataKey='label' axisLine={false} tickLine={false} />
        <Tooltip
          content={<CustomTooltip />}
          cursor={false}
          animationDuration={500}
        />
        <Line
          label={<CustomLabel />}
          strokeWidth={2}
          type='monotone'
          dataKey='value'
          stroke='#356EB1'
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineGraph;
