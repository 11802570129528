import { get } from 'idb-keyval';
import React, { useEffect, useState } from 'react';
import ActionPopup from '../../ReusableComponents/ActionPopup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  discoverRiskInformation,
  discoverTypeInformation,
} from '../../slices/creditRiskSlice';

const DiscoverInformation = ({ isVisible, onCancel, companyId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isTypeDiscovering, isRiskDiscovering } = useSelector(
    (state) => state.creditRisk
  );

  const [discoverPayload, setDiscoverPayload] = useState({});

  useEffect(() => {
    const getData = async () => {
      const data = await get(`discoverPayload`);
      if (data?.isIndividual && !data?.isScore) {
        data.content = `${data?.content}_for`;
      }
      setDiscoverPayload(data);
    };
    getData();
  }, [isVisible]);

  const onSubmit = () => {
    const payload = {
      informationType: discoverPayload?.informationType,
      clientIds: discoverPayload?.clientIds,
      companyId,
      riskType: discoverPayload?.riskType,
    };
    dispatch(
      discoverPayload.isIndividual
        ? discoverTypeInformation(payload)
        : discoverRiskInformation(payload)
    );
  };

  return (
    <ActionPopup
      isVisible={isVisible}
      from='credit_risk'
      headerText={`${t(discoverPayload?.headerText)}`}
      buttonText1={`${t(`cancel`)}`}
      buttonText2={`${t(`discover`)}`}
      onCancel={onCancel}
      onSubmit={onSubmit}
      isLoading={isTypeDiscovering || isRiskDiscovering}
    >
      <div className='credit_risk_discover_content'>
        {`${
          discoverPayload?.isIndividual
            ? discoverPayload?.isScore
              ? t(`discover_score_one`) +
                ` ${discoverPayload?.name}? ` +
                t(`discover_score_two`)
              : t(discoverPayload?.content) + ` ${discoverPayload?.name}?`
            : t(discoverPayload?.content)
        }`}
      </div>
    </ActionPopup>
  );
};
export default DiscoverInformation;
