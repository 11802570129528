import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../Utils/AxiosInstance';

const initialState = {
  accountDetails: {},
  isLoading: false,
  apiMessage: '',
  teamsList: [],
};

export const getUserAcountInformation = createAsyncThunk(
  `accountInfo`,
  async (payload) => {
    try {
      const res = await api.post(`user/details-for-edit-user`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getUserTeamsList = createAsyncThunk(
  `userTeams`,
  async (payload) => {
    try {
      const res = await api.post(`user/list-associate-user`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

const accountSlice = createSlice({
  name: `account`,
  initialState,
  reducers: {
    resetAccountStore: (state) => {
      state.isSubAdminAdded = false;
      state.apiMessage = '';
    },
  },
  extraReducers: {
    [getUserAcountInformation.pending]: (state) => {
      state.isLoading = true;
      state.apiMessage = '';
      state.accountDetails = {};
    },
    [getUserAcountInformation.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.apiMessage = '';
      state.accountDetails = action?.payload;
    },
    [getUserAcountInformation.rejected]: (state, action) => {
      state.isLoading = false;
      state.apiMessage = '';
      state.accountDetails = {};
    },
    [getUserTeamsList.pending]: (state) => {
      state.isLoading = true;
      state.apiMessage = '';
    },
    [getUserTeamsList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.apiMessage = '';
      state.teamsList = action.payload;
    },
  },
});

export const { resetAccountStore } = accountSlice.actions;

export default accountSlice.reducer;
