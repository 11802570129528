import Button from "../../ReusableComponents/Button";
import { isEmpty } from "../../Utils/utils";

export const eventSubjectListRenderer = (
  entity,
  header,
  action,
  action2,
  action3,
  translations
) => {
  if (header === `subject_name`) {
    return (
      <span className="event_subject_name">
        <label>
          <input
            type="checkbox"
            className="checkbox_wrapper"
            disabled={entity?.is_duplicate}
            onChange={() => action2(entity?._id)}
            //checked={groups?.length === client?.groups?.length}
          />
        </label>
        <span className="subject_name">{entity?.subject_name}</span>
      </span>
    );
  }
  if (header === `submitted_by`) {
    return (
      <span className="company_user_wrapper">
        <span className="user_name">{entity?.company_name}</span>
        <span className="company_name">{entity?.user_name}</span>
      </span>
    );
  }
  if (header === `dba`) {
    return <span>{entity?.dba}</span>;
  }
  if (header === `ref_no`) {
    return <span>{entity?.subject_ref_no}</span>;
  }
  if (header === `phone_number`) {
    return <span>{entity?.phone_number}</span>;
  }
  if (header === `address`) {
    return (
      <span>
        {entity?.civic_number +
          entity?.street +
          entity?.city +
          entity?.privince +
          entity?.postal_code}
      </span>
    );
  }
  if (header === `neq_corp_no`) {
    return <span>{entity?.neq}</span>;
  }
  if (header === `actions`) {
    return (
      <span className="admin_client_btns_wrapper">
        <>
          <Button
            type="secondary"
            text={translations?.edit}
            onClickHandler={() => action(entity)}
            isDisabled={entity?.is_duplicate}
          />
          <Button
            type="primary"
            text={translations?.download_credit_app}
            onClickHandler={() => action3(entity)}
            isDisabled={isEmpty(entity?.credit_application?.[0]?.path)}
          />
        </>
      </span>
    );
  }
};
