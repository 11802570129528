import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated !== null) {
      if (isAuthenticated) {
        navigate(`/creditrisk-management`);
      } else {
        window.location.href = `https://portail.alliancecredit.ca/`;
      }
    }
  }, [isAuthenticated, navigate]);

  return <></>;
};

export default NotFound;
