import React from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionImage from './SubscriptionImage';

const Subscription = () => {
  const { t } = useTranslation();
  return (
    <div className='credit_risk_basic_wrapper subscription_container'>
      <div className='title_actions'>
        <div className='title'>{`${t(`client_credit_risk_dashboard`)}`}</div>
      </div>
      <div className='subscription_wrapper'>
        <SubscriptionImage />
        <div className='title'>{`${t(`no_subscription`)}`}</div>
        <div className='text'>{`${t(`no_subscription_text_1`)}`}</div>
        <div className='text'>{`${t(`no_subscription_text_2`)}`}</div>
        <div className='subscription_content'>
          <div className='text'>{`${t(`no_subscription_text_3`)}`}</div>
          <div className='header'>{`${t(`subscribe_unlock`)}`}</div>
          <div className='content_section'>
            <div className='image'>
              <svg
                width='18'
                height='16'
                viewBox='0 0 18 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_10047_7137)'>
                  <path
                    d='M8.99995 1C6.47495 1 4.45308 2.15 2.9812 3.51875C1.5187 4.875 0.540576 6.5 0.0780762 7.61562C-0.0250488 7.8625 -0.0250488 8.1375 0.0780762 8.38437C0.540576 9.5 1.5187 11.125 2.9812 12.4812C4.45308 13.85 6.47495 15 8.99995 15C11.525 15 13.5468 13.85 15.0187 12.4812C16.4812 11.1219 17.4593 9.5 17.9249 8.38437C18.0281 8.1375 18.0281 7.8625 17.9249 7.61562C17.4593 6.5 16.4812 4.875 15.0187 3.51875C13.5468 2.15 11.525 1 8.99995 1ZM4.49995 8C4.49995 6.80653 4.97406 5.66193 5.81797 4.81802C6.66188 3.97411 7.80648 3.5 8.99995 3.5C10.1934 3.5 11.338 3.97411 12.1819 4.81802C13.0258 5.66193 13.5 6.80653 13.5 8C13.5 9.19347 13.0258 10.3381 12.1819 11.182C11.338 12.0259 10.1934 12.5 8.99995 12.5C7.80648 12.5 6.66188 12.0259 5.81797 11.182C4.97406 10.3381 4.49995 9.19347 4.49995 8ZM8.99995 6C8.99995 7.10313 8.10308 8 6.99995 8C6.77808 8 6.56558 7.9625 6.36558 7.89687C6.1937 7.84062 5.9937 7.94688 5.99995 8.12813C6.00933 8.34375 6.04058 8.55937 6.09995 8.775C6.52808 10.375 8.17495 11.325 9.77495 10.8969C11.375 10.4688 12.325 8.82188 11.8968 7.22188C11.55 5.925 10.4031 5.05312 9.12808 5C8.94683 4.99375 8.84058 5.19062 8.89683 5.36562C8.96245 5.56562 8.99995 5.77812 8.99995 6Z'
                    fill='#CBD5E1'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_10047_7137'>
                    <rect width='18' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className='content'>
              <div className='content_title'>{`${t(
                `real_time_monitoring`
              )}`}</div>
              <div className='content_subtitle'>
                {`${t(`real_time_credit_risk_text`)}`}
              </div>
            </div>
          </div>
          <div className='content_section'>
            <div className='image'>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_10047_7209)'>
                  <path
                    d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM8 4C8.41562 4 8.75 4.33437 8.75 4.75V8.25C8.75 8.66562 8.41562 9 8 9C7.58437 9 7.25 8.66562 7.25 8.25V4.75C7.25 4.33437 7.58437 4 8 4ZM7 11C7 10.7348 7.10536 10.4804 7.29289 10.2929C7.48043 10.1054 7.73478 10 8 10C8.26522 10 8.51957 10.1054 8.70711 10.2929C8.89464 10.4804 9 10.7348 9 11C9 11.2652 8.89464 11.5196 8.70711 11.7071C8.51957 11.8946 8.26522 12 8 12C7.73478 12 7.48043 11.8946 7.29289 11.7071C7.10536 11.5196 7 11.2652 7 11Z'
                    fill='#CBD5E1'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_10047_7209'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className='content'>
              <div className='content_title'>{`${t(`alerts`)}`}</div>
              <div className='content_subtitle'>{`${t(`alerts_text`)}`}</div>
            </div>
          </div>
          <div className='content_section'>
            <div className='image'>
              <svg
                width='12'
                height='16'
                viewBox='0 0 12 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_10047_7212)'>
                  <path
                    d='M2 0C0.896875 0 0 0.896875 0 2V14C0 15.1031 0.896875 16 2 16H10C11.1031 16 12 15.1031 12 14V5H8C7.44688 5 7 4.55312 7 4V0H2ZM8 0V4H12L8 0ZM3.5 8H8.5C8.775 8 9 8.225 9 8.5C9 8.775 8.775 9 8.5 9H3.5C3.225 9 3 8.775 3 8.5C3 8.225 3.225 8 3.5 8ZM3.5 10H8.5C8.775 10 9 10.225 9 10.5C9 10.775 8.775 11 8.5 11H3.5C3.225 11 3 10.775 3 10.5C3 10.225 3.225 10 3.5 10ZM3.5 12H8.5C8.775 12 9 12.225 9 12.5C9 12.775 8.775 13 8.5 13H3.5C3.225 13 3 12.775 3 12.5C3 12.225 3.225 12 3.5 12Z'
                    fill='#CBD5E1'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_10047_7212'>
                    <rect width='12' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className='content'>
              <div className='content_title'>{`${t(
                `comprehensive_reports`
              )}`}</div>
              <div className='content_subtitle'>
                {`${t(`comprehensive_reports_text`)}`}
              </div>
            </div>
          </div>
          <div className='content_section'>
            <div className='image'>
              <svg
                width='17'
                height='16'
                viewBox='0 0 17 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.14762 0.562437C8.98201 0.218726 8.63205 0 8.24772 0C7.86338 0 7.51655 0.218726 7.34782 0.562437L5.33867 4.69635L0.851669 5.35878C0.476711 5.41502 0.164246 5.67749 0.0486335 6.03682C-0.0669786 6.39616 0.0267608 6.79299 0.295481 7.05858L3.55137 10.2801L2.7827 14.8327C2.72021 15.2077 2.87644 15.5889 3.18578 15.8107C3.49512 16.0326 3.90445 16.0607 4.24191 15.8826L8.25084 13.7422L12.2598 15.8826C12.5972 16.0607 13.0066 16.0357 13.3159 15.8107C13.6252 15.5858 13.7815 15.2077 13.719 14.8327L12.9472 10.2801L16.2031 7.05858C16.4718 6.79299 16.5687 6.39616 16.4499 6.03682C16.3312 5.67749 16.0218 5.41502 15.6469 5.35878L11.1568 4.69635L9.14762 0.562437Z'
                  fill='#CBD5E1'
                />
              </svg>
            </div>
            <div className='content'>
              <div className='content_title'>{`${t(`automated_health`)}`}</div>
              <div className='content_subtitle'>
                {`${t(`automated_health_text`)}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
