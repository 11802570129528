import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from './Select';
import { getProvinceList } from '../slices/adminCreditReportSlice';

const Province = ({
  text,
  isError,
  value,
  onSetValue,
  isClosed,
  isShowValue,
  isMandatory,
  isRecommended,
  isUsa,
  onBlur,
  errorMessage,
  isFromHealthMonitoring,
  setCountry,
  isCanada,
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  const { provinceList } = useSelector((state) => state.adminReport);

  useEffect(() => {
    dispatch(getProvinceList({ region: isUsa ? `usa` : `all` }));
  }, [dispatch, isUsa]);

  useEffect(() => {
    if (provinceList?.length > 0) {
      const options = provinceList?.map((province) => {
        return {
          name: province?.name?.en,
          value: province?.code,
          isSelected: false,
          country: province?.country,
        };
      });

      setOptions(options);
    }
  }, [provinceList]);

  return (
    <Select
      options={options}
      text={text}
      isError={isError}
      value={value}
      onOptionSelection={onSetValue}
      isProvince={true}
      isClosed={isClosed}
      isShowValue={isShowValue}
      isMandatory={isMandatory}
      isRecommended={isRecommended}
      isUsa={isUsa}
      onBlur={onBlur}
      isCanada={isCanada}
      errorMessage={errorMessage}
      onOptionCallback={(value) =>
        isFromHealthMonitoring ? setCountry(value) : {}
      }
      isFromHealthMonitoring={isFromHealthMonitoring}
    />
  );
};

export default Province;
