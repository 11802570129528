import React, { useEffect, useState } from 'react';
import Input from '../../ReusableComponents/Input';
import { useTranslation } from 'react-i18next';
import { isEmailValid, isEmpty } from '../../Utils/utils';
import { useDispatch } from 'react-redux';
import { resendEmail } from '../../slices/creditApplicationSlice';

const ResendEmail = ({
  isSubmitResendEmail,
  setIsSubmitResendEmail,
  creditAppId,
  isResendEmail,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [language, setLanguage] = useState(`en`);

  useEffect(() => {
    if (!isResendEmail) {
      setEmail('');
      setError('');
      setLanguage(`en`);
    }
  }, [isResendEmail]);

  const onCheckboxChange = (value) => {
    setLanguage(value);
  };

  const onEmailChange = (e) => {
    setError('');
    setEmail(e.target.value);
    setIsSubmitResendEmail(false);
  };

  const onValidateEmail = () => {
    if (!isEmpty(email) && !isEmailValid(email)) {
      setError(`${t(`email`)} ${t(`is_invalid`)}`);
      setIsSubmitResendEmail(false);
    }
  };

  useEffect(() => {
    if (isSubmitResendEmail) {
      if (isEmpty(email)) {
        setError(`${t(`email`)} ${t(`is_required`)}`);
        setIsSubmitResendEmail(false);
      }
      if (!isEmpty(email) && isEmailValid(email)) {
        const payload = {
          credit_application_id: creditAppId,
          language: language,
          email_id: email,
        };
        creditAppId && dispatch(resendEmail(payload));
      }
    }
  }, [
    isSubmitResendEmail,
    email,
    t,
    creditAppId,
    language,
    setIsSubmitResendEmail,
    dispatch,
  ]);

  return (
    <div className='credit_application_resend_email_wrapper'>
      <div>
        <Input
          text={`${t(`subject_email`)}`}
          value={email}
          onChange={onEmailChange}
          isError={error}
          onBlur={onValidateEmail}
        />
        <span className='form_error'>{error}</span>
      </div>
      <div className='credit_type_selection'>
        <div className='lable_checkbox_wrapper'>
          <label>
            <input
              type='checkbox'
              className='checkbox_wrapper'
              value={`something`}
              onChange={() => onCheckboxChange(`en`)}
              checked={language === `en`}
            />
            {`${t(`en`)}`}
          </label>
        </div>
        <div className='lable_checkbox_wrapper'>
          <label>
            <input
              type='checkbox'
              className='checkbox_wrapper'
              value={`something`}
              onChange={() => onCheckboxChange(`fr`)}
              checked={language === `fr`}
            />
            {`${t(`fr`)}`}
          </label>
        </div>
      </div>
    </div>
  );
};

export default ResendEmail;
