import React from 'react';

const CircularProgressBar = ({ progress = 10 }) => {
  const circumference = 2 * Math.PI * 10;

  // Check if progress is a valid number; if not, set it to a default non-progress state
  const isValidProgress = typeof progress === 'number' && !isNaN(progress);

  // Constrain progress to a range of 0 to 100
  const normalizedProgress = Math.min(100, Math.max(0, progress));
  const offset = circumference - (normalizedProgress / 100) * circumference;

  const getTrackColor = () => {
    if (!isValidProgress) return '#F1F5F9';
    if (progress >= 75) return '#FEF2F2';
    else if (progress < 35) return '#F0FDF4';
    else if (progress >= 35 && progress <= 75) return '#FEF3C7';
  };

  const getProgressColor = () => {
    if (!isValidProgress) return '#F1F5F9';
    if (progress >= 75) return '#B91C1C';
    else if (progress < 35) return '#15803D';
    else if (progress >= 35 && progress <= 75) return '#D97706';
  };

  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      className='circular_progress_bar'
    >
      <circle
        stroke={getTrackColor()}
        className='circular_progress_circle'
        fill='transparent'
        strokeWidth='4'
        r='10'
        cx='12.334'
        cy='12.5'
      />
      <circle
        stroke={getProgressColor()}
        fill='transparent'
        strokeWidth='4'
        strokeLinecap='round'
        r='10'
        cx='12.334'
        cy='12.5'
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform='rotate(-90 12.334 12.5)' // Rotate the progress circle by -90 degrees
      />
    </svg>
  );
};

export default CircularProgressBar;
