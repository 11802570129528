import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import authReducer from './slices/authSlice';
import commonReducer from './slices/commonSlice';
import accountReducer from './slices/accountSlice';
import userCreditReportReducer from './slices/userCreditReportSlice';
import userDatabaseReportReducer from './slices/databaseReportSlice';
import legalWatchListReducer from './slices/legalWatchListSlice';

// admin
import adminCreditReportReducer from './slices/adminCreditReportSlice';
import adminClientsReducer from './slices/adminClientSlice';

// common
import creditApplicationReducer from './slices/creditApplicationSlice';

// groups
import groupReducer from './slices/groupSlice';

// order report
import orderReportSlice from './slices/orderReportSlice';

// health monitoring
import creditRiskSlice from './slices/creditRiskSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    userCreditReport: userCreditReportReducer,
    userDatabaseReport: userDatabaseReportReducer,
    legalWatchList: legalWatchListReducer,
    // admin
    adminReport: adminCreditReportReducer,
    adminClient: adminClientsReducer,
    // common
    common: commonReducer,
    // credit application
    creditApplication: creditApplicationReducer,
    group: groupReducer,
    // order report
    orderReport: orderReportSlice,
    creditRisk: creditRiskSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
