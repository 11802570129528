import React from 'react';
import { useTranslation } from 'react-i18next';

const AdditionalOptions = ({ isCreditLimit, setIsCreditLimit }) => {
  const { t } = useTranslation();

  const onAdditionalOption = (e) => {
    setIsCreditLimit(e.target.checked);
  };

  return (
    <div className='bank_options_wrapper'>
      <div className='heading'>{`${t(`ac_score_additional_options`)}`}</div>
      <div className='bank_options_list'>
        <label>
          <input
            className='checkbox_wrapper'
            type='checkbox'
            checked={isCreditLimit}
            onChange={onAdditionalOption}
          />
          <span>{`${t(`recommended_limit`)}`}</span>
        </label>
      </div>
    </div>
  );
};

export default AdditionalOptions;
