import React from 'react';
import { useTranslation } from 'react-i18next';

const OverLimit = ({ clientLimit, overLimit }) => {
  const { t } = useTranslation();
  return (
    <div className='overlimit_wrapper'>
      <div className='image'>
        <svg
          width='127'
          height='127'
          viewBox='0 0 127 127'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.5 63.4998C0.5 80.2085 7.13748 96.2328 18.9523 108.048C30.7671 119.862 46.7914 126.5 63.5 126.5C80.2086 126.5 96.2329 119.862 108.048 108.048C119.863 96.2328 126.5 80.2085 126.5 63.4998C126.5 46.7912 119.863 30.7669 108.048 18.9521C96.2329 7.13731 80.2086 0.499817 63.5 0.499817C46.7914 0.499817 30.7671 7.13731 18.9523 18.9521C7.13748 30.7669 0.5 46.7912 0.5 63.4998ZM100.03 84.4998L84.47 100.05L63.47 79.0498L42.47 100.05L26.91 84.4998L47.91 63.4998L26.91 42.4998L42.47 26.9498L63.47 47.9498L84.47 26.9498L100.03 42.4998L79.03 63.4998L100.03 84.4998Z'
            fill='#E2E8F0'
          />
        </svg>
      </div>
      <div className='title'>{`${t(`max_clients`)}`}</div>
      <div className='description'>
        {`${t(`max_clients_text1`)} `}
        <b>{clientLimit}</b>
        {` ${t(`max_clients_text2`)}`}
      </div>
      <div className='description'>
        {`${t(`max_clients_description1`)} `}
        <b>{overLimit}</b>
        {` ${t(`max_clients_description2`)}`}
      </div>
    </div>
  );
};

export default OverLimit;
