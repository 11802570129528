import React, { useEffect, useRef, useState } from 'react';
import Button from '../../ReusableComponents/Button';
import { useTranslation } from 'react-i18next';
import Skeleton from '../../ReusableComponents/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreditRiskOrderReport from './CreditRiskOrderReport';
import { get, set } from 'idb-keyval';
import DiscoverInformation from './DiscoverInformation';
import { getClientAddress } from '../../slices/creditRiskSlice';

const Alert = ({
  alert,
  isLoading,
  onDeleteAlertHandler,
  isEN,
  onGetClientInformation,
  isRiskFilter,
  isFilterNotFound,
  setIsFilterNotFound,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportRef = useRef();

  const {
    isClientInformationFetching,
    isRiskDiscovered,
    isClientAddressFetching,
    isClientAddressFetched,
    clientAddress,
    isClientInformationFetched,
  } = useSelector((state) => state.creditRisk);

  const [isOrderReport, setIsOrderReport] = useState(false);
  const [clientId, setClientId] = useState('');
  const [riskType, setRiskType] = useState('');
  const [isDiscover, setIsDiscover] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [isOrderReportFromAlert, setIsOrderReportFromAlert] = useState(false);
  const [alertId, setAlertId] = useState('');

  // getting pre-discovered risk types
  useEffect(() => {
    if (isRiskDiscovered) {
      const getRiskType = async () => {
        const { riskType } = await get(`discoverPayload`);
        setRiskType(riskType);
      };
      getRiskType();
      setIsDiscover(false);
    }
  }, [isRiskDiscovered]);

  useEffect(() => {
    if (isClientAddressFetched) {
      setIsOrderReport(true);
      setAlertId('');
    }
  }, [isClientAddressFetched]);

  const renderAlertIcon = (alertType) => {
    switch (alertType) {
      case 'Urgent':
        return (
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6 3C6.31172 3 6.5625 3.25078 6.5625 3.5625V6.1875C6.5625 6.49922 6.31172 6.75 6 6.75C5.68828 6.75 5.4375 6.49922 5.4375 6.1875V3.5625C5.4375 3.25078 5.68828 3 6 3ZM5.25 8.25C5.25 8.05109 5.32902 7.86032 5.46967 7.71967C5.61032 7.57902 5.80109 7.5 6 7.5C6.19891 7.5 6.38968 7.57902 6.53033 7.71967C6.67098 7.86032 6.75 8.05109 6.75 8.25C6.75 8.44891 6.67098 8.63968 6.53033 8.78033C6.38968 8.92098 6.19891 9 6 9C5.80109 9 5.61032 8.92098 5.46967 8.78033C5.32902 8.63968 5.25 8.44891 5.25 8.25Z'
              fill='#B91C1C'
            />
          </svg>
        );
      case 'Moderate':
        return (
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_8199_2677)'>
              <path
                d='M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM5.0625 7.875H5.625V6.375H5.0625C4.75078 6.375 4.5 6.12422 4.5 5.8125C4.5 5.50078 4.75078 5.25 5.0625 5.25H6.1875C6.49922 5.25 6.75 5.50078 6.75 5.8125V7.875H6.9375C7.24922 7.875 7.5 8.12578 7.5 8.4375C7.5 8.74922 7.24922 9 6.9375 9H5.0625C4.75078 9 4.5 8.74922 4.5 8.4375C4.5 8.12578 4.75078 7.875 5.0625 7.875ZM6 3C6.19891 3 6.38968 3.07902 6.53033 3.21967C6.67098 3.36032 6.75 3.55109 6.75 3.75C6.75 3.94891 6.67098 4.13968 6.53033 4.28033C6.38968 4.42098 6.19891 4.5 6 4.5C5.80109 4.5 5.61032 4.42098 5.46967 4.28033C5.32902 4.13968 5.25 3.94891 5.25 3.75C5.25 3.55109 5.32902 3.36032 5.46967 3.21967C5.61032 3.07902 5.80109 3 6 3Z'
                fill='#B45309'
              />
            </g>
            <defs>
              <clipPath id='clip0_8199_2677'>
                <rect width='12' height='12' fill='white' />
              </clipPath>
            </defs>
          </svg>
        );
      case 'Minimal':
        return (
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_8199_2688)'>
              <path
                d='M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM8.64844 4.89844L5.64844 7.89844C5.42812 8.11875 5.07188 8.11875 4.85391 7.89844L3.35391 6.39844C3.13359 6.17812 3.13359 5.82188 3.35391 5.60391C3.57422 5.38594 3.93047 5.38359 4.14844 5.60391L5.25 6.70547L7.85156 4.10156C8.07187 3.88125 8.42812 3.88125 8.64609 4.10156C8.86406 4.32188 8.86641 4.67812 8.64609 4.89609L8.64844 4.89844Z'
                fill='#15803D'
              />
            </g>
            <defs>
              <clipPath id='clip0_8199_2688'>
                <rect width='12' height='12' fill='white' />
              </clipPath>
            </defs>
          </svg>
        );

      default:
        break;
    }
  };

  const onDiscoverRisk = async (alert) => {
    const payload = {
      companyId: alert?.companyId,
      headerText: `discover_${alert?.risk?.toLowerCase()}_risk`,
      content: `health_risk_${alert?.risk?.toLowerCase()}`,
      riskType: alert?.risk,
      isIndividual: false,
    };
    setCompanyId(alert?.companyId);
    await set(`discoverPayload`, payload);
    setIsDiscover(true);
  };

  const onShowMore = (alert) => {
    navigate(`/creditrisk-management/client-details/${alert?.clientId}`, {
      state: {
        isLegal: true,
        companyId: alert?.companyId,
      },
    });
  };

  const onOrderReportHandler = (clientId) => {
    setClientId(clientId);
    clientId && dispatch(getClientAddress(clientId));
    setIsOrderReportFromAlert(true);
    setAlertId(clientId);
  };

  const onOrderReport = () => {
    reportRef?.current?.onReportValidation();
  };

  const onCancelDiscover = () => {
    setIsDiscover(false);
    set(`discoverPayload`, null);
  };

  const onFilterNotFound = () => {
    setIsFilterNotFound(true);
  };

  const onEditClientFromReport = () => {
    onGetClientInformation(alert?.clientId);
    isClientInformationFetched && setIsOrderReportFromAlert(false);
  };

  return (
    <>
      <div
        className={`alert_wrapper alert_${isLoading ? `loading` : alert?.type}`}
      >
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <svg
              width='8'
              height='8'
              viewBox='0 0 8 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='alert_close'
              onClick={() => onDeleteAlertHandler(alert?.id)}
            >
              <path
                d='M7.76324 1.36623C8.07559 1.05388 8.07559 0.546619 7.76324 0.234265C7.45088 -0.0780884 6.94362 -0.0780884 6.63127 0.234265L4 2.86803L1.36623 0.236764C1.05388 -0.0755896 0.546619 -0.0755896 0.234265 0.236764C-0.0780884 0.549118 -0.0780884 1.05638 0.234265 1.36873L2.86803 4L0.236764 6.63376C-0.0755895 6.94612 -0.0755895 7.45338 0.236764 7.76573C0.549118 8.07809 1.05638 8.07809 1.36873 7.76573L4 5.13197L6.63376 7.76324C6.94612 8.07559 7.45338 8.07559 7.76573 7.76324C8.07809 7.45088 8.07809 6.94362 7.76573 6.63127L5.13197 4L7.76324 1.36623Z'
                fill='black'
                fillOpacity='0.5'
              />
            </svg>
            <div className='alert_text'>
              {renderAlertIcon(alert?.type)}
              <span>{isEN ? alert?.message.en : alert?.message?.fr}</span>
            </div>
            <div className='alert_action'>
              {alert?.actions?.includes?.(`SHOW_MORE`) && (
                <Button
                  type='seven'
                  text={`${t(`show_more`)}`}
                  onClickHandler={() => onShowMore(alert)}
                />
              )}

              {alert?.actions?.includes?.(`REPORT_ORDER`) && (
                <Button
                  type='seven'
                  text={`${t(`order_report`)}`}
                  onClickHandler={() => onOrderReportHandler(alert?.clientId)}
                  isLoading={
                    isClientAddressFetching && alertId === alert?.clientId
                  }
                />
              )}
              {alert?.actions?.includes?.(`REMOVE_CLIENT`) && (
                <Button type='danger' text={`${t(`remove_client`)}`} />
              )}
              {alert?.actions?.includes?.(`EDIT_CLIENT`) && (
                <Button
                  type='seven'
                  text={`${t(`edit_client`)}`}
                  onClickHandler={() => onGetClientInformation(alert?.clientId)}
                  isLoading={isClientInformationFetching}
                />
              )}
              {alert?.actions?.includes?.(`FILTER`) && (
                <Button
                  type='seven'
                  text={`${t(`filter`)}`}
                  onClickHandler={onFilterNotFound}
                  isDisabled={isFilterNotFound}
                />
              )}
              {alert?.actions?.includes?.(`DISCOVER`) && (
                <Button
                  type='seven'
                  text={`${t(`discover`)}`}
                  onClickHandler={() => onDiscoverRisk(alert)}
                  isDisabled={isRiskFilter && riskType === alert?.risk}
                />
              )}
            </div>
            <div className='timestamp'>
              {new Date(alert?.createdAt)?.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
              })}
            </div>
            {alert?.isNew && <div className='alert_new'>{`${t(`new`)}`}</div>}
          </>
        )}
      </div>
      <CreditRiskOrderReport
        ref={reportRef}
        onOrderReport={onOrderReport}
        isOrderReport={isOrderReport && isOrderReportFromAlert}
        clientId={clientId}
        isClientAddressFetched={isClientAddressFetched}
        clientAddress={clientAddress}
        setIsOrderReport={setIsOrderReport}
        onCancel={setIsOrderReportFromAlert}
        isClientDetailsFetching={isClientInformationFetching}
        onEditClient={onEditClientFromReport}
      />
      <DiscoverInformation
        isVisible={isDiscover}
        onCancel={onCancelDiscover}
        companyId={companyId}
      />
    </>
  );
};

export default Alert;
