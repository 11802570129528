import axios from 'axios';
import { BASE_URL } from '../constants/config';
import { get } from 'idb-keyval';
import { store } from '../store';
import { sessionTimeOut } from '../slices/authSlice';

export const api = axios.create({
  baseURL: BASE_URL,
});

// export const interceptor = (store) => {
api.interceptors.request.use(
  async function (config) {
    const simulatedUser = await get(`simulatedUser`);
    const user = await get(`user`);
    const token = await get(`newPlatformToken`);
    const authToken = token || simulatedUser?.auth_token || user?.auth_token;
    config.headers.Authorization = `${authToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (next) => Promise.resolve(next),
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(sessionTimeOut());
    }

    return Promise.reject(error);
  }
);
// };
