import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './store';
import '../src/styles/index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { refreshState } from './slices/authSlice';
import { setLanguage } from './slices/commonSlice';
import './Utils/i18n';
import { get, set } from 'idb-keyval';
import { isEmpty } from './Utils/utils';

const getUserDetails = async () => {
  const user = await get(`user`);
  const simulatedUser = await get(`simulatedUser`);
  const loggedInUser = simulatedUser ?? user;
  let userCompanies = [];
  if (loggedInUser?.user_data?.parent_companies?._id) {
    const parentCompany = {
      _id: loggedInUser?.user_data?.parent_companies?._id,
      company_name: loggedInUser?.user_data?.parent_companies?.company_name,
    };
    userCompanies = [
      parentCompany,
      ...loggedInUser?.user_data?.sub_company_details,
    ];
  }
  const payload = {
    token: loggedInUser?.auth_token,
    userRole: loggedInUser?.user_data?.user_role,
    user: loggedInUser?.user_data,
    userCompanies: userCompanies,
    userCompanyId:
      loggedInUser?.user_data?.user_role === `admin`
        ? null
        : loggedInUser?.user_data?.parent_companies?._id,
    userCompanyName:
      loggedInUser?.user_data?.user_role === `admin`
        ? null
        : loggedInUser?.user_data?.parent_companies?.company_name,
  };
  loggedInUser
    ? store.dispatch(refreshState(payload))
    : store.dispatch(refreshState({ userRole: `none` }));
};
const getLanguage = async () => {
  const language = await get(`language`);
  isEmpty(language) && (await set(`language`, `en`));
  store.dispatch(setLanguage(language ?? `en`));
};

getUserDetails();
getLanguage();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
