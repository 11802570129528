import React from 'react';
import { useTranslation } from 'react-i18next';
import { createAddress } from '../../Utils/utils';
import Button from '../../ReusableComponents/Button';

const ReportInformation = ({
  clientAddress,
  address,
  onEditClient,
  isClientDetailsFetching,
}) => {
  const { t } = useTranslation();

  return (
    <div className='report_information_wrapper'>
      <div className='report_section'>
        <div className='title'>{`${t(`subject_name`)}: `}</div>
        <div className='value'>{clientAddress?.subjectName}</div>
      </div>
      <div className='report_section'>
        <div className='title'>{`${t(`address`)}: `}</div>
        <div className='value'>
          {createAddress(
            clientAddress?.address?.civicNumber,
            clientAddress?.address?.city,
            clientAddress?.address?.street,
            clientAddress?.address?.suite,
            clientAddress?.address?.province,
            clientAddress?.address?.postalCode
          )}
        </div>
      </div>
      {address?.isMissing && (
        <div className='missing_address_wrapper'>
          <div className='missing_address_section_left'>
            <div className='description'>{`${t(`missing_order_report`)}`}</div>
            <div className='missing_info'>
              <div className='title'>{`${t(`missing_information`)}: `}</div>
              <div className='value'>
                {address?.missingFields?.map((field, index) => (
                  <div className='missing_field' key={index}>
                    {`${t(`${field}`)}`}
                    {index === address?.missingFields?.length - 1 ? `.` : `, `}
                  </div>
                ))}
              </div>
            </div>
            <div className='missing_text'>
              <span>{`${t(`click`)} `}</span>
              <span>{`${t(`edit_details`)} `}</span>
              <span>{`${t(`missing_text`)}`}</span>
            </div>
          </div>
          <div className='missing_address_section_right'>
            <Button
              type='seven'
              text={`${t(`edit_details`)}`}
              onClickHandler={onEditClient}
              isLoading={isClientDetailsFetching}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportInformation;
