import React from 'react';

const Pagination = ({
  total,
  limit,
  selectedPageNumber,
  setSelectedPageNumber,
  setListFilters,
}) => {
  const onPageChange = (page) => {
    setSelectedPageNumber(page);
    setListFilters((filters) => ({ ...filters, skip: limit * (page - 1) }));
  };

  const pageCreator = (pages) => {
    return pages?.map((page) => (
      <div
        className={`page_wrapper ${
          selectedPageNumber === page ? `selected_page` : null
        }`}
        key={page}
        onClick={() => onPageChange(page)}
      >
        {page}
      </div>
    ));
  };

  const pageRenderer = () => {
    const dots = <div className='page_dots'>...</div>;
    const pages = total > limit ? Math.ceil(total / limit) : 1;
    const pageNumbers = Array.from({ length: pages })?.map(
      (_, index) => index + 1
    );
    const pagination = [];
    if (pages <= 10) {
      pagination.push(pageCreator(pageNumbers));
      return pagination;
    }
    if (pages > 10) {
      if (selectedPageNumber >= 1 && selectedPageNumber < 10) {
        pagination.push(pageCreator(pageNumbers?.slice(0, 10)));
        pagination.push(dots);
        pagination.push(
          pageCreator(
            pageNumbers?.slice(
              pageNumbers[pageNumbers?.length - 2],
              pageNumbers?.length
            )
          )
        );
        return pagination;
      }
      if (
        selectedPageNumber >= 10 &&
        selectedPageNumber < pageNumbers?.length
      ) {
        pagination.push(pageCreator(pageNumbers?.slice(0, 1)));
        pagination.push(dots);
        pagination.push(
          pageCreator(
            pageNumbers?.slice(selectedPageNumber - 3, selectedPageNumber + 10)
          )
        );

        if (
          !pageNumbers
            ?.slice(selectedPageNumber - 3, selectedPageNumber + 10)
            ?.includes(pageNumbers?.length)
        ) {
          pagination.push(dots);
          pagination.push(
            pageCreator(
              pageNumbers?.slice(
                pageNumbers[pageNumbers?.length - 2],
                pageNumbers?.length
              )
            )
          );
        }
        return pagination;
      }
      if (selectedPageNumber === pageNumbers?.length) {
        pagination.push(pageCreator(pageNumbers?.slice(0, 1)));
        pagination.push(dots);
        pagination.push(
          pageCreator(
            pageNumbers?.slice(selectedPageNumber - 8, selectedPageNumber + 8)
          )
        );
        return pagination;
      }
    }
  };

  return (
    <div className='pagination_wrapper'>
      <div className='pagination_content_wrapper'>{pageRenderer()}</div>
    </div>
  );
};

export default React.memo(Pagination);
