import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Utils/AxiosInstance";

const initialState = {
  isLoading: false,
  creditApplications: [],
  applicationCount: 0,
  clients: [],
  isConfigurationFetching: false,
  isConfigurationfetched: false,
  configurations: [],
  isApplicationSending: false,
  isApplicationSent: false,
  isApplicationSendError: false,
  apiMessage: "",
  isReportOrdering: false,
  isReportOrdered: false,
  isReportOrderError: false,
  isReportOptionsFetching: false,
  isReportOptionsFetched: false,
  isReportOptionsError: false,
  reportOptions: "",
  isEmailSending: false,
  isEmailSent: false,
  isEmailSendError: false,
  isCreditDetailsFetching: false,
  isCreditDetailsFetched: false,
  creditDetails: "",
};

export const getCreditApplications = createAsyncThunk(
  `credit_applications`,
  async (payload) => {
    try {
      const res = await api.post(`credit-application/list`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getAllCompaniesList = createAsyncThunk(
  `credit_application_client_list`,
  async (payload) => {
    try {
      const res = await api.post(`company/list-companies`, payload);
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const getConfigurations = createAsyncThunk(
  `credit_application_configuration_list`,
  async (payload) => {
    try {
      const res = await api.post(
        `credit-application/list-configurations`,
        payload
      );
      const { data } = res?.data;
      return data;
    } catch (error) {}
  }
);

export const sendCreditApplicationForm = createAsyncThunk(
  `credit_application_send_application_form`,
  async (payload) => {
    try {
      const res = await api.post(`credit-application/send-form`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {}
  }
);

export const sendCreditApplicationFile = createAsyncThunk(
  `credit_application_send_application_file`,
  async (payload) => {
    try {
      const res = await api.post(`credit-application/send-bulk-form`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const orderReportViaCreditApplication = createAsyncThunk(
  `credit_application_order_report`,
  async (payload) => {
    try {
      const res = await api.post(`credit-application/order_report`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getReportOptions = createAsyncThunk(
  `credit_application_consumer_order_report_options`,
  async (payload) => {
    try {
      const res = await api.get(
        `credit-application/available-reports-for-order/${payload}`
      );
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const resendEmail = createAsyncThunk(
  `credit_application_resend_email`,
  async (payload) => {
    try {
      const res = await api.post(`credit-application/resend-form`, payload);
      const { message } = res?.data;
      return message;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

export const getCreditDetails = createAsyncThunk(
  `credit_application_details`,
  async (payload) => {
    try {
      const res = await api.get(`credit-application/${payload}`);
      const { data } = res?.data;
      return data;
    } catch (error) {
      const { message } = error?.response?.data;
      throw new Error(`${message}`);
    }
  }
);

const creditApplicationSlice = createSlice({
  name: `creditApplication`,
  initialState,
  reducers: {
    resetCreditApplicationStore: (state) => {
      state.apiMessage = "";
      state.isApplicationSendError = false;
      state.isApplicationSent = false;
      state.isReportOrderError = false;
      state.isReportOrdered = false;
      state.isEmailSent = false;
      state.isEmailSendError = false;
      state.isCreditDetailsFetched = false;
      state.isReportOptionsError = false;
    },
  },
  extraReducers: {
    [getCreditApplications.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreditApplications.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.creditApplications = action?.payload?.credit_applications;
      state.applicationCount = action?.payload?.total_items_count;
    },
    [getAllCompaniesList.pending]: (state) => {
      state.clients = [];
    },
    [getAllCompaniesList.fulfilled]: (state, action) => {
      state.clients = action?.payload;
    },
    [getConfigurations.pending]: (state) => {
      state.configurations = [];
      state.isConfigurationFetching = true;
      state.isConfigurationfetched = false;
    },
    [getConfigurations.fulfilled]: (state, action) => {
      state.configurations = action?.payload;
      state.isConfigurationFetching = false;
      state.isConfigurationfetched = true;
    },
    [sendCreditApplicationForm.pending]: (state) => {
      state.isApplicationSending = true;
      state.isApplicationSent = false;
      state.isApplicationSendError = false;
      state.apiMessage = "";
    },
    [sendCreditApplicationForm.fulfilled]: (state, action) => {
      state.isApplicationSending = false;
      state.isApplicationSent = true;
      state.isApplicationSendError = false;
      state.apiMessage = action?.payload;
    },
    [sendCreditApplicationForm.rejected]: (state) => {
      state.isApplicationSending = false;
      state.isApplicationSent = false;
      state.isApplicationSendError = true;
    },
    [sendCreditApplicationFile.pending]: (state) => {
      state.isApplicationSending = true;
      state.isApplicationSent = false;
      state.isApplicationSendError = false;
      state.apiMessage = "";
    },
    [sendCreditApplicationFile.fulfilled]: (state, action) => {
      state.isApplicationSending = false;
      state.isApplicationSent = true;
      state.isApplicationSendError = false;
      state.apiMessage = action?.payload;
    },
    [sendCreditApplicationFile.rejected]: (state, action) => {
      state.isApplicationSending = false;
      state.isApplicationSent = false;
      state.isApplicationSendError = true;
      state.apiMessage = action?.error?.message;
    },
    [orderReportViaCreditApplication.pending]: (state) => {
      state.isReportOrdering = true;
      state.isReportOrdered = false;
      state.isReportOrderError = false;
    },
    [orderReportViaCreditApplication.fulfilled]: (state, action) => {
      state.isReportOrdering = false;
      state.isReportOrdered = true;
      state.isReportOrderError = false;
      state.apiMessage = action?.payload;
    },
    [orderReportViaCreditApplication.rejected]: (state, action) => {
      state.isReportOrdering = false;
      state.isReportOrdered = false;
      state.isReportOrderError = true;
      state.apiMessage = action?.error?.message;
    },
    [getReportOptions.pending]: (state) => {
      state.isReportOptionsFetching = true;
      state.isReportOptionsFetched = false;
      state.reportOptions = "";
    },
    [getReportOptions.fulfilled]: (state, action) => {
      state.isReportOptionsFetching = false;
      state.isReportOptionsFetched = true;
      state.reportOptions = action?.payload;
    },
    [getReportOptions.rejected]: (state, action) => {
      state.isReportOptionsFetching = false;
      state.isReportOptionsFetched = false;
      state.isReportOptionsError = true;
      state.apiMessage = action?.error?.message;
    },
    [resendEmail.pending]: (state) => {
      state.isEmailSending = true;
      state.isEmailSent = false;
      state.isEmailSendError = false;
    },
    [resendEmail.fulfilled]: (state, action) => {
      state.isEmailSending = false;
      state.isEmailSent = true;
      state.isEmailSendError = false;
      state.apiMessage = action?.payload;
    },
    [getCreditDetails.pending]: (state) => {
      state.isCreditDetailsFetching = true;
      state.isCreditDetailsFetched = false;
    },
    [getCreditDetails.fulfilled]: (state, action) => {
      state.isCreditDetailsFetching = false;
      state.isCreditDetailsFetched = true;
      state.creditDetails = action?.payload;
    },
  },
});
export const { resetCreditApplicationStore } = creditApplicationSlice.actions;

export default creditApplicationSlice.reducer;
