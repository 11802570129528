import React from 'react';

const InformationCard = ({ title, value }) => {
  return (
    <div className='information_content'>
      <div className='information_label'>{title}</div>
      <div className='information_value'>{value || `-`}</div>
    </div>
  );
};

export default InformationCard;
