import React, { useEffect, useRef, useState } from 'react';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';
import { isEmpty } from '../Utils/utils';

const Select = ({
  text,
  value,
  options,
  onOptionSelection,
  isDisabled,
  isClosed,
  isShowValue,
  isError,
  isMandatory,
  isRecommended,
  isProvince,
  isAutoComplete,
  message,
  setSearchTerm,
  isLoading,
  isShowSearch,
  isUsa,
  onBlur,
  isOptionsFetched,
  from,
  onManualSelection,
  errorMessage,
  onOptionCallback,
  onRemoveValue,
  isFromHealthMonitoring,
  isCanada,
}) => {
  const inputRef = useRef();
  const [optionValue, setOptionValue] = useState('');
  const [optionName, setOptionName] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [cursorIndex, setCursorIndex] = useState(0);
  const [isKeyPressed, setIsKeyPressed] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isShowSearch && isEmpty(search) && !isEmpty(value) && !isInputFocused) {
      onRemoveValue();
    }
  }, [search, isShowSearch, value, onRemoveValue, isInputFocused]);

  useEffect(() => {
    if (options?.length > 0 && isShowValue && value) {
      const option = options?.find((option) => {
        return option.value === value;
      });
      setOptionValue(option?.value);
      setOptionName(option?.name);
    }
  }, [value, options, isShowValue]);

  useEffect(() => {
    if (isKeyPressed) {
      const option = options.find((option) => {
        return option.index === cursorIndex - 1;
      });
      if (option && isEnterPressed) {
        setOptionValue(option.value);
        setOptionName(option.name);
      }
    }
  }, [cursorIndex, options, isKeyPressed, isEnterPressed]);

  useEffect(() => {
    if (isClosed) {
      setOptionName('');
      setOptionValue('');
    }
  }, [isClosed]);

  // province
  useEffect(() => {
    if (isProvince && options?.length > 0) {
      const optionsForCanada = [{ value: '', name: 'Canada' }];
      const canadaOptions = options
        ?.filter((option) => option?.country === `Canada`)
        ?.sort((a, b) => {
          const nameA = a?.name?.toLowerCase();
          const nameB = b?.name?.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      const allCanadaOptions = [...optionsForCanada, ...canadaOptions];
      const optionsForUSA = [{ value: '', name: 'USA' }];
      const usaOptions = options
        ?.filter((option) => option?.country === `USA`)
        ?.sort((a, b) => {
          const nameA = a?.name?.toLowerCase();
          const nameB = b?.name?.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      const allUSAOptions = [...optionsForUSA, ...usaOptions];
      const updatedOptions = isUsa
        ? [...allUSAOptions]
        : isCanada
        ? [...allCanadaOptions]
        : [...allCanadaOptions, ...allUSAOptions];
      setProvinceOptions(updatedOptions);
    }
  }, [isProvince, isUsa, isCanada, options]);

  const onOptionValueSelection = (option) => {
    setOptionValue(option.value);
    setOptionName(option.name);
    onOptionSelection(option.value);
    onOptionCallback(isFromHealthMonitoring ? option.country : option.value);
    isShowSearch && setSearch(option.value);
  };

  const onFocus = () => {
    setIsInputFocused(true);
    setIsEnterPressed(false);
  };

  const onBlurSelect = () => {
    setTimeout(() => {
      setIsInputFocused(false);
      onBlur();
    }, 180);
  };

  const onKeyDown = (e) => {
    setIsKeyPressed(true);
    if (e.keyCode === 38 && cursorIndex > 1) {
      setCursorIndex((cursorIndex) => (cursorIndex > 0 ? cursorIndex - 1 : 0));
    }
    if (e.keyCode === 40 && cursorIndex < options.length) {
      setCursorIndex((cursorIndex) => cursorIndex + 1);
    }
    if (e.keyCode === 13) {
      setIsInputFocused(false);
      inputRef.current.blur();
      setIsEnterPressed(true);
    }
  };

  const onInputChange = (e) => {
    e.preventDefault();
    isAutoComplete && setOptionName(e.target.value);
    isAutoComplete && setSearchTerm(e.target.value);
    isShowSearch && setOptionName(e.target.value);
    isShowSearch && setSearch(e.target.value);
  };

  return (
    <div className='select_container'>
      <div className='select_wrapper'>
        <input
          placeholder=' '
          className={`custom_select ${
            isInputFocused || optionValue ? `select_focused` : ``
          } ${
            isError ? `select_error` : isDisabled ? `select_disabled` : null
          }`}
          ref={inputRef}
          value={optionName}
          onFocus={onFocus}
          onBlur={onBlurSelect}
          onKeyDown={onKeyDown}
          onChange={onInputChange}
          disabled={isDisabled}
        />
        <label
          className={`select_label ${isDisabled ? `select_disabled` : null}`}
        >
          {text}{' '}
          <span
            className={`${
              isMandatory ? `mandatory` : isRecommended ? `recommended` : null
            }`}
          >
            {isMandatory ? `*mandatory` : isRecommended ? `*recommended` : null}
          </span>
        </label>
        <fieldset className='fieldset_wrapper'>
          <legend className='legend_wrapper'>
            {text}{' '}
            <span
              className={`${
                isMandatory ? `mandatory` : isRecommended ? `recommended` : null
              }`}
            >
              {isMandatory
                ? `*mandatory`
                : isRecommended
                ? `*recommended`
                : null}
            </span>
          </legend>
        </fieldset>
      </div>

      <span className={`select_icon ${isDisabled ? `select_disabled` : null}`}>
        {isInputFocused ? <AiFillCaretUp /> : <AiFillCaretDown />}
      </span>
      <div
        className={`select_options_wrapper ${
          isInputFocused ? `showOptions` : ``
        } `}
      >
        {isProvince ? (
          <React.Fragment>
            {provinceOptions.map((option, index) => {
              return (
                <div
                  className={`select_options option_${option?.name} ${
                    cursorIndex - 1 === option.index ? `highlighted` : ``
                  }`}
                  key={index}
                  onClick={() => onOptionValueSelection(option)}
                >
                  {option.name}
                </div>
              );
            })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {from !== `bank_details` &&
              isAutoComplete &&
              options?.length === 0 && (
                <>
                  {isLoading ? (
                    <div className='internal_loader'></div>
                  ) : (
                    <span className='select_message'>{message}</span>
                  )}
                </>
              )}
            {isShowSearch &&
              options?.filter((option) =>
                option?.name?.split('')?.join('')?.includes(optionName)
              )?.length === 0 && (
                <span className='select_message'>{message}</span>
              )}
            {(isShowSearch
              ? options?.filter((option) =>
                  option?.name?.split('')?.join('')?.includes(optionName)
                )
              : options
            ).map((option, index) => {
              return (
                <div
                  className={`select_options option_${option?.value} ${
                    cursorIndex - 1 === option.index ? `highlighted` : ``
                  }`}
                  key={index}
                  onClick={() => onOptionValueSelection(option)}
                >
                  {option.name}
                </div>
              );
            })}
            {from === `bank_details` &&
              isOptionsFetched &&
              options?.length === 0 &&
              optionName?.trim()?.length > 0 && (
                <div
                  className='select_options no_result'
                  onClick={() => onManualSelection(optionName)}
                >
                  {message}
                </div>
              )}
          </React.Fragment>
        )}
      </div>
      <div className='select_error_message'>
        <span>{errorMessage}</span>
      </div>
    </div>
  );
};

export default Select;
