import Button from "../../ReusableComponents/Button";

export const subjectListMinifiedRenderer = (
  entity,
  header,
  action,
  entityIndex,
  rowIndex,
  isButtonOneLoading,
  translations
) => {
  if (header === `subjects`) {
    return <span>{entity?.subject_name}</span>;
  }
  if (header === `actions`) {
    return (
      <Button
        type="primary"
        text={translations?.download_individual_report}
        onClickHandler={() => action(entity, entityIndex)}
        isLoading={isButtonOneLoading && entityIndex === rowIndex}
      />
    );
  }
};
