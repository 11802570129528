import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { localeSwitcherPlugin } from '@react-pdf-viewer/locale-switcher';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { get } from 'idb-keyval';
import { useDispatch, useSelector } from 'react-redux';
import { downloadEnglishFile, downloadFrenchFile } from '../slices/commonSlice';

const PdfViewer = ({ isEN }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isEnglishFileDownloading,
    isEnglishFileDownloaded,
    englishDownloadedFile,
    isFrenchFileDownloading,
    isFrenchFileDownloaded,
    frenchDonwloadedFile,
  } = useSelector((state) => state.common);

  const [englishFile, setEnglishFile] = useState('');
  const [frenchFile, setFrenchFile] = useState('');

  useEffect(() => {
    if (isEnglishFileDownloaded && englishDownloadedFile) {
      setEnglishFile(englishDownloadedFile);
    }
  }, [isEnglishFileDownloaded, englishDownloadedFile]);

  useEffect(() => {
    if (isFrenchFileDownloaded && frenchDonwloadedFile) {
      setFrenchFile(frenchDonwloadedFile);
    }
  }, [isFrenchFileDownloaded, frenchDonwloadedFile]);

  const getFileResponse = async (pdfPayload) => {
    const enPayload = {
      ...pdfPayload,
      payload: { ...pdfPayload.payload, language: 'en' },
    };
    const frPayload = {
      ...pdfPayload,
      payload: { ...pdfPayload.payload, language: 'fr' },
    };

    dispatch(downloadEnglishFile(enPayload));
    dispatch(downloadFrenchFile(frPayload));
  };

  const getPdfPayload = async () => {
    const pdfPayload = await get(`pdfPayload`);
    pdfPayload && (await getFileResponse(pdfPayload));
  };

  useEffect(() => {
    getPdfPayload();
  }, []);

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file) => {
      // `file.name` is the URL of opened file
      const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
      console.log('🚀 ~ PdfViewer ~ fileName:', fileName);
      // return pdfname;
    },
  });
  const { DownloadButton } = getFilePluginInstance;
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <div style={{ padding: '0px 2px' }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToPreviousPage />
            </div>
            <div
              style={{
                padding: '0px 2px',
                width: '4rem',
              }}
            >
              <CurrentPageInput />
            </div>
            <div style={{ padding: '0px 2px' }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </div>
            {/* <div
              style={{
                padding: '0px 2px',
                marginLeft: 'auto',
              }}
            >
              <span
                style={{ cursor: 'pointer' }}
                className='language_name'
                // onClick={() => onLanguageSwitch(isEN ? `en` : `fr`)}
              >
                {isEN ? `EN` : `FR`}
              </span>
            </div> */}

            <div style={{ padding: '0px 2px' }}>
              <DownloadButton />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Print />
            </div>

            {/* <div style={{ padding: '0px 2px' }}>
              {locale == 'en_US' ? (
                <button
                  style={{ border: 'none' }}
                  onClick={() => switchpdf('fr')}
                >
                  Français{' '}
                </button>
              ) : null}
              {locale == 'fr_FR' ? (
                <button
                  style={{ border: 'none' }}
                  onClick={() => switchpdf('en')}
                >
                  English{' '}
                </button>
              ) : null}
            </div> */}
            {/* <div style={{ display: 'none' }}>
              <LocalePopover
                initialLocale={locale}
                locales={{
                  en_US: 'English',
                  fr_FR: 'French',
                }}
                localizations={localizations}
                onUpdateLocalization={switchToLocalization}
              />
            </div> */}
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
  });
  const localeSwitcherPluginInstance = localeSwitcherPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const onLanguageChange = () => {
    debugger;
  };

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
    >
      {isEnglishFileDownloading && `loading....`}
      {isEnglishFileDownloaded && (
        <Viewer
          fileUrl={isEN ? englishFile : frenchFile}
          plugins={[
            localeSwitcherPluginInstance,
            toolbarPluginInstance,
            defaultLayoutPluginInstance,
            getFilePluginInstance,
          ]}
        />
      )}
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            padding: '4px',
          }}
        >
          <Toolbar>
            {(props) => {
              const {
                CurrentPageInput,
                CurrentScale,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  {/* <div style={{ padding: '0px 2px' }}>
                    <ZoomOut>
                      {(props) => (
                        <button
                          style={{
                            backgroundColor: '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '8px',
                          }}
                          onClick={props.onClick}
                        >
                          Zoom out
                        </button>
                      )}
                    </ZoomOut>
                  </div> */}
                  {/* <div style={{ padding: '0px 2px' }}>
                    <CurrentScale>
                      {(props) => (
                        <span>{`${Math.round(props.scale * 100)}%`}</span>
                      )}
                    </CurrentScale>
                  </div> */}
                  {/* <div style={{ padding: '0px 2px' }}>
                    <ZoomIn>
                      {(props) => (
                        <button
                          style={{
                            backgroundColor: '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '8px',
                          }}
                          onClick={props.onClick}
                        >
                          Zoom in
                        </button>
                      )}
                    </ZoomIn>
                  </div> */}
                  {/* <div style={{ padding: '0px 2px' }}>
                    <GoToPreviousPage>
                    <button
                        style={{
                          backgroundColor: props.isDisabled
                            ? '#96ccff'
                            : '#357edd',
                          border: 'none',
                          borderRadius: '4px',
                          color: '#ffffff',
                          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                          padding: '8px',
                        }}
                        disabled={props.isDisabled}
                        onClick={prevPage}
                      >
                        Previous page
                      </button>
                    </GoToPreviousPage>
                  </div> */}
                  <div
                    style={{ padding: '0px 2px' }}
                    className='pdf_viewer_action'
                  >
                    <span
                      className='language_name'
                      // onClick={() => onLanguageSwitch(isEN ? `en` : `fr`)}
                    >
                      {isEN ? `EN` : `FR`}
                    </span>
                    {/* <Button
                      type='primary'
                      text={`${isEN ? t(`FR`) : t(`EN`)} `}
                      onClickHandler={onLanguageChange}
                    /> */}
                  </div>
                  {/* <div style={{ padding: '0px 2px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    / <NumberOfPages />
                  </div> */}
                  {/* <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage>
                      {(props) => (
                        <button
                          style={{
                            backgroundColor: props.isDisabled
                              ? '#96ccff'
                              : '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: props.isDisabled
                              ? 'not-allowed'
                              : 'pointer',
                            padding: '8px',
                          }}
                          disabled={props.isDisabled}
                          onClick={nextPage}
                        >
                          Next page
                        </button>
                      )}
                    </GoToNextPage>
                  </div> */}
                </>
              );
            }}
          </Toolbar>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {/* <Viewer
            // fileUrl={demoFile}
            plugins={[toolbarPluginInstance]}
            onPageChange={onPageChange}
          /> */}
        </div>
      </div>
    </Worker>
  );
};

export default PdfViewer;
