import React, { useEffect, useRef, useState } from 'react';
import SearchFilter from '../../ReusableComponents/SearchFilter';
import SelectFilter from '../../ReusableComponents/SelectFilter';
import Button from '../../ReusableComponents/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteMonitoringClient,
  getClientAddress,
  refreshData,
  updateColumns,
} from '../../slices/creditRiskSlice';
import { camelToSnake } from '../../Utils/utils';
import SideDrawer from '../../ReusableComponents/SideDrawer';
import ArrangeColumns from './ArrangeColumns';
import { useNavigate } from 'react-router-dom';
import DeletePopup from '../../ReusableComponents/DeletePopup';
import Table from '../../ReusableComponents/Table';
import ListActions from './ListActions';
import { set, get } from 'idb-keyval';
import DiscoverInformation from './DiscoverInformation';
import Skeleton from '../../ReusableComponents/Skeleton';
import CreditRiskOrderReport from './CreditRiskOrderReport';

const ClientList = ({
  companyId,
  isRefetchClientList,
  onGetClientInformation,
  isLoading,
  isRiskFilter,
  onRemoveRiskFilter,
  isRiskDiscovered,
  riskType,
  isClientDetailsFetched,
  clientDetails,
  isSummaryFetching,
  setIsRiskFilter,
  setRiskType,
  isClientDetailsFetching,
  isRefreshing,
  isAgingFileUploaded,
  isOverLimit,
  isTypeDiscovered,
  onFilterChange,
  isTableLoading,
  setIsTableLoading,
  listFilters,
  setListFilters,
  selectedPageNumber,
  setSelectedPageNumber,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportRef = useRef();

  const riskOptions = [
    { name: `${t(`high`)}`, value: `High` },
    { name: `${t(`medium`)}`, value: `Medium` },
    { name: `${t(`low`)}`, value: `Low` },
    { name: `${t(`minimal`)}`, value: `Minimal` },
  ];

  const {
    isColumnsUpdating,
    isClientDeleting,
    summary,
    isClientDetailsFetchError,
    isClientAddressFetching,
    isClientAddressFetched,
    clientAddress,
  } = useSelector((state) => state.creditRisk);

  const [rowIndex, setRowIndex] = useState(null);
  const [isArrangeColumns, setIsArrangeColumns] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [clientIds, setClientIds] = useState([]);
  const [isDeleteClient, setIsDeleteClient] = useState(false);
  const [isExpandedView, setIsExpandedView] = useState(false);

  const [tableProps, setTableProps] = useState([]);
  const [sortingIndex, setSortingIndex] = useState(2);
  const [isOrderReport, setIsOrderReport] = useState(false);
  const [clientId, setClientId] = useState('');
  const [isDiscover, setIsDiscover] = useState(false);
  const [isListLoading, setIsListLoading] = useState(true);
  const [clientName, setClientName] = useState('');
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [discoveredEntity, setDiscoveredEntity] = useState({
    type: '',
    id: '',
  });
  const [toolTip, setToolTip] = useState({
    isVisible: false,
    text: '',
    type: '',
    entity: '',
    entityIndex: '',
  });
  const [isOrderReportFromClientList, setIsOrderReportFromClientList] =
    useState(false);

  useEffect(() => {
    if (isRefetchClientList) {
      setIsDiscover(false);
      setIsArrangeColumns(false);
      setVisibleColumns([]);
      setIsDeleteClient(false);
      setClientIds([]);
      setIsExpandedView(false);
      setRowIndex(null);
      setIsRefreshData(false);
    }
  }, [isRefetchClientList]);

  useEffect(() => {
    if (isRiskDiscovered && riskType) {
      setListFilters((prev) => ({
        ...prev,
        riskType: [riskType],
        status: '',
      }));
    }
  }, [isRiskDiscovered, setListFilters, riskType]);

  useEffect(() => {
    if (isClientDetailsFetched) {
      setIsListLoading(false);
      const { columns } = clientDetails;
      const tableProps = columns
        ?.filter((column) => column?.visible)
        ?.map((column) => ({
          name: `${t(camelToSnake(column?.field))}`,
          dataIndex: column?.refField,
          minimumWidth: '12rem',
          initialWidth: [`subjectName`]?.includes(column?.field)
            ? '25rem'
            : [`risk`, `score`, `totalDue`]?.includes(column?.field)
            ? '10rem'
            : [
                `allianceId`,
                `creditLimit`,
                `longestDuePeriod`,
                `lastUpdatedOn`,
                `longestDuePeriod`,
                `averageTimeToPay`,
              ]?.includes(column?.field)
            ? '16rem'
            : '12rem',
          isSortable: [
            `accountNumber`,
            `legalName`,
            `risk`,
            `score`,
            `clientName`,
            `lastUpdateDate`,
            `allianceMasterId`,
          ]?.includes(column?.refField),
          isEditable: column?.editable,
          isResizable: [
            `subjectName`,
            `legalName`,
            `averageTimeToPay`,
          ]?.includes(column?.field)
            ? true
            : false,
        }));
      tableProps.push({
        name: `${t(`actions`)}`,
        dataIndex: 'actions',
        minimumWidth: '25rem',
        initialWidth: '1fr',
        isSortable: false,
        isResizable: false,
        isEditable: true,
      });
      setTableProps(tableProps);
    }
  }, [isClientDetailsFetched, t, clientDetails]);

  useEffect(() => {
    setIsListLoading(isSummaryFetching);
  }, [isSummaryFetching]);

  useEffect(() => {
    if (isClientDetailsFetchError) {
      setIsTableLoading(false);
    }
  }, [isClientDetailsFetchError]);

  useEffect(() => {
    if (isClientAddressFetched) {
      setIsOrderReport(true);
    }
  }, [isClientAddressFetched]);

  useEffect(() => {
    setIsTableLoading(true);
  }, [selectedPageNumber]);

  useEffect(() => {
    if (isTypeDiscovered) {
      setIsDiscover(false);
      const getData = async () => {
        const data = await get(`discoverPayload`);
        const clientId = data?.clientIds?.[0];

        if (data?.isIndividual) {
          setDiscoveredEntity((prev) => ({
            ...prev,
            type: data?.isLegalName
              ? `legalName`
              : data?.isRisk
              ? `risk`
              : `score`,
            id: clientId,
          }));
        }
      };
      getData();
    }
  }, [isTypeDiscovered]);

  useEffect(() => {
    if (discoveredEntity?.id) {
      setTimeout(() => {
        setDiscoveredEntity((prev) => ({ ...prev, type: '', id: '' }));
      }, 6000);
    }
  }, [discoveredEntity]);

  const onOverLimitClients = (isChecked, value) => {
    setIsTableLoading(true);
    setListFilters({ ...listFilters, status: isChecked ? value : '' });
  };

  const onArrangeColumnsHandler = () => {
    setIsArrangeColumns((prev) => !prev);
    setVisibleColumns(clientDetails?.columns);
  };

  const onArrangeColumns = () => {
    const columns = visibleColumns?.map((column, index) => ({
      field: column?.field,
      order: index + 1,
      visible: column?.visible,
    }));
    setIsTableLoading(true);
    dispatch(updateColumns({ fields: columns, companyId }));
  };

  const onHistoricalData = () => {
    companyId &&
      navigate(`/creditrisk-management/historical-data/${companyId}`);
  };

  const onCancelDeleteClient = () => {
    setIsDeleteClient(false);
    setClientIds([]);
  };

  const onDeleteClientHandler = (client) => {
    setClientIds((prev) => [...prev, client?.id]);
    setIsDeleteClient(true);
    setClientName(client?.clientName);
  };

  const onDeleteClient = () => {
    clientIds?.length &&
      dispatch(deleteMonitoringClient({ clients: clientIds, companyId }));
  };

  const onDiscoverClients = async (riskType, isPurchasable) => {
    if (isPurchasable) {
      const payload = {
        companyId,
        headerText: `discover_${riskType?.toLowerCase()}_risk`,
        content: `health_risk_${riskType?.toLowerCase()}`,
        riskType,
        isIndividual: false,
      };
      await set(`discoverPayload`, payload);
      setIsDiscover(true);
    } else if (riskType === `not_found`) {
      setIsTableLoading(true);
      setIsRiskFilter(true);
      setRiskType(riskType);
      setListFilters((prev) => ({
        ...prev,
        riskType: [],
        status: 'Not Found',
      }));
    } else {
      setListFilters((prev) => ({
        ...prev,
        status: '',
        riskType: [riskType],
      }));
      setIsTableLoading(true);
      setIsRiskFilter(true);
      setRiskType(riskType);
    }
  };

  const onMoreDetails = (clientId) => {
    clientId &&
      navigate(`/creditrisk-management/client-details/${clientId}`, {
        state: { companyId },
      });
  };

  const onDiscoverHandler = async (type, client) => {
    const payload = {
      informationType: type === `LegalName` ? `CorporateDetails` : type,
      clientIds: [client?.id],
      headerText: `discover_${camelToSnake(type)}`,
      content: `discover_${camelToSnake(type)}_description`,
      name: client?.clientName,
      companyId,
      isIndividual: true,
      isScore: type === `Score`,
      isLegalName: type === `LegalName`,
      isRisk: type === `Risk`,
    };
    await set(`discoverPayload`, payload);
    setIsDiscover(true);
  };

  const onShowClientDetails = (clientIndex) => {
    setRowIndex(clientIndex === rowIndex ? '' : clientIndex);
    setIsExpandedView(true);
  };

  const onEditClient = (entityIndex, clientId) => {
    onGetClientInformation(clientId);
    setRowIndex(entityIndex);
    setIsExpandedView(false);
  };

  const onEditClientFromReport = () => {
    onGetClientInformation(clientId);
    setIsOrderReportFromClientList(false);
  };

  const onRemoveFilter = () => {
    onRemoveRiskFilter();
    setIsTableLoading(true);
  };

  const onSorting = (index, header) => {
    const sortKey = header === `lastUpdateDate` ? `lastUpdatedOn` : header;
    setSortingIndex(index);
    setIsTableLoading(true);
    setListFilters((filters) => ({
      ...filters,
      sortBy: sortKey,
      sort: filters?.sortBy === sortKey ? (filters?.sort === 1 ? -1 : 1) : -1,
    }));
  };

  const onOrderReport = () => {
    reportRef?.current?.onReportValidation();
  };

  const onOrderReportHandler = (entityIndex, clientId) => {
    setRowIndex(entityIndex);
    setIsExpandedView(false);
    clientId && dispatch(getClientAddress(clientId));
    setClientId(clientId);
    setIsOrderReportFromClientList(true);
  };

  const onCancelDiscover = () => {
    setIsDiscover(false);
    set(`discoverPayload`, null);
  };

  const onRefreshHandler = () => {
    setIsRefreshData((prev) => !prev);
  };

  const onRefresh = () => {
    dispatch(refreshData({ companyId }));
  };

  const onHover = (entity, type, entityIndex) => {
    setToolTip((toolTip) => ({
      ...toolTip,
      isVisible: true,
      entity,
      type,
      entityIndex,
      text: `${entity}_${type?.toLowerCase()}`,
    }));
  };

  const onBlur = () => {
    setToolTip((toolTip) => ({
      ...toolTip,
      isVisible: false,
      text: '',
      type: '',
      entity: '',
      entityIndex: '',
    }));
  };

  return (
    <>
      <div className='search_filters_actions_wrapper credit_risk_client_filters'>
        {!isAgingFileUploaded && isListLoading ? (
          <div className='credit_risk_filters_loading'>
            <Skeleton />
          </div>
        ) : (
          <div className='search_filters admin_credit_report_filters'>
            <SearchFilter
              placeholder={`${t(`search_clients`)}`}
              listFilters={listFilters}
              onFilterChange={onFilterChange}
              filterName={`searchQuery`}
              isReset={false}
            />

            {!isRiskFilter && (
              <SelectFilter
                placeholder={`${t(`risk`)}`}
                options={riskOptions}
                onFilterChange={onFilterChange}
                filterName={`riskType`}
                isOptionsObject={false}
                isReset={false}
              />
            )}
            {!isRiskFilter && isOverLimit && (
              <div className='lable_checkbox_wrapper'>
                <label>
                  <input
                    className='checkbox_wrapper'
                    type='checkbox'
                    checked={listFilters?.status === `Over Limit`}
                    onChange={(e) =>
                      onOverLimitClients(e.target.checked, `Over Limit`)
                    }
                  />
                  <span>{`${t(`show_overlimit_clients`)}`}</span>
                </label>
              </div>
            )}
          </div>
        )}
        {!isRiskFilter && !isListLoading && (
          <div className='actions_wrapper health_actions'>
            {/* <Button
              type='seven'
              text={`${t(`refresh_expired_data`)}`}
              onClickHandler={onRefreshHandler}
            /> */}
            <Button
              type='seven'
              text={`${t(`arrange_columns`)}`}
              onClickHandler={onArrangeColumnsHandler}
            />
            <Button
              type='seven'
              text={`${t(`show_historical_data`)}`}
              onClickHandler={onHistoricalData}
            />
          </div>
        )}
      </div>
      <div className='credit_risk_client_wrapper'>
        {isRiskFilter ? (
          <div className='credit_risk_client_filters'>
            <div className='action_info'>
              <Button
                type='seven'
                text={`${t(`remove_filter`)}`}
                onClickHandler={onRemoveFilter}
              />
              {isClientDetailsFetching ? (
                <div className='risk_info_loading' />
              ) : (
                <span className='action_text'>
                  {`${t(`showing_only_your`)} `}
                  {clientDetails?.documentCount}
                  {` ${t(`${riskType?.toLowerCase()}_risk_clients`)}.`}
                </span>
              )}
            </div>
            <div className='action_description'>
              <svg
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM5.0625 7.875H5.625V6.375H5.0625C4.75078 6.375 4.5 6.12422 4.5 5.8125C4.5 5.50078 4.75078 5.25 5.0625 5.25H6.1875C6.49922 5.25 6.75 5.50078 6.75 5.8125V7.875H6.9375C7.24922 7.875 7.5 8.12578 7.5 8.4375C7.5 8.74922 7.24922 9 6.9375 9H5.0625C4.75078 9 4.5 8.74922 4.5 8.4375C4.5 8.12578 4.75078 7.875 5.0625 7.875ZM6 3C6.19891 3 6.38968 3.07902 6.53033 3.21967C6.67098 3.36032 6.75 3.55109 6.75 3.75C6.75 3.94891 6.67098 4.13968 6.53033 4.28033C6.38968 4.42098 6.19891 4.5 6 4.5C5.80109 4.5 5.61032 4.42098 5.46967 4.28033C5.32902 4.13968 5.25 3.94891 5.25 3.75C5.25 3.55109 5.32902 3.36032 5.46967 3.21967C5.61032 3.07902 5.80109 3 6 3Z'
                  fill='#E2E8F0'
                />
              </svg>
              <span className='action_description_text'>
                {`${t(`view_historical_info`)}`}
              </span>
            </div>
          </div>
        ) : (
          <>
            {!isAgingFileUploaded && isListLoading ? (
              <div className='credit_risk_list_actions_loading'>
                <Skeleton />
              </div>
            ) : (
              <ListActions
                summary={summary}
                onDiscoverClients={onDiscoverClients}
                companyId={companyId}
              />
            )}
          </>
        )}
      </div>
      {!isAgingFileUploaded && isListLoading ? (
        <div className='credit_risk_clients_loading'>
          {[1, 2, 3, 4]?.map((_, index) => (
            <Skeleton index={index} />
          ))}
        </div>
      ) : (
        <Table
          tableProps={tableProps}
          defaultColumnHeight={'5rem'}
          from={`credit_risk_client_list`}
          isLoading={isTableLoading}
          tableData={isClientDetailsFetchError ? [] : clientDetails?.clients}
          action={onMoreDetails}
          action2={onDiscoverHandler}
          action3={onDeleteClientHandler}
          action4={onShowClientDetails}
          action5={onEditClient}
          action6={onOrderReportHandler}
          action7={onHover}
          action8={onBlur}
          isExpandedView={!isOrderReportFromClientList && isExpandedView}
          expandedIndex={rowIndex}
          rowIndex={rowIndex}
          isButtonOneLoading={isLoading}
          isButtonTwoLoading={isClientAddressFetching}
          onSorting={onSorting}
          sortingIndex={sortingIndex}
          isSorted={listFilters?.sort === -1}
          total={clientDetails?.documentCount}
          limit={listFilters?.limit}
          setListFilters={setListFilters}
          selectedPageNumber={selectedPageNumber}
          setSelectedPageNumber={setSelectedPageNumber}
          toolTip={toolTip}
          discoveredEntity={discoveredEntity}
        />
      )}

      <SideDrawer
        from='generic_drawer credit_risk_arrange_columns'
        isVisible={isArrangeColumns}
        heading={`${t(`arrange_columns`)}`}
        content={
          <ArrangeColumns
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        }
        footer={
          <>
            <Button
              type='quaternary'
              text={`${t(`cancel`)}`}
              onClickHandler={onArrangeColumnsHandler}
            />
            <Button
              type='primary'
              text={`${t(`update_columns`)}`}
              onClickHandler={onArrangeColumns}
              isLoading={isColumnsUpdating}
            />
          </>
        }
      />
      <DeletePopup
        from='admin_report'
        isShowPopup={isDeleteClient}
        header={`${t(`delete_client`)}`}
        content={`${t(`delete_client_content`)} ${clientName}?`}
        onCancel={onCancelDeleteClient}
        onSubmit={onDeleteClient}
        buttonText1={`${t(`cancel`)}`}
        buttonText2={`${t(`delete_client`)}`}
        isLoading={isClientDeleting}
      />
      <DeletePopup
        from='admin_report'
        isShowPopup={isRefreshData}
        header={`${t(`refresh_expired_data`)}`}
        content={`${t(`refresh_expired_data_text`)}`}
        onCancel={onRefreshHandler}
        onSubmit={onRefresh}
        buttonText1={`${t(`cancel`)}`}
        buttonText2={`${t(`refresh_expired_data`)}`}
        isLoading={isRefreshing}
      />
      <CreditRiskOrderReport
        ref={reportRef}
        onOrderReport={onOrderReport}
        isOrderReport={isOrderReportFromClientList && isOrderReport}
        clientId={clientId}
        setIsOrderReport={setIsOrderReport}
        clientAddress={clientAddress}
        isClientAddressFetched={isClientAddressFetched}
        onCancel={setIsOrderReportFromClientList}
        onEditClient={onEditClientFromReport}
        isClientDetailsFetching={isLoading}
      />
      <DiscoverInformation
        isVisible={isDiscover}
        onCancel={onCancelDiscover}
        companyId={companyId}
      />
    </>
  );
};

export default ClientList;
