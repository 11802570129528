import React from 'react';

const TableInput = ({ placeholder, setSearchTerm, searchTerm }) => {
  const inputHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className='table_field_wrapper'>
      <input
        value={searchTerm}
        placeholder={placeholder}
        className='table_input'
        onChange={inputHandler}
      />
    </div>
  );
};

export default TableInput;
